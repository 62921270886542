import { t } from 'i18next';
import InspectionTaskSummary from '../dto/components/InspectionTaskSummary';
import OfflineInspectionTaskSummary from "../dto/components/OfflineInspectionTaskSummary";
import CommitInspectionDrawingEditingRequest from '../dto/requests/CommitInspectionDrawingEditingRequest';
import AuthService from '../services/AuthService';
import FlagService from '../services/FlagService';
import FieldBrowserSupport from "../helpers/FieldBrowserSupport";
import HomePageData from '../dto/pages/HomePageData';
import * as React from 'react';


class OfflineStorageService {
    private DateCacheName = 'api-data-cache';
    private TileCacheName = 'api-tile-cache';
    private readonly FieldTasksObjectStore = 'FieldTasks';
    private readonly FieldInspectionTaskDrawingsStore = 'FieldInspectionTaskDrawings';

    public synchronizeAllInspectionTask = async(tasksSummary: OfflineInspectionTaskSummary[]): Promise<[JSX.Element[], JSX.Element[]]> => {
        let uploaded: JSX.Element[] = [];
        let notUploaded: JSX.Element[] = [];
        const appBridge = await FieldBrowserSupport.bridge();
        FlagService.addInfo(t("labelInspectionDrawing"), t("SynchronizationStarted"));
        for (let taskSummary of tasksSummary){
            let desc = `${taskSummary.task.name} - ${taskSummary.task.project.name} - ${taskSummary.task.structure.name}`;
                try {
                    if (taskSummary.dirty){
                        const request = this.getFieldInspectionDrawings(taskSummary);
                        request.force = true;
                        const url = `inspectiontask/${taskSummary.task.project.id}/save`;
                        await AuthService.put(url, request);
                        await appBridge.cleanDrawings(taskSummary.task);                
                        uploaded.push(React.createElement("li",{}, desc));
                    }
                } catch (error) {
                    notUploaded.push(React.createElement("li",{}, desc));            
                }        
            }
        await appBridge.synchronizeTask(tasksSummary.map(item => item.task));                
        return [uploaded, notUploaded];
    }

    public synchronizeInspectionTask = async(taskSummary: OfflineInspectionTaskSummary): Promise<void> => {

        const appBridge = await FieldBrowserSupport.bridge();
        FlagService.addInfo(t("labelInspectionDrawing"), t("SynchronizationStarted"));
        try {
            if (taskSummary.dirty){
            const request = this.getFieldInspectionDrawings(taskSummary);
                request.force = true;
                const url = `inspectiontask/${taskSummary.task.project.id}/save`;
                await AuthService.put(url, request);
                await appBridge.cleanDrawings(taskSummary.task);                
            }
        } catch (error) {         
        }        
        await appBridge.synchronizeTask([taskSummary.task]);                
    }


    public downloadInspectionTask = async (taskSummary: InspectionTaskSummary): Promise<boolean> => {
        FlagService.addInfo(t("labelInspectionDrawing"),t("textStoreTaskInfo"));
        try {
            const appBridge = await FieldBrowserSupport.bridge();
            let projectId = taskSummary.project.id;
            let structureId = taskSummary.structure.id;
            let taskId = taskSummary.id;
            let pages = [
                `api/home/data`,
                `api/home/${projectId}/data`,
                `api/projectoverview/${projectId}/data`,
                // structure overview page
                `api/structureoverview/${projectId}/${structureId}/data`,
                // structures page
                `api/structures/${projectId}/data`,
                // inspections page
                `api/inspection/${projectId}/data`,
                `api/inspection/${projectId}/${structureId}/data`,
                // inspection task
                `api/inspectiontask/${projectId}/${structureId}/${taskId}/data`
            ];

            let measurements = taskSummary.referencedMeasurements.filter( (element) => { return element.image_linked; }).map(measurement => measurement.id);
            const downloaded = await appBridge.downloadTask(taskSummary, pages, measurements);
            if (downloaded)
            {
                FlagService.addInfo(t("labelInspectionDrawing"),t("textTaskCached"));
            }
            else
            {
                FlagService.addError(t("labelInspectionDrawing"),"Task was not downloaded");
            }
            FieldBrowserSupport.releaseBridge();
            return downloaded;
        } catch (error) {
            FlagService.addError(t("labelInspectionDrawing"), error);
        }
        return false;
    }

    public uploadAndRemoveInspectionTask = async (taskSummary: InspectionTaskSummary, request?:CommitInspectionDrawingEditingRequest):Promise<boolean> => {
        try {
            if (request) {
                const url = `inspectiontask/${taskSummary.project.id}/save`
                await AuthService.put(url, request);
            }
            // Logger.info("Inspection drawings saved");
            // Logger.trace(result);
            const appBridge = await FieldBrowserSupport.bridge();
            await appBridge.deleteTask(taskSummary);
            FieldBrowserSupport.releaseBridge();
            FlagService.addInfo(t("labelInspectionDrawing"),t("textTaskRemoved"));
            return true;
        } catch (error) {
            FlagService.addError(t("textSaveFailed", {
                what: t("labelDrawings"),
                name: "",
            }), error);
        }
        return false;
    }

    public loadOfflineInspectionTasks = async (): Promise<OfflineInspectionTaskSummary[]> => {
        try {
            let data = await AuthService.get<HomePageData>(`home/data`);
            return data.offlineStatus? data.offlineStatus.tasks: [];
        } catch (error) {
            return []
        }
    }

    public getFieldInspectionDrawings = (taskSummary: OfflineInspectionTaskSummary) : CommitInspectionDrawingEditingRequest => {
        if (taskSummary.drawingRequest){
            const request: CommitInspectionDrawingEditingRequest = {
                created: taskSummary.drawingRequest.created,
                deleted: taskSummary.drawingRequest.deleted,
                updated: taskSummary.drawingRequest.updated,
                measurementId: taskSummary.task.measurement.id,
                structureId: taskSummary.task.structure.id,
                taskId: taskSummary.task.id,
                force: false,
            };
            return request;
        }
        return new CommitInspectionDrawingEditingRequest()
    }

    public putFieldInspectionDrawings = async (projectId: string, inspectionTaskId: string, request: CommitInspectionDrawingEditingRequest) => {
    }
    //////////////////////////////////////////////////////////////////////////////////////////    
}
const offlineService = new OfflineStorageService();
export default offlineService;
import * as React from "react";
import Tags, {ITagsProps} from "../Tags";
import LabelWrapper, { ILabelWrapperProps } from "./Wrapper";

interface ITagsLabeled extends ILabelWrapperProps, ITagsProps {}

export default function TagsLabeled(props: ITagsLabeled) {
    const { label, children, labelMarginTop, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
            <Tags label={""} {...rest}>
                {children}
            </Tags>
        </LabelWrapper>
    );
}

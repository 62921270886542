import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import DynamicTable from '@atlaskit/dynamic-table';
import Toggle from '@atlaskit/toggle';
import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from "./atlaskit/Button";
import BadgeWithLabel from './BadgeWithLabel';
import BadgeWithNumber from './BadgeWithNumber';
import { MoreIcon } from './Icons';
import TableItem from "./TableItem"
import DocumentsIcon from '@atlaskit/icon/glyph/documents';

interface ITableColumn<T> {
    header?: string;
    isSortable?: boolean;
    render: (item: T) => any;
    text: (item: T) => string;
}

// tslint:disable-next-line:max-classes-per-file
export class TextTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, text: (item: T) => string) {
        this.header = header;
        this.isSortable = true;
        this.render = (item: T) => text(item);
        this.text = (item: T) => text(item);
    }
}

// tslint:disable-next-line:max-classes-per-file
export class NotSortableTextTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, text: (item: T) => string) {
        this.header = header;
        this.isSortable = false;
        this.render = (item: T) => text(item);
        this.text = (item: T) => text(item);
    }
}

// tslint:disable-next-line:max-classes-per-file
export class LinkTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, text: (item: T) => string, url?: (item: T) => string) {
        this.header = header;
        this.isSortable = true;
        this.render = (item: T) => url ? <Link to={url(item)}>{text(item)}</Link> : <div>{text(item)}</div>;
        this.text = (item: T) => text(item);
    }
}

// tslint:disable-next-line:max-classes-per-file
export class CountBadgeTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, count: (item: T) => number | undefined) {
        this.header = header;
        this.isSortable = true;
        this.render = (item: T) => <BadgeWithNumber value={count(item)} />;
        this.text = (item: T) => (count(item) || 0).toString();
    }
}

// tslint:disable-next-line:max-classes-per-file
export class LabelBadgesTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, labels: (item: T) => string[], highlighted?: (item: T) => boolean) {
        this.header = header;
        this.isSortable = false;
        this.render = (item: T) => <div>{labels(item).map(label => <BadgeWithLabel key={label} label={label} highlighted={highlighted && highlighted(item)} />)}</div>;
        this.text = (item: T) => "";
    }
}

// tslint:disable-next-line:max-classes-per-file
export class DateTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, date: (item: T) => Date) {
        this.header = header;
        this.isSortable = true;
        this.render = (item: T) =>  date(item).toLocaleDateString();
        this.text = (item: T) => date(item).toLocaleDateString();
    }
}

// tslint:disable-next-line:max-classes-per-file
export class DateTimeTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, date: (item: T) => Date) {
        this.header = header;
        this.isSortable = true;
        this.render = (item: T) =>  date(item).toLocaleDateString() + ", " + date(item).toLocaleTimeString();
        this.text = (item: T) => date(item).toLocaleDateString();
    }
}

// tslint:disable-next-line:max-classes-per-file
export class IconTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(header: string, icon: (item: T) => JSX.Element) {
        this.header = header;
        this.isSortable = false;
        this.render = (item: T) => icon(item);
        this.text = (item: T) => "";
    }
}

export class FileIconTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;


    constructor(header: string, url: (item: T) =>string ) {
        this.header = header;
        this.isSortable = false;
        this.render = (item: T) => { 
            if (url(item) === "")
                return <DocumentsIcon label={""} size={"small"} primaryColor={"lightgray"} testId={"table-file-icon"} />
            else
                return <Link to={url(item)}><DocumentsIcon label={""} size={"small"}  testId={"table-file-icon-link"}  /> </Link>;
        };
        this.text = (item: T) => "";
    }
}


// tslint:disable-next-line:max-classes-per-file
export class TableItemAction<T> {
    public text: string;
    public onExecute: (item: T) => void;
    public disabled?: boolean;

    constructor(text: string, onExecute: (item: T) => void, disabled?: boolean) {
        this.text = text; 
        this.onExecute = onExecute;
        if(disabled){this.disabled = disabled;}
    }
}

// tslint:disable-next-line:max-classes-per-file
export class ActionsTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(actions: (item: T) => Array<TableItemAction<T>>) {
        this.header = "";
        this.isSortable = false;
        this.render = (item: T) => {
            return (
                <DropdownMenu trigger={<Button iconBefore={<MoreIcon />} testId={"table-menu-button"}/>}>
                    <DropdownItemGroup>
                        {actions(item).map(action =>
                            <TableItem
                                textItem={action.text}
                                key={action.text}
                                // tslint:disable-next-line:jsx-no-lambda
                                onClick={() => action.onExecute(item)}>{action.text}
                            </TableItem>
                        )}
                    </DropdownItemGroup>
                </DropdownMenu>
            );
        };
        this.text = (item: T) => "";
    }
}

// tslint:disable-next-line:max-classes-per-file
export class SingleIconActionTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor( tooltip: string, icon: JSX.Element, singleAction: (item: T) => TableItemAction<T>)  {
        this.header = "";
        this.isSortable = false;
        this.render = (item: T) => {
            return (
// tslint:disable-next-line: jsx-no-lambda
                <Button
                    iconBefore = {icon}
                    onClick={() => singleAction(item).onExecute(item)}
                    tooltip = {tooltip}
                    testId={`table-column-text?${singleAction(item).text}`}
                    isDisabled = {singleAction(item).disabled}
                    >
                    {singleAction(item).text}
                </Button>
            );
        };
        this.text = () => "";
    }
}

// tslint:disable-next-line:max-classes-per-file
export class StateItemAction<T> {
    public key: string
    public tooltip: string;
    public icons: (item:T) => JSX.Element;
    public action: (item: T) => TableItemAction<T>;

    constructor(key: string, tooltip: string, icons: (item:T) => JSX.Element, singleAction: (item: T) => TableItemAction<T>) {
        this.key = key;
        this.tooltip = tooltip; 
        this.icons = icons;
        this.action = singleAction;
    }
}
export class StateIconActionTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor( iconAction: StateItemAction<T> )
    {
        this.header = "";
        this.isSortable = false;
        this.render = (item: T) => {
            return (
// tslint:disable-next-line: jsx-no-lambda
                <Button
                    iconBefore = {iconAction.icons(item)}
                    onClick={() => iconAction.action(item).onExecute(item)}
                    tooltip = {iconAction.tooltip}
                    testId={`table-column-text?${iconAction.key}`}
                    isDisabled = {iconAction.action(item).disabled}
                    >
                    {iconAction.action(item).text}
                </Button>
            );
        };
        this.text = () => "";
    }
}

const OneLine = (props: React.HTMLProps<HTMLDivElement>) => (
    <span style={{ padding: '2px 2px', float: 'left' }} {...props}/>
  );

// tslint:disable-next-line: max-classes-per-file
export class MultipleStateIconActionTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(actions: (item: T) => Array<StateItemAction<T>|undefined|boolean>) {
        this.header = "";
        this.isSortable = false;
        this.render = (item: T) => {

            var items = actions(item).filter(action =>
                {
                    var actionItem = action as StateItemAction<T>
                    if (actionItem){
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            ) as Array<StateItemAction<T>>;

            return (
                items.map(action =>
// tslint:disable-next-line: jsx-key
                    <OneLine key={action?.key}>
                    
                        <Button
                            iconBefore = {action?.icons(item)}
                            onClick={() => action?.action(item).onExecute(item)}
                            tooltip = {action?.tooltip}
                            testId={`table-column-text?${action?.key}`}
                            isDisabled = {action?.action(item).disabled}
                            >
                            {action?.action(item).text}
                        </Button>
                    
                    </OneLine>
                )
            )
        };
        this.text = (item: T) => "";
    }
}

// tslint:disable-next-line:max-classes-per-file
export class SingleActionTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(singleAction: (item: T) => TableItemAction<T>) {
        this.header = "";
        this.isSortable = false;
        this.render = (item: T) => {
            return (
// tslint:disable-next-line: jsx-no-lambda
                <Button
                    onClick={() => singleAction(item).onExecute(item)}
                    testId={`table-column-text?${singleAction(item).text}`}
                >
                    {singleAction(item).text}
                </Button>
            );
        };
        this.text = (item: T) => "";
    }
}

const SomeSpaceAround = (props: React.HTMLProps<HTMLDivElement>) => (
    <span style={{ padding: '0px 4px', flexWrap: 'wrap' }} {...props}/>
  );

// tslint:disable-next-line: max-classes-per-file
export class MultipleActionsTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;

    constructor(actions: (item: T) => Array<TableItemAction<T>>) {
        this.header = "";
        this.isSortable = false;
        this.render = (item: T) => {
            return (
                actions(item).map(action =>
// tslint:disable-next-line: jsx-key
                    <SomeSpaceAround key={action.text}>
                        <Button
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => action.onExecute(item)}
                            testId={`table-column-text?${action.text}`}
                        >
                            {action.text}
                        </Button>
                    </SomeSpaceAround>
                )
            )
        };
        this.text = (item: T) => "";
    }
}

// tslint:disable-next-line:max-classes-per-file
export class ToggleTableColumn<T> implements ITableColumn<T> {
    public header?: string;
    public isSortable?: boolean;
    public render: (item: T) => any;
    public text: (item: T) => string;
    public offlineStateFn: (item:T) => { isDisabled:boolean, isChecked:boolean };

    constructor(header: string, sortableText: (item: T) => string, checkOnAction: (item: T) => TableItemAction<T>, checkOffAction: (item: T) => TableItemAction<T>, offlineStateFn: (item: T)=> { isDisabled:boolean, isChecked:boolean }) {
        this.header = header;
        this.isSortable = true;
        this.offlineStateFn = offlineStateFn;
        this.render = (item: T) => {
            const offlineState = this.offlineStateFn(item);
            return (
// tslint:disable-next-line: jsx-no-lambda
                <Toggle size="large" isDisabled={offlineState.isDisabled} isChecked={offlineState.isChecked} onChange={() => {
                    if (this.offlineStateFn(item).isChecked)
                    {
                        checkOffAction(item).onExecute(item);
                    }
                    else
                    {
                        checkOnAction(item).onExecute(item);
                    }
                }
            }> {checkOnAction(item).text}</Toggle>
            );
        };
        this.text = (item: T) => sortableText(item);
    }
}


export interface ITableProps<T> extends WithNamespaces {
    data?: T[];
    columns: Array<ITableColumn<T>>;
    rowsPerPage?: number;
    noDefaultSort?: boolean;
}

export interface ITableState {
    dummy?: string;
}

// tslint:disable-next-line:max-classes-per-file
class Table<T> extends React.Component<ITableProps<T>, ITableState> {
    constructor(props: ITableProps<T>) {
        super(props);
        this.state = {}
    }

    public render() {
        const firstSortableColumnIndex = this.props.columns.findIndex(_ => !!_.isSortable)

        return (
            <DynamicTable
                head={this.createTableHeaders()}
                rows={this.createTableRows()}
                rowsPerPage={this.props.rowsPerPage || 10}
                defaultPage={1}
                defaultSortKey={this.props.noDefaultSort? undefined : firstSortableColumnIndex.toString()}
                defaultSortOrder="ASC"
                isLoading={!this.props.data}
                paginationi18n={{ prev: t("labelPrevious"), next: t("labelNext") }}
            />
        );
    }

    private createTableHeaders = (): any => {
        return {
            cells: this.props.columns.map((column, index) => {
                return {
                    content: column.header,
                    isSortable: column.isSortable || false,
                    key: index.toString(),
                };
            }),
        };
    }

    private createTableRows = (): any => {
        const data = this.props.data || [];
        return data.map((item, rowIndex) => {
            return {
                cells: this.props.columns.map((column, columnIndex) => {
                    const content = column.render(item);
                    const key = `${column.text(item)}-${columnIndex}-${rowIndex}`
                    return { content, key };
                })
            };
        });
    }
}

export default withNamespaces()(Table)

import Form from '@atlaskit/form';
import CheckBoxField from "components/CheckBoxField";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ButtonConfirm from "../components/ButtonConfirm";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import TagsLabeled from "../components/labeled/TagsLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import PageTitleItem from "../components/PageTitleItem";
import AdministrationResourcePageData
    from "../dto/pages/AdministrationResourcePageData";
import UpdateResourceRequest from "../dto/requests/UpdateResourceRequest";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";

export interface IAdministrationResourcePageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationResourcePageState {
    data?: AdministrationResourcePageData; 
    name: string;
    type: string;
    isPrivate: boolean;
    users: string[];
}

class AdministrationResourcePage extends React.Component<IAdministrationResourcePageProps, IAdministrationResourcePageState> {
    constructor(props: IAdministrationResourcePageProps) {
        super(props);
        this.state = {
            isPrivate: false,
            name: "",
            type: "",
            users: [],
        };
    }

    public render() {
        const { data, name, type, isPrivate, users } = this.state;
        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleProject")} testID={"admin-resource-page-title"}>
                    {(AuthService.isAmbergAdmin() || AuthService.isCloudAdmin()) &&
                    <ButtonConfirm
                      textButton={t("buttonDelete")}
                      textPopup={t("textDeleteWarning", {what: t("labelResource")})}
                      appearance={"danger"}
                      onClick={this.onDelete}
                      testId={"delete-resource-button"}
                    />}
                </PageTitleItem>
                {data && 
                <Form onSubmit={this.onSave}>
                {({formProps}) => (
                    <form {...formProps} onSubmit={this.onSave}>
                        <TextBoxLabeled
                            label={t("labelName")}
                            placeholder={t("labelName")}
                            required={true}
                            value={name}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ name: value })}
                            testId = {"name-textbox"}
                        />
                        <TextBoxLabeled
                            label={t("labelType")}
                            disabled={true}
                            value={type}
                            testId = {"type-textbox"}
                            doNotValidate={true}
                        />
                        <CheckBoxField
                            label={t("labelVisibility")}
                            text={t("labelPrivate")}
                            value={isPrivate}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ isPrivate: value })}
                        />
                        <TagsLabeled
                            label={t("labelUsers")}
                            values={users}
                        />
                        <ButtonLabeled
                            type="submit"
                            testId={"save-resource-button"}
                        >
                            {t("buttonSave")}
                        </ButtonLabeled>
                    </form>
                    )}
                    </Form>}
                </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const { resourceId } = this.props.match.params;
            const data = await AuthService.get<AdministrationResourcePageData>(this.adminResourceAPIPath() + resourceId);
            this.setState({
                data, 
                isPrivate: data.resource.isPrivate || false,
                name: data.resource.name,
                type: data.resource.type,
                users: data.resource.users || [],
            });
            this.props.setLocationName(data.account.name);
        } catch (error) {
            //
        }
    }

    private onDelete = async () => {

        const { resourceId } = this.props.match.params;
        const { data, name, type } = this.state;

        const resourceAPIUrl = this.adminResourceAPIPath() + type +'/'+ resourceId;

        if (!data) {
            return;
        }
        try {
            await AuthService.delete(resourceAPIUrl);            
            this.props.history.push(this.adminResourcesRoutePath());
            FlagService.addInfo(t("labelDeleted"), t("textDeleted", {what:t("labelResource"), name}));
        } catch (error) {
            Logger.error(error, "AdministrationResourcePage");
            FlagService.addError(t("textDeletionFailed", {what:t("labelResource"), name}), error);
        }
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }

        const { resourceId } = this.props.match.params;
        const { name, type, isPrivate } = this.state;

        const request: UpdateResourceRequest = {
            id: resourceId,
            isPrivate,
            name,
            type,
        };
        try {
            await AuthService.put(this.adminResourceAPIPath(), request);

            this.props.history.push(this.adminResourcesRoutePath());
            FlagService.addInfo(t("labelSaved"), t("textSaved", {what:t("labelResource"), name}),);
        } catch (error) {
            Logger.error(error, "AdministrationResourcePage");
            FlagService.addError(t("textSaveFailed", {what:t("labelResource"), name}), error);
        }
    }

    private validate = (): boolean => {
        const { name, type } = this.state;
        if (!name) {
            return false;
        }
        if (!type) {
            return false;
        }
        return true;
    }

    private adminResourceAPIPath = (): string => {
       let res =  `resource/`; 
       if ( AuthService.isAmbergAdmin() ) {
            const { accountId } = this.props.match.params;
            res = `administrationresource/${accountId}/`
       }
       return res;
    }

    private adminResourcesRoutePath = () : string => {        
        let res = Routes.resources.path;
        if ( AuthService.isAmbergAdmin() )  {
            const { accountId } = this.props.match.params;
            res = Routes.administrationAccountResources.patch(accountId).path
        }
        return res;
    }
}

export default compose(withNamespaces())(AdministrationResourcePage)

import IBreadcrumbLink from '../helpers/IBreadcrumbLink';
import React from 'react';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { default as AtlasBreadcrumbs } from '@atlaskit/breadcrumbs';
import { Link } from 'react-router-dom';

export interface IBreadcrumbsProps {
    navLinks: IBreadcrumbLink[];
}

export default function Breadcrumbs(props: IBreadcrumbsProps) {
    return (
        <div className="breadCrumbs">
            <AtlasBreadcrumbs>
                {props.navLinks.map(navLink =>
                    <BreadcrumbsItem
                        text={navLink.text}
                        key={navLink.route ? navLink.route.path : navLink.text}
                        // tslint:disable-next-line:jsx-no-lambda
                        component={
                            navLink.route ?
                                () => <Link key={navLink.route ? navLink.route.path : ""} to={navLink.route ? navLink.route.path : ""}>{navLink.text}</Link>
                                :
                                () => <div>{navLink.text}</div>
                            }
                    />
                )}
            </AtlasBreadcrumbs>
        </div>
    );
}

import Drawer from "@atlaskit/drawer";
import {
  GlobalItem,
  GlobalNav,
  modeGenerator,
  ThemeProvider,
} from "@atlaskit/navigation-next";
import { t } from "i18next";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import InspectionAnalysisSearchResult
  from "../../dto/search/InspectionAnalysisSearchResult";
import ProjectSearchResult from "../../dto/search/ProjectSearchResult";
import StructureSearchResult from "../../dto/search/StructureSearchResult";
import StructureTaskSearchResult from "../../dto/search/StructureTaskSearchResult";
import Constants from "../../helpers/Constants";
import Routes from "../../helpers/Routes";
import { BackIcon, SearchIcon, TunnelCloudIcon } from "../Icons";
import LegalInformationMenu, { LabelLegalInformation } from "./LegalInformationMenu";
import SearchDrawer from "./SearchDrawer";
import UserMenu, { LabelUserMenu } from "./UserMenu";

export interface INavigationProps extends RouteComponentProps<any> {
  userName: string;
  isFieldMode: boolean;
}

export interface INavigationState {
  isNavigationOpen: boolean;
  navigationWidth: number;
  openDrawer?: "search";
}

class GlobalNavigation extends React.Component<INavigationProps, INavigationState> {
  constructor(props: INavigationProps) {
    super(props);
    this.state = {
      isNavigationOpen: !this.shouldNavigationBeCollapsed(),
      navigationWidth: 304,
      openDrawer: undefined,
    };
  }

  public render() {
    return (
      <ThemeProvider
        theme={{
          context: "product",
          mode: modeGenerator({
            product: {
              text: Constants.colorForegroundM3white,
              background: Constants.colorForegroundM2dark,
            },
          }),
        }}
      >
        <div id={"global_navigation"}>
          {this.props.isFieldMode ? (<div id={"offline_navigation"}>{t("labelOffline")}</div>) : (<React.Fragment/>)}
          <GlobalNav
            primaryItems={this.createPrimaryItems(this.props.isFieldMode)}
            secondaryItems={[
              {
                component: () => <LegalInformationMenu/>,
                id: "navigationLegalInformation",
                tooltip: LabelLegalInformation(),
              },
              {
                component: () => <UserMenu userName={this.props.userName} />,
                icon: null,
                id: "navigationUser",
                tooltip: LabelUserMenu(),
              },
            ]}
          />
          <Drawer
            backIcon={<BackIcon />}
            onClose={this.closeDrawer}
            isOpen={this.state.openDrawer === "search"}
            key="search"
            width="wide"
            primaryIcon={<TunnelCloudIcon />}
          >
            <SearchDrawer
              onGoToProject={this.onGoToProject}
              onGoToStructure={this.onGoToStructure}
              onGoToStructureTask={this.onGoToStructureTask}
              onGoToInspectionAnalysis={this.onGoToInspectionAnalysis}
            />
          </Drawer>

        </div>
      </ThemeProvider>
    );
  }

  private createPrimaryItems = (isFieldMode: boolean) => {
    const items: any = [];
    items.push(
      {
        href: Routes.home.path,
        component: () =>
          <Link key="navigation-header" to={Routes.home.path}>
            <GlobalItem key={"tunnel-cloud-icon"} icon={TunnelCloudIcon}/>
          </Link>,
        id: "logo",
      });
  if (!isFieldMode)
  {
    items.push(
        {
          icon: SearchIcon,
          id: "navigationSearch",
          onClick: this.openSearchDrawer,
          tooltip: t("navigationSearch"),
        });
    }

    return items
  };

  private shouldNavigationBeCollapsed = () => {
    return window.innerWidth < Constants.minSizeForExpandableNavigation;
  };

  private openSearchDrawer = () => {
    this.setState({ openDrawer: "search" });
  };

  private closeDrawer = () => {
    this.setState({ openDrawer: undefined });
  };

  private onGoToProject = (result: ProjectSearchResult) => {
    this.closeDrawer();
    this.props.history.push(Routes.project.patch(result.id).path);
  };

  private onGoToStructure = (result: StructureSearchResult) => {
    this.closeDrawer();
    this.props.history.push(Routes.projectStructure.patch(result.project.id, result.id).path);
  };

  private onGoToStructureTask = (result: StructureTaskSearchResult) => {
    this.closeDrawer();
    this.props.history.push(
      Routes.task.patch(result.project.id, result.structure.id, result.type, result.id).path
    );
  };

  private onGoToInspectionAnalysis = (result: InspectionAnalysisSearchResult) => {
    this.closeDrawer();
    this.props.history.push(
      Routes.analysis.patch(result.project.id, result.structure.id, result.type, result.id).path
    );
  };
}

export default compose<any, any>(
  withRouter,
  withNamespaces()
)(GlobalNavigation);

import * as htmlToImage from 'html-to-image';
import * as L from 'leaflet';
import i18next, { t } from 'i18next';
import InspectionAnalysisMapsPageData from '../dto/pages/InspectionAnalysisMapsPageData';
import InspectionMapBasic, { isLayerInBounds } from 'graphics/InspectionMapBasic';
import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { Feature } from 'geojson';
import ReactPDF, {
    Document,
    Page,
    PDFViewer,
    StyleSheet,
    Text,
    View,
    } from '@react-pdf/renderer';
import InspectionFeatureGroup from 'graphics/InspectionFeatureGroup';
import FeatureLayer from 'graphics/FeatureLayer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        borderTopWidth: 10,
        borderLeftWidth: 10,
        borderRightWidth:10,
        borderBottomWidth: 10,
        borderColor: 'white',
        backgroundColor: 'white',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    title: {
        marginTop: 5,
        fontSize: 14,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold'
    },
    subTitle: {
        marginLeft: 5,
        marginBottom: 5,
        marginTop: 5,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica-Bold',
    },
    text: {
        marginLeft: 10,
        marginBottom: 2,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica'
    },
    boldText: {
        marginLeft: 10,
        marginBottom: 5,
        marginTop: 5,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Helvetica-Bold',
    },
    smallText: {
        marginLeft: 10,
        marginBottom: 2,
        fontSize: 7,
        textAlign: 'justify',
        fontFamily: 'Helvetica'
    },
    pageNumber: {
        fontSize: 10,
        marginRight: 10,
        marginBottom: 2,
        textAlign: 'justify',
        fontFamily: 'Helvetica',
    },
});

export interface IInspectionPdfReport {
    inspectionMap?: InspectionMapBasic,
    inspectionDrawingId: string;
    data?: InspectionAnalysisMapsPageData;
    blockRange?: Array<Feature>;
    customer: CustomerAddressData;
    onDone?: () => void;
}

export type CustomerAddressData = {
    companyName: string;
    street: string;
    zipCity: string;
    country: string;
}

type TileImage = {
    img: HTMLImageElement;
    x: number;
    y: number;
};

type PathImage = {
    img: HTMLImageElement;
    x: number;
    y: number;
    width: number;
    height: number;
};

type BlockImage = {
    id?: string | number;
    name?: string;
    dataURL?: string;
    scale?: string;
}

type ReportConstants = {
    projectName: string;
    structureName: string;
    inspectionName: string;
    measurementName: string;
    updatedDate: string;
    fetchDate:string;
}

class InspectionReportGeneration extends L.Class {
    tiles: Array<L.Point>;
    tileSize: number;
    tileBounds: L.Bounds;
    tileImages: Array<TileImage>;
    polygonImages: Array<PathImage>;
    canvas: HTMLCanvasElement;
    renderingContext: CanvasRenderingContext2D | null;
    scale: number;

    constructor(){
        super();
        this.tiles = new Array<L.Point>();
        this.tileImages = new Array<TileImage>();
        this.polygonImages = new Array<PathImage>();
    }

    _getTileLayer = (layer: L.TileLayer, bounds: L.Bounds, zoom: number, resolve: (value: void | PromiseLike<void>) => void) => {
        this.tileSize = layer.getTileSize().x;
        if(bounds.min && bounds.max){
            this.tileBounds = L.bounds(bounds.min.divideBy(this.tileSize).floor(), bounds.max.divideBy(this.tileSize).floor())

            if(this.tileBounds.min && this.tileBounds.max){
                for (let j = this.tileBounds.min.y; j <= this.tileBounds.max.y; j++){
                    for (let i = this.tileBounds.min.x; i <= this.tileBounds.max.x; i++){
                        const tilePoint = new L.Point(i, j);
                        this.tiles.push(tilePoint);
                    }
                }
            }

            const promisesTileLayer = new Array<Promise<void>>();
            const min = bounds.min;
            this.tiles.forEach(tilePoint => {
                const originalTilePoint = tilePoint.clone();
                const tilePos = originalTilePoint.scaleBy(new L.Point(this.tileSize, this.tileSize)).subtract(min);

                promisesTileLayer.push(new Promise<void>((innerResolve) => {
                    this._loadTile(tilePoint, tilePos, layer, zoom, innerResolve);
                }));
            })

            Promise.all(promisesTileLayer).then(() => {
                resolve();
            });
        }
        else {
            resolve();
        }
    };

    _loadTile = (tilePoint: L.Point, tilePos: L.Point, layer: L.TileLayer, zoom: number, resolve: (value: void | PromiseLike<void>) => void) => {
        const imgIndex = tilePoint.x + ':' + tilePoint.y + ':' + zoom;
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            if(this.tileImages && !this.tileImages[imgIndex]){
                this.tileImages[imgIndex] = {img: image, x: tilePos.x, y: tilePos.y};
            }
            resolve();
        }
        image.onerror = () => {
            resolve();
        }
        image.src = layer.getTileUrl({x: tilePoint.x, y: tilePoint.y, z: zoom} as L.Coords);
    };

    _getBlockBounds = (feature: Feature): L.LatLngBounds => {
        const stationings = (feature as any).geometry.coordinates[0].map((c: number[]) => c[1]);
        const derolments = (feature as any).geometry.coordinates[0].map((c: number[]) => c[0]);
        const minStationing = Math.min(...stationings);
        const maxStationing = Math.max(...stationings);
        const minDerolment = Math.min(...derolments);
        const maxDerolment = Math.max(...derolments);
        return new L.LatLngBounds([minStationing, minDerolment], [maxStationing, maxDerolment]);
    };

    public blockDataUrlList = (leafletMap: L.Map | undefined, blocks: Array<Feature> | undefined) => {
        if(leafletMap === undefined || blocks ===  undefined){
            return undefined;
        }

        return new Promise<Array<BlockImage>>((resolve) => {
            const blockUrls = new Array<Promise<BlockImage>>();
            let promiseChain = Promise.resolve();
            for (let i=0; i < blocks.length; i++){
                const currentBlock = blocks[i];

                const makeNextPromise = (block: Feature) => async () => {
                    // Make sure to return your promise here.
                    const [blockUrl, scale] = await this.getBlockDataUrl(leafletMap, block);
                    blockUrls.push(new Promise<BlockImage>((resolve)=>{
                        const bounds = this._getBlockBounds(block);
                        const blockName = `${block.properties?.name} (${bounds.getSouth().toFixed(3)}m - ${bounds.getNorth().toFixed(3)}m)`;
                        resolve({
                            id: block.id,
                            name: blockName,
                            dataURL: blockUrl,
                            scale: scale ? '1:' + Math.round(scale).toString() : '',
                        })
                    }));
                }

                promiseChain = promiseChain.then(makeNextPromise(currentBlock))
            }

            promiseChain.then(() => {
                Promise.all(blockUrls).then((dataArray) => {
                    resolve(dataArray);
                });
            });
        });
    };

    private getBlockDataUrl(leafletMap: L.Map, blockInfo: Feature): Promise<[string, number|undefined]> {
        return new Promise<[string, number|undefined]>((resolveUrl) => {
            const scaleEntries = leafletMap.getContainer().getElementsByClassName('leaflet-control-scale-line');
            for (let idx = 0; idx < scaleEntries.length; idx++){
                const test = scaleEntries[idx] as HTMLElement;
                test.style.fontSize = '20';
            }
            leafletMap.invalidateSize(true);

            const reposPromise = new Promise<void>((resolve) => {
                leafletMap.whenReady(function() {
                    leafletMap.on('moveend', (event: L.LeafletEvent) => {
                        // console.info('something moveed:' + event.sourceTarget)
                        resolve();
                    });
                    leafletMap.on('zoomend', function() {
                        // console.info('something zoomed')
                        resolve();
                    });
                }, [leafletMap.eachLayer]);
            })

            const bounds = this._getBlockBounds(blockInfo).pad(0.05);
            leafletMap.fitBounds(bounds);
            
            reposPromise.then(() => {
                const promisesLayers = new Array<Promise<void>>();
                leafletMap.eachLayer((layer: L.Layer) => {
                    // we need to load all images in front of extraction
                    if (layer instanceof L.TileLayer) {
                        promisesLayers.push(new Promise<void>((innerResolve) => {
                            this._getTileLayer(layer, leafletMap.getPixelBounds(), leafletMap.getZoom(), innerResolve);
                        }));
                    }
                    else if (layer instanceof InspectionFeatureGroup) {
                        layer.eachActiveLayer((innerLayer: FeatureLayer) =>{
                            if(isLayerInBounds(innerLayer, bounds)){
                                promisesLayers.push(new Promise<void>((innerResolve) => {
                                    setTimeout(() => {
                                        innerResolve();
                                    }, 2000);
                                    leafletMap.whenReady(() => { 
                                        innerLayer.on('printready', () => {
                                            // console.info('printready: ' + innerLayer.feature.id);
                                            innerResolve();
                                        })} , innerLayer);
                                    
                                    
                                }));
                                
                            }
                        })
                    }
                });

                Promise.all(promisesLayers).then(() => {
                    htmlToImage.toCanvas(leafletMap.getContainer()).then((value: HTMLCanvasElement) => {
                        resolveUrl([value.toDataURL(), undefined]);
                    }).catch(() => {
                        resolveUrl(['', undefined]);
                    });
                });
            })
        });
    }
}


// Create Document Component
// page layout is generated by https://yogalayout.com/playground/
const PdfPage = (mapState: IInspectionPdfReport) => {
    const [blockImages, setBlockImages] = useState<Array<BlockImage>>([]);
    const [reportConstants, setReportConstants] = useState<ReportConstants|undefined>(undefined);
    
    useEffect(() => {
        const fetchData = () => {
            const inspectionSummary = mapState.data?.inspectionSummaries.find(s => s.measurement.id === mapState.inspectionDrawingId);
            const inspectionClassification = mapState.data?.inspectionClassifications.find(l => l.id === mapState.inspectionDrawingId);
            const inspectionAnalysis = mapState.data?.inspectionAnalysis;
            const generator = new InspectionReportGeneration();
            const result = generator.blockDataUrlList(mapState.inspectionMap?.innerMap, mapState.blockRange);
            const currentLanguage = i18next.language || window.localStorage.i18nextLng;
            const fetchDate = new Date(Date.now());
            if(inspectionClassification && inspectionSummary && inspectionAnalysis){
                const dateObj = new Date(inspectionSummary.updated);
                setReportConstants({
                    projectName: inspectionAnalysis.project.name, 
                    structureName: inspectionAnalysis.structure.name, 
                    inspectionName: inspectionSummary.name, 
                    measurementName: inspectionClassification.name, 
                    updatedDate: `${dateObj.toLocaleDateString(currentLanguage)}, ${dateObj.toLocaleTimeString(currentLanguage)}`,
                    fetchDate: `${fetchDate.toLocaleDateString(currentLanguage)}`,
                });
            }
            
            if(result){
                result.then((data) => {
                    setBlockImages(data);
                    mapState.onDone && mapState.onDone();
                }).catch(() => {
                    mapState.onDone && mapState.onDone();
                });
            }
            else{
                mapState.onDone && mapState.onDone();
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapState.data, mapState.inspectionMap, mapState.inspectionDrawingId])

    if(mapState.data === undefined || reportConstants === undefined)
    {
        return (
            <Document><Page size="A4" orientation="landscape"><View><Text>Invalid input</Text></View></Page></Document>
        )
    }
    
    return (
    <Document 
        title={`${reportConstants.projectName}-${reportConstants.measurementName}`}
        producer={'Amberg Technologies AG'}
        creator={document.location.hostname}
        keywords={getHostNameKeywords() + getReportKeywords(reportConstants) + getCusotmerKeywords(mapState.customer)}>
        {
            blockImages.map(blockItem => (
                generatedPage( mapState, blockItem, reportConstants)
            ))
        }
    </Document>
    )
};

export const PdfPreview: React.FC<IInspectionPdfReport> = (state: IInspectionPdfReport) => {
    const [waiting, setWaiting] = useState<boolean>(true);

    return (
        <>
        { 
            waiting && 
            <Spinner size={"large"} />
        }
        {
            <PDFViewer width="100%" height="100%" showToolbar={true}>
                <PdfPage 
                    data={state.data}
                    inspectionDrawingId={state.inspectionDrawingId} 
                    inspectionMap={state.inspectionMap}
                    customer={state.customer}
                    blockRange={state.blockRange}
                    onDone={() => { setWaiting(false); state.onDone && state.onDone()}}
                />
            </PDFViewer>
        }
        </>
    )
};

const generatedPage = (mapState: IInspectionPdfReport, blockItem: BlockImage, projectInfo: ReportConstants) => {
    const validCustomerData = mapState.customer.companyName.length > 0 && mapState.customer.zipCity.length > 0;

    return (<Page size="A4" orientation="landscape" style={styles.page}>
        <View style={{
            width: '100%',
            height: '5%',
            flexDirection: 'row',
            border: 1,
        }}  fixed={true}>
            <View style={{
                width: '20%',
                height: '100%',
            }} />
            <View style={{
                width: '60%',
                height: '100%',
                alignContent: 'center',
            }}>
                <Text style={styles.title}>{projectInfo.measurementName}</Text>
            </View>
            <View style={{
                width: '20%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems : 'flex-end'
            }}>
                <Text style={styles.pageNumber} fixed>{projectInfo.fetchDate}</Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    t("labelPage") + `: ${pageNumber} / ${totalPages}`
                    )} fixed/>
            </View>
        </View>
        <View style={{
            width: '100%',
            height: '75%',
            border: 1,
        }} fixed={true}>
            {
                <ReactPDF.Image src={ () => { return blockItem.dataURL; } } style={{ objectFit: 'contain' }}></ReactPDF.Image>
            }
        </View>
        <View style={{
            width: '100%',
            height: '15%',
            flexDirection: 'row',
            marginBottom: 4,
        }} fixed={true} >
            <View style={{
                width: '30%',
                height: '100%',
                border: 1,
                flexDirection: 'row'
            }}>
                <View style={{
                        width: '30%',
                    }}>
                        <Text style={styles.boldText} fixed>
                        {t("labelProject")}
                        </Text>
                        <Text style={styles.text} fixed>
                        </Text>
                        {
                            validCustomerData&&
                            <Text style={styles.boldText} fixed>
                            {t("labelCustomer")}
                            </Text>
                        }
                        
                </View>
                <View style={{
                        width: '60%',
                    }}>
                        <Text style={styles.boldText} fixed>
                            {projectInfo.projectName}
                        </Text>
                        <Text style={styles.text} fixed>
                        </Text>
                        {
                            validCustomerData&&
                            <>
                                <Text style={styles.boldText} fixed>{mapState.customer.companyName}</Text>
                                <Text style={styles.text} fixed>{mapState.customer.street}</Text>
                                <Text style={styles.text} fixed>{mapState.customer.zipCity}</Text>
                                <Text style={styles.text} fixed>{mapState.customer.country}</Text>
                            </>
                        }
                        
                </View>

            </View>
            <View style={{
                width: '50%',
                height: '100%',
                border: 1,
            }}>
                <Text style={styles.subTitle} fixed>
                {t("labelStateSheet")}
                </Text>
                <View style={{
                    width: '100%',
                    flexDirection: 'row',
                }} fixed={true} >
                    <View style={{
                        width: '20%',
                    }}>
                        <Text style={styles.text} fixed>
                        {t("labelStructure")}:
                        </Text>
                        <Text style={styles.text} fixed>
                        {t("labelInspection")}:
                        </Text>
                        <Text style={styles.text} fixed>
                        {t("labelBlock")}:
                        </Text>
                        <Text style={styles.text} fixed>
                        {t("labelUpdated")}:
                        </Text>
                    </View>
                    <View style={{
                        width: '80%',
                    }}>
                        <Text style={styles.text} fixed>
                        {projectInfo.structureName}
                        </Text>
                        <Text style={styles.text} fixed>
                        {projectInfo.inspectionName}
                        </Text>
                        <Text style={styles.text} fixed>
                        {blockItem.name}
                        </Text>
                        <Text style={styles.text} fixed>
                        {projectInfo.updatedDate}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={{
                width: '20%',
                height: '100%',
                border: 1,
            }}>
                <Text style={styles.boldText} fixed>
                    Amberg Technologies AG
                </Text>
                <Text style={styles.text} fixed>
                    Trockenloostrasse 21
                </Text>
                <Text style={styles.text} fixed>
                    CH-8105 Regensdorf-Watt
                </Text>
                <Text style={styles.smallText} fixed>
                    www.amberg.ch / info@amberg.ch
                </Text>
            </View>
        </View>
    </Page>
    )};


function getReportKeywords(data: ReportConstants | undefined) {
    if(!data){ return ''; }

    return `${data.projectName},${data.structureName},${data.inspectionName},${data.measurementName},${data.updatedDate},`;
}

function getHostNameKeywords() {
    return `${document.location.hostname},`;
}

function getCusotmerKeywords(data: CustomerAddressData) {
    if(data.companyName.length > 0){
        return `${data.companyName},`;
    }
    else{
        return '';
    }
}
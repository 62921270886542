import { t } from "i18next";
import * as React from "react";
import FeatureType from "../../dto/components/FeatureType";
import CatalogBase, { ICatalogBaseProps, ICatalogBaseState } from "./CatalogBase";
import CatalogItem from "./CatalogItem";
import FeatureIconProvider from "./FeatureIconProvider";


interface ICatalogProps extends ICatalogBaseProps {
    lastUsedFeatureTypes: FeatureType[];
    currentFeatureTypeId?: string,
    onSelect: (featureType: FeatureType) => void;
}


export default class CatalogLastUsed extends CatalogBase<ICatalogProps, ICatalogBaseState> {

    public getInitialState(): ICatalogBaseState {
        const initialState = super.getInitialState();
        initialState.isExpanded = true;
        initialState.name = this.props.name || t("labelLastUsedFeature");
        return initialState;
    }

    public renderChildren = () => {
        const featureTypes: any = [];
        this.props.lastUsedFeatureTypes.forEach(featureType => {
            featureTypes.push(
                <CatalogItem
                    key={featureType.id}
                    name={featureType.name}
                    id={featureType.id}
                    iconBefore={FeatureIconProvider.getFeatureIcon(featureType)}
                    isSelected={(this.props.currentFeatureTypeId && featureType.id === this.props.currentFeatureTypeId) || false}
                    onClick={this.getHandleSelectMethod(featureType)}
                    testId={`last-used-feature-type-id?${featureType.id}`}
                />
            );
        });

        return featureTypes;
    };

    private getHandleSelectMethod = (featureType: FeatureType) => {
        return (event: any) => {
            event.stopPropagation();
            if (this.props.onSelect){
                this.props.onSelect(featureType)
            }
        }
    };
}
import debug from "debug";

const BASE = "ambergtunnelcloud";
const COLORS = {
    error: "red",
    info: "blue",
    trace: "gray",
    warn: "orange",
};

class Logger {
    public trace = (object: any, source?: string) => {
        return this.log("trace", object, source);
    }

    public info = (message: string, source?: string) => {
        return this.log("info", message, source);
    }

    public warning = (message: string, source?: string) => {
        return this.log("warn", message, source);
    }

    public error = (message: string, source?: string) => {
        return this.log("error", message, source);
    }

    public logComponentDidMount = (component: string) => {
        this.info("componentDidMount", component)
    }

    public logComponentWillUnmount = (component: string) => {
        this.info("componentWillUnmount", component)
    }

    public logRender = (component: string) => {
        this.info("render", component)
    }

    public logComponentDidUpdate = (component: string, props: any) => {
        this.info("componentDidUpdate", component)
        this.trace(props, component);
    }

    private log = (level: string, message: any, source?: string) => {
        const createDebug: any = debug(`${BASE}:${level}`);
        createDebug.color = COLORS[level];
        if (source) {
            createDebug(source, message);
        }
        else {
            createDebug(message);
        }
    }
}

export default new Logger();
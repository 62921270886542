import { withAITracking } from '@microsoft/applicationinsights-react-js';
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import PageTitleItem from '../components/PageTitleItem';
import Table, {
    CountBadgeTableColumn,
    LinkTableColumn,
    TextTableColumn,
} from "../components/Table";
import StructureSummary from '../dto/components/StructureSummary';
import StructuresPageData from '../dto/pages/StructuresPageData';
import IRouteParams from '../helpers/IRouteParams';
import AuthService from '../services/AuthService';

export interface IStructuresPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string) => void;
}

export interface IStructuresPageState {
    data?: StructuresPageData;
}

class StructuresPage extends React.Component<IStructuresPageProps, IStructuresPageState> {
    constructor(props: IStructuresPageProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const { data } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleStructures")}  testID={"structures-page-title"}/>
                <div className="tableContainer">
                    <Table
                        data={data && data.structures}
                        columns={[
                            new LinkTableColumn<StructureSummary>(t("labelStructureName"), item => item.name, item => `/project/${item.project.id}/structure/${item.id}`),
                            new TextTableColumn<StructureSummary>(t("labelProject"), item => item.project.name),
                            new TextTableColumn<StructureSummary>(t("labelType"), item => item.type),
                            new CountBadgeTableColumn<StructureSummary>(t("labelInspectionTasks"), item => item.numInspectionTasks),
                            new CountBadgeTableColumn<StructureSummary>(t("labelInspectionAnalyses"), item => item.numInspectionAnalyses),
                        ]}
                    />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        const { projectId } = this.props.match.params
        try {
            let data: StructuresPageData;
            if (!projectId) {
                // global level
                data = await AuthService.get<StructuresPageData>(`structures/data`);
            } else {
                // project level
                data = await AuthService.get<StructuresPageData>(`structures/${this.props.match.params.projectId}/data`);
            }
            this.setState({ data });
            const projectName = data && data.project && data.project.name;
            this.props.setLocationNames(projectName);
        } catch (error) {
            //
        }
    }
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, StructuresPage,"StructuresPage","AppInsight"));

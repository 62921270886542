import Spinner from '@atlaskit/spinner';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import InspectionProjectOverviewMap from "../components/InspectionProjectOverviewMap";
import PageTitleItem from "../components/PageTitleItem";
import Table, {
  CountBadgeTableColumn,
  LinkTableColumn,
} from "../components/Table";
import TitleItem from "../components/TitleItem";
import StructureSummary from "../dto/components/StructureSummary";
import ProjectOverviewPageData from "../dto/pages/ProjectOverviewPageData";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";
import Policies from 'helpers/Policies';

export interface IProjectOverviewPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string) => void;
}

export interface IProjectOverviewPageState {
    data?: ProjectOverviewPageData
    waiting: boolean
}

class ProjectOverviewPage extends React.Component<IProjectOverviewPageProps, IProjectOverviewPageState> {
    constructor(props: IProjectOverviewPageProps) {
        super(props);
        this.state = { waiting:true };
    }

    public render() {
        const { data, waiting } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleProjectOverview")}  testID={"project-overview-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>                
                <div className="pageOverviewMap">
                    {data && data.structures.length > 0 && <InspectionProjectOverviewMap
                        mapProvider={data.structures[0].mapData.mapProvider}
                        structures={data.structures}
                        inspectionClassificationOverview={data.inspectionClassificationOverview}
                        onClick={this.navigateToStructure}
                    />}
                </div>
                <TitleItem title={t("sectionTitleStructures")} />
                <div className="tableContainer">
                    <Table
                        data={data && data.structures}
                        columns={[
                            new LinkTableColumn<StructureSummary>(
                                t("labelStructureName"),
                                item => item.name,
                                this.linkToStructrures() ? item => Routes.projectStructure.patch(item.project.id, item.id).path : undefined
                            ),
                            new CountBadgeTableColumn<StructureSummary>(t("labelInspectionTasks"), item => item.numInspectionTasks),
                            new CountBadgeTableColumn<StructureSummary>(t("labelInspectionAnalyses"), item => item.numInspectionAnalyses),
                        ]}
                    />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<ProjectOverviewPageData>(`projectoverview/${this.props.match.params.projectId}/data`);
            this.setState({ data, waiting:false, });
            this.props.setLocationNames(data.project.name);
        } catch (error) {
            this.setState({waiting:false,})
        }
    }

    private navigateToStructure = (structureId: string) => {
        if (this.state.data) {
            this.props.history.push(
              Routes.projectStructure.patch(this.state.data.project.id, structureId).path
            );
        }
    }

    private linkToStructrures = () => {
        return Policies.isValidPolicy(Policies.toSeeStructures);
    }
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, ProjectOverviewPage,"ProjectOverviewPage","AppInsight"));
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { API_ROOT } from "../Environment";
import PushNotification from '../dto/notifications/PushNotification';
import Logger from './Logger';

class NotificationService {
    private connection: HubConnection;
    private notificationReceivedHandler: (notification: PushNotification) => void;
    private consumers: Array<() => void>;

    public initialize = (userId: string) => {
        if (this.connection) {
            this.disconnect().then(() => this.connect(userId));
        } else {
            this.connect(userId);
        }
    }

    public registerNotificationReceived = (notificationReceivedHandler: (notification: PushNotification) => void) => {
        this.notificationReceivedHandler = notificationReceivedHandler;
    }

    public registerNotificationConsumer = (notificationConsumer: () => void) =>{
        if(!this.consumers) {
            this.consumers = new Array<() => void>();
        }
        this.consumers.push(notificationConsumer);
    }
    public unregisterNotificationConsumer = (notificationConsumer: () => void) =>
    {
        const index = this.consumers.indexOf(notificationConsumer, 0);
        if (index > -1) {
            this.consumers.splice(index, 1);
        }
    }

    private connect = async (userId: string) => {
        if (userId === undefined || userId === "")
        {
            return;
        }
        const url = `${API_ROOT}/notifications?userId=${userId}`;
        this.connection = new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.Information)
            .build();

        try {
            await this.connection.start();
            Logger.info("connection started", "NotificationService")
            this.connection.on("Send", (notification: PushNotification) => {
                if (this.notificationReceivedHandler) {
                    this.notificationReceivedHandler(notification);
                }
                this.consumers.forEach(element => {
                    element();
                });
            });

        } catch (error) {
            Logger.error("connection failed", "NotificationService")
        }
    }

    private disconnect = async () => {
        try {
            await this.connection.stop();
        } catch (error) {
            // tslint:disable-next-line:no-console
            console.error(error)
        }
    }
}

export default new NotificationService();
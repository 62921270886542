import Spinner from "@atlaskit/spinner";
import { Feature } from "geojson";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import InspectionTaskDrawingMap from "../components/InspectionTaskDrawingMap";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import Modal from '@atlaskit/modal-dialog';
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import CatalogLastUsed from "../components/toolbar/CatalogLastUsed";
import CatalogLayer from "../components/toolbar/CatalogLayer";
import InspectionDrawingControlsButtons from "../components/toolbar/InspectionDrawingControlsButtons";
import FeatureType from "../dto/components/FeatureType";
import { InspectionDrawingTools } from "../dto/components/InspectionDrawingTools";
import InspectionTaskPageData from "../dto/pages/InspectionTaskPageData";
import CommitInspectionDrawingEditingRequest
    from "../dto/requests/CommitInspectionDrawingEditingRequest";
import FeatureLayer from "../graphics/FeatureLayer";
import EditInspectionFeaturePropertiesModal
    from "../modals/EditInspectionFeaturePropertiesModal";
import CriticalErrorModal from "../modals/CriticalErrorModal"
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";
import MapPageBase, { IMapPageBaseProps, IMapPageBaseState } from "./MapPageBase";
import AuthService from '../services/AuthService';
import fieldBrowserSupport from "../helpers/FieldBrowserSupport";
import OfflineStorageService from '../services/OfflineStorageService';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { FlagWarningIcon } from "../components/Icons";
import StatusDTO, { StatusType } from "dto/components/StatusDTO";



export interface IInspectionTaskPageState extends IMapPageBaseState {
    currentFeatureType?: FeatureType;
    currentlySelectedFeatures?: FeatureLayer[];
    currentTool: InspectionDrawingTools;
    data?: InspectionTaskPageData;
    hiddenAdditionalInspectionDrawingIds: Set<string>;
    lastUsedFeatureTypes: FeatureType[];
    lockedFeatureTypeCodes: Set<string>;
    showEditFeaturePropertiesModal: boolean;
    disableSynchronizeButton: boolean;
    showUploadRequestModal: boolean;
    errorLoadMessage: string;
}


class InspectionTaskPage extends MapPageBase<IMapPageBaseProps, IInspectionTaskPageState> {
    public map: InspectionTaskDrawingMap | null;

    public getInitialState(): IInspectionTaskPageState {
        const initialState = super.getInitialState();
        initialState.blockDefinitionSelected = true;
        initialState.classificationSelected = false;
        initialState.currentFeatureType = undefined;
        initialState.currentTool = InspectionDrawingTools.Select;
        initialState.hiddenAdditionalInspectionDrawingIds = new Set<string>();
        initialState.lastUsedFeatureTypes = [];
        initialState.lockedFeatureTypeCodes = new Set<string>();
        initialState.sectorDefinitionSelected = true;
        initialState.showEditFeaturePropertiesModal = false;
        initialState.theoreticalSectionsSelected = true;
        initialState.showUploadRequestModal = false;
        initialState.disableSynchronizeButton = false;
        initialState.errorLoadMessage = "";
        return initialState;
    }

    public render() {
        const {
            blockDefinitionSelected,
            classificationSelected,
            currentFeatureType,
            currentTool,
            data,
            hiddenAdditionalInspectionDrawingIds,
            hiddenFeatureTypeCodes,
            lastUsedFeatureTypes,
            lockedFeatureTypeCodes,
            sectorDefinitionSelected,
            showEditFeaturePropertiesModal,
            showUploadRequestModal,
            textHiddenFeatureTypeCodes,
            theoreticalSectionsSelected,
            tileLayerId,
            errorLoadMessage,
            waiting,
        } = this.state;

        if (errorLoadMessage)
        { 
            let errorMessage = t("labelError") + ": " + errorLoadMessage + t("textErrorLoadingInspectionTask", {product:t("textAmbergTunnelVersionName")});
            return(
                <CriticalErrorModal heading={t("labelErrorLoadingInspectionTask")} message ={errorMessage} ></CriticalErrorModal>
            );
        }

        if (data && data.blockDefinitionMapData.geoJson.features.length === 0)
        { 
            return(
                <CriticalErrorModal heading={t("labelNoBlockDefinition")} message ={t("textNoBlockDefinition", {product:t("textAmbergTunnelVersionName")})} ></CriticalErrorModal>
            );
        }
        return (
            <div className="pageMap">
                {showEditFeaturePropertiesModal && this.renderEditFeaturePropertiesModal()}
                {showUploadRequestModal && this.renderUploadRequestModal()}
                <div className="pageMaximized">
                    <div>
                        { waiting && (
                            <Spinner size={"large"}/>
                        )}
                    </div>
                    {data &&
                    <div className="mapWithStationingSlider">
                        <InspectionTaskDrawingMap
                            additionalInspectionDrawings={data.additionalInspectionDrawings}
                            blockDefinition={data.blockDefinitionMapData}
                            blockDefinitionSelected={blockDefinitionSelected}
                            catalog={data.catalog}
                            classification={data.classificationMapData}
                            classificationSelected={classificationSelected}
                            hiddenAdditionalInspectionDrawingIds={hiddenAdditionalInspectionDrawingIds}
                            hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                            inspectionDrawing={data.inspectionDrawing}
                            inspectionTask={data.inspectionTask}
                            lockedFeatureTypeCodes={lockedFeatureTypeCodes}
                            onDrawingsChanged={this.onDrawingsChanged}
                            onEditFeatureProperties={this.onEditFeatureProperties}
                            ref={instance => this.map = instance}
                            sectorDefinition={data.sectorDefinitionMapData}
                            sectorDefinitionSelected={sectorDefinitionSelected}
                            selectedTileLayerId={tileLayerId}
                            textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                            theoreticalSections={data.theoreticalSectionsMapData}
                            theoreticalSectionsSelected={theoreticalSectionsSelected}
                            tileLayers={[data.tileLayer].concat(data.additionalTileLayers)}
                        />
                    </div>
                    }
                    {data &&
                    <div className="sideBar">
                        {data?.statuses &&
                            this.renderStatuses(data?.statuses)}
                            
                        <div style={{ flex: "1 1 1", width: "100%" }}>
                            <InspectionDrawingControlsButtons
                                currentTool={currentTool}
                                isFeatureTypeSelected={currentFeatureType !== undefined}
                                onEditProperties={this.editProperties}
                                onEscape={this.onEscape}
                                onOk={this.handleOk}
                                onRemove={this.onRemove}
                                onToolChange={this.onToolChange}
                                onUndoDrawing={this.onUndoDrawing}
                            />
                        </div>
                        <div style={{ flex: "1 1 0", width: "100%", height: "100%" }}>
                            <CatalogLastUsed
                                currentFeatureTypeId={currentFeatureType && currentFeatureType.id}
                                lastUsedFeatureTypes={lastUsedFeatureTypes}
                                name={t("labelLastUsedFeature")}
                                onSelect={this.onFeatureTypeSelected}
                                testId={"last-used-feature-type-catalog"}
                            />
                        </div>
                        <Separator/>
                        <div style={{ flex: "1 1 auto", width: "100%", height: "100%", overflow: "auto" }}>
                            <Scrollbar>
                                <CatalogLayer
                                    blockDefinitionSelected={blockDefinitionSelected}
                                    classificationDataSelected={classificationSelected}
                                    hiddenDrawingIds={hiddenAdditionalInspectionDrawingIds}
                                    inspectionDrawings={data.additionalInspectionDrawings || []}
                                    name={t("buttonLayers")}
                                    onBlockDefinitionSelectionChange={this.onBlockDefinitionSelectionChange}
                                    onClassificationDataSelectionChange={this.onClassificationSelectionChange}
                                    onInspectionDrawingVisibilityChange={this.onInspectionDrawingVisibilityChange}
                                    onSectorDefinitionSelectionChange={this.onSectorDefinitionSelectionChange}
                                    onTheoreticalSectionsSelectionChange={this.onTheoreticalSectionsSelectionChange}
                                    onTileLayerSelectionChange={this.onTileLayerSelectionChange}
                                    sectorDefinitionSelected={sectorDefinitionSelected}
                                    selectedTileLayerId={tileLayerId}
                                    testId={"layer-catalog"}
                                    theoreticalSectionsSelected={theoreticalSectionsSelected}
                                    tileLayers={[data.tileLayer].concat(data.additionalTileLayers)}
                                />
                                <CatalogFeatureType
                                    catalog={data.catalog}
                                    currentId={currentFeatureType && currentFeatureType.id}
                                    name={t("labelCatalog")}
                                    onLockChange={this.onFeatureTypeLockChange}
                                    onSelect={this.onFeatureTypeSelected}
                                    onTextVisibilityChange={this.onFeatureTypeTextVisibilityChange}
                                    onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                    testId={"feature-type-catalog"}
                                />
                            </Scrollbar>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }

    public loadData = async () => {
        try {
            const data = await AuthService.get<InspectionTaskPageData>(`inspectiontask/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/data`);
            if (data.offlineStatus)
            {
                for (const element of data.offlineStatus.tasks) {
                    if (element.task.id === this.props.match.params.objectId)
                    {
                        if ( !fieldBrowserSupport.isOfflineMode() && element.dirty) 
                            this.setState({showUploadRequestModal: true})

                        const request = element.drawingRequest;
                        if (request)
                        {
                            // order is important
                            // as the first add new features
                            request.created.forEach(createdFeature => {
                                data.inspectionDrawing.geoJson.features = data.inspectionDrawing.geoJson.features.filter(feature => feature.id !== createdFeature.id);
                                data.inspectionDrawing.geoJson.features.push(createdFeature);
                            });
                            // second update features
                            request.updated.forEach(updatedFeature => {
                                data.inspectionDrawing.geoJson.features = data.inspectionDrawing.geoJson.features.filter(feature => feature.id !== updatedFeature.id);
                                data.inspectionDrawing.geoJson.features.push(updatedFeature);
                            });
                            // and the last remove
                            request.deleted.forEach(deletedFeatureId => {
                                data.inspectionDrawing.geoJson.features = data.inspectionDrawing.geoJson.features.filter(feature => feature.id !== deletedFeatureId);
                            });
                        }
                        break;
                    }
                }
            }
            this.setState({
                data: new InspectionTaskPageData(data),
                hiddenAdditionalInspectionDrawingIds: new Set<string>(data.additionalInspectionDrawings.map(drawing => drawing.id)),
                tileLayerId: data.tileLayer && data.tileLayer.id,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionTask.project.name, data.inspectionTask.structure.name, data.inspectionTask.name);
        } catch (error) {
            this.setState({errorLoadMessage: error.message, waiting:false,})
        }
    };

    private onInspectionDrawingVisibilityChange = (id: string, visible: boolean) => {
        this.setState(prevState => {
            const ids: Set<string> = new Set(prevState.hiddenAdditionalInspectionDrawingIds);
            if (visible) {
                ids.add(id);
            } else {
                ids.delete(id);
            }
            return { hiddenAdditionalInspectionDrawingIds: ids };
        });
    };

    private onFeatureTypeSelected = (featureType: FeatureType) => {
        const { data } = this.state;

        if (this.map && data) {
            this.map.map.setDrawingLayer(featureType.styleCode, featureType.type);
            this.onToolChange(InspectionDrawingTools.Draw);

            this.setState(prevState => {
                let lastUsedFeatureTypes: FeatureType[] = [...prevState.lastUsedFeatureTypes];
                lastUsedFeatureTypes = Array.from(new Set([featureType, ...lastUsedFeatureTypes])).slice(0, 5);
                return {
                    currentFeatureType: featureType,
                    lastUsedFeatureTypes,
                };
            });
        }
    };

    private onUndoDrawing = () => {
        if (this.map) {
            this.map.map.undo();
        }
    };

    private onRemove = () => {
        if (this.map) {
            this.map.map.remove();
        }
    };

    private onEscape = () => {
        if (this.map) {
            this.map.map.escape();
        }
    };

    private handleOk = () => {
        if (this.map) {
            this.map.map.commitDrawing();
        }
    };

    private onToolChange = (tool: InspectionDrawingTools) => {
        const { currentTool } = this.state;

        if (this.map) {
            this.map.map.deactivateCurrentTool();

            switch (tool) {
                case InspectionDrawingTools.Select:
                    this.map.map.changeTool(InspectionDrawingTools.Select);
                    switch (currentTool) {
                        case InspectionDrawingTools.BoxSelect:
                        case InspectionDrawingTools.BoxZoom:
                            break;
                        case InspectionDrawingTools.Select:
                        default:
                            this.map.map.commitDrawing();
                            this.map.map.stopDrawing();
                            break;
                    }
                    break;
                case InspectionDrawingTools.BoxSelect:
                    this.map.map.changeTool(tool);
                    this.map.map.commitDrawing();
                    this.map.map.stopDrawing();
                    break;
                case InspectionDrawingTools.Draw:
                    this.map.map.changeTool(tool);
                    this.map.map.commitDrawing();
                    this.map.map.stopDrawing();
                    if (currentTool !== InspectionDrawingTools.Draw) {
                        this.map.map.startDrawing();
                    }
                    break;
                case InspectionDrawingTools.BoxZoom:
                    this.map.map.changeTool(tool);
                    if (currentTool === InspectionDrawingTools.Draw) {
                        this.map.map.commitDrawing();
                        this.map.map.stopDrawing();
                    }
                    break;
                default:
                    this.map.map.changeTool(InspectionDrawingTools.Select);
            }


            this.setState({ currentTool: this.map.map.currentTool() });
        }
    };

    private editProperties = () => {
        if (this.map) {
            this.map.map.editProperties();
        }
    };

    private onFeatureTypeLockChange = (featureType: FeatureType, locked: boolean) => {
        this.setState(prevState => {
            const codes: Set<string> = new Set(prevState.lockedFeatureTypeCodes);
            if (!locked) {
                codes.add(featureType.code);
            } else {
                codes.delete(featureType.code);
            }

            return { lockedFeatureTypeCodes: codes };
        });
    };

    private onDrawingsChanged = (created: Feature[], updated: Feature[], deleted: string[]) => {
        this.saveChanges(created, updated, deleted);
    };

    private saveChanges = async (created: Feature[], updated: Feature[], deleted: string[]) => {
        const { data } = this.state;
        if (!data) {
            return;
        }
        const request: CommitInspectionDrawingEditingRequest = {
            created,
            deleted,
            measurementId: data.inspectionTask.measurement.id,
            structureId: data.inspectionTask.structure.id,
            taskId: data.inspectionTask.id,
            updated,
            force: false,
        };
        try {
            const url = `inspectiontask/${data.inspectionTask.project.id}/save`
            await AuthService.put(url, request);
            // Logger.info("Inspection drawings saved");
            // Logger.trace(result);
        } catch (error) {
            FlagService.addError(t("textSaveFailed", {
                what: t("labelDrawings"),
                name: "",
            }), error);
        }
    };

    private onEditFeatureProperties = (layers: FeatureLayer[]) => {
        Logger.trace(layers);
        this.setState({
            currentlySelectedFeatures: layers,
            showEditFeaturePropertiesModal: true,
        });
    };

    private hideModals = () => {
        this.setState({
            showEditFeaturePropertiesModal: false,
        });
    };

    private renderStatuses = (statuses : StatusDTO[]) => {
        let lines = [];
        let innerIndex = 0;
        for (let index = 0; index < statuses.length; index++) {
            let status = statuses[index];
            if ((status?.type === StatusType.Tiling || status?.type === StatusType.Drawing) && !status?.done) {
                lines[innerIndex] = 
                <div style={{ flex: "1 1 1", width: "100%", paddingBottom: "5px" }}>
                    <div style={{ flex: "1 1 1", width: "100%", display: "flex" }}>
                        <FlagWarningIcon size={"small"} font-size="11px" />
                        <span style={{ paddingTop: "3px" }}>
                            {status?.type === StatusType.Tiling && t("labelTilingInProgress") + ": " + status.text}
                            {status?.type === StatusType.Drawing && t("labelStoringDrawingInProgress")}
                        </span>
                    </div>
                    <Separator />
                </div>;
                innerIndex++;
            }
        }
        return lines;
    }

    private renderEditFeaturePropertiesModal = () => {
        const { data, currentlySelectedFeatures } = this.state;
        if (!data || !currentlySelectedFeatures) {
            return;
        }
        return (
            <EditInspectionFeaturePropertiesModal
                catalog={data.catalog}
                layers={currentlySelectedFeatures}
                onOk={this.onSaveEditedFeatureProperties}
                // tslint:disable-next-line:jsx-no-lambda
                onCancel={() => {
                    this.hideModals();
                    if (this.map) {
                        this.map.setKeyboardSuppression(false);
                    }
                }}
            />
        );
    };


    private renderUploadRequestModal = () => {
        let { disableSynchronizeButton } = this.state;
        return (
            <Modal
                actions={[{ text: t("buttonSynchronize"), onClick: this.synchronize, isDisabled: disableSynchronizeButton }, { text: t("buttonCancel"), onClick: this.closeModal, isDisabled: disableSynchronizeButton }]}
                onClose={this.closeModal}
                heading={t("Synchronize")}
                scrollBehavior={"outside"}>
                <div>
                    <p>{t("StoreLocalDrawings")}</p>
                    <p>{t("SynchronizeNow")}</p>
                </div>
            </Modal>
        );
    };

    closeModal = () => this.props.history.goBack();
    synchronize = async () => {
        const {data} = this.state;
        this.setState({disableSynchronizeButton: true})
        if (data && data.offlineStatus)
        {
            let task = data.offlineStatus.tasks.find(item => {
                return item.task.id === this.props.match.params.objectId 
            })
            if (task)
            {
                await OfflineStorageService.synchronizeInspectionTask(task);
            }
        }
        this.setState({showUploadRequestModal: false, disableSynchronizeButton: false})
        this.loadData();
    }

    private onSaveEditedFeatureProperties = async () => {
        const { currentlySelectedFeatures } = this.state;
        if (!this.map || !currentlySelectedFeatures) {
            return;
        }
        this.map.updateFeatures(currentlySelectedFeatures);
        this.map.setKeyboardSuppression(false);
        this.hideModals();
    };

}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, InspectionTaskPage,"InspectionTaskPage","AppInsight"));

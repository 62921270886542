import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Redirect } from "react-router";
import Routes from "../helpers/Routes";
import useCookie, { ICookieContent } from "../helpers/CookieHelper";


const CookieConsentModal = () => {

    const [redirectToCookiePolicyPage, setRedirectToCookiePolicyPage] = useState<boolean>(false);
    const {cookieContent, updateCookieContent} = useCookie('cookie-consent', 'false');

    const _onAccept = () => {
        const content: ICookieContent = {
            value: 'true',
            days: 1
        };
        updateCookieContent(content);
    };

    const _onRedirect = () => {
        setRedirectToCookiePolicyPage(true)
    }

    if(redirectToCookiePolicyPage){
        return (<Redirect to={Routes.cookiePolicies.path} />);
    }
    if(cookieContent && JSON.parse(cookieContent.value)){
        return (<></>);
    }
    return (
        <Modal
            appearance={"danger"}
            heading={t("cookieConsentModalTitle")}
            actions={[
                {
                    id: "cookie-consent-button",
                    onClick: _onAccept,
                    text: t("buttonCookieAccept"),
                },
                {
                    id: "cookie-policies-button",
                    onClick: _onRedirect,
                    text: t("buttonCookiePolicies"),
                },
            ]}
            scrollBehavior={"outside"}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
        >
            {t("cookieConsentModalText")}
        </Modal>
    )
};

export default CookieConsentModal;
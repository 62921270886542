import "leaflet";
import { LatLngBounds, Point } from "leaflet";
import "./MapGrid.css";

const options = {
    redraw: 'move',
    showOriginLabel: true,
};

const NormalLineStyle = {
    color: '#111',
    interactive: false,
    opacity: 0.6,
    stroke: true,
    weight: 1,
};

const MainLineStyle = {
    color: '#111',
    interactive: false,
    opacity: 0.6,
    stroke: true,
    weight: 3,
};

declare const L: any;

export default class MapGrid extends L.LayerGroup {
    private map: L.Map;
    private _bounds: LatLngBounds;
    private _size: Point;
    private _isHighRes?: boolean;

    constructor(layers?: L.Layer[], options?: L.LayerOptions, highRes?: boolean){
        super(layers, options);
        
        if(highRes){
            this._isHighRes = highRes;
        }
      }

    public onAdd(map: L.Map) {
        this.map = map;

        const graticule = this.redraw();
        this.map.on('viewreset ' + this.options.redraw, graticule.redraw, graticule);

        this.eachLayer(map.addLayer, map);
    }

    public initialize() {
        L.LayerGroup.prototype.initialize.call(this);
        L.Util.setOptions(this, options);
    }


    public onRemove(map: L.Map) {
        map.off('viewreset ' + this.options.redraw, this.map as any);
        this.eachLayer(this.removeLayer, this);
    }

    public redraw() {
        this._bounds = this.map.getBounds().pad(0.5);
        this._size = this.map.getSize();

        this.clearLayers();

        this.constructLines(this.getLineParams());

        if (this.options.showOriginLabel) {
            this.addLayer(this.addOriginLabel());
        }
        return this;
    }

    private getLineParams() {
        const PixelStep = 50;
        const widthMeter = this._bounds.getEast() - this._bounds.getWest();
        const metersPerPixel = widthMeter / this._size.x;
        const calculatedInterval = this.roundTo125(PixelStep * metersPerPixel);

        return {
            interval: calculatedInterval,
            x_count: Math.ceil((this._bounds.getEast() - this._bounds.getWest()) /
                calculatedInterval),
            x_min: Math.floor(this._bounds.getWest() / calculatedInterval) * calculatedInterval,
            y_count: Math.ceil((this._bounds.getNorth() - this._bounds.getSouth()) /
                calculatedInterval),
            y_min: Math.floor(this._bounds.getSouth() / calculatedInterval) * calculatedInterval,
        };
    }

    private gridLabelHorizontal = () => {
        return this._isHighRes ? 'gridlabel-horiz-highRes' : 'gridlabel-horiz';
    }

    private gridLabelVertical = () => {
        return this._isHighRes ? 'gridlabel-vert-highRes' : 'gridlabel-vert';
    }

    private roundTo125(value: number) {
        const magnitude = Math.floor(Math.log10(value));
        value /= Math.pow(10.0, magnitude);
        if (value < 1.5) {
            value = 1.0;
        }
        else if (value < 3.5) {
            value = 2.0;
        }
        else if (value < 7.5) {
            value = 5.0;
        }
        else {
            value = 10.0;
        }
        value *= Math.pow(10.0, magnitude);
        return value;
    }

    private constructLines(lineParams: any) {
        if (!lineParams.interval) {
            return;
        }
        const lines = new Array(lineParams.x_count + lineParams.y_count);
        const labels = new Array(lineParams.x_count + lineParams.y_count);

        let i = 0;

        // for horizontal lines
        for (i = 0; i <= lineParams.x_count; i++) {
            const x = lineParams.x_min + i * lineParams.interval;
            lines[i] = this.buildXLine(x);
            labels[i] = this.buildLabel(this.gridLabelHorizontal(), x);
        }

        // for vertical lines
        for (let j = 0; j <= lineParams.y_count; j++) {
            const y = lineParams.y_min + j * lineParams.interval;
            lines[j + i] = this.buildYLine(y);
            labels[j + i] = this.buildLabel(this.gridLabelVertical(), y);
        }

        lines.forEach(this.addLayer, this);
        labels.forEach(this.addLayer, this);
    }

    private buildXLine(x: any) {
        const bottomLL = new L.LatLng(this._bounds.getSouth(), x);
        const topLL = new L.LatLng(this._bounds.getNorth(), x);

        let lineStyle = NormalLineStyle;
        if (Math.abs(x) < 1E-6) {
            lineStyle = MainLineStyle;
        }

        return new L.Polyline([bottomLL, topLL], lineStyle);
    }

    private buildYLine(y: any) {
        const leftLL = new L.LatLng(y, this._bounds.getWest());
        const rightLL = new L.LatLng(y, this._bounds.getEast());

        let lineStyle = NormalLineStyle;
        if (Math.abs(y) < 1E-6) {
            lineStyle = MainLineStyle;
        }

        return new L.Polyline([leftLL, rightLL], lineStyle);
    }

    private buildLabel(axis: any, val: any) {
        const zoomFactor = this._isHighRes ? 2 : 1;
        const BottomOffset = 25 * zoomFactor;

        const bounds = this.map.getBounds();
        let latLng;
        if (axis === this.gridLabelHorizontal()) {
            const p = new L.Point(0, this._size.y - BottomOffset);
            const offset = this.map.containerPointToLatLng(p);
            latLng = new L.LatLng(offset.lat, val);
        } else {
            latLng = new L.LatLng(val, bounds.getWest());
        }

        return L.marker(latLng, {
            icon: L.divIcon({
                className: 'leaflet-grid-label',
                html: '<div class="' + axis + '">' + val + '</div>',
                iconSize: [0, 0],
            })
        });
    }

    private addOriginLabel() {
        return L.marker([0, 0], {
            icon: L.divIcon({
                className: 'leaflet-grid-label',
                html: '<div class="gridlabel-horiz">(0,0)</div>',
                iconSize: [0, 0],
            })
        });
    }
}

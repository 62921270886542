import { Field } from "@atlaskit/form";

//import { t } from "i18next";
import React from "react";
import { ISSelectOption, SelectTouch } from "./atlaskit/SelectTouch";



export interface ISingleSelectComboBoxProps {
    id?: string;
    isClearable?: boolean;
    label?: string;
    onChange?: (option?: ISSelectOption) => void;
    options?: ISSelectOption[];
    placeholder?: string;
    required?: boolean;
    selectedOption?: ISSelectOption;
    styles?: any;
    //validate?: (option?: ISSelectOption) => boolean;
}

const formatOptionLabel = (option: ISSelectOption, { context }: any) => {
    if (context === "menu") {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div>{option.label}</div>
                {option.description ? (
                    <div
                        style={{
                            fontSize: 12,
                            fontStyle: "italic",
                        }}
                    >
                        {option.description}
                    </div>
                ) : null}
            </div>
        );
    }
    return option.label;
};

export default function SingleSelectComboBox(props: ISingleSelectComboBoxProps) {
    return (
        <Field
            name="singleSelectComboBox"
            label={props.label}
            isRequired={props.required || false}
            /*
            validators={
                props.validate && [
                    <Validator
                        key="validator"
                        func={props.validate}
                        invalid={t("labelInvalid")}
                        valid={t("labelOk")}
                    />,
                ]
            }
            */
        >
          {() => (
            <SelectTouch
                id={props.id}
                formatOptionLabel={formatOptionLabel}
                isClearable={props.isClearable}
                name="singleselect"
                placeholder={props.placeholder}
                options={props.options}
                value={props.selectedOption}
                onChange={props.onChange}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999}), ...props.styles }}
            />
          )}
        </Field>
    );
}

import Spinner from '@atlaskit/spinner';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import PageTitleItem from '../components/PageTitleItem';
import ProjectPreview from '../components/ProjectPreview';
import ProjectsPageData from '../dto/pages/ProjectsPageData';
import AuthService from '../services/AuthService';

export interface IProjectsPageProps {
    dummy?: string;
}

export interface IProjectsPageState {
    data?: ProjectsPageData;
    waiting: boolean;
}

class ProjectsPage extends React.Component<IProjectsPageProps, IProjectsPageState> {
    constructor(props: IProjectsPageProps) {
        super(props);
        this.state = { waiting:true, };
    }

    public render() {
        const { waiting } = this.state;
        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleProjects")}  testID={"projects-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>
                <div className="projectPreviewContainer">
                    {this.state.data && this.state.data.projects.map((project, index) =>
                        <ProjectPreview
                            key={index}
                            projectSummary={project}
                        />
                    )}
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<ProjectsPageData>(`projects/data`);
            this.setState({ data, waiting:false });
        } catch (error) {
            this.setState({ waiting:false, })
        }
    }
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, ProjectsPage,"ProjectsPage","AppInsight"));

import { ButtonGroup } from "@atlaskit/button";
import CrossIcon from '@atlaskit/icon/glyph/cross';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { t } from "i18next";
import * as _ from "lodash";
import React from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { InspectionDrawingTools } from "../../dto/components/InspectionDrawingTools";

import Button from "../atlaskit/Button";
import {
    BoxSelectIcon, BoxZoomIcon, CheckMarkIcon,
    iconSizes,
    RemoveIcon,
    StartDrawingIcon,
    StartNavigationIcon,
    UndoDrawingIcon,
} from "../Icons";
import Separator from "../Separator";

export interface IControlsButtons extends WithNamespaces {
    currentTool: InspectionDrawingTools;
    onUndoDrawing:()=>void;
    onRemove:()=>void;
    onEscape:()=>void;
    onToolChange:(tool: InspectionDrawingTools)=>void;
    onEditProperties:()=>void;
    onOk:()=>void;
}

export interface IInspectionDrawingControlsButtonsProps extends IControlsButtons{
    isFeatureTypeSelected?: boolean; 
}

type State = {
    iconSize?: iconSizes
}

class InspectionDrawingControlsButtons extends React.Component<IInspectionDrawingControlsButtonsProps, State> {
    state = {iconSize: "medium" as iconSizes};


    public render() {
        const { iconSize } = this.state;

        const { currentTool, isFeatureTypeSelected, onUndoDrawing, onRemove, onEscape, onToolChange, onEditProperties, onOk,
        } = this.props;  
        return(
            <React.Fragment>
                <ButtonGroup>
                    <Button
                        id={"draw-tool-button"}
                        isDisabled={!isFeatureTypeSelected}
                        isSelected={currentTool === InspectionDrawingTools.Draw}
                        onClick={_.partial(onToolChange, InspectionDrawingTools.Draw)}
                        iconBefore={<StartDrawingIcon size={"large"}/>}
                        tooltip={t("buttonDraw")}
                        testId={"drawing-tool-button"}
                    />
                    <Button
                        id={"edit-tool-button"}
                        isSelected={currentTool === InspectionDrawingTools.Select}
                        onClick={_.partial(onToolChange, InspectionDrawingTools.Select)}
                        iconBefore={<StartNavigationIcon />}
                        tooltip={t("buttonSelect")}
                        testId={"edit-tool-button"}
                    />
                    <Button
                        id={"box-zoom-tool-button"}
                        isSelected={currentTool === InspectionDrawingTools.BoxZoom}
                        onClick={_.partial(onToolChange, InspectionDrawingTools.BoxZoom)}
                        iconBefore={<BoxZoomIcon />}
                        tooltip={t("buttonBoxZoom")}
                        testId={"box-zoom-tool-button"}
                    />
                    <Button
                        id={"box-select-tool-button"}
                        isSelected={currentTool === InspectionDrawingTools.BoxSelect}
                        onClick={_.partial(onToolChange, InspectionDrawingTools.BoxSelect)}
                        iconBefore={<BoxSelectIcon />}
                        tooltip={t("buttonBoxSelect")}
                        testId={"box-select-tool-button"}
                    />
                </ButtonGroup>

                <Separator margin={true} />

                <ButtonGroup>
                    <Button
                        onClick={onUndoDrawing}
                        iconBefore={<UndoDrawingIcon />}
                        tooltip={t("buttonUndo")}
                        testId={"undo-button"}
                    />
                    <Button
                        onClick={onOk}
                        iconBefore={<CheckMarkIcon/>}
                        tooltip={t("buttonOk")}
                        testId={"ok-button"}
                    />
                    <Button
                        onClick={onEscape}
                        iconBefore={<CrossIcon label={t("buttonEscape")} size={iconSize} />}
                        tooltip={t("buttonEscape")}
                        testId={"escape-button"}
                    />
                    <Button
                        onClick={onEditProperties}
                        iconBefore={<SettingsIcon label={t("buttonProperties")} size={iconSize} />}
                        tooltip={t("buttonProperties")}
                        testId={"properties-button"}
                    />
                    <Button
                        onClick={onRemove}
                        iconBefore={<RemoveIcon />}
                        tooltip={t("buttonRemoveDrawing")}
                        testId={"remove-button"}
                    />
                </ButtonGroup>

                <Separator margin={true} />
            </React.Fragment>
        )
    }
}

export default withNamespaces()(InspectionDrawingControlsButtons)
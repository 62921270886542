import Button from '../atlaskit/Button';
import React from 'react';
import Separator from '../Separator';
import { ButtonGroup } from '@atlaskit/button';
import { t } from 'i18next';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import {
    iconSizes,
} from "../Icons";

export interface IControlsButtons extends WithNamespaces {
    onCreatePdfReport:()=>void;
    allowPdfReport: boolean;
}

export interface IInspectionAnalysisControlsButtonsProps extends IControlsButtons{
}

type State = {
    iconSize?: iconSizes
}

class InspectionAnalysisControlsButtons extends React.Component<IInspectionAnalysisControlsButtonsProps, State> {
    state = {iconSize: "medium" as iconSizes};


    public render() {
        const { onCreatePdfReport,
        } = this.props;  
        return(
            <React.Fragment>
                <ButtonGroup>
                    <Button
                        onClick={onCreatePdfReport}
                        isSelected={!this.props.allowPdfReport}
                        tooltip={t("buttonOk")}
                        testId={"ok-button"}
                    >🖶</Button>
                </ButtonGroup>
                <Separator margin={true} />
            </React.Fragment>
        )
    }
}

export default withNamespaces()(InspectionAnalysisControlsButtons)
import * as React from "react";
import Button from "../atlaskit/Button";
import FeatureIconProvider from "./FeatureIconProvider";


export interface ICatalogItemProps {
    name: string;
    id?: string,
    iconBefore?: any;
    testId: string;

    isSelected?: boolean,
    onClick?: (e: any) => void;

    isHidden?: boolean,
    onVisibilityToggle?: (featureId?: string) => void;

    isLocked?: boolean,
    onLockToggle?: (featureId?: string) => void;

    isTextHidden?: boolean,
    onTextVisibilityToggle?: (featureId?: string) => void;
}

export default class CatalogItem extends React.Component<ICatalogItemProps, {}> {

    public render() {
        const {id, isSelected, name, testId} = this.props;
        return (
            <Button
                key={id || name}
                id={id || name}
                onClick={this.props.onClick}
                iconBefore={this.props.iconBefore}
                iconAfter={this.getTools()}
                isSelected={isSelected}
                shouldFitContainer={true}
                appearance={"subtle"}
                spacing={"compact"}
                testId={testId}
            >
                <div style={{float:"left", fontSize:12}}>{name}</div>
            </Button>
        );
    };

    private getTools = () => {
        const {id, name, isHidden, isLocked, isTextHidden} = this.props;

        return FeatureIconProvider.getTools({
            id: id || name,
            isHidden,
            isLocked,
            isTextHidden,
            onVisibilityChange: this.getToggleVisibilityMethod(),
            onLockedChange: this.getToggleLockMethod(),
            onTextVisibilityChange: this.getToggleTextVisibilityMethod(),
        })
    };

    private getToggleVisibilityMethod = () => {
        if(this.props.onVisibilityToggle){
            return this.handleFeatureTypeToggle(this.props.onVisibilityToggle)
        }
        return undefined
    };

    private getToggleLockMethod = () => {
        if(this.props.onLockToggle){
            return this.handleFeatureTypeToggle(this.props.onLockToggle)
        }
        return undefined
    };

    private getToggleTextVisibilityMethod = () => {
        if(this.props.onTextVisibilityToggle){
            return this.handleFeatureTypeToggle(this.props.onTextVisibilityToggle)
        }
        return undefined
    };

    private handleFeatureTypeToggle = (method: (id?: string) => void) => {
        if(method){
            return (e: any) => {
                e.stopPropagation();
                method(this.props.id)
            }
        }
        return undefined
    };
}

import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { t } from "i18next";
import "leaflet";
import * as L from "leaflet";
import * as React from "react";
import MapData from "../../dto/maps/MapData";
import Logger from "../../services/Logger";
import Button from "../atlaskit/Button";
import { CheckMarkIcon, DownIcon, LockedIcon, UnlockedIcon, UpIcon } from "../Icons";
import MultiSelectComboBox from "../MultiSelectComboBox";
import SingleSelectComboBox from "../SingleSelectComboBox";
import MapControlInhibitDrawing from "../map-controls/MapControlInhibitDrawing"

type BlockOption = { label: string, value: string, obj: Feature }
type SectorOption = { label: string, value: string, obj: Feature, index: number }
type Props =  { blockDefinition: MapData, 
                sectorDefinition?: MapData, 
                hideSectorSelection?: boolean, 
                initialPosition?: {id: string | undefined, value: string | undefined}}
type State = { currentBlockOption?: BlockOption, currentSectorOptions?: Array<SectorOption>, lockedViewBlounds?: L.LatLngBounds }

export default class MapNavigationControl extends React.Component<Props, State> {
    map: L.Map;

    constructor(props: Props) {
        super(props);
        let firstBlock = this.props.blockDefinition.geoJson.features[0];
        if (this.props.initialPosition)
        {
            let foundItem: Feature<Geometry, GeoJsonProperties> | undefined = undefined;
            if (this.props.initialPosition.id === "block" && this.props.initialPosition.value !== undefined)
            {
                foundItem = this.props.blockDefinition.geoJson.features.find(f => {
                    if (f.properties)
                        return f.properties.name === this.props.initialPosition?.value;
                    else
                        return false;
                });
            }
            else if (this.props.initialPosition.id === "stationing" && this.props.initialPosition.value !== undefined)
            {
                const stationing: number = Number(this.props.initialPosition?.value);
                foundItem = this.props.blockDefinition.geoJson.features.find(f => {
                    const blockBounds = this._getFeatureBounds(f);
                    return blockBounds.getSouth() <= stationing && blockBounds.getNorth() > stationing;
                });
            }
            if (foundItem !== undefined)
                firstBlock = foundItem;
        }
        this.state = {
            currentBlockOption: {
                label: this._getFeatureName(firstBlock),
                value: this._getFeatureName(firstBlock),
                obj: firstBlock,
            } as BlockOption,
            currentSectorOptions: [],
        } as State;
    }

    render() {
        const { hideSectorSelection } = this.props;
        const { currentBlockOption, currentSectorOptions, lockedViewBlounds } = this.state;
        const showDerolementLock =  !this.hasSectors || hideSectorSelection;

        return (
                <div>
                {this.map &&
                        <MapControlInhibitDrawing map={this.map}>
                            <Button
                                id={"map-element-left"}
                                isDisabled={currentBlockOption === undefined}
                                onClick={() => this._updateView(this.nextBlock, currentSectorOptions)}
                                iconBefore={<UpIcon />}
                                testId={"next-block-button"}
                            />
                            <div style={{width: 150, marginTop: -8}}>
                                <SingleSelectComboBox
                                    id={"map-element-left-right"}
                                    placeholder={t("labelSelect")}
                                    options={this.blockOptions}
                                    selectedOption={currentBlockOption}
                                    onChange={(option) => {
                                        this._updateView((option as BlockOption), currentSectorOptions);
                                        this.setState({currentBlockOption: (option as BlockOption)})
                                    }}
                                    styles={{
                                        control: () => ({
                                            cursor: "pointer",
                                            display: 'flex',
                                            justifyContent: "space between",
                                            flexWrap: "wrap",
                                        }),
                                    }}
                                />
                            </div>
                            <Button
                                id={"map-element-left-right"}
                                isDisabled={currentBlockOption === undefined}
                                onClick={() => this._updateView(currentBlockOption, currentSectorOptions)}
                                iconBefore={<CheckMarkIcon label={t("buttonOk")} size={"medium"} />}
                                testId={"current-block-button"}
                            />
                            <Button
                                id={"map-element-right"}
                                isDisabled={currentBlockOption === undefined}
                                onClick={() => this._updateView(this.previousBlock, currentSectorOptions)}
                                iconBefore={<DownIcon />}
                                testId={"previous-block-button"}
                            />
                        </MapControlInhibitDrawing>
                }

                {this.hasSectors && !hideSectorSelection && this.map &&
                    <MapControlInhibitDrawing map={this.map}>
                        <div id={"map-element-container"} data-testid={"select-sector-combobox"}>
                            <div style={{ width: 261, marginTop: -8 }}>
                                <MultiSelectComboBox
                                    id={"map-element-single"}                                  
                                    placeholder={t("labelSelect")}
                                    options={this._getSectorOptions()}
                                    selectedOptions={currentSectorOptions}
                                    onChange={(options: any) => {
                                        this._updateView(currentBlockOption, options);
                                        this.setState({ currentSectorOptions: options })
                                    }}
                                    styles={{
                                        control: () => ({
                                            cursor: "pointer",
                                            display: 'flex',
                                            justifyContent: "space between",
                                            flexWrap: "wrap",
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                    </MapControlInhibitDrawing>
                }
                {showDerolementLock && this.map &&
                    <MapControlInhibitDrawing map={this.map} isRightToLeft={true}>
                        <Button
                                id={"map-element-right"}
                                isDisabled={currentBlockOption === undefined}
                                onClick={() => this._lockDerolement()}
                                iconBefore={lockedViewBlounds === undefined ? <UnlockedIcon  label={t("lockDerolement")}  size={"medium"} /> : <LockedIcon  label={t("unlockDerolement")}  size={"medium"} />}
                                tooltip={lockedViewBlounds === undefined ? t("lockDerolement") : t("unlockDerolement", {left: lockedViewBlounds.getWest().toFixed(1).toString(), right: lockedViewBlounds.getEast().toFixed(1).toString()})}
                                testId={"current-block-button"}
                            />
                    </MapControlInhibitDrawing>
                }
            </div>
            );
    };

    componentDidMount() {
        window.addEventListener("keyup", this._handlePageUpDown);
    };

    componentWillUnmount() {
        window.removeEventListener("keyup", this._handlePageUpDown);
    };

    initialize = (map: L.Map) => {
        const {currentBlockOption, currentSectorOptions} = this.state;

        this.map = map;
        this.map.on('zoomend', () => {
            this._updateCurrentBlock();
        });
        this.map.on('dragend ', () => {
            this._updateCurrentBlock();
        });
        this.map.on('moveend ', () => {
            this._updateCurrentBlock();
        });
        this._updateView(currentBlockOption, currentSectorOptions);
    };

    get blockOptions(): BlockOption[] {
        const { blockDefinition } = this.props;
        const options: BlockOption[] = [];

        blockDefinition.geoJson.features.forEach(block => {
            options.push({
                label: this._getFeatureName(block),
                value: this._getFeatureName(block),
                obj: block,
            })
        });

        return options
    };

    get previousBlock(): BlockOption | undefined {
        return this._getBlockRelativeToCurrent(-1)
    };

    get nextBlock(): BlockOption | undefined {
        return this._getBlockRelativeToCurrent(1)
    };

    _getBlockRelativeToCurrent = (index: number) => {
        const { blockDefinition } = this.props;
        const { currentBlockOption } = this.state;
        if (!blockDefinition || !currentBlockOption) {
            return;
        }
        const currentIndex = blockDefinition.geoJson.features.indexOf(currentBlockOption.obj);
        const currentBlock = blockDefinition.geoJson.features[currentIndex + index];

        if(!currentBlock){
            return
        }
        return {label: this._getFeatureName(currentBlock), value: this._getFeatureName(currentBlock), obj: currentBlock}
    };

    _updateCurrentBlock = () => {
        const { blockDefinition } = this.props;
        const center = this.mapCenter;

        if (!blockDefinition || !center) {
            return;
        }

        let currentBlock = blockDefinition.geoJson.features.find(f => {
            const blockBounds = this._getFeatureBounds(f);
            return (blockBounds && (center.lat >= blockBounds.getSouth() && center.lat <= blockBounds.getNorth())) || false;
        });

        if(!currentBlock){
            currentBlock = blockDefinition.geoJson.features.reduce((previous: Feature, current: Feature): Feature => {
                const prevBounds = this._getFeatureBounds(previous);
                const currentBounds = this._getFeatureBounds(current);
                return Math.abs(center.lat - prevBounds.getSouth()) < Math.abs(center.lat - currentBounds.getSouth()) ? previous : current
            })
        }

        this.setState({
            currentBlockOption:
                currentBlock ?
                    {label: this._getFeatureName(currentBlock), value: this._getFeatureName(currentBlock), obj: currentBlock}
                    :
                    undefined
        });
    };

    _getFeatureBounds = (feature: Feature): L.LatLngBounds => {
        const stationings = (feature as any).geometry.coordinates[0].map((c: number[]) => c[1]);
        const derolments = (feature as any).geometry.coordinates[0].map((c: number[]) => c[0]);
        const minStationing = Math.min(...stationings);
        const maxStationing = Math.max(...stationings);
        const minDerolment = Math.min(...derolments);
        const maxDerolment = Math.max(...derolments);
        return new L.LatLngBounds([minStationing, minDerolment], [maxStationing, maxDerolment]);
    };

    _updateView = (blockOption?: BlockOption, currentSectorOptions?: Array<SectorOption>) => {
        if (!blockOption) {
            return;
        }
        let viewBounds: L.LatLngBounds;
        const blockBounds = this._getFeatureBounds(blockOption.obj);
        const currentSectorBounds = this._getSectorOptionsBound(currentSectorOptions);

        if(currentSectorOptions && currentSectorBounds && !this._isStationingOverlapping(blockBounds, currentSectorBounds)){
            const oldSectorOptions = this._getSectorOptions();
            const newSectorOptions = this._getSectorOptions(blockBounds.getCenter());

            if(oldSectorOptions && newSectorOptions && oldSectorOptions.length === newSectorOptions.length){
                const newCurrentSectorOptions = currentSectorOptions.map((sectorOption) => {
                        return newSectorOptions[sectorOption.index]
                });
                const newCurrentSectorBounds = this._getSectorOptionsBound(newCurrentSectorOptions);

                viewBounds = this._combineBlockAndSectorBounds(blockBounds, newCurrentSectorBounds);
                this.setState({currentSectorOptions: newCurrentSectorOptions});
            }
            else{
                viewBounds = blockBounds;
                this.setState({currentSectorOptions: []});
            }
        }
        else {
            viewBounds = this._combineBlockAndSectorBounds(blockBounds, this.state.lockedViewBlounds === undefined ? currentSectorBounds : this.state.lockedViewBlounds);
        }

        if(this.state.lockedViewBlounds === undefined){
            this.map.flyToBounds(viewBounds.pad(0.03), { animate: false });
        }
        else{
            this.map.flyToBounds(viewBounds, { animate: false });
        }
        
    };

    _lockDerolement = () => {
        if(this.state.lockedViewBlounds){
            this.setState({lockedViewBlounds: undefined});
        }
        else{
            this.setState({lockedViewBlounds: this.map.getBounds()});
        }
    }

    _isStationingOverlapping = (boundA: L.LatLngBounds, boundB: L.LatLngBounds): boolean => {
        const center = boundA.getCenter();
        return (center.lat > boundB.getSouth() && center.lat < boundB.getNorth())
    };

    _combineBlockAndSectorBounds = (blockBounds: L.LatLngBounds, sectorBounds?: L.LatLngBounds): L.LatLngBounds => {
        return new L.LatLngBounds(
            [
                blockBounds.getSouth(),
                sectorBounds? sectorBounds.getWest() : blockBounds.getWest(),
            ], [
                blockBounds.getNorth(),
                sectorBounds? sectorBounds.getEast() : blockBounds.getEast(),
            ]
        );
    };

    _getSectorOptionsBound = (sectorOptions?: Array<SectorOption>): L.LatLngBounds | undefined=> {
        if(sectorOptions && sectorOptions.length > 0){
            return sectorOptions.map((sector): L.LatLngBounds => {
                return this._getFeatureBounds(sector.obj)
            }).reduce((totalBound, nextBound): L.LatLngBounds  => {
                return totalBound.extend(nextBound)
            });
        }
        return;
    };

    _handlePageUpDown = (e: any) => {
        const {currentSectorOptions} = this.state;
        Logger.trace(e.key);
        if (e.key === "PageUp") {
            if (this) {
                this._updateView(this.nextBlock, currentSectorOptions);
            }
        } else if (e.key === "PageDown") {
            if (this) {
                this._updateView(this.previousBlock, currentSectorOptions);
            }
        }
    };

    _getSectorOptions(center?: L.LatLng):  SectorOption[] | undefined {
        const {sectorDefinition} = this.props;
        const sectorFeatures = sectorDefinition && sectorDefinition.geoJson.features;
        const currentCenter = center ? center : this.mapCenter;

        if(sectorFeatures && currentCenter){
            return sectorFeatures.filter((sector: Feature) => {
                const stationings = (sector.geometry as any).coordinates[0].map((c: Array<number>) => c[1]);
                return currentCenter.lat >=Math.min(...stationings) && currentCenter.lat <= Math.max(...stationings)
            }).map((sector: Feature, index: number) => {
                return {
                    label: this._getFeatureName(sector) || `Sector ${index}`,
                    value: sector.id,
                    obj: sector,
                    index: index,
                } as SectorOption
            });
        }
        return;
    };

    get mapCenter(): L.LatLng | undefined {
        if (!this.map) {
            return undefined;
        }
        const center = this.map.getCenter();
        if (!center.lat) {
            return undefined;
        }
        return center
    }

    get hasSectors() {
        return this.props.sectorDefinition && this.props.sectorDefinition.geoJson.features.length > 0
    }

    _getFeatureName = (feature: Feature): string => {
        return feature.properties && feature.properties.name
    }
}

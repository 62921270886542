import { QuickSearch, ResultBase, ResultItemGroup } from '@atlaskit/quick-search';
import { t } from 'i18next';
import { debounce } from 'lodash';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import InspectionAnalysisSearchResult from '../../dto/search/InspectionAnalysisSearchResult';
import ProjectSearchResult from '../../dto/search/ProjectSearchResult';
import SearchResult from '../../dto/search/SearchResult';
import StructureSearchResult from '../../dto/search/StructureSearchResult';
import StructureTaskSearchResult from '../../dto/search/StructureTaskSearchResult';
import authService from '../../services/AuthService';
import Logger from '../../services/Logger';
import { InspectionAnalysisIcon, InspectionTaskIcon, ProjectIcon, StructureIcon } from '../Icons';

export interface ISearchDrawerProps {
    onGoToProject: (result: ProjectSearchResult) => void;
    onGoToStructure: (result: StructureSearchResult) => void;
    onGoToStructureTask: (result: StructureTaskSearchResult) => void;
    onGoToInspectionAnalysis: (result: InspectionAnalysisSearchResult) => void;
}

export interface ISearchDrawerState {
    isLoading: boolean;
    query?: string;
    results?: SearchResult;
}

class SearchDrawer extends React.Component<ISearchDrawerProps, ISearchDrawerState> {
    constructor(props: ISearchDrawerProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    public render() {
        const { isLoading, query, results } = this.state;
        return (
            <div className="quicksearch">
                <QuickSearch
                    isLoading={isLoading}
                    // tslint:disable-next-line:jsx-no-lambda
                    onSearchInput={({ target }: any) => { this.onSearchInputChanged(target.value); }}
                    value={query}
                    placeholder={t("labelSearch")}
                >
                  <div style={{height: "88vh", overflowY: "auto"}}>
                    {results && this.renderResults(results)}
                  </div>
                </QuickSearch>
            </div>
        );
    }

    public componentDidMount() {
        this.onSearchInputChanged("");
    }

    // tslint:disable-next-line:member-ordering
    private onSearchInputChanged = debounce((query: string) => {
        this.setState({
            isLoading: true,
            query,
        });

        if (query) {
            this.getSearchResults(query).then(result => {
                this.setState({
                    isLoading: false,
                    results: result,
                });
            }).catch(reason => {
                Logger.error(reason, "SearchDrawer");
                this.setState({
                    isLoading: false,
                });
            });
        }
        else {
            this.setState({
                isLoading: false,
                results: undefined,
            });
        }
    }, 1000);

    private getSearchResults = async (query: string): Promise<SearchResult> => {
        return await authService.get<SearchResult>(`search/query/${query}`);
    }

    private renderResults = (result: SearchResult) => {
        if (!result.projects.length && !result.structures.length && !result.structureTasks.length && !result.inspectionAnalyses.length) {
            return this.renderNoResults();
        }
        return this.renderSearchResultItems(result);
    };

    private renderNoResults = () => {
        return (
            <ResultItemGroup title={t("labelNoSearchResults")} key="noresults" />
        );
    }

    private renderSearchResultItems = (result: SearchResult) => {
        return (
            <div style={{ marginLeft: "10px" }}>
                {result.projects.length > 0 &&
                    <ResultItemGroup title={t("labelProjects")} key="projectresults">
                        {result.projects.map(_ => this.renderProjectResult(_))}
                    </ResultItemGroup>}
                {result.structures.length > 0 &&
                    <ResultItemGroup title={t("labelStructures")} key="strutureresults">
                        {result.structures.map(_ => this.renderStructureResult(_))}
                    </ResultItemGroup>}
                {result.structureTasks.length > 0 &&
                    <ResultItemGroup title={t("labelInspectionTasks")} key="structuretaskresults">
                        {result.structureTasks.map(_ => this.renderStructureTaskResult(_))}
                    </ResultItemGroup>}
                {result.inspectionAnalyses.length > 0 &&
                    <ResultItemGroup title={t("labelInspectionAnalyses")} key="inspectionanalysisresults">
                        {result.inspectionAnalyses.map(_ => this.renderInspectionAnalysisResult(_))}
                    </ResultItemGroup>}
            </div>
        );
    }

    private renderProjectResult = (result: ProjectSearchResult) => {
        return (
            <ResultBase
                resultId={result.id}
                key={result.id}
                icon={<ProjectIcon label={result.name} />}
                text={result.name}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.onGoToProject(result)}
            />
        );
    }

    private renderStructureResult = (result: StructureSearchResult) => {
        return (
            <ResultBase
                resultId={`${result.project.id}${result.id}`}
                key={`${result.project.id}${result.id}`}
                icon={<StructureIcon label={result.name} />}
                text={result.name}
                subText={`${t("labelProject")}: ${result.project.name}`}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.onGoToStructure(result)}
            />
        );
    }

    private renderStructureTaskResult = (result: StructureTaskSearchResult) => {
        return (
            <ResultBase
                resultId={`${result.project.id}${result.id}`}
                key={`${result.project.id}${result.id}`}
                icon={<InspectionTaskIcon label={result.name} />}
                text={result.name}
                subText={`${t("labelProject")}: ${result.project.name}, ${t("labelStructure")}: ${result.structure.name}`}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.onGoToStructureTask(result)}
            />
        );
    }

    private renderInspectionAnalysisResult = (result: InspectionAnalysisSearchResult) => {
        return (
            <ResultBase
                resultId={`${result.project.id}${result.id}`}
                icon={<InspectionAnalysisIcon label={result.name} />}
                text={result.name}
                subText={`${t("labelProject")}: ${result.project.name}, ${t("labelStructure")}: ${result.structure.name}`}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.onGoToInspectionAnalysis(result)}
            />
        );
    }
}

export default compose<any, any>(withNamespaces())(SearchDrawer)


import Badge from '@atlaskit/badge';
import * as React from 'react';
import Constants from '../helpers/Constants';

export interface IBadgeWithLabelProps {
    label: string;
    highlighted?: boolean;
}

export default function BadgeWithLabel(props: IBadgeWithLabelProps) {
    return (
        <Badge
            appearance={props.highlighted ? { backgroundColor: Constants.colorForegroundM1blue, textColor: Constants.colorForegroundM3white } : "default"}
        >
            {props.label}
        </Badge>

    );
}

import { DropdownItem } from '@atlaskit/dropdown-menu';
import * as React from "react";

export interface ITableItemWithPopupProps {
  textItem: string;
  onClick: () => void;
}

export interface ITableItemWithPopupState {
  isOpen: boolean;
}

class CustomItem extends React.Component<
  ITableItemWithPopupProps,
  ITableItemWithPopupState
> {
  constructor(props: ITableItemWithPopupProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  public render() {
    return (
      <React.Fragment>
        <DropdownItem 
          key={this.props.textItem}
          onClick={this.open}
        >
            {this.props.children}
        </DropdownItem>
      </React.Fragment>
    );
  }

  private open = () => this.props.onClick();

  private close = () => this.setState({ isOpen: false });
}

export default CustomItem;

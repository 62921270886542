import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import "./i18n";
import './index.css';
import * as serviceWorker from './serviceWorker';
import FieldBrowserSupport from './helpers/FieldBrowserSupport'
import swConfig from './helpers/swConfig'
import Logger from './services/Logger';
import AuthService from "./services/AuthService";

FieldBrowserSupport.initialize().then(async () => {
    if (FieldBrowserSupport.isFieldBrowser()) {
        Logger.info(`Application is running in Amberg Inspector application`);
    } else {
        Logger.info(`Application is running in a browser`)
    }
    if (FieldBrowserSupport.isOfflineMode()) {
        await AuthService.setOfflinePolicies();
    }

    ReactDOM.render(
        <App />,
        document.getElementById('root') as HTMLElement
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);

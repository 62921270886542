import * as React from 'react';
import Toggle, {IToggleProps} from "../atlaskit/Toggle";
import LabelWrapper, { ILabelWrapperProps } from './Wrapper';

interface IToggleLabeled extends ILabelWrapperProps, IToggleProps {}

export default function ToggleLabeled(props: IToggleLabeled) {
  const { label,   ...rest } = props as IToggleProps;
  return (
    <LabelWrapper label={label} labelMarginTop={props.labelMarginTop}>
      <div style={{ height: "32px" }}>
        <div style={{ marginTop: "12px" }}>
          <Toggle label={""}{...rest}/>
        </div>
      </div>
    </LabelWrapper>
  );
}

import "leaflet";
/*global L*/

L.Map.mergeOptions({
    boundingBox: true
});

export var BoundingBox = L.Handler.extend({
    initialize: function (map) {
        this._map = map;
        this._container = map._container;
        this._pane = map._panes.overlayPane;
        this._resetStateTimeout = 0;
        this._active = false;
        map.on('unload', this._destroy, this);
    },

    setAlwaysActive: function (mode) {
        this._active = mode
    },

    addHooks: function () {
        L.DomEvent.on(this._container, 'mousedown', this._onMouseDown, this);
    },

    removeHooks: function () {
        L.DomEvent.off(this._container, 'mousedown', this._onMouseDown, this);
    },

    moved: function () {
        return this._moved;
    },

    _destroy: function () {
        L.DomUtil.remove(this._pane);
        delete this._pane;
    },

    _resetState: function () {
        this._resetStateTimeout = 0;
        this._moved = false;
    },

    _clearDeferredResetState: function () {
        if (this._resetStateTimeout !== 0) {
            clearTimeout(this._resetStateTimeout);
            this._resetStateTimeout = 0;
        }
    },

    _onMouseDown: function (e) {
        if(
            (!e.shiftKey && !this._active) ||
            ((e.which !== 1) && (e.button !== 1))
        ){return false;}

        this._map.dragging.disable();

        this._clearDeferredResetState();
        this._resetState();

        L.DomUtil.disableTextSelection();
        L.DomUtil.disableImageDrag();

        this._startPoint = this._map.mouseEventToContainerPoint(e);

        L.DomEvent.on(document, {
            contextmenu: L.DomEvent.stop,
            mousemove: this._onMouseMove,
            mouseup: this._onMouseUp,
            keydown: this._onKeyDown
        }, this);
    },

    _onMouseMove: function (e) {
        if (!this._moved) {
            this._moved = true;

            this._box = L.DomUtil.create('div', 'leaflet-zoom-box', this._container);
            L.DomUtil.addClass(this._container, 'leaflet-crosshair');
        }

        this._point = this._map.mouseEventToContainerPoint(e);

        var bounds = new L.Bounds(this._point, this._startPoint),
            size = bounds.getSize();

        L.DomUtil.setPosition(this._box, bounds.min);

        this._box.style.width = size.x + 'px';
        this._box.style.height = size.y + 'px';
    },

    _finish: function () {
        if (this._moved) {
            L.DomUtil.remove(this._box);
            L.DomUtil.removeClass(this._container, 'leaflet-crosshair');
        }

        L.DomUtil.enableTextSelection();
        L.DomUtil.enableImageDrag();

        L.DomEvent.off(document, {
            contextmenu: L.DomEvent.stop,
            mousemove: this._onMouseMove,
            mouseup: this._onMouseUp,
            keydown: this._onKeyDown
        }, this);
    },

    _onMouseUp: function (e) {
        if ((e.which !== 1) && (e.button !== 1)) { return; }

        this._finish();

        if (!this._moved) { return; }
        this._clearDeferredResetState();
        this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0);

        var bounds = new L.LatLngBounds(
            this._map.containerPointToLatLng(this._startPoint),
            this._map.containerPointToLatLng(this._point));

        this._map.fire('boundingbox', { bounds, ctrlKey: e.ctrlKey });
        this._map.dragging.enable();
    },

    _onKeyDown: function (e) {
        if (e.keyCode === 27) {
            this._finish();
        }
    }
});

L.Map.addInitHook('addHandler', 'boundingBox', BoundingBox);

import FeatureType from "./FeatureType";
import ProjectItemBasics from "./ProjectItemBasics";

export default class FeatureTypeGroupData extends ProjectItemBasics {
    public phenomena: FeatureType[];
    public isLocked?: boolean;
    public isHidden?: boolean;

    get featureTypes(): FeatureType[]{
            return this.phenomena;
    }
    
    constructor(data: FeatureTypeGroupData){
        super(data.id, data.name);
        this.phenomena = []; 
        for (const feature of data.phenomena){     
            this.phenomena.push(new FeatureType(feature));
        }
    }

}

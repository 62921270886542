import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Table, {
    DateTimeTableColumn,
    IconTableColumn,
    LinkTableColumn,
    TableItemAction,
    TextTableColumn,
    ToggleTableColumn,
    NotSortableTextTableColumn,
    FileIconTableColumn,
    StateItemAction,
    MultipleStateIconActionTableColumn
} from "../components/Table";
import InspectionTaskSummary from '../dto/components/InspectionTaskSummary';
import OfflineInspectionTaskSummary from '../dto/components/OfflineInspectionTaskSummary';
import InspectionHelper from '../helpers/InspectionHelper';
import Policies from '../helpers/Policies';
import Routes from '../helpers/Routes';
import OfflineStorageService from '../services/OfflineStorageService';
import FieldBrowserSupport from '../helpers/FieldBrowserSupport'
import CommitInspectionDrawingEditingRequest from '../dto/requests/CommitInspectionDrawingEditingRequest';
import {AutomaticInspectionIcon, RefreshImagesIcon} from "../components/Icons";
import StructureMeasurementSumary from '../dto/components/StructureMeasurementSumary';
import fieldBrowserSupport from '../helpers/FieldBrowserSupport';
import StatusDTO, { StatusType } from 'dto/components/StatusDTO';

export interface IInspectionTableProps extends WithNamespaces {
    showProjectColumn: boolean;
    showStructureColumn: boolean;
    showControls: boolean;
    inspectionTasks?: InspectionTaskSummary[];
    measurements: StructureMeasurementSumary[];
    offlineTasks?: OfflineInspectionTaskSummary[];
    onAutoInspection?:(taskSummary: InspectionTaskSummary) => Promise<void>;
    onRefreshImages?:(taskSummary: InspectionTaskSummary) => Promise<void>;
}

export interface IInspectionTableState {
    offlineTasks: OfflineInspectionTaskSummary[];
    disableTaskActions: boolean;
}

class InspectionTable extends React.Component<IInspectionTableProps, IInspectionTableState> {

    constructor(props: IInspectionTableProps) {
        super(props);
        let { offlineTasks } = this.props;
        if (!offlineTasks) {
            offlineTasks=[];
        }
        this.state = {
            offlineTasks,
            disableTaskActions: FieldBrowserSupport.isOfflineMode(),
        };
    }

    public render() {
        const { inspectionTasks, measurements } = this.props;
        const { offlineTasks, disableTaskActions } = this.state;

        const show_state = (typeof inspectionTasks === undefined) ? false : inspectionTasks!.some(e => e.status?.done === false);
        let columns = [];
        columns.push(new IconTableColumn<InspectionTaskSummary>("", item => InspectionHelper.iconForStructureTaskType(item.type)));
        Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems) && columns.push(new NotSortableTextTableColumn<InspectionTaskSummary>("", item => {
            for (const element of offlineTasks) {
                if (element.task.id === item.id)
                {
                    return element.dirty ? "*": "";
                }
            }
            return "";
        }));
        columns.push(new LinkTableColumn<InspectionTaskSummary>(
            t("labelName"),
            item => item.name,
            Policies.isValidPolicy(Policies.toSeeInspection) ?
                item => Routes.task.patch(item.project.id, item.structure.id, item.type, item.id).path
                :
                undefined
        ));
        //columns.push(new TextTableColumn<InspectionTaskSummary>(t("labelType"), item => InspectionHelper.nameForStructureTask(item.type)));
        this.props.showProjectColumn && columns.push(new LinkTableColumn<InspectionTaskSummary>(
            t("labelProject"),
            item => item.project.name,
            Policies.isValidPolicy(Policies.toSeeProject) ?
              item => Routes.project.patch(item.project.id).path
              :
              undefined
          ));
          this.props.showStructureColumn && columns.push(new LinkTableColumn<InspectionTaskSummary>(
            t("labelStructure"),
            item => item.structure.name,
            Policies.isValidPolicy(Policies.toSeeProject) && Policies.isValidPolicy(Policies.toSeeStructures) ?
              item => Routes.projectStructure.patch(item.project.id, item.structure.id).path
              :
              undefined
        ));
        columns.push(new TextTableColumn<InspectionTaskSummary>(t("labelMeasurement"), item => item.measurement.name));
        columns.push(new DateTimeTableColumn<InspectionTaskSummary>(t("labelUpdated"), item => new Date(item.updated)));
        !fieldBrowserSupport.isOfflineMode() && measurements && measurements.length > 0 && columns.push(new FileIconTableColumn<InspectionTaskSummary>(t("labelFiles"), 
            (item:InspectionTaskSummary)=>{
                for (const measurement of measurements) {
                    if (measurement.id === item.measurement.id && measurement.filesCount > 0)
                    {
                        return Routes.projectStructureMeasurementFiles.patch(item.project.id,item.structure.id, measurement.id).path
                    }
                }
                return "";
            }));
        Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems) && columns.push(new ToggleTableColumn<InspectionTaskSummary>(t("labelDownloaded"),
            item => {
                for (const element of offlineTasks) {
                    if (element.task.id === item.id)
                    {
                        return element.downloaded ? "0_" + item.name : "1_" + item.name;
                    }
                }
                return "1_" + item.name;
            },
            item => new TableItemAction(t("buttonDownload"), this.onSetTaskAsOffline),
            item => new TableItemAction(t("buttonUpload"), this.onSetTaskAsOnline),
            item => {
                for (const element of offlineTasks) {
                    if (element.task.id === item.id)
                    {
                        return { isDisabled:disableTaskActions, isChecked:element.downloaded };
                    }
                }
                return { isDisabled:disableTaskActions, isChecked:false };
            }
        ));

        this.props.showControls && columns.push(new MultipleStateIconActionTableColumn<InspectionTaskSummary>(_ => [ 
            new StateItemAction(
                `${_.id}-re-tile`,
                t("buttonRefreshImages"),
                icon => 
                { 
                    if (icon.status === undefined || icon.status === null)
                        return <RefreshImagesIcon />  
                    else
                        return <RefreshImagesIcon disabled={!icon.status?.done}/> 
                
                }, 
                action => { 
                    if (action.status !== undefined && action.status !== null)
                        return new TableItemAction("", this.onRefreshImages, !action.status.done);
                    else
                        return new TableItemAction("", this.onRefreshImages, false)
            }),
            this.seeAutoInspection() &&
            new StateItemAction(
                `${_.id}-auto-inspection`,
                t("buttonAutomaticInspection"),
                icon => 
                { 
                    if (icon.status === undefined || icon.status === null)
                        return <AutomaticInspectionIcon />  
                    else
                        return <AutomaticInspectionIcon disabled={!icon.status?.done}/> 
                
                }, 
                action => { 
                    if (action.status !== undefined && action.status !== null)
                        return new TableItemAction("", this.onAutoInspection, !action.status.done);
                    else
                        return new TableItemAction("", this.onAutoInspection, false)
            }),
            ])
        )

        this.props.showControls && show_state && columns.push(new TextTableColumn<InspectionTaskSummary>(t("labelAutomaticInspectionStatus") , item => {
            if (item === undefined){
                return ""
            }
            var task = item as InspectionTaskSummary;
            if (task.status as StatusDTO){
                if (task.status.type === StatusType.AutomaticInspection)
                {
                    return t("labelAutomaticInspection") + ": " + task.status.text;
                }
                else if (task.status.type === StatusType.Tiling)
                {
                    return t("labelTilingInProgress") + ": " + task.status.text;
                }
                else if (task.status.type === StatusType.Drawing)
                {
                    return "";
                }
                else {
                    return "";
                }
            }
            else {
                return "";
            }
        }))
        
        return (
            <Table
            data={inspectionTasks}
            columns={columns}
        />
        );
    }

    public async componentDidUpdate(prevProps: IInspectionTableProps, prevState: IInspectionTableState) {
        if (this.props.offlineTasks !== prevProps.offlineTasks) {
            const offlineTasks = this.props.offlineTasks;
            if (offlineTasks)
            {
                this.setState({ offlineTasks });
            }
        }
    }

    private onSetTaskAsOffline = async (taskSummary: InspectionTaskSummary) => {
        this.setState({ disableTaskActions:true });
        const downloaded = await OfflineStorageService.downloadInspectionTask(taskSummary);
        if (downloaded){
            const { offlineTasks } = this.state;
            offlineTasks.push({ downloaded: true, dirty: false, task: taskSummary });
            this.setState({ offlineTasks, disableTaskActions:FieldBrowserSupport.isOfflineMode() });
        } else {
            this.setState({ disableTaskActions:FieldBrowserSupport.isOfflineMode() });
        }
    }
    
    private onSetTaskAsOnline = async (taskSummary: InspectionTaskSummary) => {
        this.setState({ disableTaskActions:true });
        const { offlineTasks } = this.state;
        let commitDrawingRequest: CommitInspectionDrawingEditingRequest|undefined = undefined;
        const index = offlineTasks.findIndex(e => e.task.id === taskSummary.id);
        if (index > -1) {
            const r = offlineTasks[index].drawingRequest;
            if (r) {
                commitDrawingRequest = {
                    created: r.created,
                    deleted: r.deleted,
                    measurementId: taskSummary.measurement.id,
                    structureId: taskSummary.structure.id,
                    taskId: taskSummary.id,
                    updated: r.updated,
                    force: true,
                };
            }
        }
        await OfflineStorageService.uploadAndRemoveInspectionTask(taskSummary, commitDrawingRequest);
        if (index > -1) {
            offlineTasks.splice(index, 1);
        }
        this.setState({ offlineTasks, disableTaskActions:FieldBrowserSupport.isOfflineMode() });
    }
    
    private onAutoInspection = async(taskSummary: InspectionTaskSummary) => {
        if (this.props.onAutoInspection)
            await this.props.onAutoInspection(taskSummary);
    }

    private seeAutoInspection = () => {
        return this.props.onAutoInspection && Policies.isValidPolicy(Policies.toSeeAutomaticInspection);
    }

    private onRefreshImages = async(taskSummary: InspectionTaskSummary) => {
        if (this.props.onRefreshImages) {
            await this.props.onRefreshImages(taskSummary);
        }
        else {
            console.info("onRefreshImages called")
        }
    }
}

export default withNamespaces()(InspectionTable)

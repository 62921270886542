import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import { Layer } from 'leaflet';
import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import SingleSelectComboBoxLabeled from '../components/labeled/SingleSelectComboBoxLabeled';
import TextBoxLabeled from '../components/labeled/TextBoxLabeled';
import CatalogData from '../dto/components/CatalogData';
import { GeometryType } from '../dto/components/GeometryType';
import InspectionHelper from '../helpers/InspectionHelper';

export interface IEditInspectionFeaturePropertiesModalProps extends WithNamespaces {
    catalog: CatalogData;
    layers: Layer[];
    onCancel: () => void;
    onOk: () => void;
}

export interface IEditInspectionFeaturePropertiesModalState {
    codes: {Point?: number, Line?: number, Area?: number};
    comments: {Point: string, Line: string, Area: string};
    labels: {Point: string, Line: string, Area: string};
}

class EditInspectionFeaturePropertiesModal extends React.Component<IEditInspectionFeaturePropertiesModalProps, IEditInspectionFeaturePropertiesModalState> {
    constructor(props: IEditInspectionFeaturePropertiesModalProps) {
        super(props);
        this.state = {
            comments: {Point: "", Line: "", Area: ""},
            codes: {Point: undefined, Line: undefined, Area: undefined},
            labels:  {Point: t("labelPoint"), Line: t("labelLine"), Area: t("labelArea")},
        }
    }

    public render() {
        const geometryTypes: Set<GeometryType> = new Set();
        this.props.layers.forEach(layer => {
            const geometryType = InspectionHelper.geometryTypeForFeature((layer as any).feature);
            if (geometryType){
                geometryTypes.add(geometryType)
            }
        });

        return (
            <Modal
                heading={t("modalTitleEditFeatureProperties")}
                actions={[{
                    onClick: this.onSave,
                    text: t("buttonSave"),
                    id:"save-feature-properties",
                }, {
                    onClick: this.props.onCancel,
                    text: t("buttonCancel"),
                }]}
                onClose={this.props.onCancel}
            >
                <form className="modalForm" onSubmit={this.onSave}>
                    {this.renderInputFields(geometryTypes)}
                </form>
            </Modal>
        );
    }

    private renderInputFields = (geometryTypes: any) => {
        const { codes, comments, labels } = this.state;
        const comboBoxes: any[] = [];

        geometryTypes.forEach((geometryType: any) => {
            const featureOptions: any = [];
            this.props.catalog.groups.forEach(featureTypeGroups => {
                const featureTypesSorted = featureTypeGroups.featureTypes.sort(
                    (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
                );

                featureTypesSorted.forEach(
                    featureType => {
                        if (featureType.type === geometryType) {
                            featureOptions.push({
                                label: `${featureTypeGroups.name} - ${featureType.name}`,
                                value: featureType.code,
                            })
                        }
                    }
                )
            });

            const selectedFeatureOption = featureOptions.find((o:any) => o.value === codes[geometryType]);

            let comment="";
            const features = this.props.layers.filter((_:any) => InspectionHelper.geometryTypeForFeature(_.feature) === geometryType)
            if (features.length === 1) {
                comment = (features[0] as any).feature.properties.remark
            }

            comboBoxes.push(
                <React.Fragment key={geometryType}>
                    <SingleSelectComboBoxLabeled
                        key={`combobox_${labels[geometryType]}`}
                        label={labels[geometryType]}
                        placeholder={t("labelSelect")}
                        options={featureOptions}
                        selectedOption={selectedFeatureOption}
                        onChange={this.onFeatureTypeSelectionChange(geometryType)}
                    />
                    <TextBoxLabeled
                        key={`textbox_${labels[geometryType]}`}
                        label={t("labelRemark")}
                        placeholder={comment}
                        value={comments[geometryType] || comment}
                        onChange={this.onCommentChange(geometryType)}
                        testId = {"remark-textbox"}
                        id = {"remark-textbox_"+ geometryType}
                    />
                </React.Fragment>
            )
        });
        return comboBoxes
    }

    private onFeatureTypeSelectionChange = (geometryType: string) => {
        const codes = {...this.state.codes};
        return (option?: ISSelectOption) => {
            codes[geometryType] = option && option.value;
            this.setState({ codes });
        }
    }

    private onCommentChange = (geometryType: string) => {
        const comments = {...this.state.comments};
        return (comment?: string) => {
            comments[geometryType] = comment;
            this.setState({ comments });
        }
    }

    private onSave = async (event: any) => {
        event.preventDefault();

        const { codes, comments} = this.state;
        const { layers } = this.props;

        const featureTypes: Set<GeometryType> = new Set();
        this.props.layers.forEach(layer => {
            const featureType = InspectionHelper.geometryTypeForFeature((layer as any).feature);
            if (featureType){
                featureTypes.add(featureType)
            }
        });
        featureTypes.forEach(geometryType => {
            layers.forEach(layer => {
                if (InspectionHelper.geometryTypeForFeature((layer as any).feature) === geometryType){
                    if ((layer as any).feature.properties && codes && codes[geometryType]) {
                        (layer as any).feature.properties.prevCode = (layer as any).feature.properties.code;
                        (layer as any).feature.properties.code = codes[geometryType];
                    }
                    if ((layer as any).feature.properties && comments && comments[geometryType]) {
                        (layer as any).feature.properties.remark = comments[geometryType];
                    }
                }
            })
        });
        this.props.onOk();
    }
}

export default withNamespaces()(EditInspectionFeaturePropertiesModal)

import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import PageTitleItem from '../components/PageTitleItem';
import IRouteParams from '../helpers/IRouteParams';
import AuthService from '../services/AuthService';

export interface IAdministrationPageProps extends RouteComponentProps<IRouteParams> {
    dummy?: string;
}

export interface IAdministrationPageState {
    data?: any;
}

class AdministrationPage extends React.Component<IAdministrationPageProps, IAdministrationPageState> {
    constructor(props: IAdministrationPageProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleAdministration")} testID={"admin-page-title"}/>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            //
        } catch (error) {
            //
        }
    }
}

export default compose(withNamespaces())(AdministrationPage)

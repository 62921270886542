import Spinner from "@atlaskit/spinner";
import * as chartjs from "chart.js";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import { t } from "i18next";
import * as React from "react";
import { Bar, ChartData } from "react-chartjs-2";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import PageTitleItem from "../components/PageTitleItem";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import TitleItem from "../components/TitleItem";
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import { ICatalogItemProps } from "../components/toolbar/CatalogItem";
import CatalogLayerGroupSingleChoice
    from "../components/toolbar/CatalogLayerGroupSingleChoice";
import FeatureType from "../dto/components/FeatureType";
import { GeometryType } from "../dto/components/GeometryType";
import StructureMeasurementBasics from "../dto/components/StructureMeasurementBasics";
import InspectionAnalysisChartsPageData
    from "../dto/pages/InspectionAnalysisChartsPageData";
import Constants from "../helpers/Constants";
import IRouteParams from "../helpers/IRouteParams";
import AuthService from "../services/AuthService";
import Logger from "../services/Logger";

export interface IInspectionAnalysisSingleBlockBasedChartsPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string, structureItemName: string) => void;
}

export interface IInspectionAnalysisSingleBlockBasedChartsPageState {
    data?: InspectionAnalysisChartsPageData;
    measurements: StructureMeasurementBasics[];
    measurementId?: string;
    blockId?: string;
    hiddenFeatureTypeCodes: Set<string>;
    waiting: boolean;
}

class InspectionAnalysisSingleBlockBasedChartsPage extends React.Component<IInspectionAnalysisSingleBlockBasedChartsPageProps, IInspectionAnalysisSingleBlockBasedChartsPageState> {
    private overviewChart: Bar | null;
    constructor(props: IInspectionAnalysisSingleBlockBasedChartsPageProps) {
        super(props);
        this.state = {
            hiddenFeatureTypeCodes: new Set<string>(),
            measurements: [],
            waiting: true,
        };
    }

    public render() {
        const { data, waiting } = this.state;

        const overviewChartData = this.getOverviewChartData();
        const detailChartData = this.getDetailChartData();

        const overviewChartOptions = this.getOverviewChartOptions(overviewChartData);
        const detailChartOptions = this.getDetailChartOptions(detailChartData);

        return (
            <div className="pageMap">
                <PageTitleItem title={t("pageTitleBlockBasedCharts")}  testID={"inspection-analysis-single-block-based-charts-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>
                <div className="pageMaximized">
                    <div className="diagramsvertical">
                        <TitleItem title={t("sectionTitleBlockOverview")} />
                        <div style={{ height: "120px" }}>
                            {overviewChartData && <Bar
                                ref={instance => this.overviewChart = instance}
                                data={overviewChartData}
                                legend={{ display: false }}
                                options={(overviewChartOptions as any)}
                            />}
                        </div>
                        <TitleItem title={t("sectionTitleBlockDetail")} />
                        <div style={{ flex: 1 }}>
                            <div style={{ height: "99%" }}>
                                {detailChartData && <Bar
                                    data={detailChartData}
                                    legend={{ display: false }}
                                    options={detailChartOptions}
                                />}
                            </div>
                        </div>
                    </div>
                    {data &&
                    <div className="sideBar">
                        <CatalogLayerGroupSingleChoice
                            name={t("labelInspectionDrawings")}
                            items={this.getMeasurementItems()}
                            appearance={"primary"}
                            isExpanded={true}
                        />
                        <Separator/>
                        <Scrollbar>
                            <CatalogFeatureType
                                catalog={data.catalog}
                                isExpanded={true}
                                onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                testId={"feature-type-catalog"}
                            />
                        </Scrollbar>
                    </div>
                    }
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisChartsPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/chartdata`);
            const firstMeasurement = data.measurements[0];
            const firstChartData = firstMeasurement && data.inspectionChartDatas.find(d => d.id === firstMeasurement.id);
            const firstBlock = firstChartData && firstChartData.statisticsPerBlock[0];

            this.setState({
                blockId: firstBlock && firstBlock.id,
                data: new InspectionAnalysisChartsPageData(data),
                measurementId: firstMeasurement && firstMeasurement.id,
                measurements: data.measurements,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            this.setState({ waiting: false })
        }
    }

    private getMeasurementItems = (): ICatalogItemProps[] => {
        return this.state.measurements.map(measurement => {
            const isHidden = this.state.measurementId !== measurement.id;
            return {
                name: measurement.name,
                id: measurement.id,
                isHidden,
                onVisibilityToggle: this.getMeasurementSelectionChangeMethod(measurement.id),
                testId: `item-id?${measurement.id}`,
            }}
        )
    };


    private getMeasurementSelectionChangeMethod = (measurementId?: string) => {
        return () => this.setState({ measurementId });
    }

    public onFeatureTypeVisibilityChange = (featureType: FeatureType, visible: boolean) => {
        this.setState(prevState => {
            const codes: Set<string> = new Set(prevState.hiddenFeatureTypeCodes);

            if (visible) {
                codes.delete(featureType.code);
            } else {
                codes.add(featureType.code);
            }

            return { hiddenFeatureTypeCodes: codes };
        });
    };

    private getOverviewChartData = (): ChartData<chartjs.ChartData> | undefined => {
        const { data, measurementId, blockId, hiddenFeatureTypeCodes } = this.state;
        if (!data) {
            return;
        }
        const chartData = data.inspectionChartDatas.find(d => d.id === measurementId);
        if (!chartData) {
            return;
        }

        const centerBlockIndex = chartData.statisticsPerBlock.findIndex(_ => _.id === blockId);

        const dataLabels = chartData.statisticsPerBlock.map(_ => _.name);

        const pointValues = chartData.statisticsPerBlock.map(block => {
            return block.statistics.filter(_ => _.type === GeometryType.Point && !hiddenFeatureTypeCodes.has(_.code)).reduce((a, b) => a + b.value, 0);
        });
        const lineValues = chartData.statisticsPerBlock.map(block => {
            return block.statistics.filter(_ => _.type === GeometryType.Line && !hiddenFeatureTypeCodes.has(_.code)).reduce((a, b) => a + b.value, 0);
        });
        const areaValues = chartData.statisticsPerBlock.map(block => {
            return block.statistics.filter(_ => _.type === GeometryType.Area && !hiddenFeatureTypeCodes.has(_.code)).reduce((a, b) => a + b.value, 0);
        });

        const result: ChartData<chartjs.ChartData> = {
            datasets: [{
                backgroundColor: this.colorsForOverviewDatasets(pointValues, centerBlockIndex),
                data: pointValues,
                label: "Count",
            }, {
                backgroundColor: this.colorsForOverviewDatasets(lineValues, centerBlockIndex),
                data: lineValues,
                label: "Length",
            }, {
                backgroundColor: this.colorsForOverviewDatasets(areaValues, centerBlockIndex),
                data: areaValues,
                label: "Area",
            }],
            labels: dataLabels,
        };

        return result;
    }

    private getDetailChartData = (): ChartData<chartjs.ChartData> | undefined => {
        const { data, measurementId, blockId, hiddenFeatureTypeCodes } = this.state;
        if (!data) {
            return;
        }
        const chartData = data.inspectionChartDatas.find(d => d.id === measurementId);
        if (!chartData) {
            return;
        }

        const centerBlockIndex = chartData.statisticsPerBlock.findIndex(_ => _.id === blockId);
        if (centerBlockIndex < 0) {
            return;
        }

        const fromIndex = Math.max(centerBlockIndex - 2, 0);
        const toIndex = Math.min(centerBlockIndex + 3, chartData.statisticsPerBlock.length );
        const statisticsPerBlockSubset = chartData.statisticsPerBlock.slice(fromIndex, toIndex);

        const dataLabels = statisticsPerBlockSubset.map(_ => _.name);

        const allFeatureTypes = data.catalog.groups.map(l => l.featureTypes).reduce((a, b) => a.concat(b), []);
        const allFeatureDatasets = allFeatureTypes.filter(f => !hiddenFeatureTypeCodes.has(f.code)).map(p => {
            const values = statisticsPerBlockSubset.map(_ => {
                const valueInBlock = _.statistics.find(i => i.code === p.styleCode);
                return (valueInBlock && valueInBlock.value) || 0;
            });
            return {
                abbreviation: p.abbreviation,
                backgroundColor: p.strokeColorString,
                data: values,
                label: p.name,
                stack: p.type,
            };
        });

        allFeatureDatasets.sort((first, second) => {
            const sumFirst = first.data.reduce((a, b) => a + b, 0);
            const sumSecond = second.data.reduce((a, b) => a + b, 0);
            return sumSecond - sumFirst;
        });

        const result: ChartData<chartjs.ChartData> = {
            datasets: [...allFeatureDatasets.filter(_ => _.stack === GeometryType.Point), ...allFeatureDatasets.filter(_ => _.stack === GeometryType.Line), ...allFeatureDatasets.filter(_ => _.stack === GeometryType.Area)],
            labels: dataLabels,
        };

        return result;
    }

    private getOverviewChartOptions = (data?: ChartData<chartjs.ChartData>) => {
        if (!data) {
            return;
        }
        const options = {
            maintainAspectRatio: false,
            onClick: this.onBlockSelection,
            plugins: { datalabels: { display: false } },
            tooltips: {
                enabled: false,
                intersect: false,
                mode: "index",
            },
        };
        return options;
    }


    private getDetailChartOptions = (data?: ChartData<chartjs.ChartData>) => {
        if (!data) {
            return;
        }
        const options = {
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    backgroundColor: (context: any) => context.dataset.backgroundColor,
                    borderRadius: 4,
                    color: 'white',
                    display: (context: any) => {
                        const abbreviation = context.dataset.abbreviation;
                        const value = context.dataset.data[context.dataIndex];
                        const values: number[] = (data as any).datasets.map((_: any) => _.data[context.dataIndex]);
                        values.sort((a, b) => b - a);
                        const topXValues = values.slice(0, 10);
                        const topXValue = topXValues[topXValues.length - 1];
                        return abbreviation && value > topXValue && value >= 5;
                    },
                    font: {
                        weight: 'bold',
                    },
                    formatter: (value: number, context: any) => context.dataset.abbreviation,
                },
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem: any, d: any) => {
                        let l = (d as any).datasets[tooltipItem.datasetIndex].label || '';
                        if (l) { l += ': '; }
                        l += Math.round(tooltipItem.yLabel * 100) / 100;
                        return l;
                    },
                },
            },
        };

        return options;
    }

    private colorsForOverviewDatasets = (data: number[], selectedIndex: number) => {
        const colors = data.map(_ => Constants.colorBackgroundM3);
        if (selectedIndex < 0) {
            return;
        }
        if (colors[selectedIndex - 2]) { colors[selectedIndex - 2] = Constants.colorBackgroundM2; }
        if (colors[selectedIndex - 1]) { colors[selectedIndex - 1] = Constants.colorBackgroundM2; }
        if (colors[selectedIndex]) { colors[selectedIndex] = Constants.colorForegroundM1blue; }
        if (colors[selectedIndex + 1]) { colors[selectedIndex + 1] = Constants.colorBackgroundM2; }
        if (colors[selectedIndex + 2]) { colors[selectedIndex + 2] = Constants.colorBackgroundM2; }
        return colors;
    }

    private onBlockSelection = (e: any) => {
        if (!this.overviewChart) {
            return;
        }
        const elements = (this.overviewChart.chartInstance as any).getElementsAtXAxis(e);
        Logger.trace(elements);
        if (!elements[0]) {
            return;
        }

        const { data, measurementId } = this.state;
        if (!data) {
            return;
        }
        const chartData = data.inspectionChartDatas.find(d => d.id === measurementId);
        if (!chartData) {
            return;
        }
        const blockIndex = elements[0]._index;

        const block = chartData.statisticsPerBlock[blockIndex];
        const blockId = block && block.id;
        this.setState({ blockId });
    }
}

export default compose(withNamespaces())(InspectionAnalysisSingleBlockBasedChartsPage)

import * as L from "leaflet";
import FeatureLayer from "./FeatureLayer";
import FeatureNode from "./FeatureNode";


export default class SearchTree{
    private _layers: Map<string, FeatureLayer> = new Map<string, FeatureLayer>();
    private _map: L.Map;
    private _root: FeatureNode;

    constructor(map: L.Map){
        this._map = map;
        this._root = new FeatureNode();
    };

    public get = (id: any): FeatureLayer | undefined => {
        return this._layers.get(id)
    };

    public set = (id: string, layer: FeatureLayer) => {
        this._layers.set(id, layer);
        this._root.set(id, layer);
    };

    public has = (id: string) => {
        return this._layers.has(id);
    };

    public delete = (id: string) => {
        this._layers.delete(id);
        this._root.delete(id);
    };

    public forEach = (callback: (layer: FeatureLayer) => void) => {
        this._layers.forEach(callback);
    };

    public toArray = () => {
        return Array.from(this._layers.values());
    };

    public getActiveLayers = (): FeatureLayer[] => {
        if(this._map){
            const bounds = this._map.getBounds();
            if(bounds.isValid()){
                return this._root.get(bounds.getSouth(), bounds.getNorth())
            }
        }
        return [];
    };
}
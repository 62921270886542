import "leaflet";
import { t } from "i18next";
import * as React from "react";
import MapData from "../../dto/maps/MapData";
import MapGrid from "../../graphics/grids/MapGrid";
import MapGridBlockBased from "../../graphics/grids/MapGridBlockBased";
import InspectionMapBasic from "../../graphics/InspectionMapBasic";
import Button from ".././atlaskit/Button";
import { GridBockBasedIcon, GridIcon } from "../Icons";
import MapControlInhibitDrawing from "./MapControlInhibitDrawing";


type Props =  { blockDefinition: MapData; }
type State = { currentGrid: MapGrid|MapGridBlockBased; }

export default class MapGridControl extends React.Component<Props, State> {
    map: InspectionMapBasic;
    grids: Array<MapGrid|MapGridBlockBased> = [new MapGrid()];
    state = { currentGrid: this.grids[0]};

    render() {
        const {currentGrid} = this.state;
        return (
            <div>
            {this.map && this.map.innerMap &&
                <MapControlInhibitDrawing map={this.map.innerMap}>
                <Button
                    id={"map-element-single"}
                    onClick={this.handleGridChange}
                    iconBefore={currentGrid instanceof MapGrid ? <GridBockBasedIcon label={t("buttonChangeGrid")} size={"medium"} /> : <GridIcon label={t("buttonChangeGrid")} size={"medium"} /> }
                    tooltip={t("buttonChangeGrid")}
                    testId={"grid-change-button"}
                />
            </MapControlInhibitDrawing>
            }
            </div>
        );
    };

    componentDidMount() {
        if(this.props.blockDefinition){
            this.grids.push(new MapGridBlockBased(this.props.blockDefinition))
        }
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        if(prevState.currentGrid !== this.state.currentGrid){
            this.map.switchGrid(prevState.currentGrid, this.state.currentGrid)
        }
    };

    initialize = (map: InspectionMapBasic) => {
        this.map = map;
    };

    get currentGrid() {
        return this.state.currentGrid
    };

    handleGridChange = () => {
        const newIndex = this.grids.findIndex((el) => el === this.state.currentGrid) + 1;
        const grid = ((newIndex + 1) > this.grids.length)? this.grids[0] : this.grids[newIndex];
        this.setState({currentGrid: grid})
    };
}

import * as React from "react";
import Avatar, { IAvatarProps } from "../atlaskit/Avatar";
import LabelWrapper, { ILabelWrapperProps } from "./Wrapper";

interface IAvatarLabeledProps extends ILabelWrapperProps, IAvatarProps {}

export default function AvatarLabeled(props: IAvatarLabeledProps) {
    const { label, children, labelMarginTop, ...rest } = props as ILabelWrapperProps;
    return (
        <LabelWrapper label={label} labelMarginTop={getMargin(props.size)}>
            <Avatar {...rest} />
        </LabelWrapper>
    );
}

const getMargin = (size: string): number => {
    switch (size) {
        case "xxlarge":
            return 58;
        case "xlarge":
            return 40;
        case "large":
            return 10;
        case "medium":
            return 7;
        case "small":
            return 3;
        default:
            return -1;
    }
};

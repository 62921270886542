import { Field } from "@atlaskit/form";
import TagAK from "@atlaskit/tag";
import TagGroup from "@atlaskit/tag-group";
import * as React from "react";

export interface ITagProps {
    label?: string;
    value: string;
    highlighted?: boolean;
    isRemoveable?: boolean;
}

export default function Tag(props: ITagProps) {
    const color = props.highlighted ? "teal" : "standard";
    return (
        <Field name='singleTag'>
          {() => (
            <TagGroup>
                <TagAK text={props.value} appearance="rounded" color={color} isRemovable={props.isRemoveable} />
            </TagGroup>
          )}
        </Field>
    );
}

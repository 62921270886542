import * as H from "history";
import InspectionAnalysisSummary from "../dto/components/InspectionAnalysisSummary";
import InspectionTaskSummary from "../dto/components/InspectionTaskSummary";
import ProjectSummary from "../dto/components/ProjectSummary";
import AuthService from "./AuthService";
import StructureOverviewPageData from "../dto/pages/StructureOverviewPageData";

interface InspectionTaskDef{projectId: string; structureId:string; inspectionId: string}
interface AnalysisDef{projectId: string; structureId:string; analysisId: string}
interface StructureDef{projectId: string; structureId: string}


class HomePageService {
    private static setRecentIds(currentLocation: H.Location, id: string, regexp: string, ids: string[], numberOfItems: number) {
        const r = new RegExp(regexp);
        const match = r.exec(currentLocation.pathname);

        if (match){
            localStorage.setItem(
                id,
                JSON.stringify(Array.from(
                    new Set([match[1], ...ids])
                ).slice(0, numberOfItems))
            )
        }
    }
    private static setRecentIdsForStructure(currentLocation: H.Location, id: string, regexp: string, ids: InspectionTaskDef[], numberOfItems: number) {
        const r = new RegExp(regexp);
        const match = r.exec(currentLocation.pathname);

        if (match){
            let newitem: InspectionTaskDef = {projectId: match[2], structureId: match[4], inspectionId: match[6]}
            let arr = Array.from([newitem,...ids]
            )
            // remove duplicates
            arr = arr.filter((value, index, array) => 
                    !array.filter((v, i) => JSON.stringify(value) === JSON.stringify(v) && i < index).length);

            localStorage.setItem(
                id,
                JSON.stringify(arr.slice(0, numberOfItems))
            )
        }
    }

    private static setRecentIdsForAnalysis(currentLocation: H.Location, id: string, regexp: string, ids: AnalysisDef[], numberOfItems: number) {
        const r = new RegExp(regexp);
        const match = r.exec(currentLocation.pathname);

        if (match){
            let newitem: AnalysisDef = {projectId: match[2], structureId: match[4], analysisId: match[6]}
            let arr = Array.from([newitem,...ids]
            )
            // remove duplicates
            arr = arr.filter((value, index, array) => 
                    !array.filter((v, i) => JSON.stringify(value) === JSON.stringify(v) && i < index).length);

            localStorage.setItem(
                id,
                JSON.stringify(arr.slice(0, numberOfItems))
            )
        }
    }

    public setRecentHomePageElements = (currentLocation: H.Location): void => {
        // Projects
        HomePageService.setRecentIds(
            currentLocation,
            "id_recent_projects",
            "project\\/([^\\/]*)(\\/|$)",
            this.getRecentProjectIds(),
            2
        );
        // StructureTasks
        HomePageService.setRecentIdsForStructure(
            currentLocation,
            "id_recent_structure_tasks",
            ".*(project\\/([^\\/]*))+.*(structure\\/([^\\/]*))+.*(structuretask\\/inspection\\/([^\\/]*))",
            this.getRecentStructureTaskIds(),
            10
        );
        // StructureAnalysis
        HomePageService.setRecentIdsForAnalysis(
            currentLocation,
            "id_recent_structure_analysis",
            ".*(project\\/([^\\/]*))+.*(structure\\/([^\\/]*))+.*(\\/inspectionanalysis\\/[^\\/]*\\/([^\\/]*))",
            this.getRecentStructureAnalysisIds(),
            10
        )
    };

    public loadRecentProjects = async (): Promise<ProjectSummary[]> => {
        let projectsummary: ProjectSummary[] = [];
        for (let id of this.getRecentProjectIds()) {
            try {
                let data = await AuthService.get<ProjectSummary>(`home/${id}/data`);
                projectsummary.push(data);
            } catch (error) {
                if(error.response?.status === 403)
                {
                    this.dropLostProjectId(id);
                }
            }
        }
        return projectsummary;
    };

    public loadRecentStructureTasksAndAnalysis = async (): Promise<[InspectionTaskSummary[], InspectionAnalysisSummary[]]> => {
        let tasksSummary: InspectionTaskSummary[] =[];
        let analysisSummary: InspectionAnalysisSummary[] = [];
        let allStructures: StructureDef[] = []; 

        for (let item of this.getRecentStructureTaskIds())
            allStructures.push({projectId: item.projectId, structureId: item.structureId});

        for (let item of this.getRecentStructureAnalysisIds())
            allStructures.push({projectId: item.projectId, structureId: item.structureId});

        let filtered = allStructures.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.projectId === item.projectId && t.structureId === item.structureId
            ))
        )
        
        for (let id of filtered)
        {          
            try{
                let data = await AuthService.get<StructureOverviewPageData>(`structureoverview/${id.projectId}/${id.structureId}/data`);
                tasksSummary.push.apply(tasksSummary, data.inspectionTasks);
                analysisSummary.push.apply(analysisSummary, data.inspectionAnalyses)
            }      
            catch(error)
            {
                if(error.response?.status === 403)
                {
                    this.dropLostStructureDef(id);
                }
            }
        }
        const filteredAndSortedTasks = await this.loadRecentStructureTasks(tasksSummary);
        const filteredAndSortedAnalysis = await this.loadRecentStructureAnalysis(analysisSummary);
        
        return [filteredAndSortedTasks,filteredAndSortedAnalysis];
    };

    private loadRecentStructureTasks = async (inspectionTasks: InspectionTaskSummary[] | undefined): Promise<InspectionTaskSummary[]> => {
        try {
            if (inspectionTasks){
                const ids = this.getRecentStructureTaskIds();
                const sorted: InspectionTaskSummary[] = [];

                inspectionTasks.forEach((task) => {
                    ids.forEach((structuredef,index) => {
                        if (structuredef.inspectionId === task.id)
                            sorted[index] = task
                    })
                });
                const filtered = sorted.filter((item: InspectionTaskSummary) =>{
                    return item !== undefined
                })
                return filtered
            }
            return [];
        } catch (error) {
            return []
        }
    };

    private loadRecentStructureAnalysis = async (inspectionAnalysis: InspectionAnalysisSummary[] | undefined): Promise<InspectionAnalysisSummary[]> => {
        try {
            if (inspectionAnalysis){
                const ids = this.getRecentStructureAnalysisIds();
                const sorted: InspectionAnalysisSummary[] = [];

                inspectionAnalysis.forEach((task) => {
                    ids.forEach((analysisdef,index) => {
                        if (analysisdef.analysisId === task.id)
                            sorted[index] = task
                    })
                });
                const filtered = sorted.filter((item: InspectionAnalysisSummary) =>{
                    return item !== undefined
                })
                return filtered
            }
            return [];
        } catch (error) {
            return []
        }
    };

    private getRecentProjectIds = (): string[] => {
        return JSON.parse(localStorage.getItem("id_recent_projects") || "[]");
    };

    private getRecentStructureTaskIds = (): InspectionTaskDef[] => {
        let data = JSON.parse(localStorage.getItem("id_recent_structure_tasks") || "[]");
        return data.filter( (item: InspectionTaskDef) => {
            return item.projectId !== undefined
        });
    };

    private getRecentStructureAnalysisIds = (): AnalysisDef[] => {
        let data = JSON.parse(localStorage.getItem("id_recent_structure_analysis") || "[]");
        return data.filter((item: AnalysisDef) => {
            return item.projectId !== undefined
        });
    };

    private dropLostProjectId = (id: string) => {
        const data = this.getRecentProjectIds();
        let filtered = data.filter((item: string) => {
            return item !== id;
        });
        localStorage.setItem(
            "id_recent_projects", 
            JSON.stringify(filtered)
        )
    }

    private dropLostStructureDef = (structure: StructureDef) => {
        const structureTaskData = this.getRecentStructureTaskIds();
        const structureAnalysisData = this.getRecentStructureAnalysisIds();

        let filteredTask = structureTaskData.filter((item: InspectionTaskDef) => {
            return !(item.projectId === structure.projectId && item.structureId === structure.structureId)
        })

        let filteredAnalysis = structureAnalysisData.filter((item: AnalysisDef) => {
            return !(item.projectId === structure.projectId && item.structureId === structure.structureId)
        })

        localStorage.setItem(
            "id_recent_structure_tasks", 
            JSON.stringify(filteredTask)
        )

        localStorage.setItem(
            "id_recent_structure_analysis", 
            JSON.stringify(filteredAnalysis)
        )
    }
}


const homePageService = new HomePageService();
export default homePageService;
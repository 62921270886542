import { FeatureGroup } from "leaflet";
import InspectionFeatureGroup from "./InspectionFeatureGroup";
import DefaultStyle from "./styles/defaultStyle";

export default class InspectionFeatureGroups{
    private _groups: Map<string, InspectionFeatureGroup> = new Map<string, InspectionFeatureGroup>();
    private _defaultGroup: InspectionFeatureGroup;

    constructor(map: L.Map){
        this._defaultGroup =  new InspectionFeatureGroup({
            id: "default",
            map,
            style: new DefaultStyle({interactive: true})
        })
    };

    get defaultGroup() {
        return this._defaultGroup;
    }

    public get = (id: any): InspectionFeatureGroup => {
        return this._groups.get(id) || this._defaultGroup;
    };

    public set = (featureGroup: InspectionFeatureGroup): InspectionFeatureGroup => {
        this._groups.set(featureGroup.id, featureGroup);
        return featureGroup
    };

    public has = (id: string) => {
        return this._groups.has(id);
    };

    public forEach = (method: (featureGroup: FeatureGroup, key?: any, object?: Map<string, InspectionFeatureGroup>) => void) => {
        this._groups.forEach((featureGroup, key) => {
            method(featureGroup, key, this._groups)
        });
        method(this.defaultGroup, "inspectiondrawing_default")
    };
}
import Constants from "./Constants";

export default class Styles {
  public static  box: {} = {
    backgroundColor: Constants.backgoundUselected,
    borderColor: Constants.borderUnselected,
    borderRadius: 3,
    borderStyle: "solid",
    paddingBottom: 8,
  };

  public static centered: {} = {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };

}
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import PageTitleItem from "../components/PageTitleItem";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface IAboutPageProps {
    dummy?: string;
}

class CookiePolicyPage extends React.Component<IAboutPageProps> {
    public render() {
        return (
            <div className="page" style={{maxWidth: 700}}>
                <PageTitleItem title={t("pageTitleCookiePolicy")}  testID={"cookie-policy-page-title"}/>
                <p>{t("textCookiePolicy")}</p>

                <h2>{t("titleWhatAreCookies")}</h2>
                <p>{t("textWhatAreCookies")}</p>
                <p>{t("textWhatAreCookiesWiki")}</p>

                <h2>{t("titleHowWeUseCookies")}</h2>
                <p>{t("textHowWeUseCookies")}</p>

                <h2>{t("titleDisableCookies")}</h2>
                <p>{t("textDisableCookies")}</p>

                <h2>{t("titleOurCookies")}</h2>
                <ul>
                    <li>
                        <p>{t("subtitleOurCookiesAccount")}</p>
                        <p>{t("subtextOurCookiesAccount")}</p>
                        <p></p>
                    </li>
                    <li>
                        <p>{t("subtitleOurCookiesLogin")}</p>
                        <p>{t("subtextOurCookiesLogin")}</p>
                        <p></p>
                    </li>
                    <li>
                        <p>{t("subtitleOurCookiesSitePreferences")}</p>
                        <p>{t("subtextOurCookiesSitePreferences")}</p>
                        <p></p>
                    </li>
                </ul>

                <h2>{t("title3rdCookies")}</h2>
                <p>{t("text3rdCookies")}</p>
                <ul>
                    <li>
                        <p>{t("text3rdCookiesFirst")}</p>
                    </li>
                    <li>
                        <p>{t("text3rdCookiesSecond")}</p>
                    </li>
                </ul>

                <h2>{t("titleEvenMoreCookies")}</h2>
                <p>{t("textEvenMoreCookies")} <a href="https://www.cookiepolicygenerator.com">Cookies Policy Template Generator</a> & <a href="https://www.webterms.org">WebTerms Generator</a>.</p>
                <p>{t("textEvenMoreCookiesContact")}</p>
                <ul>
                    <li>{t("textEvenMoreCookiesLink")} <a href="https://amberggroup.com/about-us/location-contact/amberg-technologies-regensdorf-watt/">{t("labelContact")}</a></li>
                </ul>
                <p></p>
            </div>
        );
    }
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, CookiePolicyPage,"CookiePolicyPage","AppInsight"));
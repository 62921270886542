import L from "leaflet";

export default class LayerHelper {
    public static fixOrientation = (layer: any) => {

        if (layer._latlngs === undefined) {
            return;
        }

        var isFlat = L.LineUtil.isFlat(layer._latlngs);
        if (isFlat) {
            LayerHelper.fixOrientationFlat(layer);
        } else {
            LayerHelper.fixOrientationNotFlat(layer);
        }
    }

    private static fixOrientationFlat = (layer: any) => {
        let poly = layer._latlngs;
        if (LayerHelper.isClockwise(poly)) {
            let invertedPolygon: Array<number> = [];
            for (let pointId = poly.length - 1; pointId >= 0; pointId--) {
                invertedPolygon.push(poly[pointId]);
            }
            layer._latlngs = invertedPolygon;
        }
    }

    private static fixOrientationNotFlat = (layer: any) => {
        let poly = layer._latlngs;
        let invertedPolygons: Array<Array<number>> = [];

        for (let polygonId = 0; polygonId < poly.length; polygonId++) {

            if (LayerHelper.isClockwise(poly[polygonId])) {
                let invertedPolygon: Array<number> = [];
                for (let pointId = poly[polygonId].length - 1; pointId >= 0; pointId--) {
                    invertedPolygon.push(poly[polygonId][pointId]);
                }
                invertedPolygons.push(invertedPolygon);
            } else {
                invertedPolygons.push(poly[polygonId]);
            }
        }
        layer._latlngs = invertedPolygons;
    }

    private static calcArea(poly: any) {
        if (!poly || poly.length < 2 || poly.length === undefined) {
            return null;
        }
        let end = poly.length - 1;
        let sum = poly[end].lat * poly[0].lng - poly[0].lat * poly[end].lng;

        if (poly.length === 2) {
            return sum;
        }

        for (let i = 0; i < end; ++i) {
            const n = i + 1;
            sum += poly[i].lat * poly[n].lng - poly[n].lat * poly[i].lng;
        }
        return sum;
    }

    private static isClockwise = (poly: any): boolean => {
        let area = LayerHelper.calcArea(poly);
        if (area == null) {
            return false;
        }
        return area > 0;
    };
}
import "leaflet";
import { LatLngBounds, Point } from "leaflet";
import MapData from "../../dto/maps/MapData";
import "./MapGrid.css";

const options = {
    redraw: 'move',
    showOriginLabel: true,
};

const NormalHorizontalLineStyle = {
    color: '#beff40',
    interactive: false,
    opacity: 0.8,
    stroke: true,
    weight: 1,
};

const HighlightHorizontalLineStyle = {
    color: '#beff40',
    interactive: false,
    opacity: 0.8,
    stroke: true,
    weight: 2,
};

const NormalVerticalLineStyle = {
    color: '#fff12f',
    interactive: false,
    opacity: 0.8,
    stroke: true,
    weight: 1,
};

const MainLineStyle = {
    color: '#68fff8',
    interactive: false,
    opacity: 0.8,
    stroke: true,
    weight: 3,
};

declare const L: any;

export default class MapGridBlockBased extends L.LayerGroup {
    private map: L.Map;
    private blockStarts: number[];
    private _bounds: LatLngBounds;
    private _size: Point;

    constructor (blockDefinitions: MapData){
        super();
        this.blockStarts = this.getBlockStarts(blockDefinitions);
    }

    public initialize() {
        L.LayerGroup.prototype.initialize.call(this);
        L.Util.setOptions(this, options);
    }

    public onAdd(map: L.Map) {
        this.map = map;

        const graticule = this.redraw();
        this.map.on('viewreset ' + this.options.redraw, graticule.redraw, graticule);

        this.eachLayer(map.addLayer, map);
    }

    public onRemove(map: L.Map) {
        map.off('viewreset ' + this.options.redraw, this.map as any);
        this.eachLayer(this.removeLayer, this);
    }

    public redraw() {
        this._bounds = this.map.getBounds().pad(0.5);
        this._size = this.map.getSize();

        this.clearLayers();

        this.constructHorizontalLines();
        this.constructVerticalLines();

        return this;
    }

    private getBlockStarts = (blockDefinitions: MapData) => {
        const BlockY = new Set(blockDefinitions.geoJson.features.map(f => {
            return parseFloat((f.geometry as any).coordinates[0][1])
        }));
        return Array.from(BlockY)
    };

    private constructHorizontalLines() {
        const visibleBlockStarts = this.getVisibleBlocks();

        const lines: L.Polyline[] = [];
        const labels: string[] = [];
        visibleBlockStarts.forEach((blockStart, index) => {
            let blockEnd = visibleBlockStarts[index + 1];
            lines.push(this.getHorizontalPolyline(blockStart, MainLineStyle));
            labels.push( this.buildLabel('gridlabel-horiz', blockStart));
            blockStart += 1;

            if(!blockEnd){
                blockEnd = blockStart + 5;
            }

            let counter = 1;
            while(blockStart < blockEnd){
                if(counter%5 === 0){
                    lines.push(this.getHorizontalPolyline(blockStart, HighlightHorizontalLineStyle));
                }
                else{
                    lines.push(this.getHorizontalPolyline(blockStart, NormalHorizontalLineStyle));
                }
                labels.push( this.buildLabel('gridlabel-horiz', blockStart));
                blockStart += 1;
                counter++;
            }

        });

        lines.forEach(this.addLayer, this);
        labels.forEach(this.addLayer, this);
    }

    private getVisibleBlocks = () => {
        const startMeter = this._bounds.getSouth();
        const endMeter = this._bounds.getNorth();

        return this.blockStarts.filter((start, index, blockStarts) => {
                const nextStart = blockStarts[index + 1];
                const prevStart = blockStarts[index - 1];

                if (start >= startMeter && start <= endMeter) {
                    return true
                }
                if (start <= startMeter && nextStart >= startMeter) {
                    return true
                }
                return !!(start >= endMeter && prevStart <= endMeter);
            }
        );
    };

    private constructVerticalLines() {
        let startMeter = Math.round(this._bounds.getWest());
        const endMeter = this._bounds.getEast();

        const lines: L.Polyline[] = [];
        const labels: string[] = [];

        while(startMeter < endMeter){
            lines.push(this.getVerticalPolyline(startMeter, NormalVerticalLineStyle));
            labels.push( this.buildLabel('gridlabel-vert', startMeter));
            startMeter += 1;
        }

        lines.forEach(this.addLayer, this);
        labels.forEach(this.addLayer, this);
    }


    private getHorizontalPolyline(y: any, style: L.PolylineOptions) {
        const left = new L.LatLng(y, this._bounds.getWest());
        const right = new L.LatLng(y, this._bounds.getEast());
        return new L.Polyline([left, right], style);
    }

    private getVerticalPolyline(x: any, style: L.PolylineOptions) {
        const bottom = new L.LatLng(this._bounds.getSouth(), x);
        const top = new L.LatLng(this._bounds.getNorth(), x);
        return new L.Polyline([bottom, top], style);
    }

    private buildLabel(axis: any, val: any) {
        const BottomOffset = 25;

        const bounds = this.map.getBounds();
        let latLng;
        if (axis === 'gridlabel-horiz') {
            latLng = new L.LatLng(val, bounds.getWest());
        } else {
            const p = new L.Point(0, this._size.y - BottomOffset);
            const offset = this.map.containerPointToLatLng(p);
            latLng = new L.LatLng(offset.lat, val);

        }

        return L.marker(latLng, {
            icon: L.divIcon({
                className: 'leaflet-grid-label',
                html: '<div class="' + axis + '">' + val + '</div>',
                iconSize: [0, 0],
            })
        });
    }
}

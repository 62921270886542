import Spinner from "@atlaskit/spinner";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { FeatureCollection, LineString, GeoJsonProperties } from "geojson";
import { t } from "i18next";
import "libs/infra3dapi";
import proj4 from "proj4";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import InspectionMapBase, {
    IInspectionMapBaseProps,
} from "../components/InspectionMapBase";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import CatalogLayerAnalysis from "../components/toolbar/CatalogLayerAnalysis";
import InspectionAnalysisMapsPageData
    from "../dto/pages/InspectionAnalysisMapsPageData";
import AuthService from "../services/AuthService";
import MapPageBase, { IMapPageBaseProps, IMapPageBaseState } from "./MapPageBase";

export interface IInspectionAnalysisInfra3dPageState extends IMapPageBaseState {
    data?: InspectionAnalysisMapsPageData;
    infraEpsg?: number;
    infraProj4?: string;
}

class InspectionAnalysisInfra3dPage extends MapPageBase<IMapPageBaseProps, IInspectionAnalysisInfra3dPageState> {
    public map: InspectionMapBase<IInspectionMapBaseProps, {}> | null;

    public render() {
        const {
            blockDefinitionSelected,
            classificationSelected,
            data,
            inspectionDrawingId,
            hiddenFeatureTypeCodes,
            sectorDefinitionSelected,
            textHiddenFeatureTypeCodes,
            theoreticalSectionsSelected,
            waiting,
        } = this.state;

        const inspectionClassification = data && data.inspectionClassifications.find(l => l.id === inspectionDrawingId);

        return (
            <div className="pageMap">
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>
                <div className="pageMaximized">
                {data &&
                    <div className="sideBar">
                        <div
                            style={{ flex: "1 1 auto", width: "100%", height: "100%" }}>
                            <Scrollbar>
                                <CatalogLayerAnalysis
                                    blockDefinitionSelected={this.state.blockDefinitionSelected}
                                    classificationDataSelected={this.state.classificationSelected}
                                    inspectionDrawings={data.inspectionDrawings}
                                    name={t("buttonLayers")}
                                    onBlockDefinitionSelectionChange={this.onBlockDefinitionSelectionChange}
                                    onClassificationDataSelectionChange={this.onClassificationSelectionChange}
                                    onInspectionDrawingSelectionChange={this.onInspectionDrawingSelectionChange}
                                    onSectorDefinitionSelectionChange={this.onSectorDefinitionSelectionChange}
                                    onTheoreticalSectionsSelectionChange={this.onTheoreticalSectionsSelectionChange}
                                    onTileLayerSelectionChange={this.onTileLayerSelectionChange}
                                    sectorDefinitionSelected={this.state.sectorDefinitionSelected}
                                    selectedInspectionDrawingId={this.state.inspectionDrawingId}
                                    selectedTileLayerId={this.state.tileLayerId}
                                    testId={"layer-catalog"}
                                    theoreticalSectionsSelected={this.state.theoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                            />
                            <Separator/>
                            <CatalogFeatureType
                                catalog={data.catalog}
                                onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                onTextVisibilityChange={this.onFeatureTypeTextVisibilityChange}
                                testId={"feature-type-catalog"}
                            />
                            </Scrollbar>
                        </div>
                    </div>
                    }
                    {data &&
                    <div className="mapWithStationingSlider">
                        <InspectionMapBase
                            blockDefinition={data.blockDefinitionMapData}
                            blockDefinitionSelected={blockDefinitionSelected}
                            catalog={data.catalog}
                            classification={inspectionClassification && inspectionClassification.mapData}
                            classificationSelected={classificationSelected}
                            hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                            hideSectorSelection={true}
                            inspectionDrawing={this.getDrawing(data.inspectionDrawings)}
                            ref={instance => this.map = instance}
                            sectorDefinition={data.sectorDefinitionMapData}
                            sectorDefinitionSelected={sectorDefinitionSelected}
                            selectedTileLayerId={this.state.tileLayerId}
                            textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                            theoreticalSections={data.theoreticalSectionsMapData}
                            theoreticalSectionsSelected={theoreticalSectionsSelected}
                            tileLayers={data.tileLayers}
                        />
                    </div>
                    }
                    <React.Fragment>
                        <div id="infra3d" style={{width: "45%", height: "100%", overflow: "hidden"}}>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        );
    }

    public loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisMapsPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/mapdata/Extended`);
            
            infra3d.init("infra3d", "https://client-v3.infra3d.ch", {
                "lang":"de",
                "map":true,
                "layer":false,
                "navigation":false,
                "buttons":["map", "navigation"],
            }, this._infra3dInitialized, this);
            
            this.setState({
                data: new InspectionAnalysisMapsPageData(data),
                tileLayerId: data.tileLayers[0].id,
                inspectionDrawingId: data.inspectionDrawings[0].id,
                waiting:false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);

            if(this.map)
                this.map.map.innerMap.on('moveend', () => { this.changeInfra3dPosition(); });
        } catch (error) {
            this.setState({waiting:false})
        }
    }

    public unloadData = () => {
        infra3d.unsetOnPositionChanged();
        this.setState({infraEpsg: undefined, infraProj4: undefined});
    }

    private changeInfra3dPosition = () => {
        if(this.map) {
            const bounds = this.map.map.innerMap.getBounds();
            const stationing = bounds.getCenter().lat;
            const stationingCollection = this.state.data && this.state.data.stationingDefinitionMapData.geoJson as FeatureCollection<LineString, GeoJsonProperties>;
            const positionOnTrack = this.state.data && stationingCollection && this.getCoordinate(stationing, stationingCollection);

            if(positionOnTrack) {
                infra3d.loadAtPosition(positionOnTrack[0], positionOnTrack[1]);
            }
        }
    }

    private getCoordinate = (stationing: number, collection: FeatureCollection<LineString, GeoJsonProperties>) => {
        const positionIndex =  collection.features[1].geometry.coordinates.reduce( (prev: any, curr: any) => {
            return (Math.abs(curr[1] - stationing) < Math.abs(prev[1] - stationing) ? curr : prev);
        });
        
        const axisCoordinates = collection.features[0].geometry.coordinates;
        let axisPoint = axisCoordinates[positionIndex[0]];
        
        if(this.state.infraProj4 && this.state.data){
            const positionOnTrack = proj4(this.state.data.proj4Definition, 
                this.state.infraProj4, 
                axisPoint)
            axisPoint = positionOnTrack;
        }
        return axisPoint;
    }

    _onPositionChanged = (easting: number, northing: number, height: number, epsg: number) => {
            if(this.state.infraEpsg === undefined || this.state.infraEpsg !== epsg){
                const espgCodes = require('epsg-index/all.json');
                console.info("infra3d updated EPSG:" + epsg);
                this.setState({infraEpsg: epsg, infraProj4: espgCodes[epsg.toString()].proj4})
            }
        }

    _infra3dInitialized = () => {
        console.log("infra3d initialized");
        infra3d.setOnPositionChanged(this._onPositionChanged, document.location.origin);
    }
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, InspectionAnalysisInfra3dPage,"InspectionAnalysisInfra3dPage","AppInsight"));
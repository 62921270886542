import * as React from "react";
import TextBox, {ITextBoxProps} from "../TextBox";
import LabelWrapper, { ILabelWrapperProps } from "./Wrapper";

interface ITextBoxLabeled extends ILabelWrapperProps, ITextBoxProps {}

export default function TextBoxLabeled(props: ITextBoxLabeled) {
    const { label, children, labelMarginTop, testId, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop} >
            <TextBox label={""} {...rest}  testId={testId}>
                    {children}
            </TextBox>
        </LabelWrapper>
    );
}

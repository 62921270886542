import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";


export default class Scrollbar extends React.Component<{}, {}> {
    public render() {
        return (
            <Scrollbars
                hideTracksWhenNotNeeded={true}
                renderTrackVertical={this.scrollbarTrackVertical}
                renderThumbVertical={this.scrollbarThumbVertical}
            >
                {this.props.children}
            </Scrollbars>
        )
    };

    private scrollbarTrackVertical = (props: any) => {
        return  <div {...props} className="track-vertical"/>
    };

    private scrollbarThumbVertical = (props: any) => {
        return  <div {...props} className="thumb-vertical"/>
    };
}

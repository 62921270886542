import { Feature, FeatureCollection } from "geojson";
import Proj4Helper from "../helpers/Proj4Helper";
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import proj4 from "proj4";
import "proj4leaflet";
import * as React from 'react';
import MapProviderHelper from "../helpers/MapProviderHelper";

export interface IGeographicalMapThumbnailProps {
    mapDiv: string;
    geojson: FeatureCollection;
    proj4config: string;
    mapProvider: string;
}

export default class GeographicalMapThumbnail extends React.Component<IGeographicalMapThumbnailProps, {}> {
    private map: L.Map;
    private features: L.FeatureGroup;
    private tiles: L.TileLayer;

    public render() {
        return (
            <div id={this.props.mapDiv} style={{ height: "125px" }} />
        );
    }

    public componentDidMount() {
        this.createMap(this.props);
        try
        {
            this.updateMap(this.props, this.props.proj4config);
        }
        catch
        {
            this.updateMap(this.props, Proj4Helper.createCustomProj4String(0,0,0,0));
        }
    }

    public componentDidUpdate(props: IGeographicalMapThumbnailProps) {
        try
        {
            this.updateMap(props, props.proj4config);
        }
        catch
        {
            this.updateMap(props, Proj4Helper.createCustomProj4String(0,0,0,0));
        }
    }

    private createMap = (props: IGeographicalMapThumbnailProps) => {
        if (this.map) {
            return;
        }
        this.map = L.map(this.props.mapDiv, {
            attributionControl: false,
            boxZoom: false,
            crs: L.CRS.EPSG3857,
            doubleClickZoom: false,
            dragging: false,
            scrollWheelZoom: false,
            zoomControl: false,
        }).setView([0.0, 0.0], 5);

        this.features = new L.FeatureGroup();
        this.map.addLayer(this.features);
    }

    private updateMap = (props: IGeographicalMapThumbnailProps, proj4Definition: string) => {
        if (!this.map) {
            return;
        }
        this.updateTileLayers(props.mapProvider);

        if (props.geojson && proj4Definition) {
            proj4.defs("custom", proj4Definition);
            const geojsonOptions: L.GeoJSONOptions = {
                pointToLayer: (feature: Feature, latlng: L.LatLng) => {
                    const layer = L.circleMarker(latlng, { radius: 3 });
                    (layer as any).feature = feature;
                    this.features.addLayer(layer);
                    return layer;
                },
                style: (feature: any) => {
                    return {
                        color: "red",
                    };
                },
            };

            const geoJson = (L as any).Proj.geoJson(props.geojson, geojsonOptions);

            this.features.clearLayers();
            this.features.addLayer(geoJson);

            const bounds = this.features.getBounds()
            if (bounds.isValid()) {
                this.map.fitBounds(bounds);
            }
        }
    }

    private updateTileLayers = (mapProvider: string) => {
        if (!this.map) {
            return;
        }
        if (this.tiles) {
            this.tiles.removeFrom(this.map);
        }

        const urlTemplate = MapProviderHelper.urlTemplateForMapProvider(mapProvider);
        this.tiles = L.tileLayer(urlTemplate);
        this.tiles.addTo(this.map);
    }
}

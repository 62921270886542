import { Feature } from "geojson";
import * as L from "leaflet";
import InspectionHelper from "../../helpers/InspectionHelper";
import ILayerStyle from "./ILayerStyle";

export default class ClassificationStyle implements ILayerStyle {
    public getOptions = (): L.GeoJSONOptions => {
        return {
            style: (feature: Feature) => {
                const classificationValue = feature && feature.properties && feature.properties.classification;
                return {
                    color: InspectionHelper.colorForClassification(classificationValue),
                    fillOpacity: 0.5,
                    interactive: false,
                    stroke: false,
                };
            },
        }
    };
}
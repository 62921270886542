import * as L from "leaflet";
import Line from "./elements/Line";


export interface IFillPattern {
    color: string,
    fillOpacity?: number,
    lineCap?: string,
    path?: Line[],
    size?: number[],
    dashed?: boolean,
    text?: string,
    hasText? : boolean,
}


export default class PatternStyle{
    public fillColor: string;
    public fillOpacity?: number;
    public fillPattern?: any;
    private lineWidth = 2;
    private dashSize = 0.2;

    public constructor(options: IFillPattern){
        this.fillColor = options.color;
        this.fillOpacity = options.fillOpacity === undefined ? 0.8 : options.fillOpacity;
        this.fillPattern = this.getFillPattern(options);
    };

    private getFillPattern = (options: IFillPattern) => {
        if(!options.size){ return undefined }

        if(!options.hasText) {
            if(!options.path){ return undefined }
            const pattern = new (L as any).Pattern({  width: options.size[0], height: options.size[1] });
            pattern.addShape(new (L as any).PatternPath({
                color: this.fillColor,
                d: this.path2svg(options.path, options.size, options.dashed),
                weight: this.lineWidth,
                lineCap: options.lineCap || "butt",
            
            }));
            return pattern
        }
        else
        {
            if(!options.text){ return undefined }
            var height = 40;
            var width =  40;
            const pattern = new (L as any).Pattern({ width: options.text.length * width, height: height});
            pattern.addShape(new (L as any).PatternPath({
                color: this.fillColor,
                d: this.text2svg(width / 2, height / 2, 2, options.text),
                weight: this.lineWidth,
                lineCap: options.lineCap || "butt",
                dashOffset: 10 
            }));
            return pattern
        }
    };

    private text2svg = ( width: number, height: number, spacing: number, text?: string): string => {

        function SVGMove(x: number, y: number): string {
            return "M " + x + " " + y + " ";
        }
        function SVGLine(x: number, y: number): string {
            return "L " + x + " " + y + " ";
        }
        function SVGArc(rx: number, ry: number, xRot: number, laFlag: number, sFlag: number, x: number, y: number): string {
            return "A " + rx + " " + ry + " " + xRot + " " + laFlag + " " + sFlag + " " + x + " " + y + " ";
        }
        function SVGZ(): string {
            return "Z ";
        }

        function drawA(index: number)
        {
            return SVGMove(spacing + width * index, height - spacing) +
            SVGLine(width/2 + width * index, spacing) + 
            SVGLine(width - spacing + width * index, height - spacing)+ 
            SVGMove(width / 4 + width * index, height / 2)+ 
            SVGLine(width / 4 * 3 + width * index, height / 2)
        }

        function drawB(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - width/4 - spacing + width * index, spacing) + 
            SVGArc((height - 2*spacing) / 4, (height - 2*spacing) / 4, 0, 1, 1, width - width/4 - spacing + width * index, height / 2)+
            SVGLine(spacing + width * index, height / 2) + 
            SVGMove(width - width/4 - spacing + width * index, height / 2) +
            SVGArc((height - 2*spacing) / 4, (height - 2*spacing) / 4, 0, 1, 1, width - width/4 - spacing + width * index, height  - spacing) + 
            SVGLine(spacing + width * index, height - spacing)
        }

        function drawC(index: number)
        {
            return SVGMove(width - spacing + width * index, height / 4 + spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - width / 4- spacing + width * index , spacing) +
            SVGLine(width / 4 + spacing + width * index, spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, spacing + width * index, height / 4 + spacing) +
            SVGLine(spacing + width * index, height - height / 4 - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4- spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing) 
        }

        function drawD(index: number)
        {
            return SVGMove(width - spacing + width * index, height / 4 + spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - width / 4- spacing + width * index , spacing) +
            SVGLine(spacing + width * index, spacing) +
            SVGLine(spacing + width * index, height - spacing )+
            SVGLine(width - width / 4- spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing) +
            SVGZ()
        }

        function drawE(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - spacing + width * index, spacing)+
            SVGMove(spacing + width * index, height - spacing) +
            SVGLine(width - spacing + width * index, height - spacing) +  
            SVGMove(spacing + width * index, height / 2) + 
            SVGLine(width / 2 + width * index, height / 2)
        }

        function drawF(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - spacing + width * index, spacing)+  
            SVGMove(spacing + width * index, height / 2) + 
            SVGLine(width / 2 + width * index, height / 2)
        }

        function drawG(index: number)
        {
            return SVGMove(width - spacing + width * index, height / 4 + spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - width / 4- spacing + width * index , spacing) +
            SVGLine(width / 4 + spacing + width * index, spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, spacing + width * index, height / 4 + spacing) +
            SVGLine(spacing + width * index, height - height / 4 - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4- spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing)  +
            SVGLine(width - spacing + width * index, height/2) +
            SVGLine(width / 2 + width * index, height/2)
        }

        function drawH(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(width - spacing + width * index, spacing) + 
            SVGLine(width - spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, height / 2) + 
            SVGLine(width - spacing + width * index, height / 2)
        }

        function drawI(index: number)
        {
            return SVGMove(width - width / 4- spacing + width * index , spacing) + 
            SVGLine(width / 4 + spacing + width * index, spacing) + 
            SVGMove(width / 2 + width * index, spacing) + 
            SVGLine(width /2 + width * index, height - spacing) + 
            SVGMove(width - width / 4- spacing + width * index , height - spacing) + 
            SVGLine(width / 4 + spacing + width * index, height - spacing) 
        }

        function drawJ(index: number)
        {
            return SVGMove(spacing + width * index, height - height / 4 - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4- spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing)  +
            SVGLine(width - spacing + width * index, spacing)
        }

        function drawK(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, height / 2) + 
            SVGLine(width - spacing + width * index, spacing) + 
            SVGMove(spacing + width * index, height / 2) + 
            SVGLine(width - spacing + width * index, height - spacing)
        }

        function drawL(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) +
            SVGLine(width - spacing + width * index, height - spacing)
        }

        function drawM(index: number)
        {
            return SVGMove(spacing + width * index, height - spacing) + 
            SVGLine(spacing + width * index, spacing) +
            SVGLine(width /2 + width * index, height / 2) + 
            SVGLine(width - spacing + width * index, spacing) +
            SVGLine(width - spacing + width * index, height - spacing)
        }
   
        function drawN(index: number)
        {
            return SVGMove(spacing + width * index, height - spacing) + 
            SVGLine(spacing + width * index, spacing) +
            SVGLine(width - spacing + width * index, height - spacing) + 
            SVGLine(width - spacing + width * index, spacing) 
        }

        function drawO(index: number)
        {
            return SVGMove(width - spacing + width * index, height / 4 + spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - width / 4- spacing + width * index , spacing) +
            SVGLine(width / 4 + spacing + width * index, spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, spacing + width * index, height / 4 + spacing) +
            SVGLine(spacing + width * index, height - height / 4 - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4- spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing) +
            SVGZ()
        }

        function drawP(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - width/4 - spacing + width * index, spacing) + 
            SVGArc((height - 2*spacing) / 4, (height - 2*spacing) / 4, 0, 1, 1, width - width/4 - spacing + width * index, height / 2)+
            SVGLine(spacing + width * index, height / 2) 
        }

        function drawQ(index: number)
        {
            return SVGMove(width - spacing + width * index, height / 4 + spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - width / 4- spacing + width * index , spacing) +
            SVGLine(width / 4 + spacing + width * index, spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, spacing + width * index, height / 4 + spacing) +
            SVGLine(spacing + width * index, height - height / 4 - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4- spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing) +
            SVGZ() +
            SVGMove(width - width / 4- spacing + width * index , height - height / 4 - spacing) + 
            SVGLine(width - spacing + width * index, height)
        }

        function drawR(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(spacing + width * index, height - spacing) + 
            SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - width/4 - spacing + width * index, spacing) + 
            SVGArc((height - 2*spacing) / 4, (height - 2*spacing) / 4, 0, 1, 1, width - width/4 - spacing + width * index, height / 2)+
            SVGLine(spacing + width * index, height / 2) +
            SVGLine(width - spacing + width * index, height - spacing)
        }

        function drawS(index: number)
        {
            return SVGMove(width - spacing + width * index, height / 4 + spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - width / 4- spacing + width * index , spacing) +
            SVGLine(width / 4 + spacing + width * index, spacing) +
            SVGArc(height / 4, height / 4, 0, 0, 0, spacing + width * index, height / 4 + spacing) +
            SVGLine(width - spacing + width * index, height - height / 4 - spacing)+
            SVGArc(height / 4, height / 4, 0, 0, 1, width - width / 4- spacing + width * index, height - spacing ) +
            SVGLine(width / 4 +  spacing + width * index, height - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 1, spacing + width * index, height - height / 4 - spacing) 
        }

        function drawT(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - spacing + width * index, spacing) + 
            SVGMove(width/2 + width * index, spacing)+ 
            SVGLine(width/2 + width * index, height - spacing)
        }

        function drawU(index: number)
        {
            return SVGMove(spacing + width * index, spacing) +
            SVGLine(spacing + width * index, height - height / 4 - spacing )+
            SVGArc(height / 4, height / 4, 0, 0, 0, width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4- spacing + width * index, height - spacing ) +
            SVGArc(height / 4, height / 4, 0, 0, 0, width - spacing + width * index, height - height / 4 - spacing) +
            SVGLine(width - spacing + width * index, spacing )
        }

        function drawV(index: number)
        {
            return SVGMove(spacing + width * index, spacing) +
            SVGLine(width / 2 + width * index, height - spacing) +
            SVGLine(width - spacing + width * index, spacing )
        }
        
        function drawW(index: number)
        {
            return SVGMove(spacing + width * index, spacing) +
            SVGLine(width / 4 + spacing + width * index, height - spacing) +
            SVGLine(width - width / 4 - spacing + width * index, spacing ) +
            SVGMove(width / 4 + spacing + width * index, spacing)+
            SVGLine(width - width / 4 - spacing + width * index, height - spacing) +
            SVGLine(width - spacing + width * index, spacing )
        }

        function drawX(index: number)
        {
            return SVGMove(spacing + width * index, spacing) + 
            SVGLine(width - spacing + width * index, height - spacing) + 
            SVGMove(width - spacing + width * index, spacing)+ 
            SVGLine(spacing + width * index, height - spacing)
        }    

        function drawY(index: number)
        {
            return SVGMove(spacing + width * index, spacing) +
            SVGLine(width / 2 + width * index, height / 2) +
            SVGLine(width - spacing + width * index, spacing ) +
            SVGMove(width / 2 + width * index, height / 2) +
            SVGLine(width / 2 + width * index, height - spacing) 
        }  

        function drawZ(index: number)
        {
            return SVGMove(spacing + width * index, spacing) +
            SVGLine(width - spacing + width * index, spacing)  +
            SVGLine(spacing + width * index, height - spacing) +
            SVGLine(width - spacing + width * index, height - spacing)
        }

        function drawLetter(char: string, index: number)
        {
            switch(char)
            {
                case "A":
                    return drawA(index);
                case "B":
                    return  drawB(index);
                case "C":
                    return drawC(index);
                case "D":
                    return drawD(index);
                case "E":
                    return drawE(index);
                case "F":
                    return drawF(index);
                case "G":
                    return drawG(index);
                case "H":
                    return drawH(index);
                case "I":
                    return drawI(index);
                case "J":
                    return drawJ(index);
                case "K":
                    return drawK(index);
                case "L":
                    return drawL(index);
                case "M":
                    return drawM(index);
                case "N":
                    return drawN(index);
                case "O":
                    return drawO(index);
                case "P":
                    return  drawP(index);
                case "Q":
                    return drawQ(index);
                case "R":
                    return drawR(index);
                case "S":
                    return drawS(index);
                case "T":
                    return drawT(index);
                case "U":
                    return drawU(index);
                case "V":
                    return drawV(index);
                case "W":
                    return drawW(index);
                case "X":
                    return drawX(index);
                case "Y":
                    return drawY(index);
                case "Z":
                    return drawZ(index);
                default:
                    return ;
            }
        }

        let svg = "";
        if(text === undefined) {
            return "";
        }

        var uppercaseText = text.toUpperCase();

        let counter = 0;
        for (var char of uppercaseText) {
            svg += drawLetter(char, counter); // prints chars: H e l l o  W o r l d
            counter++;
          }

        return svg
    }

    private path2svg = (path: Line[], size: number[], dashed?: boolean): string => {
        let svg = "";

        path.forEach((element: Line) => {
            if(dashed){
                element.x1 = element.x1 - (this.dashSize * element.x1);
                element.y1 = element.y1 - (this.dashSize * element.y1);
                element.x2 = element.x2 - (this.dashSize * element.x2);
                element.y2 = element.y2 - (this.dashSize * element.y2);
            }

            const lx = (element.x2 * size[0]);
            const ly = (element.y2 * size[1]);
            const mx = (element.x1 * size[0]);
            const my = (element.y1 * size[1]);

            svg = svg + `M${mx} ${my} L${lx} ${ly} `;
            svg = svg + `M${mx + size[0]} ${my} L${lx + size[0]} ${ly} `;
            svg = svg + `M${mx - size[0]} ${my} L${lx - size[0]} ${ly} `;
            svg = svg + `M${mx} ${my + size[1]} L${lx} ${ly + size[1]} `;
            svg = svg + `M${mx} ${my - size[1]} L${lx} ${ly - size[1]} `;
        });
        return svg
    }
}

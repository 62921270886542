import Avatar, { Skeleton } from "@atlaskit/avatar";
import Dropdown, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { t } from "i18next";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Constants from "../../helpers/Constants";
import Routes from "../../helpers/Routes";
import Policies from '../../helpers/Policies';
import AuthService from "../../services/AuthService";
import FieldBrowserSupport from 'helpers/FieldBrowserSupport'
import { InspectorOfflineIcon, InspectorOnlineIcon } from "../Icons";

export interface IUserMenuProps extends RouteComponentProps<any> {
  userName: string;
}

export interface IUserMenuState {
  isAuthenticatedUser: boolean;
}

class UserMenu extends React.Component<IUserMenuProps, IUserMenuState> {
  constructor(props: IUserMenuProps) {
    super(props);
    this.state = {
      isAuthenticatedUser: AuthService.isAuthenticatedUser(),
    };
  }

  render() {
    var menu = this._getMenu();
    return (
        <div id={"user_menu_button"}>
          <Dropdown trigger={this._getAvatar()} tooltip="Any">
            <div id={"user_menu"}>
              {menu}
            </div>
          </Dropdown>
        </div>
    );
  }

  _getMenu = () => {
    const userName = (FieldBrowserSupport.isOfflineMode()) ? "" : this.props.userName;
    let menu;
    menu = <DropdownItemGroup  title={userName}>
      {!this._isOffline() &&<DropdownItem id={"user_profile_button"} onClick={this._navigateToUserProfilePage}>{t("pageTitleUserProfile")}</DropdownItem>}
      {!this._isOffline() && <DropdownItem id={"user_sign_out_button"} onClick={this._signOut}>{t("buttonSignOut")}</DropdownItem>}
      {this._isOffline() && <DropdownItem id={"user_sign_in_button"} onClick={this._signIn}>{t("buttonSignIn")}</DropdownItem>}
    </DropdownItemGroup>;
    return menu;
  }

  _getAvatar = () => {
    if(Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems)) {
      if(FieldBrowserSupport.isOfflineMode()) {
        return (<div>
          <Skeleton color={Constants.colorAmbergTechnologiesRed} style={{opacity:0.8}}>
            <InspectorOfflineIcon size={"large"} />
          </Skeleton>
        </div>)
      } else {
        return (<div>
          <Skeleton color={'limegreen'} style={{opacity:0.9}}>
            <InspectorOnlineIcon size={"large"} />
          </Skeleton>
        </div>)
      }
    } else {
      return (<div>
        <Avatar isActive={false} isHover={false} src={''} />
      </div>);
    }
  }

  _signIn = () => {
    this.props.history.push(Routes.login.path);
  };

  _signOut = async () => {
    await AuthService.signOut();
    if (Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems)) {
      this.props.history.push(Routes.home.path);
    } else {
      this.props.history.push(Routes.login.path);
    }
  };

  _navigateToUserProfilePage = () => {
    this.props.history.push(Routes.userprofile.path);
  };

  _isOffline = () => {
    if(FieldBrowserSupport.isOfflineMode()) {
      return true;
    } else if (!AuthService.hasValidAuthentication()) {
      return true;
    }
    else {
      return false;
    }
  }
}

export default withRouter(UserMenu);

export function LabelUserMenu() {
  if(FieldBrowserSupport.isOfflineMode()) {
    return t("labelOffline");
  } else {
    return t("navigationUser");
  }
};

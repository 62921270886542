import Form from '@atlaskit/form';
import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import MultiSelectComboBoxLabeled
  from "../components/labeled/MultiSelectComboBoxLabeled";
import TagsLabeled from "../components/labeled/TagsLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import CreateUserRequest from '../dto/requests/CreateUserRequest';
import GroupSummary from '../dto/responses/GroupSummary';
import ResourceSummary from '../dto/responses/ResourceSummary';
import {isNoneEmptyValidString, hasInvalidChars} from '../helpers/StringHelpers';
import AuthService from '../services/AuthService';
import FlagService from '../services/FlagService';
import Logger from '../services/Logger';

export interface ICreateUserModalProps extends WithNamespaces {
    createUrl: string;
    allGroups: GroupSummary[];
    allResources: ResourceSummary[];
    allRoles: string[];
    onCancel: () => void;
    onOk: () => void;
}

export interface ICreateUserModalState {
    loginName: string;
    name: string;
    password: string;
    groups: GroupSummary[];
    ownRoles: string[];
    groupRoles: string[];
    ownResources: ResourceSummary[];
    groupResources: ResourceSummary[];
    error: string
}

const isBlockedUser = (value: string) => {
    if(0 === value.localeCompare('admin', undefined, {sensitivity: 'accent'})){
        return true;
    }
    return false;
}

class CreateUserModal extends React.Component<ICreateUserModalProps, ICreateUserModalState> {
    constructor(props: ICreateUserModalProps) {
        super(props);
        this.state = {
            groupResources: [],
            groupRoles: [],
            groups: [],
            loginName: "",
            name: "",
            ownResources: [],
            ownRoles: [],
            password: "",
            error: t("textInvalidEmptyField"),
        }
    }

    isValidUser = (value: string) => {
        if(!isNoneEmptyValidString(value)){
            this.setState({error: t("textInvalidEmptyField")})
            return false;
        }

        if(isBlockedUser(value)){
            this.setState({error: t("textBlockedUserName")})
            return false;
        }
        return true;
    }

    public render() {
        const { loginName, name, password, groups, ownRoles, groupRoles, ownResources, groupResources, error } = this.state;
        const { allGroups, allRoles, allResources } = this.props;

        return (
            <Modal
                heading={t("modalTitleCreateUser")}
                actions={[{
                    onClick: this.onSave,
                    text: t("buttonCreate"),
                }, {
                    onClick: this.props.onCancel,
                    text: t("buttonCancel"),
                }]}
                onClose={this.props.onCancel}
            >
                <Form onSubmit={this.onSave}>
                    {({ formProps }) => (
                        <form {...formProps} className="modalForm">
                        <TextBoxLabeled
                            label={t("labelUserName")}
                            placeholder={t("labelEMail")}
                            required={true}
                            value={loginName}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ loginName: value })}
                            // tslint:disable-next-line:jsx-no-lambda
                            validate={(value: string) => {
                                return this.isValidUser(value);
                            }}
                            invalid={error}
                            isSpellCheckEnabled={false}
                            testId = {"user-name-textbox"}
                        />
                        <TextBoxLabeled
                            label={t("labelPassword")}
                            placeholder={t("labelPassword")}
                            required={true}
                            value={password}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ password: value })}
                            // tslint:disable-next-line:jsx-no-lambda // will not be executed when doNotValidate = true
                            validate={value => value !== ""}
                            invalid={t("textInvalidEmptyField")}
                            isPassword={true}
                            isSpellCheckEnabled={false}
                            testId = {"user-password-textbox"}
                        />
                        <TextBoxLabeled
                            label={t("labelFullName")}
                            placeholder={t("labelFullName")}
                            value={name}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ name: value })}
                            validate={(value: string) => {
                                return isNoneEmptyValidString(value);
                            }}
                            invalid={t("textInvalidEmptyField")}
                            testId = {"user-full-name-textbox"}
                        />
                        <MultiSelectComboBoxLabeled
                            label={t("labelGroups")}
                            placeholder={t("labelSelect")}
                            options={allGroups.map(group => {
                                return {
                                    label: group.name,
                                    value: group.id,
                                };
                            })}
                            selectedOptions={groups.map(group => {
                                return {
                                    label: group.name,
                                    value: group.id,
                                };
                            })}
                            onChange={this.onGroupSelectionChange}
                        />
                        <MultiSelectComboBoxLabeled
                            label={t("labelLicences")}
                            placeholder={t("labelSelect")}
                            options={allRoles.map(role => {
                                return {
                                    label: role,
                                    value: role,
                                };
                            })}
                            selectedOptions={ownRoles.map(role => {
                                return {
                                    label: role,
                                    value: role,
                                };
                            })}
                            onChange={this.onRoleSelectionChange}
                        />
                        <TagsLabeled
                            label={t("labelGroupLicences")}
                            values={groupRoles.length > 0 ? groupRoles : []}
                            readonly={true}
                        />
                        <MultiSelectComboBoxLabeled
                            label={t("labelProjects")}
                            placeholder={t("labelSelect")}
                            options={allResources.map(resource => {
                                return {
                                    description: resource.type,
                                    label: resource.name,
                                    value: resource.id,
                                };
                            })}
                            selectedOptions={ownResources.map(resource => {
                                return {
                                    label: resource.name,
                                    value: resource.id,
                                };
                            })}
                            onChange={this.onResourceSelectionChange}
                        />
                        <TagsLabeled
                            label={t("labelGroupProjects")}
                            values={groupResources.map(r => r.name)}
                            readonly={true}
                        />
                        </form>
                    )}
                </Form>
            </Modal>
        );
    }

    private onGroupSelectionChange = (options: ISSelectOption[]) => {
        // update inherited and user roles/resouces according to group memberships
        const selectedGroupIds = options.map(o => o.value);
        const groups = this.props.allGroups.filter(g => selectedGroupIds.some(value => value === g.id));

        const groupRoleIds = Array.from(new Set(groups.map(g => g.roles).reduce((a, b) => a.concat(b), [])));
        const groupResourceIds = Array.from(new Set(groups.map(g => g.resources).reduce((a, b) => a.concat(b), []).map(resource => resource.id)));

        const groupRoles = this.props.allRoles.filter(role => groupRoleIds.some(id => id === role));
        const groupResources = this.props.allResources.filter(resource => groupResourceIds.some(id => id === resource.id));

        this.setState({
            groupResources,
            groupRoles,
            groups,
        });
    }

    private onRoleSelectionChange = (options: ISSelectOption[]) => {
        const selectedRoleIds = options.map(o => o.value);
        const ownRoles = this.props.allRoles.filter(role => selectedRoleIds.some(id => id === role));
        this.setState({ ownRoles });
    }

    private onResourceSelectionChange = (options: ISSelectOption[]) => {
        const selectedResourceIds = options.map(o => o.value);
        const ownResources = this.props.allResources.filter(resource => selectedResourceIds.some(id => id === resource.id));
        this.setState({ ownResources });
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }
        const { loginName, name, password, groups, ownRoles, ownResources } = this.state;
        const request: CreateUserRequest = {
            groups: groups.map(group => group.id),
            loginName,
            name,
            password,
            resources: ownResources.map(resource => resource.id),
            roles: ownRoles,
        };
        try {
            const { createUrl } = this.props;
            await AuthService.post(createUrl, request);
            FlagService.addInfo(t("labelCreated"), t("textCreated", {what:t("labelUser"), name}));
            this.props.onOk();
        } catch (error) {
            Logger.error(error, "CreateUserModal");
            FlagService.addError(t("textCreationFailed", {what:t("labelUser"), name}), error);
        }
    }

    private validate = (): boolean => {
        const { loginName, name, password } = this.state;
        const listToValidate = [name, password];
        if(!isNoneEmptyValidString(loginName) || hasInvalidChars(loginName) || isBlockedUser(loginName)){
            return false;
        }
        if(!isNoneEmptyValidString(name)){
            return false;
        }
        for (const s of listToValidate) {
            if (!s || hasInvalidChars(s) )
            {
                return false;
            }
        }
        return true;
    }   

}

export default withNamespaces()(CreateUserModal)

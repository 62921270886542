import Form from '@atlaskit/form';
import LicenseHelper from "helpers/LicenseHelper";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ButtonConfirm from "../components/ButtonConfirm";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import DateSelectLabeled from "../components/labeled/DateSelectLabeled";
import TagLabeled from "../components/labeled/TagLabeled";
import TagsLabeled from "../components/labeled/TagsLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import PageTitleItem from "../components/PageTitleItem";
import AdministrationLicensePageData from "../dto/pages/AdministrationLicensePageData";
import UpdateLicenseRequest from "../dto/requests/UpdateLicenseRequest";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import { isNoneZeroPositiveDouble } from "../helpers/StringHelpers";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";

export interface IAdministrationLicensePageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationLicensePageState {
    data?: AdministrationLicensePageData;
    id: string;
    name: string;
    count: number;
    assigned: number;
    expirationDate: string;
    users: string[];
}

class AdministrationLicensePage extends React.Component<IAdministrationLicensePageProps, IAdministrationLicensePageState> {
    constructor(props: IAdministrationLicensePageProps) {
        super(props);
        this.state = {
            assigned: 0,
            count: 1,
            expirationDate: "",
            id: "",
            name: "",
            users: [],
        };
    }

    public render() {
        const { data, id, name, count, assigned, expirationDate, users } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleLicense")}  testID={"admin-license-page-title"}>
                    {AuthService.isAmbergAdmin() &&
                        <ButtonConfirm
                            textButton={t("buttonDelete")}
                            textPopup={t("textDeleteWarning", {what: t("labelLicence")})}
                            appearance={"danger"}
                            onClick={this.onDelete}
                            testId={"delete-license-button"}
                        />
                    }
                </PageTitleItem>
                {data &&
                <Form onSubmit={this.onSave}>{
                    ({formProps}) => (
                        <form {...formProps}>
                            <TextBoxLabeled
                                label={t("labelLicence")}
                                disabled={true}
                                value={LicenseHelper.labelForLicense(name)}
                                testId = {"license-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelArticleNr")}
                                disabled={true}
                                value={LicenseHelper.descriptionForLicense(id)}
                                testId = {"article-number-textbox"}
                            />
                            {LicenseHelper.isAssignableToUser(id) && <TextBoxLabeled
                                label={t("labelCount")}
                                disabled={!AuthService.isAmbergAdmin()}
                                value={count.toString()}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ count: parseInt(value, undefined) })}
                                validate = { isNoneZeroPositiveDouble }
                                invalid = {t("validatorNumberBoxMin", { minimum: 0.0 })}
                            />}
                            {LicenseHelper.isAssignableToUser(id) && <TagLabeled
                                label={t("labelUsage")}
                                value={`${assigned} / ${count}`}
                                highlighted={assigned === count}
                                isRemoveable={false}
                            />}
                            <DateSelectLabeled
                                label={t("labelExpirationDate")}
                                value={expirationDate}
                                disabled={!AuthService.isAmbergAdmin()}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={(value) => this.setState({ expirationDate: value })}
                            />
                            <TagsLabeled
                                label={t("labelUsers")}
                                values={users}
                                readonly={!AuthService.isAmbergAdmin()}
                            />
                            {AuthService.isAmbergAdmin() &&
                                <ButtonLabeled
                                    type="submit"
                                    testId={"save-license-button"}
                                >
                                    {t("buttonSave")}
                                </ButtonLabeled>}
                        </form>
                    )}
                </Form>}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const { accountId, licenseId } = this.props.match.params;
            const dataUrl = AuthService.isAmbergAdmin() ? `administrationlicense/${accountId}` : `license`;
            const data = await AuthService.get<AdministrationLicensePageData>(`${dataUrl}/${licenseId}`);
            this.setState({
                assigned: data.license.assigned,
                count: data.license.count !== undefined ? data.license.count : 1,
                data,
                expirationDate: data.license.expirationDate,
                id: data.license.id,
                name: data.license.name,
                users: data.license.users || [],
            });
            this.props.setLocationName(data.account.name);
        } catch (error) {
            //
        }
    }

    private onDelete = async () => {
        const { data, id, name } = this.state;
        if (!data) {
            return;
        }
        try {
            const { accountId } = this.props.match.params;
            await AuthService.delete(`administrationlicense/${accountId}/${id}`);
            this.props.history.push(Routes.administrationAccountLicenses.patch(accountId).path);
            FlagService.addInfo(t("labelDeleted"), t("textDeleted", {what:t("labelLicence"), name}));
        } catch (error) {
            Logger.error(error, "AdministrationLicensePage");
            FlagService.addError( t("textDeletionFailed", {what:t("labelLicence"), name}), error);
        }
    }

    private onSave = async (event: any) => {
        if (!this.validate()) {
            return;
        }
        const { id, name, count, expirationDate } = this.state;

        const assignableToUserAsRole = LicenseHelper.isAssignableToUser(id);

        const request: UpdateLicenseRequest = {
            count: assignableToUserAsRole ? count : 1,
            expirationDate,
            id,
        };
        try {
            const { accountId } = this.props.match.params;
            await AuthService.put(`administrationlicense/${accountId}`, request);
            this.props.history.push(Routes.administrationAccountLicenses.patch(accountId).path);
            FlagService.addInfo(t("labelSaved"),t("textSaved", {what:t("labelLicence"), name}));
        } catch (error) {
            Logger.error(error, "AdministrationLicensePage");
            FlagService.addError( t("textSaveFailed", {what:t("labelLicence"), name}), error);
        }
    }

    private validate = (): boolean => {
        const { id, name, expirationDate } = this.state;
        if (!id) {
            return false;
        }
        if (!name) {
            return false;
        }
        if (!expirationDate) {
            return false;
        }
        return true;
    }
}

export default compose(withNamespaces())(AdministrationLicensePage)

import * as React from "react";
import DateSelect, { IDateSelectProps } from "../DateSelect";
import LabelWrapper from "./Wrapper";
import { ILabelWrapperProps } from "./Wrapper";

interface IDateSelectLabeledProps extends ILabelWrapperProps, IDateSelectProps {}

export default function DateSelectLabeled(props: IDateSelectLabeledProps) {
    const { label, children, labelMarginTop, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
            <DateSelect label={""} {...rest}>
                {children}
            </DateSelect>
        </LabelWrapper>
    );
}

import Spinner from "@atlaskit/spinner";
import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import Button from '../components/atlaskit/Button';
import PageTitleItem from '../components/PageTitleItem';
import ProjectPreview from '../components/ProjectPreview';
import Table, {
    DateTimeTableColumn,
    IconTableColumn,
    LinkTableColumn,
    NotSortableTextTableColumn,
    TextTableColumn
} from "../components/Table";
import TitleItem from '../components/TitleItem';
import InspectionAnalysisSummary from '../dto/components/InspectionAnalysisSummary';
import InspectionTaskSummary from '../dto/components/InspectionTaskSummary';
import OfflineInspectionTaskSummary from '../dto/components/OfflineInspectionTaskSummary';
import ProjectSummary from "../dto/components/ProjectSummary";
import InspectionHelper from '../helpers/InspectionHelper';
import Policies from "../helpers/Policies";
import Routes from "../helpers/Routes";
import AuthService from '../services/AuthService';
import homePageService from "../services/HomePageService";
import OfflineStorageService from '../services/OfflineStorageService';
import FieldBrowserSupport from '../helpers/FieldBrowserSupport'
import Modal from '@atlaskit/modal-dialog';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface IHomePageProps {
    dummy?: string;
}

export interface IHomePageState {
    recentProjects?: ProjectSummary[];
    recentInspectionTasks?: InspectionTaskSummary[];
    recentInspectionAnalyses?: InspectionAnalysisSummary[];
    fieldInspectionTasks?: OfflineInspectionTaskSummary[];
    isSyncResultOpen: boolean;
    syncResult: JSX.Element;
    isSyncButtonDisabled: boolean;
}

class HomePage extends React.Component<IHomePageProps, IHomePageState> {
    constructor(props: IHomePageProps) {
        super(props);
        this.state = {recentProjects: undefined, recentInspectionTasks: undefined, recentInspectionAnalyses: undefined, isSyncResultOpen: false, syncResult:<span/>, isSyncButtonDisabled:false};
    }

    public render() {
        const { recentProjects, recentInspectionTasks, recentInspectionAnalyses, fieldInspectionTasks, isSyncResultOpen, syncResult, isSyncButtonDisabled } = this.state;
        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleHome")}  testID={"home-page-title"}/>
                <div className="homeContainer">
                    {isSyncResultOpen && (
                        <Modal 
                        actions= {[{ text: t("buttonOk"), onClick: this.closeModal }]}
                        onClose= {this.closeModal}
                        heading= {t("ResultOfSynchronization")}
                        scrollBehavior={"outside"}>
                            {syncResult}
                        </Modal>
                    )}

                    <div id="previewMaps">
                        <TitleItem title={t("sectionTitleRecentProjects")} />
                        <div>
                            { !recentProjects && (
                                <Spinner size={"large"} />
                            )}
                        </div>
                        <div className="homeProjectPreviewContainer">
                            {recentProjects && recentProjects.map((project, index) =>
                                <ProjectPreview
                                    key={index}
                                    projectSummary={project}
                                />
                            )}
                        </div>
                    </div>
                    <div id="tables">
                        <div>
                            <TitleItem title={t("sectionTitleRecentInspectionTasks")} />
                            <div className="tableContainer">
                                <Table
                                    data={recentInspectionTasks}
                                    columns={[
                                        new IconTableColumn<InspectionTaskSummary>("", item => InspectionHelper.iconForStructureTaskType(item.type)),
                                        new LinkTableColumn<InspectionTaskSummary>(
                                            t("labelName"),
                                            item => item.name,
                                            Policies.isValidPolicy(Policies.toSeeInspection) ?
                                              item => Routes.task.patch(item.project.id, item.structure.id, item.type, item.id).path
                                              :
                                              undefined
                                        ),
                                        new LinkTableColumn<InspectionTaskSummary>(
                                          t("labelProject"),
                                          item => item.project.name,
                                          Policies.isValidPolicy(Policies.toSeeProject) ?
                                            item => Routes.project.patch(item.project.id).path
                                            :
                                            undefined
                                        ),
                                        new LinkTableColumn<InspectionTaskSummary>(
                                          t("labelStructure"),
                                          item => item.structure.name,
                                          Policies.isValidPolicy(Policies.toSeeProject) ?
                                            item => Routes.projectStructure.patch(item.project.id, item.structure.id).path
                                            :
                                            undefined
                                        ),
                                        new DateTimeTableColumn<InspectionTaskSummary>(t("labelUpdated"), item => new Date(item.updated)),
                                    ]}
                                    rowsPerPage={5}
                                    noDefaultSort={true}
                                />
                            </div>
                        </div>
                        {fieldInspectionTasks && fieldInspectionTasks.length > 0 &&
                        <div>
                            <TitleItem title={t("sectionTitleFieldInspections")}>
                                <Button
                                    onClick={this.synchronizeAllFieldInspectionTasks}
                                    testId={"synchronize-inspection-tasks-button"}
                                    isDisabled={isSyncButtonDisabled}
                                >
                                    {t("buttonSynchronizeAll")}
                                </Button>
                            </TitleItem>
                            <div className="tableContainer">
                                <Table
                                    data={fieldInspectionTasks}
                                    columns={[
                                        new NotSortableTextTableColumn<OfflineInspectionTaskSummary>("", item => item.dirty? "*": ""),
                                        new IconTableColumn<OfflineInspectionTaskSummary>("", item => InspectionHelper.iconForStructureTaskType(item.task.type)),
                                        new LinkTableColumn<OfflineInspectionTaskSummary>(
                                            t("labelName"),
                                            item => item.task.name,
                                            item => Routes.task.patch(item.task.project.id, item.task.structure.id, item.task.type, item.task.id).path
                                        ),
                                        new LinkTableColumn<OfflineInspectionTaskSummary>(
                                            t("labelProject"),
                                            item => item.task.project.name,
                                            Policies.isValidPolicy(Policies.toSeeProject) ?
                                            item => Routes.project.patch(item.task.project.id).path
                                            :
                                            undefined
                                        ),
                                        new LinkTableColumn<OfflineInspectionTaskSummary>(
                                            t("labelStructure"),
                                            item => item.task.structure.name,
                                            Policies.isValidPolicy(Policies.toSeeProject) ?
                                            item => Routes.projectStructure.patch(item.task.project.id, item.task.structure.id).path
                                            :
                                            undefined
                                        ),
                                        new TextTableColumn<OfflineInspectionTaskSummary>(t("labelMeasurement"), item => item.task.measurement.name),
                                        new DateTimeTableColumn<OfflineInspectionTaskSummary>(t("labelUpdated"), item => new Date(item.task.updated)),
                                    ]}
                                />
                            </div>
                        </div>}
                        <div>
                            <TitleItem title={t("sectionTitleRecentInspectionAnalysis")} />
                            <div className="tableContainer">
                                <Table
                                    data={recentInspectionAnalyses}
                                    columns={[
                                        new IconTableColumn<InspectionAnalysisSummary>("", item => InspectionHelper.iconForAnalysisType(item.type)),
                                        new LinkTableColumn<InspectionAnalysisSummary>(
                                            t("labelName"),
                                            item => item.name,
                                            item => Routes.analysis.patch(item.project.id, item.structure.id, item.type, item.id).path
                                        ),
                                        new LinkTableColumn<InspectionTaskSummary>(
                                          t("labelProject"),
                                          item => item.project.name,
                                          Policies.isValidPolicy(Policies.toSeeProject) ?
                                            item => Routes.project.patch(item.project.id).path
                                            :
                                            undefined
                                        ),
                                        new LinkTableColumn<InspectionTaskSummary>(
                                          t("labelStructure"),
                                          item => item.structure.name,
                                          Policies.isValidPolicy(Policies.toSeeProject) ?
                                            item => Routes.projectStructure.patch(item.project.id, item.structure.id).path
                                            :
                                            undefined
                                        ),
                                    ]}
                                    rowsPerPage={5}
                                    noDefaultSort={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    closeModal = () => this.setState({isSyncResultOpen: false})

    public async componentDidMount() {
        this.loadRecentProjects();
        this.loadRecentStructureTasksAndAnalysis();
        Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems) && this.loadFieldInspectionTasks();
    };

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    };

    private loadRecentProjects = async () => {
        const recentProjects = await homePageService.loadRecentProjects();
        this.setState({ recentProjects });
    };

    private loadRecentStructureTasksAndAnalysis = async () => {
        const recentInspections = await homePageService.loadRecentStructureTasksAndAnalysis();
        const recentInspectionTasks = recentInspections[0];
        const recentInspectionAnalyses = recentInspections[1];
        
        this.setState({ recentInspectionTasks, recentInspectionAnalyses });
    };

    private loadFieldInspectionTasks = async () => {
        try {
            const offlineInspection = await OfflineStorageService.loadOfflineInspectionTasks();
            this.setState({ fieldInspectionTasks: offlineInspection});
            this.setSyncButtonState();
        } catch (error) {
            //
        }
    }

    private synchronizeAllFieldInspectionTasks = async () => {
        const {fieldInspectionTasks} = this.state;
        this.setState({isSyncButtonDisabled: true });
        let uploaded: JSX.Element[]= [];
        let notUploaded: JSX.Element[]= [];
        if (fieldInspectionTasks){
            [uploaded, notUploaded] = await OfflineStorageService.synchronizeAllInspectionTask(fieldInspectionTasks);
            let resultText = <div>
                <p>{t("AllTaskSynchronized")}</p>
                <p>{t("TasksWasUploaded")}</p>
                {uploaded}
                <p>{t("TasksWasNotUploaded")}</p>
                {notUploaded}
            </div>;
            
            this.setState({isSyncResultOpen: true, syncResult:resultText})            
        }
        await this.loadFieldInspectionTasks();
        this.setSyncButtonState();
    }
    private setSyncButtonState = () =>{
        const disabled = FieldBrowserSupport.isOfflineMode();
        this.setState({ isSyncButtonDisabled:disabled });
    }
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, HomePage,"HomePage","AppInsight"));

export enum PatternStyleType {
    PatternNone = "PatternNone",
    PatternSolid = "PatternSolid",

    PatternHorizontal = "PatternHorizontal",
    PatternHorizontalDense = "PatternHorizontalDense",
    PatternHorizontalSparse = "PatternHorizontalSparse",

    PatternVertical = "PatternVertical",
    PatternVerticalDense = "PatternVerticalDense",
    PatternVerticalSparse = "PatternVerticalSparse",

    PatternBDiagonal = "PatternBDiagonal",
    PatternBDiagonalDashed = "PatternBDiagonalDashed",
    PatternBDiagonalDense = "PatternBDiagonalDense",
    PatternBDiagonalDenseDashed = "PatternBDiagonalDenseDashed",
    PatternBDiagonalSparse = "PatternBDiagonalSparse",
    PatternBDiagonalSparseDashed = "PatternBDiagonalSparseDashed",

    PatternFDiagonal = "PatternFDiagonal",
    PatternFDiagonalDashed = "PatternFDiagonalDashed",
    PatternFDiagonalDense = "PatternFDiagonalDense",
    PatternFDiagonalDenseDashed = "PatternFDiagonalDenseDashed",
    PatternFDiagonalSparse = "PatternFDiagonalSparse",
    PatternFDiagonalSparseDashed = "PatternFDiagonalSparseDashed",

    PatternDotted1 = "PatternDotted1",
    PatternDotted2 = "PatternDotted2",
    PatternDotted3 = "PatternDotted3",

    PatternCrossPattern = "PatternCrossPattern",
    PatternCrossDashed = "PatternCrossDashed",

    PatternDiagCross = "PatternDiagCross",
    PatternDiagCrossLong = "PatternDiagCrossLong",
    PatternDiagCrossDashed = "PatternDiagCrossDashed",
    PatternCrossDiagCross = "PatternCrossDiagCross",

    PatternDots1 = "PatternDots1",
    PatternDots2 = "PatternDots2",
    PatternDots3 = "PatternDots3",

    PatternBDiagonal2 = "PatternBDiagonal2",
    PatternFDiagonal2 = "PatternFDiagonal2",
    PatternBDiagonal3 = "PatternBDiagonal3",
    PatternFDiagonal3 = "PatternFDiagonal3",
    PatternBDiagonal5 = "PatternBDiagonal5",
    PatternFDiagonal5 = "PatternFDiagonal5",
    PatternTextInRectangle = "PatternTextInRectangle",
}

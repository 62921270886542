import { Checkbox } from "@atlaskit/checkbox";
import * as React from "react";
import LabelWrapper from "./labeled/Wrapper";

export interface ICheckBoxFieldProps {
  label: string;
  text: string;
  value: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

export default function CheckBoxField(props: ICheckBoxFieldProps) {
  return (
    <LabelWrapper label={props.label}>
      <div style={{ height: "40px" }}>
        <div style={{ marginTop: "16px" }}>
        <Checkbox
          name="Checkbox"
          label={props.text}
          value={props.text}
          disabled={props.disabled || false}
          isChecked={props.value}
          // tslint:disable-next-line:jsx-no-lambda
          onChange={(event: any) => props.onChange && props.onChange(!props.value)}
        />
        </div>
      </div>
    </LabelWrapper>
  );
}

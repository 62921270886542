import * as L from "leaflet";
import React from "react";
import Constants from "../helpers/Constants";
import MapProviderHelper from "../helpers/MapProviderHelper";
import Proj4Helper from "../helpers/Proj4Helper";

export interface IProjectSettingsMapProps {
  mapProvider: string;
  coordinateSystem?: string;
  customProj4?: string;
  customCrsOriginLatitude?: number;
  customCrsOriginLongitude?: number;
}

export default class ProjectSettingsMap extends React.Component<
  IProjectSettingsMapProps,
  {}
> {
  public static getDerivedStateFromProps(props: any, state: any) {
    return null;
  }

  private map: L.Map;
  private readonly tileLayers: Map<string, L.Layer>;
  private areaLayer: Map<string, L.Layer>;

  constructor(props: IProjectSettingsMapProps) {
    super(props);
    this.tileLayers = new Map<string, L.Layer>();
    this.areaLayer = new Map<string, L.Layer>();
  }

  public componentDidUpdate(prevProps: IProjectSettingsMapProps) {
    if (this.props.mapProvider !== prevProps.mapProvider) {
      this.setMapProvider(this.props.mapProvider);
    }
    this.markArea();
    this.map.invalidateSize()
  }

  public render() {
    return <div id="project-settings-map"  style={{ height: "100%" }}/>;
  }

  public componentDidMount() {
    this.createMap();
  }

  public componentWillUnmount(){
    this.map.remove();
  }

  private createMap = () => {
    this.map = L.map("project-settings-map", {
      attributionControl: false,
      crs: L.CRS.EPSG3857,
      doubleClickZoom: false,
      dragging: false,
      scrollWheelZoom: false,
      touchZoom: false,
      zoomControl: false,
      zoomSnap: 0,
    });

    this.setMapProvider(this.props.mapProvider);
    this.markArea();
  };

  private markArea = () => {
    this.clearArea();

    switch (this.props.coordinateSystem) {
      case Proj4Helper.CH1903: {
        this.addInvertedBoundingBox(
          Proj4Helper.latLonBoundsForEPSG(this.props.coordinateSystem)
        );
        break;
      }
      case Proj4Helper.CH1903plus: {
        this.addInvertedBoundingBox(
          Proj4Helper.latLonBoundsForEPSG(this.props.coordinateSystem)
        );
        break;
      }
      case Proj4Helper.CustomProj4String: {
        this.addCrosshair(
          Proj4Helper.getNumericParameterFromProj4(this.props.customProj4, "+lat_0"),
          Proj4Helper.getNumericParameterFromProj4(this.props.customProj4, "+lon_0")
        );
        break;
      }
      case Proj4Helper.CustomLocalCrs: {
        this.addCrosshair(
          Number(this.props.customCrsOriginLatitude),
          Number(this.props.customCrsOriginLongitude)
        );
        break;
      }
    }
  };

  private clearArea = () => {
    if (this.areaLayer) {
      this.areaLayer.forEach(l => {
        this.map.removeLayer(l);
      });
    }
  };

  private addInvertedBoundingBox = (bounds?: [[number, number], [number, number]]) => {
    if (bounds) {
      this.addBoundingBox([[-90, -180], [90, bounds[0][1]]], "west");
      this.addBoundingBox([[-90, bounds[0][1]], [bounds[0][0], bounds[1][1]]], "north");
      this.addBoundingBox([[bounds[1][0], bounds[0][1]], [90, bounds[1][1]]], "south");
      this.addBoundingBox([[-90, bounds[1][1]], [90, 180]], "east");
      this.map.fitBounds(bounds, { padding: [24, 24] });
    }
  };

  private addBoundingBox = (bounds: any, asdf: string) => {
    const newBox = this.createBoundingBox(bounds);
    this.areaLayer.set(asdf, newBox);
    this.map.addLayer(newBox);
  };

  private createBoundingBox = (bound: any) => {
    return L.rectangle(bound, {
      color: "#000000",
      fillOpacity: 0.5,
      interactive: false,
      stroke: false,
    });
  };

  private addCrosshair = (lat?: number, long?: number) => {
    if (lat!=null && long!=null && !isNaN(lat) && !isNaN(long)) {
      const vertical = this.createLine([lat + 1, long], [lat - 1, long]);
      const horizontal = this.createLine([lat, long + 1], [lat, long - 1]);

      this.areaLayer.set("vertical", vertical);
      this.map.addLayer(vertical);
      this.areaLayer.set("horizontal", horizontal);
      this.map.addLayer(horizontal);

      this.map.setView(new L.LatLng(lat, long), 12);
    }
  };

  private createLine = (pointA: [number, number], pointB: [number, number]) => {
    return new L.Polyline([pointA, pointB], {
      color: Constants.colorAmbergTechnologiesRed,
      weight: 1,
      opacity: 1,
      smoothFactor: 1,
    });
  };

  private setMapProvider = (mapProvider: string) => {
    this.addMapProvider(mapProvider);
    this.tileLayers.forEach(l => {
      this.map.removeLayer(l);
    });
    const layer = this.tileLayers.get(mapProvider);
    if (layer) {
      this.map.addLayer(layer);
    }
  };

  private addMapProvider = (mapProvider: string) => {
    if (!this.tileLayers.get(mapProvider)) {
      const urlTemplate = MapProviderHelper.urlTemplateForMapProvider(mapProvider);
      const tileLayer = L.tileLayer(urlTemplate);
      this.tileLayers.set(mapProvider, tileLayer);
    }
  };
}

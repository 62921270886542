import Select, { components, CheckboxSelect } from "@atlaskit/select";
import * as React from "react";


export interface ISSelectOption {
    label: string;
    value: string;
    description?: string;
}


export interface IBaseProps {
    children?: any;
    id?: string;
    formatOptionLabel?: React.ReactNode;
    menuPortalTarget?: React.ReactNode;
    name?: string;
    options?: ISSelectOption[];
    placeholder?: string;
    styles?: any
    isClearable?: boolean;
}


export interface ISelectProps extends IBaseProps {
    onChange?: (option?: ISSelectOption) => void;
    value?: ISSelectOption;
}


export interface IMultiSelectProps extends IBaseProps {
    onChange?: (option?: ISSelectOption[]) => void;
    value?: ISSelectOption[];
}


const Menu = (props: any) => (
    <components.Menu {...props}>
    </components.Menu>
);


export function SelectTouch(props: ISelectProps) {
    return (
        <Select
            id={props.id}
            formatOptionLabel={props.formatOptionLabel}
            isMulti={false}
            isClearable={props.isClearable || false}
            menuPortalTarget={props.menuPortalTarget}
            name={props.name}
            onChange={props.onChange}
            options={props.options}
            placeholder={props.placeholder}
            styles={props.styles}
            value={props.value}
            components={{ Menu }}
        >
            {props.children}
        </Select>
    );
}


export function CheckboxSelectTouch(props: IMultiSelectProps) {
    return (
        <CheckboxSelect
            id={props.id}
            formatOptionLabel={props.formatOptionLabel}
            isMulti={true}
            menuPortalTarget={props.menuPortalTarget}
            name={props.name}
            onChange={props.onChange}
            options={props.options}
            placeholder={props.placeholder}
            styles={props.styles}
            value={props.value}
            components={{ Menu }}
        >
            {props.children}
        </CheckboxSelect>
    );
}

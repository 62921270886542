import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import Button from "../components/atlaskit/Button";
import { CreateIcon } from "../components/Icons";
import PageTitleItem from "../components/PageTitleItem";
import Table, {
  ActionsTableColumn,
  DateTableColumn,
  LabelBadgesTableColumn,
  LinkTableColumn,
  TableItemAction,
  TextTableColumn,
} from "../components/Table";
import AdministrationLicensesPageData
  from "../dto/pages/AdministrationLicensesPageData";
import LicenseSummary from "../dto/responses/LicenseSummary";
import IRouteParams from "../helpers/IRouteParams";
import LicenseHelper from "../helpers/LicenseHelper";
import Routes from "../helpers/Routes";
import CreateLicenseModal from "../modals/CreateLicenseModal";
import AuthService from "../services/AuthService";

export interface IAdministrationLicensesPageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationLicensesPageState {
    showCreateLicenseModal: boolean;
    data?: AdministrationLicensesPageData;
}

class AdministrationLicensesPage extends React.Component<IAdministrationLicensesPageProps, IAdministrationLicensesPageState> {
    constructor(props: IAdministrationLicensesPageProps) {
        super(props);
        this.state = {
            showCreateLicenseModal: false,
        };
    }

    public render() {
        const { data, showCreateLicenseModal } = this.state;
        const { accountId } = this.props.match.params;
        const licenseLinkUrlBase = AuthService.isAmbergAdmin() ? Routes.administrationAccountLicense.patch(accountId) : Routes.licence;

        return (
            <div className="page">
                {showCreateLicenseModal && this.renderCreateLicenseModal()}
                <PageTitleItem title={t("pageTitleLicenses")} testID={"admin-licenses-page-title"}>
                    {AuthService.isAmbergAdmin() &&
                    <Button
                        iconBefore={<CreateIcon />}
                        onClick={this.showCreateLicenseModal}
                        testId={"create-license-button"}
                    >
                        {t("buttonCreate")}
                    </Button>}
                </PageTitleItem>
                <div className="tableContainer">
                    <Table
                        data={data && data.licenses}
                        columns={[
                            new LinkTableColumn<LicenseSummary>(
                                t("labelLicence"),
                                item => LicenseHelper.labelForLicense(item.name),
                                item => licenseLinkUrlBase.patch(item.id).path
                            ),
                            new TextTableColumn<LicenseSummary>(t("labelArticleNr"), item => LicenseHelper.descriptionForLicense(item.id)),
                            new LabelBadgesTableColumn<LicenseSummary>(t("labelUsage"), item => item.assignableToUserAsRole || false ? [`${item.assigned} / ${item.count}`] : [], item => item.assignableToUserAsRole && item.assigned === item.count),
                            new DateTableColumn<LicenseSummary>(t("labelExpirationDate"), item => new Date(item.expirationDate)),]
                            .concat(AuthService.isAmbergAdmin() ? [
                                new ActionsTableColumn<LicenseSummary>(item => [
                                    new TableItemAction(t("buttonEdit"), this.onEditLicense),
                                    new TableItemAction(t("buttonDelete"), this.onDeleteLicense),
                                ]),] : [])}
                    />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const { accountId } = this.props.match.params;
            const dataUrl = AuthService.isAmbergAdmin() ? `administrationlicenses/${accountId}` : `licenses`;
            const data = await AuthService.get<AdministrationLicensesPageData>(dataUrl);
            this.setState({ data });
            this.props.setLocationName(data.account.name);
        } catch (error) {
            //
        }
    }

    private renderCreateLicenseModal = () => {
        const { accountId } = this.props.match.params;

        return (
            <CreateLicenseModal
                accountId={accountId}
                onOk={this.onLicenseCreated}
                onCancel={this.hideModals}
            />
        );
    }

    private onLicenseCreated = async () => {
        this.hideModals();
        await this.loadData();
    }

    private showCreateLicenseModal = () => {
        this.setState({
            showCreateLicenseModal: true,
        });
    }

    private hideModals = () => {
        this.setState({
            showCreateLicenseModal: false,
        });
    }

    private onEditLicense = (license: LicenseSummary) => {
        const { accountId } = this.props.match.params;
        this.props.history.push(Routes.administrationAccountLicense.patch(accountId, license.id).path)
    }

    private onDeleteLicense = async (license: LicenseSummary) => {
        const { accountId } = this.props.match.params;
        await AuthService.delete(`administrationlicenses/${accountId}/${license.id}`);
        await this.loadData();
    }
}

export default compose(withNamespaces())(AdministrationLicensesPage)

/*global L*/
/*eslint no-unused-expressions: "off"*/
!(function () {
  //Leaflet-SVGIcon
  //SVG icon for any marker class
  //Ilya Atkin
  //ilya.atkin@unh.edu

  L.DivIcon.SVGIcon = L.DivIcon.extend({
      options: {
          "className": "svg-icon",
          "color": "rgb(0,102,255)",
          "fillColor": null, // defaults to color
          "fillOpacity": 0.4,
          "fontColor": "rgb(0, 0, 0)",
          "fontOpacity": "1",
          "fontSize": null, // defaults to iconSize.x/4
          "iconAnchor": null, //defaults to [iconSize.x/2, iconSize.y/2] (point tip)
          "iconSize": L.point(8,8),
          "opacity": 1,
          "popupAnchor": null,
          "weight": 2,
          "path": "M 0, 0 L 10, 10 M 10, 0 L 0, 10"
      },
      initialize: function(options) {
          options = L.Util.setOptions(this, options)
          
          //iconSize needs to be converted to a Point object if it is not passed as one
          options.iconSize = L.point(options.iconSize)

          //in addition to setting option dependant defaults, Point-based options are converted to Point objects
          if (!options.fillColor) { 
              options.fillColor = options.color
          }
          if (!options.fontSize) {
              options.fontSize = Number(options.iconSize.x/4) 
          }
          if (!options.iconAnchor) {
              options.iconAnchor = L.point(Number(options.iconSize.x)/2, Number(options.iconSize.y)/2)
          }
          else {
              options.iconAnchor = L.point(options.iconAnchor)
          }
          if (!options.popupAnchor) {
              options.popupAnchor = L.point(0, (-0.75)*(options.iconSize.y))
          }
          else {
              options.popupAnchor = L.point(options.popupAnchor)
          }
          options.html = this._createSVG()
      },
      _createPathDescription: function () {
          return this.options.path;
      },
      _createPath: function() {
          var pathDescription = this._createPathDescription()
          var strokeWidth = this.options.weight
          var stroke = this.options.color
          var strokeOpacity = this.options.Opacity
          var fill = this.options.fillColor
          var fillOpacity = this.options.fillOpacity
          var className = this.options.className + "-path"

          var path = '<path class="' + className + '" d="' + pathDescription +
              '" stroke-width="' + strokeWidth + '" stroke="' + stroke + '" stroke-opacity="' + strokeOpacity +
              '" fill="' + fill + '" fill-opacity="' + fillOpacity + '"/>'

          return path
      },
      _createSVG: function() {
          var path = this._createPath()
          var text = this._createText()
          var className = this.options.className + "-svg"

          var style = "width:" + this.options.iconSize.x + "; height:" + this.options.iconSize.y + ";"

          var svg = '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="' + className + '" style="' + style + '">' + path + text + '</svg>'

          return svg
      },
      _createText: function() {
          var fontSize = this.options.fontSize + "px"
          var lineHeight = Number(this.options.fontSize)

          var x = Number(this.options.iconSize.x) / 2
          var y = x + (lineHeight * 0.35) //35% was found experimentally 
          var textColor = this.options.fontColor.replace("rgb(", "rgba(").replace(")", "," + this.options.fontOpacity + ")")

          var text = '<text text-anchor="middle" x="' + x + '" y="' + y + '" style="font-size: ' + fontSize + '" fill="' + textColor + '"></text>'

          return text
      }
  })

  L.divIcon.svgIcon = function(options) {
      return new L.DivIcon.SVGIcon(options)
  }

  L.Marker.SVGMarker = L.Marker.extend({
      options: {
          "iconFactory": L.divIcon.svgIcon,
          "iconOptions": {}
      },
      initialize: function(latlng, options) {
          options = L.Util.setOptions(this, options)
          options.icon = options.iconFactory(options.iconOptions)
          this._latlng = latlng
      },
      onAdd: function(map) {
          L.Marker.prototype.onAdd.call(this, map)
      },
      setStyle: function(style) {
          if (this._icon) {
              var iconBody = this._icon.children[0].children[0]

              if (style.color && !style.iconOptions) {
                  var stroke = style.color.replace("rgb","rgba").replace(")", ","+this.options.icon.options.opacity+")")
                  var fill = style.color.replace("rgb","rgba").replace(")", ","+this.options.icon.options.fillOpacity+")")
                  iconBody.setAttribute("stroke", stroke)
                  iconBody.setAttribute("fill", fill)

                  this.options.icon.fillColor = fill
                  this.options.icon.color = stroke
              }
              if (style.opacity) {
                  this.setOpacity(style.opacity)
              }
              if (style.iconOptions) {
                  if (style.color) { style.iconOptions.color = style.color }
                  var iconOptions = L.Util.setOptions(this.options.icon, style.iconOptions)
                  this.setIcon(L.divIcon.svgIcon(iconOptions))
              }
          }
      }
  })

  L.marker.svgMarker = function(latlng, options) {
      return new L.Marker.SVGMarker(latlng, options)
  }
})();

import axios, { CancelTokenSource, AxiosRequestConfig, CancelToken } from "axios";
import { t } from "i18next";
import { API_ROOT } from "../Environment";
import Policies from "../helpers/Policies";
import FlagService from "./FlagService";
import Logger from "./Logger";
import FieldBrowserSupport from "../helpers/FieldBrowserSupport";

class AuthService {
    private signedInHandler: (userId: string) => void;
    private forcedSignOutHandler: (reason: string) => void;
    private forcedCookieUpdateHandler: () => void;
    private cancellationTokenSources: Set<CancelTokenSource>;

    constructor(private serviceUrl: string) {
        this.cancellationTokenSources = new Set<CancelTokenSource>();

        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Accept'] = "application/json, application/octet-stream";
        
        axios.interceptors.response.use(response => {
            return response;
        }, async error => {
            if (error.response && error.response.status === 419) {
                Logger.error("Concurrent login detected, signing out");
                await this.signOut();
                if (this.forcedSignOutHandler) {
                    this.forcedSignOutHandler("concurrentlogin");
                }
            }
            return Promise.reject(error);
        });
    }

    public registerSignedInHandler = (signedInHandler: (userId: string) => void) => {
        this.signedInHandler = signedInHandler;
    }

    public registerForcedSignOutHandler = (forcedSignOutHandler: (reason: "concurrentlogin") => void) => {
        this.forcedSignOutHandler = forcedSignOutHandler;
    }

    public registerCookieUpdateHandler = (forcedCookieUpdateHandler: () => void) => {
        Logger.info("registerCookieUpdateHandler");
        this.forcedCookieUpdateHandler = forcedCookieUpdateHandler;
    }

    public getServiceUrl = (): string => {
        return this.serviceUrl;
    }

    public getInitalRequestToken = async (): Promise<string> => {
        
        const cookieUrl = `${this.serviceUrl}/api/antiforgery/request-token`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            if (this.forcedCookieUpdateHandler){
                this.forcedCookieUpdateHandler();
            }
            
            const response = await axios({
                cancelToken: source.token,
                method: "get",
                url: cookieUrl,
                responseType: 'text',
                withCredentials: true,
            });

            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: GET ${cookieUrl}`);
            } else {
                Logger.error("Request failed", "InitalRequestToken")
            }
            return "";
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public signIn = async (username: string, password: string): Promise<boolean> => {

        const initalToken = await this.getInitalRequestToken();

        this.userName = username;
        const endpointUrl = `${this.serviceUrl}/web/secure/token`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            const params = new URLSearchParams();
            params.append("grant_type", "password");
            params.append("username", this.userName);
            params.append("password", password);
            
            const response = await axios({
                
                cancelToken: source.token,
                data: params,
                headers: {
                    "Cache-Control": "no-cache",
                    "X-XSRF-TOKEN": initalToken,
                },
                method: "post",
                url: endpointUrl,
                withCredentials: true,
            });

            Logger.trace(response.data, "AuthService")

            this.helperToken = response.data.request_token;
            this.policies = this._responseHasPolicies(response.data.policies) ? this._splitResponsePolicies(response.data.policies) : [];
            this.accessToken = response.data.access_token;
            this.logToken = response.data.log_token ?? ""; 
            await FieldBrowserSupport.setOfflineMode(false);
    
            Logger.info("SignIn succeeded", "AuthService")
            if (this.signedInHandler) {
                if(response.data.signalr_id){
                    this.signedInHandler(response.data.signalr_id);
                }
            }
            return true;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: GET ${endpointUrl}`);
            } else {
                Logger.error("SignIn failed", "AuthService")
            }
            await this.signOut();
            return false;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public signOut = async (): Promise<void> => {
        localStorage.removeItem("id_roles");
        this.userName = "";
        this.accessToken = "";
        await FieldBrowserSupport.setOfflineMode(true);
        if (FieldBrowserSupport.isOfflineMode()) {
            await this.setOfflinePolicies();
        }
        else{
            this.policies = [];
        }
        Logger.info("Signed out", "AuthService");
    }

    public isAuthenticatedUser = (): boolean => {
        if (FieldBrowserSupport.isOfflineMode()) return true;
        return this.hasValidAuthentication();
    }

    public hasValidAuthentication = (): boolean => {
        return this.accessToken != null && this.accessToken.length > 0
    }

    public isAmbergAdmin = () => {
        return [Policies.toSeeAmbergAdmin].some((v: string) => this.policies.indexOf(v) >= 0);
    };

    public isCloudAdmin = () => {
        return [Policies.toSeeAdmin].some((v: string) => this.policies.indexOf(v) >= 0);
    };

    public request = async <T>(config: any): Promise<any> => {
        try {
            config.headers = {"Authorization": "Bearer " + this.accessToken};
            if (config.data){
                config.data = JSON.parse(config.data);
            }
            return await axios.request<T>(config);
        } catch (error) {
            throw error;
        }
    };

    public get = async <T>(route: string): Promise<T> => {
        const endpointUrl = `${this.serviceUrl}/api/${route}`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            Logger.info(`Request started: GET ${endpointUrl}`);
            const response = await axios.get<T>(endpointUrl, this.requestConfig(source.token));
            Logger.info(`Request finished: GET ${endpointUrl}`);
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: GET ${endpointUrl}`);
            } else {
                Logger.error(`Request failed with code ${error.response.status}: GET ${endpointUrl}`);
                if(error.response.status === 404 && !FieldBrowserSupport.isOfflineMode()){
                    FlagService.addError(t("textBackend404"), error);
                }
            }
            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }
    public getBinary = async <T>(route: string): Promise<T> => {
        const endpointUrl = `${this.serviceUrl}/api/${route}`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            Logger.info(`Request started: GET ${endpointUrl}`);
            const response = await axios.get<T>(endpointUrl, this.requestConfigForFile(source.token));
            Logger.info(`Request finished: GET ${endpointUrl}`);
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: GET ${endpointUrl}`);
            } else {
                Logger.error(`Request failed with code ${error.response.status}: GET ${endpointUrl}`);
                if(error.response.status === 404 && !FieldBrowserSupport.isOfflineMode()){
                    FlagService.addError(t("textBackend404"), error);
                }
            }
            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public post = async<T>(route: string, data: any): Promise<T> => {
        const endpointUrl = `${this.serviceUrl}/api/${route}`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            Logger.info(`Request started: POST ${endpointUrl}`);
            const response = await axios.post<T>(endpointUrl, data, this.requestConfig(source.token));
            Logger.info(`Request finished: POST ${endpointUrl}`);
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: POST ${endpointUrl}`);
            } else {
                Logger.error(`Request failed with code ${error.response.status}: POST ${endpointUrl}`);
            }
            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public put = async<T>(route: string, data: any): Promise<T> => {
        const endpointUrl = `${this.serviceUrl}/api/${route}`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            Logger.info(`Request started: PUT ${endpointUrl}`);
            const response = await axios.put<T>(endpointUrl, data, this.requestConfig(source.token));
            Logger.info(`Request finished: PUT ${endpointUrl}`);
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: PUT ${endpointUrl}`);
            } else {
                Logger.error(`Request failed with code ${error.response.status}: PUT ${endpointUrl}`);
            }
            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public delete = async (route: string): Promise<void> => {
        const endpointUrl = `${this.serviceUrl}/api/${route}`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            Logger.info(`Request started: DELETE ${endpointUrl}`);
            await axios.delete(endpointUrl, this.requestConfig(source.token));
            Logger.info(`Request finished: DELETE ${endpointUrl}`);
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: DELETE ${endpointUrl}`);
            } else {
                Logger.error(`Request failed with code ${error.response.status}: DELETE ${endpointUrl}`);
            }
            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public downloadFile = async (route: string, filename: string): Promise<void> => {
        const data = await this.get<any>(route);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    };

    public downloadBinaryFile = async (route: string, filename: string): Promise<void> => {
        const data = await this.getBinary<any>(route);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    };



    public uploadFile = async <T>(route: string, file: File): Promise<T> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("filename", file.name);
        const endpointUrl = `${this.serviceUrl}/api/${route}`;
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);
        try {
            Logger.info(`Request started: POST ${endpointUrl}`);
            let config = this.requestConfig(source.token);
            config.headers["Content-Type"] = "multipart/form-data";
            const response = await axios.post<T>(endpointUrl, formData, config);
            Logger.info(`Request finished: POST ${endpointUrl}`);
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                Logger.info(`Request was canceled: POST ${endpointUrl}`);
            } else {
                Logger.error(`Request failed with code ${error.response.status}: POST ${endpointUrl}`);
            }
            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    }

    public cancelAllRequests = () => {
        if (this.cancellationTokenSources.size) {
            Logger.info(`Cancelling ${this.cancellationTokenSources.size} requests`);
            this.cancellationTokenSources.forEach(source => source.cancel());
        }
    }

    public isInspector(): boolean {
        return FieldBrowserSupport.isFieldBrowser();
    }

    public setOfflinePolicies = async () => {
        const appBridge = await FieldBrowserSupport.bridge();
        this.policies = await appBridge.getOfflinePolicies();
        FieldBrowserSupport.releaseBridge();        
    }   

    get policies(): string[] {
        let p: string[] = JSON.parse(sessionStorage.getItem("id_policies") || "[]");
        if (FieldBrowserSupport.isFieldBrowser()) 
        {
            p.push(Policies.toSeeAmbergInspectorItems);
        }
        return p;
    }

    set policies(policies: string[]) {
        sessionStorage.setItem("id_policies", JSON.stringify(policies))
    }

    get userName(): string {
        return sessionStorage.getItem("id_username")!
    }

    set userName(username: string) {
        sessionStorage.setItem("id_username", username)
    }

    get accessToken(): string {
        return sessionStorage.getItem("id_token")!
    }

    set accessToken(access_token: string) {
        sessionStorage.setItem("id_token", access_token)
    }

    get helperToken(): string{
        return sessionStorage.getItem("id_helper_token")!
    }

    set helperToken(helper_token: string) {
        sessionStorage.setItem("id_helper_token", helper_token)
    }

    get logToken(): string{
        return sessionStorage.getItem("id_log_token")!
    }

    set logToken(value: string) {
        sessionStorage.setItem("id_log_token", value)
    }

    private requestConfig = (cancelToken: CancelToken): AxiosRequestConfig =>
    {
        return FieldBrowserSupport.isOfflineMode() ?
        {
            cancelToken: cancelToken,
            headers: {
            },
            withCredentials: false,
        }
        :
        {
            cancelToken: cancelToken,
            headers: {
                "Authorization": "Bearer " + this.accessToken,
                "X-XSRF-TOKEN": this.helperToken
            },
            withCredentials: true,
            //xsrfCookieName: "XSRF-REQUEST-TOKEN",
            //xsrfHeaderName: "X-XSRF-TOKEN"
        };
    }
    private requestConfigForFile = (cancelToken: CancelToken): AxiosRequestConfig =>
    {
        return FieldBrowserSupport.isOfflineMode() ?
        {
            cancelToken: cancelToken,
            headers: {
            },
            withCredentials: false,
        }
        :
        {
            cancelToken: cancelToken,
            responseType: 'arraybuffer',
            headers: {
                "Authorization": "Bearer " + this.accessToken,
                "X-XSRF-TOKEN": this.helperToken,
                'Content-Type': 'application/json',
                'Accept': 'application/octet-stream'
            },
            withCredentials: true,
        };
    }

    _responseHasPolicies = (policies: string): boolean => {
        return (policies.charAt(1) !== "]");
    }

    _splitResponsePolicies = (policies: string): string[] => {
        return policies.slice(1,-1).split(",");
    }

    hasNoLicences = ():boolean => {
        return this.policies && this.policies.length === 0;
    }

}

const authService = new AuthService(API_ROOT);
export default authService;
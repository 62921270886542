import * as React from "react";
import AtTextArea, {IAtTextArea} from "../TextArea";
import LabelWrapper, { ILabelWrapperProps } from "./Wrapper";

interface ITextAreaLabeled extends ILabelWrapperProps, IAtTextArea {}

export default function TextAreaLabeled(props: ITextAreaLabeled) {
    const { label, children, labelMarginTop, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
            <AtTextArea label={""} {...rest}>
                {children}
            </AtTextArea>
        </LabelWrapper>
    );
}

import { t } from "i18next";
import { Fragment } from "react";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import Button from "../components/atlaskit/Button";
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import ButtonConfirm from "../components/ButtonConfirm";
import SingleSelectComboBoxLabeled
    from "../components/labeled/SingleSelectComboBoxLabeled";
import TextAreaLabeled from "../components/labeled/TextAreaLabeled";
import LabelWrapper from "../components/labeled/Wrapper";
import NumberBox from "../components/NumberBox";
import PageTitleItem from "../components/PageTitleItem";
import ProjectSettingsMap from "../components/ProjectSettingsMap";
import ProjectSettings from "../dto/components/ProjectSettings";
import ProjectSettingsPageData from "../dto/pages/ProjectSettingsPageData";
import ItemBasics from "../dto/responses/ItemBasics";
import BackupFrequencyHelper from "../helpers/BackupFrequencyHelper";
import IRouteParams from "../helpers/IRouteParams";
import MapProviderHelper from "../helpers/MapProviderHelper";
import Proj4Helper from "../helpers/Proj4Helper";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";
import Spinner from "@atlaskit/spinner";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface IProjectSettingsPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string) => void;
}

export interface IProjectSettingsPageState {
    project?: ItemBasics;
    mapProvider?: string;
    coordinateSystem?: string;
    customProj4?: string;
    customCrsOriginLatitude?: number;
    customCrsOriginLongitude?: number;
    customCrsFalseEasting?: number;
    customCrsFalseNorthing?: number;
    backupFrequency?: string;
    waiting?: boolean;
}

class ProjectSettingsPage extends React.Component<
    IProjectSettingsPageProps,
    IProjectSettingsPageState
> {
    constructor(props: IProjectSettingsPageProps) {
        super(props);
        this.state = {};
        this.setState({
            waiting:false,
        });
    }

    public render() {
        const {
            mapProvider,
            coordinateSystem,
            customProj4,
            customCrsOriginLatitude,
            customCrsOriginLongitude,
            customCrsFalseEasting,
            customCrsFalseNorthing,
            backupFrequency,
            waiting,
        } = this.state;

        const coordinateSystems: ISSelectOption[] = this.map2singleSelectOption(
            Proj4Helper.supportedCoordinateSystems()
        );
        const mapProviders: ISSelectOption[] = this.map2singleSelectOption(
            MapProviderHelper.supportedMapProviders()
        );

        const selectedMapProvider: ISSelectOption = this.findSelectedOption(
            mapProviders,
            mapProvider
        );

        const selectedCoordinateSystem: ISSelectOption = this.findSelectedOption(
            coordinateSystems,
            coordinateSystem
        );

        const backupFrequencies: ISSelectOption[] = this.map2singleSelectOption(BackupFrequencyHelper.backupFrequencies());

        const selectedBackupFrequency: ISSelectOption = this.findSelectedOption(
            backupFrequencies,
            backupFrequency
        );

        return (
            <div className="page">
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>
                <PageTitleItem title={t("pageTitleProjectSettings")}  testID={"project-settings-page-title"}>
                    <Button
                        onClick={this.backup}
                        testId={"backup-project-button"}
                    >
                        {t("buttonBackupProject")}
                    </Button>
                    <ButtonConfirm
                        textButton={t("buttonDeleteProject")}
                        textPopup={t("textDeleteWarning", {what:t("labelProject")})}
                        appearance={"danger"}
                        onClick={this.delete}
                        testId={"delete-project-button"}
                    />
                </PageTitleItem>

                {mapProvider ? (
                    <div className="pageOverviewMap">
                        <ProjectSettingsMap
                            mapProvider={mapProvider}
                            coordinateSystem={this.state.coordinateSystem}
                            customProj4={this.state.customProj4}
                            customCrsOriginLatitude={this.state.customCrsOriginLatitude}
                            customCrsOriginLongitude={
                                this.state.customCrsOriginLongitude
                            }
                        />
                    </div>
                ) : (
                    <Fragment />
                )}

                <SingleSelectComboBoxLabeled
                    label={t("labelMapProvider")}
                    placeholder={t("labelSelect")}
                    options={mapProviders}
                    selectedOption={selectedMapProvider}
                    onChange={this.updateMapProvider}
                />

                <SingleSelectComboBoxLabeled
                    label={t("labelCoordinateSystem")}
                    placeholder={t("labelSelect")}
                    options={coordinateSystems}
                    selectedOption={selectedCoordinateSystem}
                    onChange={this.updateCoordinateSystem}
                />

                {this.state.coordinateSystem === Proj4Helper.CustomProj4String ? (
                    <TextAreaLabeled
                        label={t("labelProj4String")}
                        placeholder={customProj4 || ""}
                        value={customProj4 || ""}
                        // tslint:disable-next-line:jsx-no-lambda
                        onChange={(value: any) => this.setState({ customProj4: value })}
                        disabled={
                            this.state.coordinateSystem !==
                            Proj4Helper.CustomProj4String
                        }
                        minimumRows={4}
                    />
                ) : (
                    <Fragment />
                )}

                {this.state.coordinateSystem === Proj4Helper.CustomLocalCrs ? (
                    <Fragment>
                        <NumberBox
                            label={t("labelOriginLatitude")}
                            placeholder={customCrsOriginLatitude || 0}
                            value={customCrsOriginLatitude || 0}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value =>
                                this.setState({ customCrsOriginLatitude: value })
                            }
                            disabled={
                                this.state.coordinateSystem !==
                                Proj4Helper.CustomLocalCrs
                            }
                        />
                        <NumberBox
                            label={t("labelOriginLongitude")}
                            placeholder={customCrsOriginLongitude || 0}
                            value={customCrsOriginLongitude || 0}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value =>
                                this.setState({ customCrsOriginLongitude: value })
                            }
                            disabled={
                                this.state.coordinateSystem !==
                                Proj4Helper.CustomLocalCrs
                            }
                        />
                        <NumberBox
                            label={t("labelFalseEasting")}
                            placeholder={customCrsFalseEasting || 0}
                            value={customCrsFalseEasting || 0}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value =>
                                this.setState({ customCrsFalseEasting: value })
                            }
                            disabled={
                                this.state.coordinateSystem !==
                                Proj4Helper.CustomLocalCrs
                            }
                        />
                        <NumberBox
                            label={t("labelFalseNorthing")}
                            placeholder={customCrsFalseNorthing || 0}
                            value={customCrsFalseNorthing || 0}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value =>
                                this.setState({ customCrsFalseNorthing: value })
                            }
                            disabled={
                                this.state.coordinateSystem !==
                                Proj4Helper.CustomLocalCrs
                            }
                        />
                    </Fragment>
                ) : (
                    <Fragment />
                )}

                <SingleSelectComboBoxLabeled
                    label={t("labelBackupInCloud")}
                    placeholder={t("labelSelect")}
                    options={backupFrequencies}
                    selectedOption={selectedBackupFrequency}
                    onChange={this.updateBackupFrequency}
                />

                <div style={{ marginTop: "16px" }}>
                    <LabelWrapper label={""}>
                        <Button
                            onClick={this.save}
                            testId={"save-project-settings-button"}
                        >
                            {t("buttonSave")}
                        </Button>
                    </LabelWrapper>
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.load();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private findSelectedOption = (
        options: ISSelectOption[],
        selection: any
    ): ISSelectOption => {
        const res = options.find((obj: ISSelectOption) => {
            return obj.value === selection;
        });

        if (res) {
            return res;
        }
        return { label: "", value: "" };
    };

    private map2singleSelectOption = (
        map: Map<string, string>
    ): ISSelectOption[] => {
        const options: ISSelectOption[] = [];
        map.forEach((value, key) => {
            options.push({ label: value, value: key });
        });
        return options;
    };

    private updateMapProvider = (selection?: ISSelectOption) => {
        const mapProvider = selection && selection.value;
        if (mapProvider) {
            this.setState({ mapProvider });
        }
    };

    private updateCoordinateSystem = (selection?: ISSelectOption) => {
        const coordinateSystem = selection && selection.value;
        if (coordinateSystem) {
            this.setState({ coordinateSystem });
        }
    };

    private updateBackupFrequency = (selection?: ISSelectOption) => {
        const backupFrequency = selection && selection.value;
        if (backupFrequency) {
            this.setState({ backupFrequency });
        }
    };

    private load = async () => {
        // http://localhost:3000/project/6EB60D55-5A4-4D96-87BB0F7114FD9996/settings
        try {
            const data = await AuthService.get<ProjectSettingsPageData>(
                `projectsettings/${this.props.match.params.projectId}/data`
            );
            this.setState({
                coordinateSystem: data.settings.coordinateSystem,
                customCrsFalseEasting: data.settings.customCrsFalseEasting,
                customCrsFalseNorthing: data.settings.customCrsFalseNorthing,
                customCrsOriginLatitude: data.settings.customCrsOriginLatitude,
                customCrsOriginLongitude: data.settings.customCrsOriginLongitude,
                customProj4: data.settings.customProj4,
                mapProvider: data.settings.mapProvider,
                backupFrequency: data.settings.backupFrequency || BackupFrequencyHelper.Never,
                project: data.project,
            });
            this.props.setLocationNames(data.project.name);
        } catch (error) {
            //
        }
    };

    private save = async () => {
        
        this.setState({
            waiting:true,
        });

        try {

            let customCrsFalseEastingTmp: number | undefined;
            let customCrsFalseNorthingTmp: number | undefined;
            let customCrsOriginLatitudeTmp: number | undefined;
            let customCrsOriginLongitudeTmp: number | undefined;
            let customProj4Tmp: string | undefined;

            customCrsFalseEastingTmp = Number(this.state.customCrsFalseEasting);
            customCrsFalseNorthingTmp = Number(this.state.customCrsFalseNorthing);
            customCrsOriginLatitudeTmp = Number(this.state.customCrsOriginLatitude);
            customCrsOriginLongitudeTmp = Number(this.state.customCrsOriginLongitude);
            customProj4Tmp = this.state.customProj4;

            if(this.state.coordinateSystem === Proj4Helper.CustomLocalCrs)
            {
                customProj4Tmp = Proj4Helper.createCustomProj4String(Number(this.state.customCrsOriginLatitude),Number(this.state.customCrsOriginLongitude),Number(this.state.customCrsFalseEasting),Number(this.state.customCrsFalseNorthing));
            }
            else if(this.state.coordinateSystem === Proj4Helper.CH1903plus)
            {
                customProj4Tmp = Proj4Helper.proj4StringForEPSG(Proj4Helper.CH1903plus);
            }
            else if(this.state.coordinateSystem === Proj4Helper.CH1903)
            {
                customProj4Tmp = Proj4Helper.proj4StringForEPSG(Proj4Helper.CH1903);
            }

            if(this.state.coordinateSystem === Proj4Helper.CustomProj4String || this.state.coordinateSystem === Proj4Helper.CH1903plus ||this.state.coordinateSystem === Proj4Helper.CH1903)
            {
                if(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+x_0") !== undefined)
                {
                    customCrsFalseEastingTmp = Number(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+x_0"));
                }
                if(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+y_0") !== undefined)
                {
                    customCrsFalseNorthingTmp = Number(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+y_0"));
                }
                if(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+lat_0") !== undefined)
                {
                    customCrsOriginLatitudeTmp = Number(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+lat_0"));
                }
                if(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+lon_0") !== undefined)
                {
                    customCrsOriginLongitudeTmp = Number(Proj4Helper.getNumericParameterFromProj4(customProj4Tmp, "+lon_0"));
                }
            }

            const response = await AuthService.put<ProjectSettings>(
                `projectsettings/${this.props.match.params.projectId}`,
                {
                    coordinateSystem: this.state.coordinateSystem,
                    customCrsFalseEasting: Number(customCrsFalseEastingTmp),
                    customCrsFalseNorthing: Number(customCrsFalseNorthingTmp),
                    customCrsOriginLatitude: Number(customCrsOriginLatitudeTmp),
                    customCrsOriginLongitude: Number(customCrsOriginLongitudeTmp),
                    customProj4: String(customProj4Tmp),
                    mapProvider: this.state.mapProvider,
                    backupFrequency: this.state.backupFrequency
                }
            );

            this.setState({
                customCrsFalseEasting: customCrsFalseEastingTmp,
                customCrsFalseNorthing: customCrsFalseNorthingTmp,
                customCrsOriginLatitude: customCrsOriginLatitudeTmp,
                customCrsOriginLongitude: customCrsOriginLongitudeTmp,
                customProj4: customProj4Tmp
            });

            Logger.info(response.toString());

            if(Proj4Helper.validateProj4String(customProj4Tmp)){
                FlagService.addInfo(t("labelSaved"),  t("textSaved", {what:t("labelSettings"), name:"", count:2}));
            }
            else{
                FlagService.addWarning(t("labelSaved"),  t("textSavedWithBadProj4String", {what:t("labelSettings"), name:"", count:2}));
            }
        } catch (error) {
            Logger.error(error, "ProjectSettingsPage");
            FlagService.addError( t("textSaveFailed", {what:t("labelSettings"), name:""}), error);
        }
       
        this.setState({
            waiting:false,
        });
    };

    private delete = async () => {
        const { project } = this.state;
        if (!project) {
            return;
        }
        try {
            const { projectId } = this.props.match.params;
            await AuthService.put(`projectsettings/${projectId}/delete`, {});
            this.props.history.push(Routes.projects.path);
            FlagService.addInfo(t("labelDeleted"), t("textDeleted",{what:t("labelProject"), name:project.name}));
        } catch (error) {
            Logger.error(error, "ProjectSettingsPage");
            FlagService.addError(t("textDeletionFailed",{what:t("labelProject"), name:project.name}), error);
        }
    };
    private backup = async () => {
        const { project } = this.state;
        if (!project) {
            return;
        }
        try {
            const { projectId } = this.props.match.params;
            await AuthService.put(`projectsettings/${projectId}/backup`, {});
            FlagService.addInfo(t("labelBackedUp"), t("textBackedUp",{what:t("labelProject"), name:project.name}));
        } catch (error) {
            Logger.error(error, "ProjectSettingsPage");
            FlagService.addError(t("textBackupFailed",{what:t("labelProject"), name:project.name}), error);
        }
    };
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, ProjectSettingsPage,"ProjectSettingsPage","AppInsight"));

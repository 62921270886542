import Form from '@atlaskit/form';
import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import MultiSelectComboBoxLabeled
    from "../components/labeled/MultiSelectComboBoxLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import CreateGroupRequest from '../dto/requests/CreateGroupRequest';
import ResourceSummary from '../dto/responses/ResourceSummary';
import UserSummary from '../dto/responses/UserSummary';
import {isNoneEmptyValidString, hasInvalidChars} from '../helpers/StringHelpers';
import AuthService from '../services/AuthService';
import FlagService from '../services/FlagService';
import Logger from '../services/Logger';

export interface ICreateGroupModalProps extends WithNamespaces {
    createUrl: string;
    allUsers: UserSummary[];
    allResources: ResourceSummary[];
    allRoles: string[];
    onCancel: () => void;
    onOk: () => void;
}

export interface ICreateGroupModalState {
    id: string;
    name: string;
    users: UserSummary[];
    roles: string[];
    resources: ResourceSummary[];
}

class CreateGroupModal extends React.Component<ICreateGroupModalProps, ICreateGroupModalState> {
    constructor(props: ICreateGroupModalProps) {
        super(props);
        this.state = {
            id: "",
            name: "",
            resources: [],
            roles: [],
            users: [],
        }
    }

    public render() {

        const { name, users, roles, resources } = this.state;
        const { allUsers, allRoles, allResources } = this.props;

        return (
            <Modal
                heading={t("modalTitleCreateGroup")}
                actions={[{
                    onClick: this.onSave,
                    text: t("buttonCreate"),
                }, {
                    onClick: this.props.onCancel,
                    text: t("buttonCancel"),
                }]}
                onClose={this.props.onCancel}
            >
                <Form onSubmit={this.onSave}>
                    {({formProps}) => (
                        <form {...formProps} className="modalForm">
                            <TextBoxLabeled
                                label={t("labelGroupName")}
                                placeholder={t("labelGroupName")}
                                value={name}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ id: value, name: value })}
                                // tslint:disable-next-line:jsx-no-lambda
                                validate={isNoneEmptyValidString}
                                invalid={t("textInvalidEmptyField")}
                                testId = {"group-name-textbox"}
                                required={true}
                            />
                            <MultiSelectComboBoxLabeled
                                label={t("labelUsers")}
                                placeholder={t("labelSelect")}
                                options={allUsers.map(user => {
                                    return {
                                        label: user.name,
                                        value: user.id,
                                    };
                                })}
                                selectedOptions={users.map(user => {
                                    return {
                                        label: user.name,
                                        value: user.id,
                                    };
                                })}
                                onChange={this.onUserSelectionChange}
                            />
                            <MultiSelectComboBoxLabeled
                                label={t("labelLicence")}
                                placeholder={t("labelSelect")}
                                options={allRoles.map(role => {
                                    return {
                                        label: role,
                                        value: role,
                                    };
                                })}
                                selectedOptions={roles.map(role => {
                                    return {
                                        label: role,
                                        value: role,
                                    };
                                })}
                                onChange={this.onRoleSelectionChange}
                            />
                            <MultiSelectComboBoxLabeled
                                label={t("labelProjects")}
                                placeholder={t("labelSelect")}
                                options={allResources.map(resource => {
                                    return {
                                        description: resource.type,
                                        label: resource.name,
                                        value: resource.id,
                                    };
                                })}
                                selectedOptions={resources.map(resource => {
                                    return {
                                        label: resource.name,
                                        value: resource.id,
                                    };
                                })}
                                onChange={this.onResourceSelectionChange}
                            />
                        </form>
                    )}
                </Form>
            </Modal>
        );
    }

    private onUserSelectionChange = (options: ISSelectOption[]) => {
        const selectedUserIds = options.map(o => o.value);
        const users = this.props.allUsers.filter(g => selectedUserIds.some(value => value === g.id));
        this.setState({ users });
    }

    private onRoleSelectionChange = (options: ISSelectOption[]) => {
        const selectedRoleIds = options.map(o => o.value);
        const roles = this.props.allRoles.filter(role => selectedRoleIds.some(id => id === role));
        this.setState({ roles });
    }

    private onResourceSelectionChange = (options: ISSelectOption[]) => {
        const selectedResourceIds = options.map(o => o.value);
        const resources = this.props.allResources.filter(resource => selectedResourceIds.some(id => id === resource.id));
        this.setState({ resources });
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }
        const { name, users, roles, resources } = this.state;
        const request: CreateGroupRequest = {            
            name,
            resources: resources.map(resource => resource.id),
            roles,
            users: users.map(user => user.id),
        };
        try {
            const { createUrl } = this.props;
            await AuthService.post(createUrl, request);
            FlagService.addInfo(t("labelCreated"), t("textCreated", {what:t("labelGroup"), name}));
            this.props.onOk();
        } catch (error) {
            Logger.error(error, "CreateGroupModal");
            FlagService.addError(t("textCreationFailed", {what:t("labelGroup"), name}), error);
        }
    }

    private validate = (): boolean => {
        const { id, name, users, roles, resources } = this.state;
        if (!id) {
            return false;
        }
        if(!isNoneEmptyValidString(name) || hasInvalidChars(name)){
            return false;
        }
        if (!users) {
            return false;
        }
        if (!roles) {
            return false;
        }
        if (!resources) {
            return false;
        }
        return true;
    }
}

export default withNamespaces()(CreateGroupModal)

import ToggleAK from "@atlaskit/toggle";
import * as React from "react";

export interface IToggleProps {
    isDefaultChecked?: boolean;
    isDisabled?: boolean;
    isChecked?:boolean;
    label?: string;
    name?: string;
    value?: string;
    size?: any;
    children?: any;
    onChange?: () => void;
}

export default function Toggle(props: IToggleProps) {
    return (
        <ToggleAK
            isChecked ={props.isChecked}
            isDefaultChecked={props.isDefaultChecked}
            isDisabled={props.isDisabled}
            label={props.label}
            name={props.name}
            value={props.value}
            size={props.size}
            onChange={props.onChange}
        />
    );
}
import InspectionAnalysisSummary from '../components/InspectionAnalysisSummary';
import StructureMeasurementBasics from '../components/StructureMeasurementBasics';

export default class InspectionAnalysisPageData {
    public inspectionAnalysis: InspectionAnalysisSummary;
    public measurements: StructureMeasurementBasics[];
    public reportStngScale: number;

    constructor (data: InspectionAnalysisPageData) {
        this.inspectionAnalysis= data.inspectionAnalysis;
        this.measurements= data.measurements;
        this.reportStngScale = data.reportStngScale;
    }    
}
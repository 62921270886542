import { Feature } from "geojson";
import * as L from "leaflet";
import "../../libs/leaflet.pattern";
import "../../libs/leaflet.textpath";
import "../../libs/svg.icon";
import ILayerStyle from "./ILayerStyle";

export default class FeatureLockedStyle implements ILayerStyle {
    private pane?: string;

    constructor(pane?: string) {
        this.pane = pane;
    }

    public getOptions = (): L.GeoJSONOptions => {
        return {
            pane: this.pane,
            pointToLayer: (feature: Feature, latlng: L.LatLng) => {
                const layer = L.circleMarker(latlng, {
                    color: "gray",
                    interactive: false,
                    radius: 3,
                    pane: this.pane,
                });
                (layer as any).feature = feature;
                return layer;
            },
            style: () => {
                return {
                    color: "gray",
                    fillColor: "gray",
                    fillPattern: undefined,
                    fillOpacity: 0.4,
                    interactive: false,
                };
            },
        }
    };
}

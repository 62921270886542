import * as i18n from 'i18next';
import XhrBackend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

i18n.use(XhrBackend)
    //   .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format(value, format, lng) {
                if (format === 'uppercase') {return value? value.toUpperCase() : value}
                if (format === 'lowercase') {return value? value.toLowerCase() : value}
                return value;
            }
        },
        lng: localStorage.getItem("id_language") || "en",
        react: {
            wait: true
        },
        returnEmptyString: false
    });

export default i18n;
export enum LineStyleType {
    LineSolid = "LineSolid",
    LineDash = "LineDash",
    LineDot = "LineDot",
    LineDouble = "DoubleLine",
    LineTriple = "TripleLine",

    LineWithDots = "LineWithDots",
    LineWithSquares = "LineWithSquares",
    LineWithXCentered = "LineWithXCentered",
    LineWithXUpper = "LineWithXUpper",
    LineWithICentered = "LineWithICentered",
    LineWithIUpper = "LineWithIUpper",

    LineWithSlashUpper = "LineWithSlashUpper",
    LineWithSlashDenseUpper = "LineWithIntensiveSlashUpper",
    LineWithSlashDenseDashedUpper = "LineWithIntensiveSlashUpperDashed",
    LineWithSlashSparseUpper = "LineWithSparseSlashUpper",
    LineWithOverlappingRectanglesVar1Pattern = "LineWithOverlappingRectanglesVar1Pattern",
    LineWithOverlappingRectanglesVar2Pattern = "LineWithOverlappingRectanglesVar2Pattern"
}

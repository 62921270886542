import * as L from "leaflet";
import "../libs/svg.icon";

(function () {
    const __onAddPath = L.Path.prototype.onAdd;
    const PathID = {
        onAdd: function (map) {
            __onAddPath.call(this, map);
            this._updateId();
        },
        _updateId: function () {
            const id = this._id;
            if (id) { this.setId(null).setId(id); }
        },
        setId: function (id) {
            this._id = id;

            if (!id || !this._path) { return this }

            this._path.setAttribute('data-testid', id);
            return this;
        }
    };

    const __onAddMarker = L.Marker.SVGMarker.prototype.onAdd;
    const MarkerID = {
        onAdd: function (map) {
            __onAddMarker.call(this, map);
            this._updateId();
        },

        _updateId: function () {
            const id = this._id;
            if (id) { this.setId(null).setId(id); }
        },

        setId: function (id) {
            this._id = id;

            if (!id || !this._icon) { return this; }

            this._icon.children[0].children[0].setAttribute('data-testid', id);
            return this;
        }
    };

    L.Marker.SVGMarker.include(MarkerID);
    L.Path.include(PathID);
})();
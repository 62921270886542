import CatalogData from '../components/CatalogData';
import InspectionAnalysisSummary from '../components/InspectionAnalysisSummary';
import InspectionChartData from '../components/InspectionChartData';
import StructureMeasurementBasics from '../components/StructureMeasurementBasics';

export default class InspectionAnalysisChartsPageData {
    public inspectionAnalysis: InspectionAnalysisSummary;
    public measurements: StructureMeasurementBasics[];
    public catalog: CatalogData;
    public inspectionChartDatas: InspectionChartData[];

    constructor (data: InspectionAnalysisChartsPageData) {
        this.inspectionAnalysis= data.inspectionAnalysis;
        this.measurements= data.measurements;
        this.catalog = new CatalogData(data.catalog);
        this.inspectionChartDatas= data.inspectionChartDatas;
    }    
}


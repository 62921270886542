import { t } from "i18next";
import * as React from "react";
import InspectionDrawing from "../../dto/components/InspectionDrawing";
import InspectionTileLayer from "../../dto/components/InspectionTileLayer";
import CatalogBase, { ICatalogBaseProps, ICatalogBaseState } from "./CatalogBase";
import { ICatalogItemProps } from "./CatalogItem";
import CatalogLayerGroup from "./CatalogLayerGroup";
import CatalogLayerGroupSingleChoice from "./CatalogLayerGroupSingleChoice";

export interface ICatalogProps extends ICatalogBaseProps{
    inspectionDrawings: InspectionDrawing[];
    hiddenDrawingIds: Set<string>;
    onInspectionDrawingVisibilityChange: (id: string, visible: boolean) => void;

    tileLayers: InspectionTileLayer[];
    selectedTileLayerId: string;
    onTileLayerSelectionChange: (id: string) => void;

    blockDefinitionSelected: boolean;
    onBlockDefinitionSelectionChange: () => void;
    classificationDataSelected: boolean;
    onClassificationDataSelectionChange: () => void;
    sectorDefinitionSelected: boolean;
    onSectorDefinitionSelectionChange: () => void;
    theoreticalSectionsSelected: boolean;
    onTheoreticalSectionsSelectionChange: () => void;
}

export default class CatalogLayer extends CatalogBase<ICatalogProps, ICatalogBaseState> {

    public getInitialState(): ICatalogBaseState {
        const initialState = super.getInitialState();
        initialState.name = this.props.name || t("buttonLayers");
        return initialState;
    }

    public renderChildren = () => {
        return (
            <React.Fragment>
                <CatalogLayerGroupSingleChoice
                    name={t("labelImages")}
                    items={this.getImageItems()}
                />
                <CatalogLayerGroup
                    name={t("labelInspectionDrawings")}
                    items={this.getItems()}
                />
                <CatalogLayerGroup
                    name={t("labelDesign")}
                    items={[
                        {
                            name: t("labelBlockDefinition"),
                            isHidden: !this.props.blockDefinitionSelected,
                            onVisibilityToggle: this.props.onBlockDefinitionSelectionChange,
                            testId: "toggle-block-definition-button",
                        },
                        {
                            name: t("labelSectorDefinition"),
                            isHidden: !this.props.sectorDefinitionSelected,
                            onVisibilityToggle: this.props.onSectorDefinitionSelectionChange,
                            testId: "toggle-sector-definition-button",
                        },
                        {
                            name: t("labelTheoreticalSections"),
                            isHidden: !this.props.theoreticalSectionsSelected,
                            onVisibilityToggle: this.props.onTheoreticalSectionsSelectionChange,
                            testId: "toggle-theoretical-section-button",
                        },
                    ]}
                />
                <CatalogLayerGroup
                    name={t("labelClassification")}
                    items={[{
                        name: t("labelClassification"),
                        isHidden: !this.props.classificationDataSelected,
                        onVisibilityToggle: this.props.onClassificationDataSelectionChange,
                        testId: "toggle-classification-button",
                    }]}
                />
            </React.Fragment>
        )
    }

    private getItems = (): ICatalogItemProps[] => {
        return this.props.inspectionDrawings.map(drawing => {
            const isHidden =  this.props.hiddenDrawingIds.has(drawing.id);
            return {
                name: drawing.name,
                id: drawing.id,
                isHidden,
                onVisibilityToggle: this.getInspectionDrawingVisibilityToggleMethod(drawing.id, isHidden),
                testId: `item-id?${drawing.id}`,
            }}
        )
    };

    private getImageItems = () => {
        return this.props.tileLayers.map(tileLayer => {
            const isHidden = this.props.selectedTileLayerId !== tileLayer.id;
            return {
                name: tileLayer.name,
                id: tileLayer.id,
                isHidden,
                onVisibilityToggle: this.getTileLayerSelectionMethod(tileLayer.id),
                testId: `item-id?${tileLayer.id}`,
            }}
        )
    };

    private getInspectionDrawingVisibilityToggleMethod = (id: string, isHidden: boolean) => {
        if(isHidden){
            return () => {this.props.onInspectionDrawingVisibilityChange(id, false)};
        }
        else{
            return () => {this.props.onInspectionDrawingVisibilityChange(id, true)};
        }
    };

    private getTileLayerSelectionMethod = (id: string) => {
        return () => {this.props.onTileLayerSelectionChange(id)}
    }
}
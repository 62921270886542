import FieldMessage from './FieldMessage';
import React, { Fragment } from 'react';
import TextField from '@atlaskit/textfield';
import { Field, FieldProps } from '@atlaskit/form';
import {hasInvalidChars} from '../helpers/StringHelpers';

const type = (isPassword?: boolean, isNumber?: boolean): string => {
  if (isPassword){
    return "password"
  }
  else if (isNumber){
    return "number"
  }
  else{
    return "text"
  }
}

const validation = (
  value?: string,
  customValidation?: (value?: string) => boolean
  ): void | string | Promise<string | void> => {
      if (customValidation && !customValidation(value)) {
        return "CUSTOM_ERROR";
      } else if (value && hasInvalidChars(value)) {
        return "INVALID_CHARS";
      } else if (!value) {
        return "EMPTY";
      } else {
        return undefined;
      }
  };

export interface ITextBoxProps {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  isPassword?: boolean;
  isNumber?: boolean;
  step?: number;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  doNotValidate?: boolean;
  validate?: (value: string) => boolean;
  invalid?: string;
  valid?: string;
  testId?: string;
  isSpellCheckEnabled?: boolean;
  autoComplete?:string;
}

const CutOutFieldProps = (props: FieldProps<string>) => {
  const {value, ...rest} = props;
  return rest;
}

const TextBox = (props: ITextBoxProps) => {
  return (
      <Field 
        label={props.label || undefined}
        name={props.testId ? props.testId : "TextBoxField"}
        defaultValue={props.value || ""}
        isRequired={props.required || false}
        isDisabled={props.disabled || false}
        validate={(value: string | undefined) => {
          if(props.doNotValidate){
            return undefined;
          } else {
            return validation(value, props.validate)
          }
        }}
      >
        {
         (fieldData) => (
          <Fragment>
            <TextField
              {...CutOutFieldProps(fieldData.fieldProps)}
              testId={props.testId}
              onChange={(event: any) => {
                //fieldData.fieldProps.onChange(event);
                if (props.onChange) {
                  props.onChange(event.target.value);
                }
              }}
              placeholder={props.placeholder || ""}
              type={type(props.isPassword, props.isNumber)}
              step={props.step? props.step: 1}
              autoComplete={props.autoComplete ? props.autoComplete : "off"}
              spellCheck={props.isSpellCheckEnabled === undefined ? true : props.isSpellCheckEnabled }
              value={props.value}
              />
              {!props.doNotValidate && <FieldMessage 
                isValid={fieldData.meta.valid}
                error={fieldData.error? fieldData.error : fieldData.meta.error}
                doNotValidate={props.doNotValidate}
                validMessage={props.valid}
                errorMessage={props.invalid}
              />}
          </Fragment>
        )}
      </Field>
  );
}

export default TextBox;


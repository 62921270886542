import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import PageTitleItem from "../components/PageTitleItem";

class UnauthorizedPage extends React.Component<{}> {
  public render() {
    return (
      <div className="page">
        <PageTitleItem title={t("pageTitleUnauthorized")}  testID={"unauthorized-page-title"}/>
        <p>{t("textPageUnauthorized")}</p>
      </div>
    );
  }
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, UnauthorizedPage,"UnauthorizedPage","AppInsight"));

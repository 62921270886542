import { t } from "i18next";
import * as React from "react";
import { Feature } from "../../node_modules/@types/geojson";
import {
    CadExportAnalysisIcon,
    ChartAnalysisIcon,
    GraphicalAnalysisIcon,
    InspectionAnalysisIcon,
    InspectionTaskIcon,
    NumericExportAnalysisIcon,
    OverlayAnalysisIcon,
    PlaceholderIcon,
    SideBySideAnalysisIcon,
} from "../components/Icons";
import { GeometryType } from "../dto/components/GeometryType";
import Constants from "./Constants";

export default class InspectionHelper {
    public static colorForClassification = (classification?: number): string => {
        if (!classification) {
            return Constants.colorBackgroundM2
        }
        if (classification <= 1) {
            return "green";
        }
        if (classification <= 2) {
            return "yellow";
        }
        if (classification <= 3) {
            return "gold";
        }
        if (classification <= 4) {
            return "orange";
        }
        if (classification <= 5) {
            return "red";
        }

        return Constants.colorBackgroundM2
    }

    public static supportedInspectionAnalysisTypes = (): string[] => {
        return [
            "singlephenomenonbasedcharts",
            "singleblockbasedcharts",
            "singlemap",
            "sidebysidemaps",
            "overlaymaps",
            "singlenumerical",
            "singlecadexport",
            // "infra3d"
        ]
    }

    public static supportedStructureTaskTypes = (): string[] => {
        return [
            "inspection",
            "fieldinspection",
        ]
    }

    public static nameForAnalysisType = (type: string): string => {
        switch (type) {
            case "singlephenomenonbasedcharts": return t("labelInspectionChartsFeatureBased");
            case "singleblockbasedcharts": return t("labelInspectionChartsBlockBased");
            case "singlemap": return t("labelInspectionMap");
            case "sidebysidemaps": return t("labelInspectionMapSBS");
            case "overlaymaps": return t("labelInspectionMapOverlay");
            case "singlenumerical": return t("labelInspectionStatisticsNumerical");
            case "singlecadexport": return t("labelInspectionDxfExport");
            case "infra3d": return t("labelInfra3d");
            default: return type;
        }
    }

    public static nameForStructureTask = (type: string) => {
        switch (type) {
            case "inspection": return t("labelInspectionTask");
            case "fieldinspection": return t("labelFieldInspectionTask");
            default: return type;
        }
    }

    public static iconForAnalysisType = (type: string): JSX.Element => {
        switch (type) {
            case "singlephenomenonbasedcharts": return (<ChartAnalysisIcon size="small" />);
            case "singleblockbasedcharts": return (<ChartAnalysisIcon size="small" />);
            case "singlemap": return (<GraphicalAnalysisIcon size="small" />);
            case "sidebysidemaps": return (<SideBySideAnalysisIcon size="small" />);
            case "overlaymaps": return (<OverlayAnalysisIcon size="small" />);
            case "singlenumerical": return (<NumericExportAnalysisIcon size="small" />);
            case "singlecadexport": return (<CadExportAnalysisIcon size="small" />);
            case "infra3d": return (<SideBySideAnalysisIcon size="small" />);
            default: return (<InspectionAnalysisIcon size="small" />);
        }
    }

    public static iconForStructureTaskType = (type: string): JSX.Element => {
        switch (type) {
            case "inspection": return (<InspectionTaskIcon size="small" />);
            case "fieldinspection": return (< PlaceholderIcon size="small" />);
            default: return (<PlaceholderIcon size="small" />);
        }
    }

    public static downloadOfflineActionEnabled = (type: string): boolean => {
        switch (type) {
            case "fieldinspection": return true;
            default: return false;
        }
    }

    public static geometryTypeForFeature = (feature: Feature): GeometryType | undefined => {
        if (feature.geometry.type === "Point") {
            return GeometryType.Point;
        }
        if (feature.geometry.type === "LineString") {
            return GeometryType.Line;
        }
        if (feature.geometry.type === "Polygon") {
            return GeometryType.Area;
        }
        return undefined;
    }
}
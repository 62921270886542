import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import PageTitleItem from "../components/PageTitleItem";

class NotFoundPage extends React.Component<{}> {
  public render() {
    return (
      <div className="page">
        <PageTitleItem title={t("pageTitleNotFound")}  testID={"not-found-page-title"} />
        <p>{t("textPageNotFound")}</p>
      </div>
    );
  }
}

export default compose(withNamespaces())(NotFoundPage);

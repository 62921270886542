import Form from '@atlaskit/form';
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";
import { t } from "i18next";
import React, { ReactNode, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Redirect, RouteComponentProps } from "react-router";
import { compose } from "recompose";
import Button from "../components/atlaskit/Button";
import { TunnelCloudLogoLight } from "../components/Icons";
import PageTitleItem from "../components/PageTitleItem";
import TextBox from "../components/TextBox";
import Constants from "../helpers/Constants";
import CookieConsentModal from "../modals/CookieConsentModal";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import FieldBrowserSupport from '../helpers/FieldBrowserSupport'
import Logger from 'services/Logger';

export interface ILoginPageProps extends RouteComponentProps<any> {
    location: any;
    children?: ReactNode;
}

const LoginPage = (props: ILoginPageProps) => {
    const packageJson = require("../../package.json");
    const aboutString = `Amberg ${t("labelTunnelCloud")} | ${t("labelVersion")}: ${packageJson.version}.${packageJson.clientbuildnumber} | © Amberg Technologies AG`;

    const [loggedIn, setLoggedIn] = useState<boolean>(AuthService.isAuthenticatedUser());
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [disableInput, setDisableInput] = useState<boolean>(false);

    useEffect(() => {
        if (!navigator.onLine) {
            FlagService.addInfo(t("labelOffline"), t("textOffline"));
        }

        // returned function will be called on component unmount 
        return () => {
            AuthService.cancelAllRequests();
        }
    }, []);

    const signIn = async (event: any): Promise<void> => {
        event.preventDefault();
        setDisableInput(true);
        if (!userName || !password) {
            FlagService.clearFlags();
            FlagService.addError(t("labelLoginFailed"), t("textLoginFailed"), "login")
        }
        else {
            if (await AuthService.signIn(userName, password)) {
                FlagService.clearFlags();
                setLoggedIn(true);
                const { history } = props;
                const instrumentationKey = AuthService.logToken
                if(instrumentationKey && instrumentationKey !== ""){
                    try {
                        ais.initialize(instrumentationKey, history)
                    } catch (error) {
                        if (process.env.NODE_ENV !== 'production') {
                            Logger.error(error);
                        }
                    }
                }
            } else {
                FlagService.clearFlags();
                FlagService.addError(t("labelLoginFailed"), t("textLoginFailed"), "login")
            }
        }
        setDisableInput(false);
    };

    if(loggedIn && !FieldBrowserSupport.isOfflineMode()){
        const { from } = props.location.state || { from: { pathname: '/' } }
        from.pathname = from.pathname === "/login" ? "/" : from.pathname;
        return (
            <Redirect to={from} />
        )
    }
    return (
        <div id="login-page">
            <CookieConsentModal/>
            <div className="header">
                <TunnelCloudLogoLight />
            </div>
            <div className="header">
                <PageTitleItem title={t("labelTunnelCloud")} color={Constants.colorForegroundM3white}  testID={"login-page-title"}/>
            </div>
            
            <div className="panel">
                <Form onSubmit={signIn}>
                    {({formProps}) => (
                        <form {...formProps} onSubmit={signIn}>
                            <TextBox
                                id="userName"
                                placeholder={t("labelUserName")}
                                value={userName}
                                onChange={setUserName}
                                isSpellCheckEnabled={false}
                                doNotValidate={true}
                                test-id={"username-textbox"}
                            />
                            <TextBox
                                id="password"
                                placeholder={t("labelPassword")}
                                isPassword={true}
                                value={password}
                                onChange={setPassword}
                                isSpellCheckEnabled={false}
                                autoComplete={"current-password"}
                                doNotValidate={true}
                                test-id={"password-textbox"}
                            />
                            <br />
                            <Button
                                className="login-button"
                                id="login-button"
                                type="submit"
                                appearance="primary"
                                testId={"login-button"}
                                isDisabled={disableInput}
                            >
                                {t("buttonSignIn")}
                            </Button>
                        </form>
                    )}
                </Form>
            </div>
            
            <div className="footer">
                <br />
                {aboutString}
            </div>
        </div>
    );
}

export default compose(withNamespaces())(LoginPage);

import { t } from 'i18next';

export default {
    onUpdate: (registration: ServiceWorkerRegistration) => {
        if(window.confirm(t('textNewVersionAvailable'))){
            registration.unregister().then(() => {
                window.location.reload()
            })
        }
    },
    onSuccess: (registration: ServiceWorkerRegistration) => {
        console.info('service worker on success state')
        console.log(registration)
    },
}
import * as React from 'react';
import styled from 'styled-components';

export interface IMainTitleItemProps {
    title: string;
    children?: any;
    color?: string;
    testID?: string;
}

export default function PageTitleItem(props: IMainTitleItemProps) {
    const Item = styled.div`
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.6em 0;
    `;

    return (
        <Item>
            <h1 style={{
                flex: 1,
                color: props.color
            }} data-testid={props.testID}>
                {props.title}
            </h1>
            {props.children}
        </Item>
    );
}

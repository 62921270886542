import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import AvatarLabeled from "../components/labeled/AvatarLabeled";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import TagsLabeled from "../components/labeled/TagsLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import PageTitleItem from "../components/PageTitleItem";
import CriticalErrorModal from "../modals/CriticalErrorModal"
import UpdateUserPasswordModal from "../modals/UpdateUserPasswordModal";
import AuthService from "../services/AuthService";
import LicenseHelper from "../helpers/LicenseHelper";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface IUserProfilePageProps {
    dummy?: string;
}


export interface IUserProfilePageState {
    userId: string;
    loginName: string;
    userName: string;
    resources: any;
    roles: any;
    showUpdateUserPasswordModal: boolean;
    showNoLicenceModal: boolean;
}

class UserProfilePage extends React.Component<
    IUserProfilePageProps,
    IUserProfilePageState
> {
    constructor(props: IUserProfilePageProps) {
        super(props);

        this.state = {
            resources: [],
            roles: [],
            userId: "",
            loginName: "",
            userName: "",
            showUpdateUserPasswordModal: false,
            showNoLicenceModal: false,
        };
    }

    public render() {
        const {
            loginName,
            userName,
            resources,
            roles,
            showUpdateUserPasswordModal,
            showNoLicenceModal,
        } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleUserProfile")}  testID={"user-profile-page-title"}/>

                <AvatarLabeled
                    label={t("labelAvatar")}
                    name={""}
                    size="xxlarge"
                    src={' '}
                />
                <TextBoxLabeled
                    label={t("labelUserName")}
                    disabled={true}
                    placeholder={loginName}
                    value={loginName}
                />
                <ButtonLabeled
                    label={t("labelPassword")}
                    onClick={this.showUpdateUserPasswordModal}
                    testId={"change-user-password-button"}
                >
                    {t("buttonChange")}
                </ButtonLabeled>
                {showUpdateUserPasswordModal && this.renderUpdateUserPasswordModal()}
                <TextBoxLabeled
                    label={t("labelFullName")}
                    placeholder={userName}
                    value={userName}
                    // tslint:disable-next-line:jsx-no-lambda
                    onChange={value => this.setState({ userName: value })}
                    testId = {"full-name-textbox"}
                />
                <TagsLabeled label={t("labelLicences")} values={roles} />
                <TagsLabeled label={t("labelProjects")} values={resources} />
                {showNoLicenceModal && 
                <CriticalErrorModal                     
                    heading={t("modalTitleUserNoLicence")} 
                    message ={t("textUserNoLicence")} 
                    buttonLabel = "close"
                    onClick={this._closeNoLicenceModal} >
                </CriticalErrorModal> }
            </div>
        );
    }

    public async componentDidMount() {
        const route = `userProfile`;
        const data = await AuthService.get<any>(route);

        this.setState({
            userId: data.user.id,
            loginName: data.user.loginName,
            userName: data.user.name,
            resources: Array.from(new Set([...data.user.accountResources.map((r: any) => r.name), ...data.user.groupResources.map((r: any) => r.name)])),
            roles: Array.from(new Set([...data.user.roles.map((l:string) => LicenseHelper.labelForLicense(l)), ...data.user.groupRoles.map((l:string) => LicenseHelper.labelForLicense(l))])),
            showNoLicenceModal: AuthService.hasNoLicences(),
        });
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private showUpdateUserPasswordModal = () => {
        this.setState({
            showUpdateUserPasswordModal: true,
        });
    };

    private hideModals = () => {
        this.setState({
            showUpdateUserPasswordModal: false,
        });
    };

    private renderUpdateUserPasswordModal = () => {
        const updateUrl = AuthService.isCloudAdmin()?`user/password`:`userProfile/password`;
        return (
            <UpdateUserPasswordModal
                updateUrl={updateUrl} 
                id={this.state.userId}
                loginName={this.state.loginName}
                name={this.state.userName}
                onOk={this.hideModals}
                onCancel={this.hideModals}
            />
        );
    };

    _closeNoLicenceModal = () => {
        this.setState({
            showNoLicenceModal: false,
        });
    }
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, UserProfilePage,"UserProfilePage","AppInsight"));

import * as React from "react";
import SingleSelectComboBox, {
    ISingleSelectComboBoxProps,
} from "../SingleSelectComboBox";
import LabelWrapper from "./Wrapper";
import { ILabelWrapperProps } from "./Wrapper";

interface ISingleSelectComboBoxLabeledProps
    extends ILabelWrapperProps,
        ISingleSelectComboBoxProps {}

export default function SingleSelectComboBoxLabeled(
    props: ISingleSelectComboBoxLabeledProps
) {
    const { label, children, labelMarginTop, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
            <SingleSelectComboBox label={""} {...rest}>
                {children}
            </SingleSelectComboBox>
        </LabelWrapper>
    );
}

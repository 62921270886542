import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import ButtonConfirm from "../components/ButtonConfirm";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import PageTitleItem from "../components/PageTitleItem";
import GroupPageData from "../dto/pages/GroupPageData";
import UpdateGroupRequest from "../dto/requests/UpdateGroupRequest";
import ResourceSummary from "../dto/responses/ResourceSummary";
import UserSummary from "../dto/responses/UserSummary";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import MultiSelectComboBoxLabeled from "../components/labeled/MultiSelectComboBoxLabeled";

export interface IAdministrationGroupPageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationGroupPageState {
    data?: GroupPageData;
    id: string;
    name: string;
    users: UserSummary[];
    roles: string[];
    resources: ResourceSummary[];
    allUsers: UserSummary[];
    allResources: ResourceSummary[];
    allRoles: string[];
}

class AdministrationGroupPage extends React.Component<IAdministrationGroupPageProps, IAdministrationGroupPageState> {
    constructor(props: IAdministrationGroupPageProps) {
        super(props);
        this.state = {
            allResources: [],
            allRoles: [],
            allUsers: [],
            id: "",
            name: "",
            resources: [],
            roles: [],
            users: [],
        };
    }

    public render() {
        const { data, name, users, roles, resources, allUsers, allRoles, allResources } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleGroup")} testID={"admin-group-page-title"}>
                    <ButtonConfirm
                        textButton={t("buttonDelete")}
                        textPopup={t("textDeleteWarning", {what: t("labelGroup")})}
                        appearance={"danger"}
                        onClick={this.onDelete}
                        testId={"delete-group-button"}
                    />
                </PageTitleItem>
                {data && <form className="modalForm" onSubmit={this.onSave}>
                    <TextBoxLabeled
                        label={t("labelGroupName")}
                        disabled={true}
                        value={name}
                    />
                    <MultiSelectComboBoxLabeled
                        label={t("labelUsers")}
                        placeholder={t("labelSelect")}
                        options={allUsers.map(user => {
                            return {
                                label: user.name,
                                value: user.id,
                            };
                        })}
                        selectedOptions={users.map(user => {
                            return {
                                label: user.name,
                                value: user.id,
                            };
                        })}
                        onChange={this.onUserSelectionChange}
                    />
                    <MultiSelectComboBoxLabeled
                        label={t("labelLicences")}
                        placeholder={t("labelSelect")}
                        options={allRoles.map(role => {
                            return {
                                label: role,
                                value: role,
                            };
                        })}
                        selectedOptions={roles.map(role => {
                            return {
                                label: role,
                                value: role,
                            };
                        })}
                        onChange={this.onRoleSelectionChange}
                    />
                    <MultiSelectComboBoxLabeled
                        label={t("labelProjects")}
                        placeholder={t("labelSelect")}
                        options={allResources.map(resource => {
                            return {
                                description: resource.type,
                                label: resource.name,
                                value: resource.id,
                            };
                        })}
                        selectedOptions={resources.map(resource => {
                            return {
                                label: resource.name,
                                value: resource.id,
                            };
                        })}
                        onChange={this.onResourceSelectionChange}
                    />
                    <ButtonLabeled
                        type="submit"
                        testId={"save-group-button"}
                    >
                        {t("buttonSave")}
                    </ButtonLabeled>
                </form>}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const { accountId, groupId } = this.props.match.params;
            const dataUrl = AuthService.isAmbergAdmin() ? `administrationgroup/${accountId}/${groupId}` : `group/${groupId}`;
            const data = await AuthService.get<GroupPageData>(dataUrl);

            const groupUserIds = data.group.users.map(g => g.id);
            const users = data.users.filter(u => groupUserIds.some(value => value === u.id));

            const groupResourceIds = data.group.resources.map(r => r.id);
            const resources = data.resources.filter(g => groupResourceIds.some(value => value === g.id));


            this.setState({
                allResources: data.resources,
                allRoles: data.roles,
                allUsers: data.users,
                data,
                id: data.group.id,
                name: data.group.name,
                resources,
                roles: data.group.roles,
                users,
            });
            this.props.setLocationName(accountId && data.account.name);
        } catch (error) {
            //
        }
    }

    private onUserSelectionChange = (options: ISSelectOption[]) => {
        const { allUsers } = this.state;
        const selectedUserIds = options.map(o => o.value);
        const users = allUsers.filter(g => selectedUserIds.some(value => value === g.id));
        this.setState({ users });
    }

    private onRoleSelectionChange = (options: ISSelectOption[]) => {
        const { allRoles } = this.state;
        const selectedRoleIds = options.map(o => o.value);
        const roles = allRoles.filter(role => selectedRoleIds.some(id => id === role));
        this.setState({ roles });
    }

    private onResourceSelectionChange = (options: ISSelectOption[]) => {
        const { allResources } = this.state;
        const selectedResourceIds = options.map(o => o.value);
        const resources = allResources.filter(resource => selectedResourceIds.some(id => id === resource.id));
        this.setState({ resources });
    }

    private onDelete = async () => {
        const { data, id, name } = this.state;
        if (!data) {
            return;
        }
        try {
            const { accountId } = this.props.match.params;
            const deleteUrl = AuthService.isAmbergAdmin() ? `administrationgroup/${accountId}/${id}` : `group/${id}`;
            await AuthService.delete(deleteUrl);

            this.props.history.push(AuthService.isAmbergAdmin() ?
              Routes.administrationAccountUsers.patch(accountId).path
              :
              Routes.users.path
            );

            FlagService.addInfo(t("labelDeleted"), t("textDeleted", {what:t("labelGroup"), name}));
        } catch (error) {
            Logger.error(error, "AdministrationGroupPage");
            FlagService.addError(t("textDeletionFailed", {what:t("labelGroup"), name}), error);
        }
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }
        const { id, name, users, roles, resources } = this.state;
        const request: UpdateGroupRequest = { 
            id,
            name,
            resources: resources.map(resource => resource.id),
            roles,
            users: users.map(user => user.id),
        };
        try {
            const { accountId } = this.props.match.params;
            const updateUrl = AuthService.isAmbergAdmin() ? `administrationgroup/${accountId}` : `group`;

            await AuthService.put(updateUrl, request);
            this.props.history.push(AuthService.isAmbergAdmin() ?
              Routes.administrationAccountUsers.patch(accountId).path
              :
              Routes.users.path
            );
            FlagService.addInfo(t("labelSaved"), t("textSaved", {what:t("labelGroup"), name}));
        } catch (error) {
            Logger.error(error, "AdministrationGroupPage");
            FlagService.addError( t("textSaveFailed", {what:t("labelGroup"), name}), error);
        }
    }

    private validate = (): boolean => {
        const { id, name, users, roles, resources } = this.state;
        if (!id) {
            return false;
        }
        if (!name) {
            return false;
        }
        if (!users) {
            return false;
        }
        if (!roles) {
            return false;
        }
        if (!resources) {
            return false;
        }
        return true;
    }
}

export default compose(withNamespaces())(AdministrationGroupPage)

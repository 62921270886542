import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import InspectionMapBase, {
    IInspectionMapBaseProps,
} from "../components/InspectionMapBase";
import FeatureType from "../dto/components/FeatureType";
import InspectionDrawing from "../dto/components/InspectionDrawing";
import IRouteParams from "../helpers/IRouteParams";
import AuthService from "../services/AuthService";


export interface IMapPageBaseProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string, structureItemName: string) => void;
}


export interface IMapPageBaseState {
    blockDefinitionSelected: boolean;
    classificationSelected: boolean;
    hiddenFeatureTypeCodes: Set<string>;
    inspectionDrawingId: string;
    sectorDefinitionSelected: boolean;
    textHiddenFeatureTypeCodes: Set<string>;
    theoreticalSectionsSelected: boolean;
    tileLayerId: string;
    waiting: boolean;
}


export default class MapPageBase<P extends IMapPageBaseProps, S extends IMapPageBaseState> extends React.Component<P, S> {
    public map: InspectionMapBase<IInspectionMapBaseProps, {}> | null;

    constructor(props: P) {
        super(props);
        this.state = this.getInitialState();
    }

    public getInitialState(): S {
        return {
            blockDefinitionSelected: false,
            classificationSelected: false,
            hiddenFeatureTypeCodes: new Set<string>(),
            inspectionDrawingId: "",
            sectorDefinitionSelected: false,
            textHiddenFeatureTypeCodes: new Set<string>(),
            theoreticalSectionsSelected: false,
            tileLayerId: "",
            waiting: true,
        } as S;
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
        this.unloadData();
    }

    public loadData = async () => {
        // pass
    };

    public unloadData = () => {
        // pass
    };

    public getDrawing = (inspectionDrawings: InspectionDrawing[]): InspectionDrawing => {
        for (const inspectionDrawing of inspectionDrawings) {
            if (inspectionDrawing.id === this.state.inspectionDrawingId) {
                return inspectionDrawing;
            }
        }
        return inspectionDrawings[0];
    };

    public onFeatureTypeVisibilityChange = (featureType: FeatureType, visible: boolean) => {
        this.setState(prevState => {
            const codes: Set<string> = new Set(prevState.hiddenFeatureTypeCodes);

            if (visible) {
                codes.delete(featureType.code);
            } else {
                codes.add(featureType.code);
            }

            return { hiddenFeatureTypeCodes: codes };
        });
    };

    public onFeatureTypeTextVisibilityChange = (featureType: FeatureType, locked: boolean) => {
        this.setState(prevState => {
            const codes: Set<string> = new Set(prevState.textHiddenFeatureTypeCodes);
            if (!locked) {
                codes.add(featureType.code);
            } else {
                codes.delete(featureType.code);
            }
            return { textHiddenFeatureTypeCodes: codes };
        });
    };

    public onInspectionDrawingSelectionChange = (id: string) => {
        this.setState({ inspectionDrawingId: id });
    };

    public onTileLayerSelectionChange = (tileLayerId: string) => {
        this.setState({ tileLayerId: tileLayerId });
    };

    public onBlockDefinitionSelectionChange = () => {
        this.setState(prevState => {
            return { blockDefinitionSelected: !prevState.blockDefinitionSelected };
        });
    };

    public onClassificationSelectionChange = () => {
        this.setState(prevState => {
            return { classificationSelected: !prevState.classificationSelected };
        });
    };

    public onSectorDefinitionSelectionChange = () => {
        this.setState(prevState => {
            return { sectorDefinitionSelected: !prevState.sectorDefinitionSelected };
        });
    };

    public onTheoreticalSectionsSelectionChange = () => {
        this.setState(prevState => {
            return { theoreticalSectionsSelected: !prevState.theoreticalSectionsSelected };
        });
    };
}

import { Field } from "@atlaskit/form";
import Tag from "@atlaskit/tag";
import TagGroup from "@atlaskit/tag-group";
import * as React from "react";
import Styles from "../helpers/styles";
import { t } from "i18next";

export interface ITagsProps {
    label?: string;
    values?: string[];
    highlighted?: boolean;
    readonly?: boolean;
}

export default function Tags(props: ITagsProps) {
    const color = props.highlighted ? "teal" : "standard";
    return (
        <div style={Styles.box}>
            <Field name="tagGroup">
              {() => (
                <TagGroup>
                    {props.values ? (
                        !props.values.length && (
                            <Tag text={t("labelNotApplicable")} appearance="rounded" color={color} isRemovable={false}/>
                        )
                    ) : (
                        <div />
                    )}
                    {props.values ? (
                        props.values.map(value => (
                            <Tag
                                key={value}
                                text={value}
                                appearance="rounded"
                                color={color}
                                isRemovable={props.readonly ? false : true}
                            />
                        ))
                    ) : (
                        <div />
                    )}
                </TagGroup>
              )}
            </Field>
        </div>
    );
}

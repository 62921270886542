import * as React from "react";
import FeatureType from "../../dto/components/FeatureType";
import { GeometryType } from "../../dto/components/GeometryType";
import {
    AreaFeatureIcon,
    DefaultTooltipIcon,
    ForcedTooltipIcon,
    HelpIcon,
    InvisibleIcon,
    LineFeatureIcon,
    LockedIcon,
    PointFeatureIcon,
    UnlockedIcon,
    VisibleIcon,
} from "../Icons";

export default class FeatureIconProvider {

    public static getTools = ({id, isHidden, isLocked, isTextHidden, onVisibilityChange, onLockedChange, onTextVisibilityChange}:
      {
          id: string,
          isHidden?: boolean,
          isLocked?: boolean,
          isTextHidden?: boolean,
          onVisibilityChange?: (e: any) => void,
          onLockedChange?: (e: any) => void,
          onTextVisibilityChange?: (e: any) => void
      }) => {
        return (
            <div style={{float:"right"}}>
                {onVisibilityChange && isHidden !== undefined &&
                <span id={id+"-hide"} onClick={onVisibilityChange}>
                    {isHidden ?
                        <InvisibleIcon size="small"/>
                        :
                        <VisibleIcon size="small"/>
                    }
                </span>
                }

                {onLockedChange && isLocked !== undefined &&
                <span id={id+"-lock"} onClick={onLockedChange}>
                        {isLocked ?
                            <LockedIcon size="small" />
                            :
                            <UnlockedIcon size="small"/>
                        }
                    </span>
                }

                {onTextVisibilityChange && isTextHidden !== undefined &&
                <span id={id+"-show-abbreviation"} onClick={onTextVisibilityChange}>
                        {isTextHidden ?
                            <ForcedTooltipIcon size="small" />
                            :
                            <DefaultTooltipIcon size="small"/>
                        }
                    </span>
                }
            </div>
        );
    };

    public static getFeatureIcon = (featureType: FeatureType) => {
        switch (featureType.type) {
            case GeometryType.Point:
                return (
                    <PointFeatureIcon
                        size="small"
                        primaryColor={featureType.strokeColorString}
                        secondaryColor={featureType.strokeColorString}
                    />
                );
            case GeometryType.Line:
                return (
                    <LineFeatureIcon
                        size="small"
                        primaryColor={featureType.strokeColorString}
                        secondaryColor={featureType.strokeColorString}
                    />
                );
            case GeometryType.Area:
                return (
                    <AreaFeatureIcon
                        size="small"
                        primaryColor={featureType.strokeColorString}
                        secondaryColor={featureType.strokeColorString}
                    />
                );
        }
        // fallback
        return (<HelpIcon />)
    };
}
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import Button from "../components/atlaskit/Button";
import { CreateIcon } from "../components/Icons";
import PageTitleItem from "../components/PageTitleItem";
import Table, {
    ActionsTableColumn,
    CountBadgeTableColumn,
    LabelBadgesTableColumn,
    LinkTableColumn,
    TableItemAction,
    TextTableColumn,
} from "../components/Table";
import TitleItem from "../components/TitleItem";
import UsersPageData from "../dto/pages/UsersPageData";
import GroupSummary from "../dto/responses/GroupSummary";
import UserSummary from "../dto/responses/UserSummary";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import CreateGroupModal from "../modals/CreateGroupModal";
import CreateUserModal from "../modals/CreateUserModal";
import AuthService from "../services/AuthService";
import LicenseHelper from "../helpers/LicenseHelper";
import Logger from "../services/Logger";
import Modal from "@atlaskit/modal-dialog";
// import { withAnalytics } from "@atlaskit/analytics";

export interface IAdministrationUsersPageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationUsersPageState {
    showCreateUserModal: boolean;
    showCreateGroupModal: boolean;
    data?: UsersPageData;
    deleteReqeuested?: UserSummary | GroupSummary;
}

class AdministrationUsersPage extends React.Component<IAdministrationUsersPageProps, IAdministrationUsersPageState> {
    constructor(props: IAdministrationUsersPageProps) {
        super(props);
        this.state = {
            showCreateGroupModal: false,
            showCreateUserModal: false,
        };
    }

    public render() {
        const { data, showCreateUserModal, showCreateGroupModal, deleteReqeuested } = this.state;
        const { accountId } = this.props.match.params;
        const userLinkUrlBase = AuthService.isAmbergAdmin() ? Routes.administrationAccountUser.patch(accountId) : Routes.user;
        const groupLinkUrlBase = AuthService.isAmbergAdmin() ? Routes.administrationAccountGroup.patch(accountId) : Routes.group;

        return (
            <div className="page">
                {showCreateUserModal && this.renderCreateUserModal()}
                {showCreateGroupModal && this.renderCreateGroupModal()}
                {deleteReqeuested && this.rederDeleteModal()}
                <PageTitleItem title={t("pageTitleUsersAndGroups")}  testID={"admin-users-page-title"}/>
                <TitleItem title={t("sectionTitleUsers")}>
                    <Button
                        iconBefore={<CreateIcon />}
                        onClick={this.showCreateUserModal}
                        testId={"create-user-button"}
                    >
                        {t("buttonCreate")}
                    </Button>
                </TitleItem>
                <div className="tableContainer">
                    <Table
                        data={data && data.users}
                        columns={[
                            new LinkTableColumn<UserSummary>(
                                t("labelName"),
                                item => item.name,
                                item => userLinkUrlBase.patch(item.id).path
                            ),
                            new TextTableColumn<UserSummary>(t("labelUserName"), item => item.loginName),
                            new LabelBadgesTableColumn<UserSummary>(t("labelLicences"), item => item.roles.map(l => LicenseHelper.labelForLicense(l))),
                            new CountBadgeTableColumn<UserSummary>(t("labelGroups"), item => item.groups.length),
                            new CountBadgeTableColumn<UserSummary>(t("labelProjects"), item => item.numResources),
                            new ActionsTableColumn<UserSummary>(item => [
                                new TableItemAction(t("buttonEdit"), this.onEditUser),
                                new TableItemAction(
                                    t("buttonDelete"), 
                                    this.onDeleteRequest),
                            ]),
                        ]}
                    />
                </div>
                <TitleItem title={t("sectionTitleGroups")}>
                    <Button
                        iconBefore={<CreateIcon />}
                        onClick={this.showCreateGroupModal}
                        testId={"create-group-button"}
                    >
                        {t("buttonCreate")}
                    </Button>
                </TitleItem>
                <div className="tableContainer">
                    <Table
                        data={data && data.groups}
                        columns={[
                            new LinkTableColumn<GroupSummary>(
                                t("labelName"),
                                item => item.name,
                                item => groupLinkUrlBase.patch(item.id).path
                            ),
                            new CountBadgeTableColumn<GroupSummary>(t("labelUsers"), item => item.numUsers),
                            new LabelBadgesTableColumn<GroupSummary>(t("labelLicences"), item => item.roles.map(l => LicenseHelper.labelForLicense(l))),
                            new CountBadgeTableColumn<GroupSummary>(t("labelProjects"), item => item.numResources),
                            new ActionsTableColumn<GroupSummary>(item => [
                                new TableItemAction(t("buttonEdit"), this.onEditGroup),
                                new TableItemAction(
                                    t("buttonDelete"), 
                                    this.onDeleteRequest),
                            ]),
                        ]}
                    />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const { accountId } = this.props.match.params;
            const dataUrl = AuthService.isAmbergAdmin() ? `administrationusers/${accountId}` : `users`;
            const data = await AuthService.get<UsersPageData>(dataUrl);
            this.setState({ data });
            this.props.setLocationName(accountId && data.account.name);
        } catch (error) {
            //
        }
    }

    private renderCreateUserModal = () => {
        const {data} = this.state;
        const { accountId } = this.props.match.params;
        const createUrl = AuthService.isAmbergAdmin() ? `administrationusers/${accountId}/createuser` : `users/createuser`;
        const allGroups = (data && data.groups) || [];
        const allRoles = (data && data.roles) || [];
        const allResources = (data && data.resources) || [];

        return (
            <CreateUserModal
                createUrl={createUrl}
                allGroups={allGroups}
                allRoles={allRoles}
                allResources={allResources}
                onOk={this.onUserCreated}
                onCancel={this.hideModals}
            />
        );
    }

    private renderCreateGroupModal = () => {
        const {data} = this.state;
        const { accountId } = this.props.match.params;
        const createUrl = AuthService.isAmbergAdmin() ? `administrationusers/${accountId}/creategroup` : `users/creategroup`;
        const allUsers = (data && data.users) || [];
        const allRoles = (data && data.roles) || [];
        const allResources = (data && data.resources) || [];

        return (
            <CreateGroupModal
                createUrl={createUrl}
                allUsers={allUsers}
                allRoles={allRoles}
                allResources={allResources}
                onOk={this.onGroupCreated}
                onCancel={this.hideModals}
            />
        );
    }

    private rederDeleteModal = () => {
        const {deleteReqeuested} = this.state;

        return (
            <Modal
            key="button-popup"
            actions={[{ 
              text: t("buttonDelete"), 
              onClick: this.onDeleteSummary
            },  {
              onClick: this.onCloseDeleteRequest,
              text: t("buttonCancel"),
          }]}
            appearance={"danger"}
            onClose={this.onCloseDeleteRequest}
            heading={t("buttonDelete")}
            scrollBehavior={"outside"}
          >
            {t("textDeleteWarning", {what: this.isUserSummary(deleteReqeuested) ? t("labelUser") : t("labelGroup")})}
          </Modal>
        )
    }

    private onUserCreated = async () => {
        this.hideModals();
        await this.loadData();
    }

    private onGroupCreated = async () => {
        this.hideModals();
        await this.loadData();
    }

    private showCreateUserModal = () => {
        this.setState({
            showCreateUserModal: true,
        });
    }

    private showCreateGroupModal = () => {
        this.setState({
            showCreateGroupModal: true,
        });
    }

    private hideModals = () => {
        this.setState({
            showCreateGroupModal: false,
            showCreateUserModal: false,
        });
    }

    private onEditUser = (user: UserSummary) => {
        const { accountId } = this.props.match.params;
        this.props.history.push(AuthService.isAmbergAdmin() ?
          Routes.administrationAccountUser.patch(accountId, user.id).path
          :
          Routes.user.patch(user.id).path
        );
    }

    private isUserSummary = (summary: any): summary is UserSummary => {
        if(summary.loginName === undefined){
            return false;
        }
        return true;
    }

    private isGroupSummary = (summary: any): summary is GroupSummary => {
        if(summary.numUsers === undefined){
            return false;
        }
        return true;
    }

    private onDeleteRequest = (summary: UserSummary | GroupSummary) => {
        this.setState({deleteReqeuested: summary});
    }

    private onCloseDeleteRequest = () => {
        this.setState({deleteReqeuested: undefined});
    }

    private onDeleteSummary = () => {
        const {deleteReqeuested} = this.state;

        if (this.isUserSummary(deleteReqeuested)){
            Logger.info('User delete request!' + deleteReqeuested.id);
            this.onDeleteUser(deleteReqeuested);
        }
        else if(this.isGroupSummary(deleteReqeuested)){
            Logger.info('Group delete request!' + deleteReqeuested.id);
            this.onDeleteGroup(deleteReqeuested);
        }
        this.onCloseDeleteRequest();
    }

    private onDeleteUser = async (user: UserSummary) => {
        const { accountId } = this.props.match.params;
        await AuthService.delete(AuthService.isAmbergAdmin() ?
          `administrationusers/${accountId}/deleteuser/${user.id}`
          :
          `users/deleteuser/${user.id}`
        );
        await this.loadData();
    }

    private onEditGroup = (group: GroupSummary) => {
        const { accountId } = this.props.match.params;
        this.props.history.push(AuthService.isAmbergAdmin() ?
          Routes.administrationAccountGroup.patch(accountId, group.id).path
          :
          Routes.group.patch(group.id).path
        );
    }

    private onDeleteGroup = async (group: GroupSummary) => {
        const { accountId } = this.props.match.params;
        await AuthService.delete(AuthService.isAmbergAdmin() ?
          `administrationusers/${accountId}/deletegroup/${group.id}`
          :
          `users/deletegroup/${group.id}`
        );
        await this.loadData();
    }
}

export default compose(withNamespaces())(AdministrationUsersPage)

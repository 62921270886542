import { FlagErrorIcon, FlagSuccessIcon, FlagWarningIcon } from "../components/Icons";

export enum FlagType {
    Information = "normal",
    Warning = "warning",
    Error = "error",
}

export interface IFlag {
    id: string;
    type: FlagType;
    title: string;
    description: string;
    icon: any;
}

class FlagService {
    private addFlagHandler: (flag: IFlag) => void;
    private clearFlagsHandler: () => void;

    public initialize = (addFlagHandler: (flag: IFlag) => void, clearFlagsHandler: () => void) => {
        this.addFlagHandler = addFlagHandler;
        this.clearFlagsHandler = clearFlagsHandler;
    }

    public addInfo = (title: string, description: string, id?: string) => {
        this.addFlag({
            description,
            icon: FlagSuccessIcon,
            id: id || Date.now().toString(),
            title,
            type: FlagType.Information,
        });
    }

    public addWarning = (title: string, description: string, id?: string) => {
        this.addFlag({
            description,
            icon: FlagWarningIcon,
            id: id || Date.now().toString(),
            title,
            type: FlagType.Information,
        });
    }

    public addError = (title: string, details: string | Error, id?: string) => {
        let description = "";
        if (details instanceof Error) {
            if ((details as any).response && (details as any).response.data) {
                description = (details as any).response.data;
            } else {
                description = details.message;
            }
        } else {
            description = details;
        }
        this.addFlag({
            description: description,
            icon: FlagErrorIcon,
            id: id || Date.now().toString(),
            title: title,
            type: FlagType.Error,
        });
    }

    public addFlag = (flag: IFlag) => {
        if (!this.addFlagHandler) {
            return;
        }
        this.addFlagHandler(flag);
    }

    public clearFlags = () => {
        if (!this.clearFlagsHandler) {
            return;
        }
        this.clearFlagsHandler();
    }
}

export default new FlagService();
import * as H from "history";
import AuthService from "../services/AuthService";

export default class RouteParser {
  public static getProjectId = (route: H.Location): string => {
    return route.pathname.split("/")[2];
  };

  public static getAccountId = (location: H.Location): string => {
    return location.pathname.split("/")[3];
  };

  public static getStructureId = (location: H.Location): string => {
    return location.pathname.split("/")[4];
  };

  public static isUserRoute = (location: H.Location): boolean => {
    return !RouteParser.isAdministrationRoute(location);
  };

  public static isAdministrationRoute = (location: H.Location): boolean => {
    return AuthService.isAmbergAdmin();
  };

  // public static isUserOffline = (location: H.Location): boolean => 
  // {
  //   return !(navigator.onLine);
  // }

  public static isUserTopLevelRoute = (location: H.Location): boolean => {
    return (
      RouteParser.isUserRoute(location) && !location.pathname.includes("/project/")
    );
  };

  public static isUserProjectLevelRoute = (location: H.Location): boolean => {
    return (
      RouteParser.isUserRoute(location) &&
      location.pathname.includes("/project/") &&
      !location.pathname.includes("/structure/")
    );
  };

  public static isUserStructureLevelRoute = (location: H.Location): boolean => {
    return (
      RouteParser.isUserRoute(location) &&
      location.pathname.includes("/project/") &&
      location.pathname.includes("/structure/")
    );
  };

  public static isUserStructureMeasurementFilesRoute = (location: H.Location): boolean => {
    return (
      RouteParser.isUserRoute(location) &&
      location.pathname.includes("/project/") &&
      location.pathname.includes("/structuremeasurement/")
    );
  };

  public static isTaskRoute = (location: H.Location): boolean => {
    return (
        RouteParser.isUserRoute(location) &&
        location.pathname.includes("/structuretask/")
    );
  };

  public static isAnalysisRoute = (location: H.Location): boolean => {
    return (
        RouteParser.isUserRoute(location) &&
        location.pathname.includes("/inspectionanalysis/")
    );
  };

  public static isAdministrationTopLevelRoute = (location: H.Location): boolean => {
    return (
      RouteParser.isAdministrationRoute(location) &&
      !location.pathname.includes("/account/")
    );
  };

  public static isAdministrationAccountLevelRoute = (location: H.Location): boolean => {
    return (
      RouteParser.isAdministrationRoute(location) &&
      location.pathname.includes("/account/")
    );
  };
}

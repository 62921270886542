import {  ResultBase, ResultItemGroup } from "@atlaskit/quick-search";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import { TunnelCloudIcon, AmbergInspectorIcon } from "../components/Icons";
import PageTitleItem from "../components/PageTitleItem";
import Constants from "../helpers/Constants";
import FieldBrowserSupport from '../helpers/FieldBrowserSupport';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface IAboutPageProps {
  dummy?: string;
}

export interface IAboutPageState {
  inAmbergInspector: boolean;
  apiVersion?: number;
  ambergInspectorVersion?: string;
  ambergInspectorBuild?: string;
  ambergInspectorReleaseDate?: string;
}


class AboutPage extends React.Component<IAboutPageProps, IAboutPageState> {
  private packageJson = require("../../package.json");
    constructor(props: IAboutPageProps) {
        super(props);
        this.state = { inAmbergInspector: false, apiVersion: undefined, ambergInspectorVersion: undefined, ambergInspectorBuild: undefined, ambergInspectorReleaseDate: undefined };
    }

  public render() {
    return (
      <div className="page" style={{ maxWidth: 500 }}>
        <PageTitleItem title={t("pageTitleAbout")}  testID={"about-page-title"}/>

        <div>
          <div style={{ float: "left", marginRight: 10 }}>
            <TunnelCloudIcon size={"xlarge"} />
          </div>
          <div style={{ width: 400, marginTop: 10 }}>
            <h2>Amberg {t("labelTunnelCloud")}</h2>
            <p style={{ marginTop: -4, fontSize: 12 }}>
              {t("labelVersion")} {this.packageJson.version}.{this.packageJson.clientbuildnumber}
            </p>
          </div>
        </div>
        {this.state.inAmbergInspector && (<p><div style={{overflow: "auto"}}>
          <div style={{ float: "left", marginRight: 10 }}>
            <AmbergInspectorIcon size={"xlarge"} />
          </div>
          <div style={{ width: 400, marginTop: 10 }}>
            <h2>{t("labelAmbergInspector")}</h2>
            <p style={{ marginTop: -4, fontSize: 12 }}>{this.state.ambergInspectorVersion && (<span>{t("labelVersion")} {this.state.ambergInspectorVersion}</span>)}</p>
          </div>
          </div>
          <div style={{fontSize: 12}}>
            {this.state.ambergInspectorBuild && (
            <span>{t("labelBuild")} {this.state.ambergInspectorBuild} {t("labelApiVersion")} {this.state.apiVersion} {t("labelReleaseDate")} {this.state.ambergInspectorReleaseDate}</span>)}
          </div>
        {FieldBrowserSupport.isIncompatibleFieldBrowser() && (<div id={"outdated-alert"}>{t("messageOutdatedAmbergInspector")}</div>)}
        </p>)}

        {/*TODO: Add links to the end user agreement and privacy policy*/}
        <p>{t("textEUA")}</p>

        {this.renderDependencies()}

        <p style={{ color: Constants.colorBackgroundM2 }}>{t("textCopyrightNotice")}</p>
        <p style={{ color: Constants.colorBackgroundM2, marginTop: 0 }}>
          {t("textAllRightReserved")}
        </p>
      </div>
    );
  }

  public async componentDidMount() {
    if (FieldBrowserSupport.isFieldBrowser() || FieldBrowserSupport.isIncompatibleFieldBrowser())
    {
      let inAmbergInspector = true;
      let apiVersion = undefined;
      let ambergInspectorVersion = undefined;
      let ambergInspectorBuild = undefined;
      let ambergInspectorReleaseDate = undefined;
      try
      {
          const appBridge = await FieldBrowserSupport.bridge();
          apiVersion = await appBridge.majorVersion();
          ambergInspectorVersion = await appBridge.applicationVersionNumber();
          ambergInspectorBuild = await appBridge.applicationBuildNumber();
          ambergInspectorReleaseDate = await appBridge.applicationReleaseDate();
          FieldBrowserSupport.releaseBridge();
        }
        catch (err) {
        }
        this.setState({ inAmbergInspector, apiVersion, ambergInspectorVersion, ambergInspectorBuild, ambergInspectorReleaseDate });
  }
};


  private renderDependencies = () => {
    return (
      <React.Fragment>
        <p>{t("textThirdPartyLong")}</p>
        <div style={{ maxWidth: 450, margin: 10, marginLeft: 15 }}>
          <ResultItemGroup title={t("textThirdPartyShort")}>
            <div style={{ height: "50vh", overflowY: "scroll" }}>
              {Object.keys(this.packageJson.dependencies).map(name => {
                const version = this.packageJson.dependencies[name];
                const moduleInfo = this.getModuleInfo(name);
                return (
                  <ResultBase
                    key={name}
                    text={name}
                    caption={moduleInfo.license}
                    subText={t("labelVersion") + ": " + version}
                    onClick={
                      // tslint:disable-next-line:jsx-no-lambda
                      () => this.goTo(moduleInfo.url)
                    }
                  />
                );
              })}
            </div>
          </ResultItemGroup>
        </div>
      </React.Fragment>
    );
  };

  private getModuleInfo = (name: string): { license: string; url: string } => {
    const packageJson = require(`../../node_modules/${name}/package.json`);

    return { license: packageJson.license, url: this.getURL(packageJson) };
  };

  private getURL = (json: any): string => {
    let url = "";
    if (json.homepage) {
      url = json.homepage;
    } else if (json.repository.url) {
      url = json.repository.url;
    } else if (json.repository) {
      url = json.repository;
    }
    return this.parseURL(url);
  };

  private parseURL = (url: string): string => {
    const match = url.match("git:\\/\\/(.*)");
    if (match) {
      return "https://" + match[1];
    }
    if (url === "bitbucket:atlaskit/atlaskit-mk-2") {
      return "https://bitbucket.org/atlassian/atlaskit-mk-2";
    }
    if (!url.match("[\\:.]")) {
      return "https://github.com/" + url;
    }
    return url;
  };

  private goTo = (url: string) => {
    window.open(url);
  };
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, AboutPage,"AboutPage","AppInsight"));

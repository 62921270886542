export enum PointStyleType {
    EmptyCircle = "EmptyCircle",
    EmptySquare = "EmptySquare",
    XShape = "XShape",
    rShape = "rShape",
    EmptyVRectangle = "EmptyVRectangle",
    EmptyHRectangle = "EmptyHRectangle",
    FilledVRectangle = "FilledVRectangle",
    FilledHRectangle = "FilledHRectangle",
    XShapeInCircle = "XShapeInCircle",
    LetterSInRectangle = "LetterSInRectangle",
    LineWithCircles = "LineWithCircles",
    OverlappingRectanglesVar1 = "OverlappingRectanglesVar1",
    OverlappingRectanglesVar2 = "OverlappingRectanglesVar2",
    SplitHRectangle = "SplitHRectangle",
    CircleOnCross = "CircleOnCross"
}

export default class MapProviderHelper {
    public static MapProviderOpenStreetMap = "osm";
    public static MapProviderEsriWorldTopo = "esri_world_topo";
    public static MapProviderEsriWorldImagery = "esri_world_imagery";
    // public static MapProviderSwissTopoPixel = "swisstopo_pixel";

    public static supportedMapProviders = (): Map<string, string> => {
        return new Map([
            [MapProviderHelper.MapProviderOpenStreetMap, "Open Street Map"],
            [MapProviderHelper.MapProviderEsriWorldTopo, "ESRI World Topo"],
            [MapProviderHelper.MapProviderEsriWorldImagery, "ESRI World Imagery"],
            // [MapProviderHelper.MapProviderSwissTopoPixel, "Swisstopo Pixelkarte"],
        ]);
    }

    public static urlTemplateForMapProvider = (mapProvider: string): string => {
        switch (mapProvider) {
            case MapProviderHelper.MapProviderOpenStreetMap: return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
            case MapProviderHelper.MapProviderEsriWorldTopo: return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}";
            case MapProviderHelper.MapProviderEsriWorldImagery: return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
            // case MapProviderHelper.MapProviderSwissTopoPixel: return "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg";
            default: return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        }
    }
}
import Spinner from '@atlaskit/spinner';
import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import SingleSelectComboBoxLabeled from "../components/labeled/SingleSelectComboBoxLabeled";
import ToggleLabeled from "../components/labeled/ToggleLabeled";
import PageTitleItem from '../components/PageTitleItem';
import StructureMeasurementBasics from '../dto/components/StructureMeasurementBasics';
import InspectionAnalysisPageData from '../dto/pages/InspectionAnalysisPageData';
import IRouteParams from '../helpers/IRouteParams';
import AuthService from '../services/AuthService';
import Logger from '../services/Logger';



export interface IInspectionAnalysisSingleNumericalPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string, structureItemName: string) => void;
}

enum ExportType {
    csv = "csv",
    json = "json",
}

export interface IInspectionAnalysisSingleNumericalPageState {
    data?: InspectionAnalysisPageData;
    measurements: StructureMeasurementBasics[];
    measurementId?: string;
    groupedByRemark: boolean;
    groupedByRemarkEnabled: boolean;
    type?: string;
    waiting: boolean;
    waitingDownloading: boolean;
}

class InspectionAnalysisSingleNumericalPage extends React.Component<IInspectionAnalysisSingleNumericalPageProps, IInspectionAnalysisSingleNumericalPageState> {
    constructor(props: IInspectionAnalysisSingleNumericalPageProps) {
        super(props);
        this.state = {
            measurements: [],
            groupedByRemark: false,
            groupedByRemarkEnabled: true,
            type: ExportType.csv,
            waiting: true,
            waitingDownloading: false,
        };
    }

    public render() {

        const { data, measurements, measurementId, type, waiting, waitingDownloading, groupedByRemark, groupedByRemarkEnabled } = this.state;
        const measurementOptions = measurements.map(m => {
            return {
                label: m.name,
                value: m.id,
            };
        });
        const selectedMeasurementOption = measurementOptions.find(m => m.value === measurementId);

        const exportTypeOptions = [{label: ExportType.csv, value: ExportType.csv}, 
                                   {label: ExportType.json, value: ExportType.json}];

        const selectedExportType = exportTypeOptions.find(m => m.value === type);

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleNumericalExport")}  testID={"inspection-analysis-single-numerical-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>                   
                {data && 
                <form className="modalForm" onSubmit={this.onDownload}>
                    <SingleSelectComboBoxLabeled
                        label={t("labelMeasurement")}
                        placeholder={t("labelSelect")}
                        options={measurementOptions}
                        selectedOption={selectedMeasurementOption}
                        onChange={this.onMeasurementSelectionChange}
                    />
                    <SingleSelectComboBoxLabeled
                        label={t("labelType")}
                        placeholder={t("labelSelect")}
                        options={exportTypeOptions}
                        selectedOption={selectedExportType}
                        onChange={this.onExportTypeSelectionChange}
                    />
                    <ToggleLabeled 
                        label={t("labelGroupedByRemark")}
                        size="large"
                        isChecked = {groupedByRemark}
                        isDisabled = {!groupedByRemarkEnabled}
                        onChange = {this.onGroupByRemark}
                        />
                    <ButtonLabeled
                        type="submit"
                        testId={"download-button"}
                        isDisabled={waitingDownloading}
                    >
                        {t("buttonDownload")}
                        {waitingDownloading && (
                            <span style={{ display: "inline", margin: "5px" }}> <Spinner size={"small"} /></span>
                        )}

                    </ButtonLabeled>
                </form>}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/data`);
            this.setState({
                data: new InspectionAnalysisPageData(data),
                measurements: data.measurements,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            this.setState({waiting:false,})
        }
    }

    private onMeasurementSelectionChange = (option?: ISSelectOption) => {
        const measurementId = option && option.value;
        this.setState({ measurementId });
    }

    private onExportTypeSelectionChange = (option?: ISSelectOption) => {
        const type = option && option.value;
        const isGroupedEnabled = type === ExportType.csv
        if (isGroupedEnabled === false){
            this.setState({groupedByRemark: false});
        }
        this.setState({ type, groupedByRemarkEnabled: isGroupedEnabled });
    }

    private onGroupByRemark = () => {
        const groupByRemark = this.state.groupedByRemark;
        this.setState({groupedByRemark : !groupByRemark});
      };

    private onDownload = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }

        const { data, measurementId, groupedByRemark, type } = this.state;
        if (!data || !measurementId) {
            return;
        }

        try {
            const grouping = groupedByRemark ? 'remark' : '';
            // make string downloadcsv or downloadjson
            const exportType = "download" + type;
            this.setState({waitingDownloading: true});
            await AuthService.downloadBinaryFile(`inspectionanalysis/${data.inspectionAnalysis.project.id}/${data.inspectionAnalysis.structure.id}/${measurementId}/${exportType}/${grouping}`, "inspection." + type);
        } catch (error) {
            Logger.error(error, "InspectionAnalysisSingleNumericalPage");
            this.setState({waitingDownloading: false});

        }
        this.setState({waitingDownloading: false});
    }

    private validate = (): boolean => {
        const { data, measurementId } = this.state;
        if (!data) {
            return false;
        }
        if (!measurementId) {
            return false;
        }
        return true;
    }
}

export default compose(withNamespaces())(InspectionAnalysisSingleNumericalPage)

import Spinner from "@atlaskit/spinner";
import InspectionTable from "../components/InspectionTable";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import InspectionStructureOverviewMap
    from "../components/InspectionStructureOverviewMap";
import PageTitleItem from "../components/PageTitleItem";
import Table, {
    IconTableColumn,
    LinkTableColumn,
    TextTableColumn,
} from "../components/Table";
import TitleItem from "../components/TitleItem";
import InspectionAnalysisSummary from "../dto/components/InspectionAnalysisSummary";
import StructureOverviewPageData from "../dto/pages/StructureOverviewPageData";
import InspectionHelper from "../helpers/InspectionHelper";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";
import FieldBrowserSupport from '../helpers/FieldBrowserSupport';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface IStructureOverviewPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string) => void;
    reloadTrigger: ( func: ()=>void ) => void;
}

export interface IStructureOverviewPageState {
    data?: StructureOverviewPageData
    waiting: boolean
}

class StructureOverviewPage extends React.Component<IStructureOverviewPageProps, IStructureOverviewPageState> {
    constructor(props: IStructureOverviewPageProps) {
        super(props);
        this.state = { waiting:true };
    }

    public render() {
        const { data, waiting } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleStructureOverview")}  testID={"structure-overview-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div> 
                <div className="pageOverviewMap">
                    {data && <InspectionStructureOverviewMap
                        mapProvider={data.structure.mapData.mapProvider}
                        structure={data.structure}
                        inspectionClassificationDetails={data.inspectionClassificationDetails}
                    />}
                </div>
                <div>
                    <TitleItem title={t("sectionTitleInspectionTasks")} />{}
                    <div className="tableContainer">{
                        data &&
                        <InspectionTable inspectionTasks={data.inspectionTasks} measurements={data.measurements}  offlineTasks={data.offlineStatus && data.offlineStatus.tasks} showProjectColumn={false} showStructureColumn={false} showControls={false}/>
                    }
                    </div>
                </div>
                {data && data.inspectionAnalyses && (data.inspectionAnalyses.length > 0) && !FieldBrowserSupport.isOfflineMode() && this.renderInspectionAnalysis()}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
        this.props.reloadTrigger(this.reloadData)
    }

    public reloadData = async () => {
        await this.loadData();
    };

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<StructureOverviewPageData>(`structureoverview/${this.props.match.params.projectId}/${this.props.match.params.structureId}/data`);
            this.setState({ data, waiting:false });
            this.props.setLocationNames(data.structure.project.name, data.structure.name);
        } catch (error) {
            this.setState({waiting:false,})
        }
    }

    private renderInspectionAnalysis = () => {
        const { data } = this.state;
        return (
            <div>
                <TitleItem title={t("sectionTitleInspectionAnalysis")} />
                <div className="tableContainer">
                    <Table
                        data={data && data.inspectionAnalyses}
                        columns={[
                            new IconTableColumn<InspectionAnalysisSummary>("", item => InspectionHelper.iconForAnalysisType(item.type)),
                            new LinkTableColumn<InspectionAnalysisSummary>(
                                t("labelName"),
                                item => item.name,
                                item => Routes.analysis.patch(item.project.id, item.structure.id, item.type, item.id).path
                            ),
                            new TextTableColumn<InspectionAnalysisSummary>(t("labelType"), item => InspectionHelper.nameForAnalysisType(item.type)),
                        ]}
                    />
                </div>
            </div>
        );
    }
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, StructureOverviewPage,"StructureOverviewPage","AppInsight"));

import FeatureTypeGroupData from './FeatureTypeGroupData';
import ProjectItemBasics from './ProjectItemBasics';
import FlagService from "../../services/FlagService";
import { t } from "i18next";


export default class CatalogData extends ProjectItemBasics {
    private layers: FeatureTypeGroupData[];
    get groups(): FeatureTypeGroupData[]{
        return this.layers;
    }

    constructor(data: CatalogData){
        super(data.id, data.name);
        this.layers = [];        
        if (data.layers === null ){
            FlagService.addWarning(t("labelWarning"),  t("labelMissingCatalog") + t("textErrorLoadingInspectionTask", {product:t("textAmbergTunnelVersionName")}));
            return;
        }
        for(const layer of data.layers){
        const featureTypeGroup = new FeatureTypeGroupData(layer);
           this.layers.push(featureTypeGroup);
         }

    }
}
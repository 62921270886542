import { colors } from '@atlaskit/theme';

export default class Constants {

    // theme colors
    public static colorForegroundM1blue: string = "#09ACE3";
    public static colorForegroundM2dark: string = "#16222A";
    public static colorForegroundM3white: string = "#FFFFFF";
    public static colorBackgroundM1: string = "#F2FCFF";
    public static colorBackgroundM2: string = "#506E78";
    public static colorBackgroundM3: string = "#F4F5F7";

    public static colorNavBackgroudGlobal: string = "#294050";
    public static colorNavGroupForegroundGlobal: string = "#EEEEEE";

    public static colorAmbergTechnologiesRed: string = "#E2003B";

    public static backgoundUselected: string = "#FAFBFC";
    public static borderUnselected: string = "#DFE1E6";

    // other colors
    public static successColor: string = colors.G300;
    public static errorColor: string = colors.R400;
    public static warningColor: string = "#F6BE00";

    // sizes
    public static minSizeForExpandableNavigation = 1000;
}
import * as React from "react";
import Button from "../atlaskit/Button";
import { CollapsedIcon, ExpandedIcon } from "../Icons";


export interface ICatalogHeadingProps {
    name: string,
    isExpanded?: boolean,
    onClick?: () => void,
    iconAfter?: any,
    appearance?: string,
}

const styles = {
    "subtle": {
        float: 'left',
    } as React.CSSProperties,
    "primary": {
        float: "left",
        fontSize: 11,
        fontWeight: 600,
    } as React.CSSProperties
};

export default class CatalogHeading extends React.Component<ICatalogHeadingProps, {}> {

    public render() {
        return (
            <Button
                key={this.props.name}
                onClick={this.props.onClick}
                iconBefore={this.props.isExpanded !== undefined && (this.props.isExpanded ? <ExpandedIcon/> : <CollapsedIcon/>)}
                iconAfter={this.props.iconAfter}
                shouldFitContainer={true}
                appearance={"subtle"}
                testId={"catalog-heading-button"}
            >
                <div style={ styles[this.props.appearance || "primary"]}>
                    { this.props.appearance === "subtle" ?
                        this.props.name
                        :
                        this.props.name.toLocaleUpperCase()
                    }
                </div>
            </ Button>
        );
    };
}
import { FeatureCollection } from "geojson";
import * as L from "leaflet";
import { LatLngBounds, Util } from "leaflet";
import * as _ from "lodash";
import FeatureLayer  from "./FeatureLayer";
import SearchTree from "./SearchTree";
import ILayerStyle from "./styles/ILayerStyle";

export default class InspectionFeatureGroup extends L.GeoJSON {
    public static getFeatureLayerId(layer: FeatureLayer) {
        return layer.feature.id;
    };

    public static getInspectionFeatureGroupID(code: string){
        return `inspectiondrawing_${code}`;
    }

    public id: string;
    private layers: SearchTree;
    private _style: ILayerStyle;
    private _styleLocked: ILayerStyle;

    private _eventHandlers: Array<(layer: FeatureLayer) => void> = [];

    private hidden: boolean = false;
    private locked: boolean = false;
    private tooltipForced: boolean = false;

    constructor({id,  map, style, styleLocked, geojson}: {
        id: string,
        map: L.Map,
        style: ILayerStyle,
        styleLocked?: ILayerStyle,
        geojson?: FeatureCollection
    }) {
        super();
        this.id = id;
        this._map = map;

        Util.setOptions(this, style.getOptions());
        this._style = style;
        this._styleLocked = styleLocked || style;

        if(style.eventHandlers){
            Array.prototype.push.apply(this._eventHandlers, style.eventHandlers)
        }

        this.layers = new SearchTree(map);

        if (geojson) {
            this.addData(geojson);
        }
        this.addViewUpdateHandler()
    }

    public addEventHandler(handler: (layer: any)=>void )  { this._eventHandlers.push(handler) };

    public addData (geojson: FeatureCollection) {
        const features = Util.isArray(geojson) ? geojson : geojson.features;

        if (features) {
            for (let i = 0; i < (features as any).length; i++) {
                // only add this if geometry or geometries are set and not null
                const feature = features[i];
                if (feature.geometries || feature.geometry || feature.features || feature.coordinates) {
                    this.addData(feature);
                }
            }
            return this;
        }

        const layer = InspectionFeatureGroup.geometryToLayer((geojson as any), this.options);
        if (!layer) {
            return this;
        }

        (layer as any).feature = InspectionFeatureGroup.asFeature((geojson as any));

        (layer as any).defaultOptions = (layer as any).options;
        this.resetStyle(layer);

        this._eventHandlers.forEach(handler => {
            handler((layer as FeatureLayer));
        });

        if (this.options.onEachFeature) {
            this.options.onEachFeature((geojson as any), layer);

            if((layer as any).setId){
                (layer as any).setId(`feature-id?${(layer as FeatureLayer).feature.id}`)
            }
        }

        return this.addLayer((layer as FeatureLayer));
    };

    public addLayer(layer: FeatureLayer) {
        layer.addEventParent(this);

        if(this.tooltipForced){
            const tooltip = layer.getTooltip();
            if(tooltip) {
                layer.unbindTooltip();
                tooltip.options.permanent = true;
                layer.bindTooltip(tooltip)
            }
        }

        const id = InspectionFeatureGroup.getFeatureLayerId(layer) || Util.stamp(layer).toString();
        layer.feature.id = id;
        this.layers.set(id, layer);

        if (this._map) {
            if(!this.hidden && this.isActive(layer)){
                this._map.addLayer(layer);
            }
        }

        return this.fire("layeradd", {layer});
    };

    public isActive = (layer: FeatureLayer) => {
        let isActive = false;

        if(this._map){
            const bounds = this._map.getBounds();
            if((layer as any)._latlng){
                isActive = bounds.contains((layer as any)._latlng)
            }
            else if((layer as any)._bounds){
                isActive = bounds.intersects((layer as any)._bounds)
            }
        }

        return isActive
    };

    public removeLayer(layer: any) {
        if (!this.hasLayer(layer)) {
            return this;
        }

        if (this._map) {
            layer = this.layers.get(layer.feature.id);
            this._map.removeLayer(layer);

            const id = InspectionFeatureGroup.getFeatureLayerId(layer);
            this.layers.delete(id);
        }

        return this;
    };

    public removeAllLayers() {
        this.eachLayer((layer: any) => {
            this.removeLayer(layer)
            }
        );
        this.layers = new SearchTree(this._map);
    };

    public hasLayer(layer: any) {
        return !!layer && this.layers.has(InspectionFeatureGroup.getFeatureLayerId(layer));
    };

    public invoke(methodName: any, ...args: any[]) {
        const allArgs = Array.prototype.slice.call(args, 1);

        this.layers.forEach( (layer: any) => {
            if (layer[methodName]) {
                layer[methodName].apply(layer, allArgs);
            }
        });

        return this;
    };

    public eachLayer(method: any, context?: any) {
        this.layers.forEach( (layer: any) => {
            method.call(context, layer);
        });
        return this;
    };

    public eachActiveLayer(method: any, context?: any) {
        if(!this.hidden && !this.locked){
            const active = this.layers.getActiveLayers();
            active.forEach( (layer: any) => {
                method.call(context, layer);
            });
        }
        return this;
    };

    public getLayer(id: any) {
        return this.layers.get(id);
    };

    public getLayers()  {
        return this.layers.toArray()
    }

    public setStyle(options:  L.GeoJSONOptions): this {
        this.layers.forEach((layer: FeatureLayer) => {
            this._map.removeLayer(layer);

            if(layer.feature.geometry.type === "Point"){
                const id = InspectionFeatureGroup.getFeatureLayerId(layer);
                const feature = layer.feature;

                if(options.pointToLayer){
                    layer = options.pointToLayer((feature as any), (layer as any)._latlng) as FeatureLayer;
                    this._eventHandlers.forEach(handler => {
                        handler((layer as FeatureLayer));
                    });
                }
                if(options.onEachFeature){
                    options.onEachFeature(feature, layer)
                }
                this.layers.set(id, layer)
            }

            let style = options.style;
            if (typeof style === 'function') {
                style = style(layer.feature);
            }
            (layer as any).setStyle(style);

            this.addLayer(layer)
        });
        return this;
    }

    public getBounds() {
        // @ts-ignore
        const bounds = new LatLngBounds();
        this.layers.forEach( (layer: any) => {
            bounds.extend(layer.getBounds ? layer.getBounds() : layer.getLatLng());
        });

        return bounds;
    }

    public changeLock = (locked: boolean) => {
        if(this.locked !== locked && this._styleLocked) {
            this.locked = locked;
            this.options = locked ? this._styleLocked.getOptions() : this._style.getOptions();
            this.setStyle(this.options);
        }
    };

    public changeVisibility = (hidden: boolean) => {
        if(this.hidden !== hidden){
            this.hidden = hidden;
            if(this.hidden){
                this.layers.forEach( (layer: any) => {
                    this._map.removeLayer(layer)
                });
            }
            else {
                this.layers.forEach( (layer: any) => {
                    this.addLayer(layer)
                });
            }
        }
    };

    public forceTooltip = (tooltipForced: boolean) => {
        if(this.tooltipForced !== tooltipForced){
            this.tooltipForced = tooltipForced;
            if(this.tooltipForced){
                this.layers.forEach( (layer: any) => {
                    const tooltip = layer.getTooltip();
                    if(tooltip) {
                        layer.unbindTooltip();
                        tooltip.options.permanent = true;
                        layer.bindTooltip(tooltip)
                    }
                });
            }
            else {
                this.layers.forEach( (layer: any) => {
                    const tooltip = layer.getTooltip();
                    if(tooltip){
                        layer.unbindTooltip();
                        tooltip.options.permanent = false;
                        layer.bindTooltip(tooltip)
                    }
                });
            }
        }
    };

    private addViewUpdateHandler() {
        const updater = _.debounce(() => {
            if(this._map){
                this.layers.forEach((layer) => {
                    this._map.removeLayer(layer)
                });

                if (!this.hidden) {
                    const active = this.layers.getActiveLayers();
                    active.forEach((layer:FeatureLayer) => {
                            this._map.addLayer(layer);
                            layer.fire('printready');
                    });
                }
            }
        }, 100);

        if(this._map){
            this._map.on("move", ()=> {
                updater()
            });
            this._map.on("zoomend", ()=> {
                updater()
            });
        }
    }
};

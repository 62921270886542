import * as React from "react";
import CatalogBase, { ICatalogBaseState } from "./CatalogBase";
import CatalogItem, { ICatalogItemProps } from "./CatalogItem";
import FeatureIconProvider from "./FeatureIconProvider";


interface ICatalogProps {
    name: string;
    items: ICatalogItemProps[];
}


export default class CatalogLayerGroup extends CatalogBase<ICatalogProps, ICatalogBaseState> {

    public getInitialState(): ICatalogBaseState {
        const initialState = super.getInitialState();
        initialState.appearance = "subtle";
        initialState.marginRight = 0;
        return initialState;
    }

    public renderChildren = () => {
        const items: any = [];

        const itemsSorted = this.props.items.sort(
            (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
        );

        itemsSorted.forEach(item => {
            items.push(
                <CatalogItem
                    key={item.id || item.name}
                    name={item.name}
                    id={item.id}
                    onClick={this.getHandleSelectMethod(item)}
                    isHidden={item.isHidden}
                    onVisibilityToggle={item.onVisibilityToggle}
                    testId={item.testId}
                />
            )});

        return items
    };

    public renderTools = () => {
        return FeatureIconProvider.getTools({
            id:  this.props.name,
            isHidden: this.isGroupHidden(),
            onVisibilityChange: this.toggleGroupVisibility,
        })
    };

    public toggleGroupVisibility = (event: any) => {
        event.stopPropagation();


        if(this.isGroupHidden()){
            this.props.items.forEach(item => {
                if(item.onVisibilityToggle){
                    item.onVisibilityToggle();
                }
            })
        }
        else {
            this.props.items.forEach(item => {
                if(!item.isHidden){
                    if(item.onVisibilityToggle){
                        item.onVisibilityToggle();
                    }
                }
            })
        }
    };

    public isGroupHidden = () => {
        return this.props.items.every(item => {
            return item.isHidden || false;
        })
    };

    private getHandleSelectMethod = (item: ICatalogItemProps) => {
        return (event: any) => {
            event.stopPropagation();
            if (item.onVisibilityToggle){
                item.onVisibilityToggle();
            }
        }
    };

}

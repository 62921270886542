import { ErrorMessage, HelperMessage, ValidMessage } from '@atlaskit/form';
import { t } from 'i18next';
import React from 'react';

export interface IFieldMessage {
    isValid: boolean;
    error?: string;
    doNotValidate?: boolean;
    validMessage?: string;
    errorMessage?: string;
    helperMessage?: string;
  }
  
const FieldMessage = (props: IFieldMessage) => {
    if (props.isValid) {
      return <ValidMessage>{props.validMessage ? props.validMessage : t("labelOk")}</ValidMessage>;
    } else if (props.error === "INVALID_CHARS") {
      return <ErrorMessage>{t("textValidateNoPercentSign")}</ErrorMessage>;
    } else if (props.error === "CUSTOM_ERROR") {
      return <ErrorMessage>{props.errorMessage ? props.errorMessage : t("labelInvalid")}</ErrorMessage>;
    } else if (props.error === "EMPTY") {
        if(props.helperMessage){
            return <HelperMessage>{props.helperMessage}</HelperMessage>
        } else {
            return <></>;
        }
    } else if (!props.error ) {
        return <></>
    } else {
      return <ErrorMessage>{t("labelInvalid")}</ErrorMessage>;
    }
  };

export default FieldMessage;
import * as React from "react";
import LabelWrapper from "./Wrapper";
import TextBox, {ITextBoxProps} from "../TextBox";
import { ILabelWrapperProps } from "./Wrapper";

interface IScaleSettingLabeledProps
    extends ILabelWrapperProps,
        ITextBoxProps {}

export default function ScaleSettingLabeled(
    props: IScaleSettingLabeledProps
) {
    const { label, children, labelMarginTop, testId, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
            <div style={{ display: "inline-flex", alignContent:""}}>
                <div style={{width:"2em" }}>
                    <TextBox value={"1"} disabled={true} doNotValidate={true}/>

                </div>
                <label style={{
                fontSize: "0.8571428571428571em",
                fontStyle: "inherit",
                lineHeight: 1.3333333333333333,
                color: "#6B778C",
                fontWeight: 600,
                display: "inline-block",
                marginBottom: 0,
                marginTop: 20,
                marginLeft:7,
                marginRight:7,
              }}>:</label>
                    <TextBox label={""} isNumber={true} {...rest} testId={testId}>
                        {children}
                    </TextBox>
            </div>
        </LabelWrapper>
    );
}


import Spinner from "@atlaskit/spinner";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { Rnd } from "react-rnd";
import { compose } from "recompose";
import InspectionMapBase from "../components/InspectionMapBase";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import CatalogLayerAnalysis from "../components/toolbar/CatalogLayerAnalysis";
import InspectionAnalysisMapsPageData
    from "../dto/pages/InspectionAnalysisMapsPageData";
import Constants from "../helpers/Constants";
import AuthService from "../services/AuthService";
import DoubleMapPageBase, {
    IDoubleMapPageBaseProps,
    IDoubleMapPageBaseState,
} from "./DoubleMapPageBase";


export interface IInspectionAnalysisOverlayMapsPageState extends IDoubleMapPageBaseState {
    data?: InspectionAnalysisMapsPageData;
    splitterSize: number;
    splitterX: number;
    splitterY: number;
}

class InspectionAnalysisOverlayMapsPage extends DoubleMapPageBase<IDoubleMapPageBaseProps, IInspectionAnalysisOverlayMapsPageState> {
    private readonly mapContainer: any;

    constructor(props: IDoubleMapPageBaseProps) {
        super(props);
        this.state = this.getInitialState();
        this.mapContainer = React.createRef();
    }

    public getInitialState(): IInspectionAnalysisOverlayMapsPageState {
        const initialState = super.getInitialState();
        initialState.splitterSize = 4;
        initialState.splitterX = 0;
        initialState.splitterY = 0;
        return initialState;
    }

    public render() {
        const {
            blockDefinitionSelected,
            classificationSelected,
            data,
            inspectionDrawingId,
            secondBlockDefinitionSelected,
            secondClassificationSelected,
            secondInspectionDrawingId,
            secondSectorDefinitionSelected,
            secondTheoreticalSectionsSelected,
            secondTileLayerId, hiddenFeatureTypeCodes,
            sectorDefinitionSelected,
            splitterSize,
            splitterX,
            splitterY,
            textHiddenFeatureTypeCodes,
            theoreticalSectionsSelected,
            tileLayerId,
            waiting,
        } = this.state;

        const classification = data && data.inspectionClassifications.find(l => l.id === inspectionDrawingId);
        const secondClassification = data && data.inspectionClassifications.find(l => l.id === secondInspectionDrawingId);
        const showSpinner = !data && waiting;

        return (
            <div className="pageMap">
                <div>
                    {showSpinner && (
                        <Spinner size={"large"}/>
                    )}
                </div>
                <div className="pageMaximized">
                    {data &&
                    <div className="mapWithStationingSlider">
                        <div className={"mapStacked"} ref={this.mapContainer}>
                            <div className="mapStackedChild">
                                <InspectionMapBase
                                    blockDefinition={data.blockDefinitionMapData}
                                    blockDefinitionSelected={blockDefinitionSelected}
                                    catalog={data.catalog}
                                    classification={classification && classification.mapData}
                                    classificationSelected={classificationSelected}
                                    hideSectorSelection={true}
                                    hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                                    inspectionDrawing={this.getDrawing(data.inspectionDrawings)}
                                    ref={instance => this.map = instance}
                                    sectorDefinition={data.sectorDefinitionMapData}
                                    sectorDefinitionSelected={sectorDefinitionSelected}
                                    selectedTileLayerId={tileLayerId}
                                    textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                                    theoreticalSections={data.theoreticalSectionsMapData}
                                    theoreticalSectionsSelected={theoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                                    zIndex={299}
                                />
                            </div>
                            <div className="mapStackedChild"
                                 style={{ clipPath: `inset(0 0 0 ${splitterX}px)` }}>
                                <InspectionMapBase
                                    blockDefinition={data.blockDefinitionMapData}
                                    blockDefinitionSelected={secondBlockDefinitionSelected}
                                    catalog={data.catalog}
                                    classification={secondClassification && secondClassification.mapData}
                                    classificationSelected={secondClassificationSelected}
                                    hideSectorSelection={true}
                                    hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                                    inspectionDrawing={this.getSecondDrawing(data.inspectionDrawings)}
                                    ref={instance => this.secondMap = instance}
                                    sectorDefinition={data.sectorDefinitionMapData}
                                    sectorDefinitionSelected={secondSectorDefinitionSelected}
                                    selectedTileLayerId={secondTileLayerId}
                                    textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                                    theoreticalSections={data.theoreticalSectionsMapData}
                                    theoreticalSectionsSelected={secondTheoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                                    zIndex={300}
                                />
                            </div>
                            <Rnd
                                dragAxis={"x"}
                                style={{
                                    backgroundColor: Constants.colorForegroundM3white,
                                    zIndex: 1000,
                                }}
                                bounds="parent"
                                enableResizing={{
                                    bottom: false,
                                    bottomLeft: false,
                                    bottomRight: false,
                                    left: false,
                                    right: false,
                                    top: false,
                                    topLeft: false,
                                    topRight: false,
                                }}
                                size={{ width: splitterSize, height: "100%" }}
                                position={{ x: splitterX, y: 0 }}
                                // tslint:disable-next-line:jsx-no-lambda
                                onDrag={(e: any, d: any) => {
                                    this.setState({ splitterX: d.x });
                                }}
                                // tslint:disable-next-line:jsx-no-lambda
                                onDragStop={(e: any, d: any) => {
                                    this.setState({ splitterX: d.x });
                                }}
                            >
                                <div
                                    id={"splitter"}
                                    style={{
                                        backgroundColor: "white",
                                        clipPath: "circle(50% at 50% 50%)",
                                        fontSize: 20,
                                        height: splitterSize * 10,
                                        lineHeight: `${splitterSize * 9}px`,
                                        marginLeft: -(splitterSize * 4.5),
                                        marginTop: splitterY,
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        width: splitterSize * 10,
                                    }}
                                >
                                    |||
                                </div>
                            </Rnd>


                        </div>
                    </div>
                    }
                    {data &&
                    <div className="sideBar">
                        <div
                            style={{ flex: "1 1 auto", width: "100%", height: "100%" }}>
                            <Scrollbar>
                                <CatalogLayerAnalysis
                                    blockDefinitionSelected={blockDefinitionSelected}
                                    classificationDataSelected={classificationSelected}
                                    inspectionDrawings={data.inspectionDrawings}
                                    name={t("labelMapLeft")}
                                    onBlockDefinitionSelectionChange={this.onBlockDefinitionSelectionChange}
                                    onClassificationDataSelectionChange={this.onClassificationSelectionChange}
                                    onInspectionDrawingSelectionChange={this.onInspectionDrawingSelectionChange}
                                    onSectorDefinitionSelectionChange={this.onSectorDefinitionSelectionChange}
                                    onTheoreticalSectionsSelectionChange={this.onTheoreticalSectionsSelectionChange}
                                    onTileLayerSelectionChange={this.onTileLayerSelectionChange}
                                    sectorDefinitionSelected={sectorDefinitionSelected}
                                    selectedInspectionDrawingId={inspectionDrawingId}
                                    selectedTileLayerId={tileLayerId}
                                    testId={"layer-catalog"}
                                    theoreticalSectionsSelected={theoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                                />
                                <Separator/>
                                <CatalogLayerAnalysis
                                    blockDefinitionSelected={this.state.secondBlockDefinitionSelected}
                                    classificationDataSelected={this.state.secondClassificationSelected}
                                    inspectionDrawings={data.inspectionDrawings}
                                    name={t("labelMapRight")}
                                    onBlockDefinitionSelectionChange={this.onSecondBlockDefinitionSelectionChange}
                                    onClassificationDataSelectionChange={this.onSecondClassificationSelectionChange}
                                    onInspectionDrawingSelectionChange={this.onSecondInspectionDrawingSelectionChange}
                                    onSectorDefinitionSelectionChange={this.onSecondSectorDefinitionSelectionChange}
                                    onTheoreticalSectionsSelectionChange={this.onSecondTheoreticalSectionsSelectionChange}
                                    onTileLayerSelectionChange={this.onSecondTileLayerSelectionChange}
                                    sectorDefinitionSelected={this.state.secondSectorDefinitionSelected}
                                    selectedInspectionDrawingId={this.state.secondInspectionDrawingId}
                                    selectedTileLayerId={this.state.secondTileLayerId}
                                    testId={"layer-catalog"}
                                    theoreticalSectionsSelected={this.state.secondTheoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                                />
                                <Separator/>
                                <CatalogFeatureType
                                    catalog={data.catalog}
                                    onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                    onTextVisibilityChange={this.onFeatureTypeTextVisibilityChange}
                                    testId={"feature-type-catalog"}
                                />
                            </Scrollbar>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await super.componentDidMount();
        this.initializeSplitter();
        window.addEventListener("resize", this.initializeSplitter);
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this.initializeSplitter);
    }

    public loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisMapsPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/mapdata`);

            this.setState({
                data: new InspectionAnalysisMapsPageData(data),
                tileLayerId: data.tileLayers[0].id,
                secondTileLayerId: data.tileLayers[data.tileLayers.length - 1].id,
                inspectionDrawingId: data.inspectionDrawings[0].id,
                secondInspectionDrawingId: data.inspectionDrawings[data.tileLayers.length - 1].id,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            this.setState({waiting:false})
        }
    };

    private initializeSplitter = () => {
        this.setState({
            splitterX: this.mapContainer.current?.offsetWidth / 2 - this.state.splitterSize / 2,
            splitterY: this.mapContainer.current?.offsetHeight / 2 - this.state.splitterSize / 2,
        });
    };

}

export default compose(withNamespaces())(InspectionAnalysisOverlayMapsPage);

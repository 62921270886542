import Proj4Helper from "../helpers/Proj4Helper";
import { t } from "i18next";
import { PathOptions } from "leaflet";
import * as React from "react";
import InspectionClassificationOverview
    from "../dto/components/InspectionClassificationOverview";
import StructureSummary from "../dto/components/StructureSummary";
import OverviewMap from "../graphics/OverviewMap";
import Constants from "../helpers/Constants";
import InspectionHelper from "../helpers/InspectionHelper";

export interface IInspectionProjectOverviewMapProps {
    mapProvider: string;
    structures: StructureSummary[];
    inspectionClassificationOverview: InspectionClassificationOverview[];
    onClick?: (id: string) => void;
}

export default class InspectionProjectOverviewMap extends React.Component<IInspectionProjectOverviewMapProps, {}> {
    private map: OverviewMap;

    public render() {
        return (
            <div id="inspectionprojectoverviewmap" style={{ height: "100%" }} />
        );
    }

    public componentDidMount() {
        this.createMap();
    }

    public componentDidUpdate(prevProps: IInspectionProjectOverviewMapProps) {
        this.map.invalidateSize()
    }

    public componentWillUnmount(){
        this.map.map.remove();
    }

    private createMap = () => {
        const { mapProvider, structures, inspectionClassificationOverview } = this.props;

        this.map = new OverviewMap("inspectionprojectoverviewmap");
        this.map.setMapProvider(mapProvider);
        structures.forEach(structure => {
            this.map.setStyleHandlers(`axis_${structure.id}`, this.getAxisStyle);

            try
            {
                this.map.addFeatureLayer(`axis_${structure.id}`, structure.mapData.geoJson, structure.mapData.proj4Definition, {
                excludeFromExtents: true,});
            }
            catch
            {
                this.map.addFeatureLayer(`axis_${structure.id}`, structure.mapData.geoJson, Proj4Helper.createCustomProj4String(0,0,0,0), {
                excludeFromExtents: true,});
            }
            
        });
        inspectionClassificationOverview.forEach(inspection => {
            this.map.setStyleHandlers(`inspection_${inspection.id}`, this.getInspectionStyle, this.getInspectionStyleActive);
            this.map.setClickHandler(`inspection_${inspection.id}`, this.onInspectionClick);
            this.map.setTooltipHandler(`inspection_${inspection.id}`, this.getInspectionTooltip);

            try
            {
                this.map.addFeatureLayer(`inspection_${inspection.id}`, inspection.mapData.geoJson, inspection.mapData.proj4Definition);
            }
            catch
            {
                this.map.addFeatureLayer(`inspection_${inspection.id}`, inspection.mapData.geoJson, Proj4Helper.createCustomProj4String(0,0,0,0));
            }
        });

        this.map.fitBounds();
    }

    private getAxisStyle = (): PathOptions => {
        return {
            color: Constants.colorForegroundM1blue,
            interactive: false,
            weight: 3,
        };
    }

    private getInspectionStyle = (id: string): PathOptions => {
        const classification = this.props.inspectionClassificationOverview.find(s => s.id === id);
        const color = InspectionHelper.colorForClassification(classification && classification.classification);
        return {
            color,
            lineCap: "butt",
            weight: 10,
        };
    }

    private getInspectionStyleActive = (): PathOptions => {
        return {
            color: Constants.colorForegroundM1blue,
            lineCap: "butt",
            weight: 10,
        };
    }

    private onInspectionClick = (id: string) => {
        if (this.props.onClick) {
            this.props.onClick(id);
        }
    }

    private getInspectionTooltip = (id: string) => {
        const classification = this.props.inspectionClassificationOverview.find(s => s.id === id);
        const name = (classification && classification.name) || t("labelUnknown");
        const classificationValue = (classification && classification.classification) || t("labelUnknown");
        const remark = (classification && classification.remark) || t("labelUnknown");

        return `Structure: ${name}<br/>Classification: ${classificationValue}<br/>Remark: ${remark}`;
    }
}

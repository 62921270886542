import * as React from "react";
import CatalogBase, {
    ICatalogBaseProps,
    ICatalogBaseState,
} from "./CatalogBase";
import CatalogItem, { ICatalogItemProps } from "./CatalogItem";


interface IProps extends ICatalogBaseProps {
    items: ICatalogItemProps[];
}


export default class CatalogLayerGroupSingleChoice extends CatalogBase<IProps, ICatalogBaseState> {    

    public getInitialState(): ICatalogBaseState {
        const initialState = super.getInitialState();
        initialState.appearance = this.props.appearance || "subtle";
        return initialState;
    }

    public renderChildren = () => {
        const items: any = [];

        const itemsSorted = this.props.items.sort(
            (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
        );

        itemsSorted.forEach(item => {
            items.push(
                <CatalogItem
                    key={item.id || item.name}
                    name={item.name}
                    id={item.id}
                    onClick={this.getHandleSelectMethod(item)}
                    isHidden={item.isHidden}
                    onVisibilityToggle={this.getVisibilityToggleMethod(item)}
                    testId={item.testId}
                />
        )});

        return items
    };

    public getVisibilityToggleMethod = (item: ICatalogItemProps) => {
        return () => {
            if(item.isHidden){
                this.props.items.forEach(i => {
                    if(!i.isHidden){
                        if(i.onVisibilityToggle){
                            i.onVisibilityToggle();
                        }

                    }
                });
                if(item.onVisibilityToggle){
                    item.onVisibilityToggle();
                }
            }
        }
    };

    private getHandleSelectMethod = (item: ICatalogItemProps) => {
        return (event: any) => {
            event.stopPropagation();
            this.getVisibilityToggleMethod(item)();
        }
    };
}

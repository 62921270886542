import * as L from "leaflet";
import ILayerStyle from "./ILayerStyle";

export default class BlockDefinitionStyle implements ILayerStyle {
    public getOptions = (): L.GeoJSONOptions => {
        return {
            style: () => {
                return {
                    color: "green",
                    fillOpacity: 0,
                    interactive: false,
                };
            },
        }
    };
}
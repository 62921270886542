import * as H from 'history';
import AuthService from '../services/AuthService';
import axios from 'axios';
import FieldBrowserSupport from '../helpers/FieldBrowserSupport';
import FlagService from '../services/FlagService';
import Logger from '../services/Logger';
import Modal from '@atlaskit/modal-dialog';
import React from 'react';
import Routes from '../helpers/Routes';
import TextBoxLabeled from '../components/labeled/TextBoxLabeled';
import { t } from 'i18next';
import { WithNamespaces, withNamespaces } from 'react-i18next';


export interface ILoginModalProps extends WithNamespaces {
    location: H.Location;
    onLogin?: () => void;
}

export interface ILoginModalState {
    userName?: string;
    password?: string;
    showModal: boolean;
    responseReady?: boolean;
}

class LoginModal extends React.Component<ILoginModalProps, ILoginModalState> {
    constructor(props: ILoginModalProps) {
        super(props);
        this.state = {
            userName: undefined,
            password: undefined,
            showModal: false,
            responseReady: false,
        };

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            Logger.info("Failed request registered");
            return new Promise((resolve, reject) => {
                if (!FieldBrowserSupport.isOfflineMode() && error.response && error.response.status === 401 && this.props.location.pathname !== Routes.login.path) {
                    this.setState(
                        { showModal: true },
                        async () => {
                            const timeout = async (ms: number) => {
                                return new Promise(res => setTimeout(res, ms))
                            };
                            while (this.state.responseReady === false) { await timeout(250) }

                            await this.signIn();
                            resolve(this.runFailedRequest(error.config));
                        }
                    );
                }
                else {
                    reject(error)
                }
            })
        });
    }

    public render() {
        const { password, userName, showModal } = this.state;


        return (
            <React.Fragment>
                {showModal &&  <Modal
                    heading={t("labelLoginExpired")}
                    actions={[{
                        id: "login-button",
                        onClick: this.onLogin,
                        text: t("buttonSignIn"),
                        autoFocus: true,
                    }
                    ]}
                    scrollBehavior={"outside"}
                    width={400}
                >
                    <form className="modalForm" onSubmit={this.onLogin}>
                        Login again to keep working:
                        <TextBoxLabeled
                            id="userName"
                            label={t("labelUserName")}
                            placeholder={t("labelUserName")}
                            required={true}
                            value={userName}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ userName: value })}
                            testId = {"User-name-textbox"}
                        />
                        <TextBoxLabeled
                            id="password"
                            label={t("labelPassword")}
                            placeholder={t("labelPassword")}
                            required={true}
                            value={password}
                            // tslint:disable-next-line:jsx-no-lambda
                            onChange={value => this.setState({ password: value })}
                            isPassword={true}
                            testId = {"Password-textbox"}
                        />
                    </form>
                </Modal>}
            </React.Fragment>
        );
    }

    private onLogin = async (event: any): Promise<void> => {
        event.preventDefault();
        this.setState({
            responseReady: true,
        })
    };

    private signIn = async (): Promise<any> => {
        const { userName, password } = this.state;

        this.setState({
            showModal: false,
            responseReady: false,
            userName: undefined,
            password: undefined,

        });

        if (!userName || !password) {
            FlagService.clearFlags();
            FlagService.addError(t("labelLoginFailed"), t("textLoginFailed"), "login")
        }
        else {
            if (await AuthService.signIn(userName, password)) {
                FlagService.clearFlags();
            } else {
                FlagService.clearFlags();
                FlagService.addError(t("labelLoginFailed"), t("textLoginFailed"), "login")
            }
        }
    };

    private runFailedRequest = async (config: any): Promise<any> => {
        try {
            Logger.info("Repeating failed request: " + config);
            return await AuthService.request(config);
        } catch (error) {
            throw error;
        }
    };
}

export default withNamespaces()(LoginModal)

import Proj4Helper from "../helpers/Proj4Helper";
import { t } from "i18next";
import { PathOptions } from 'leaflet';
import * as React from 'react';
import InspectionClassificationDetails from '../dto/components/InspectionClassificationDetails';
import StructureSummary from '../dto/components/StructureSummary';
import OverviewMap from "../graphics/OverviewMap";
import Constants from '../helpers/Constants';
import InspectionHelper from '../helpers/InspectionHelper';

export interface IInspectionStructureOverviewMapProps {
    mapProvider: string;
    structure: StructureSummary;
    inspectionClassificationDetails: InspectionClassificationDetails;
}

export default class InspectionStructureOverviewMap extends React.Component<IInspectionStructureOverviewMapProps, {}> {
    private map: OverviewMap;

    public render() {
        return (
            <div id="inspectionstructureoverviewmap" style={{ height: "100%" }} />
        );
    }

    public componentDidMount() {
        this.createMap();
    }

    public componentDidUpdate(prevProps: IInspectionStructureOverviewMapProps) {
        this.map.invalidateSize()
    }

    public componentWillUnmount(){
        this.map.map.remove();
    }

    private createMap = () => {
        const { mapProvider, structure, inspectionClassificationDetails } = this.props;

        this.map = new OverviewMap("inspectionstructureoverviewmap");
        this.map.setMapProvider(mapProvider);
        this.map.setStyleHandlers(`axis_${structure.id}`, this.getAxisStyle);

        try
        {
            this.map.addFeatureLayer(`axis_${structure.id}`, structure.mapData.geoJson, structure.mapData.proj4Definition, {
                excludeFromExtents: true,
            });
        }
        catch
        {
            this.map.addFeatureLayer(`axis_${structure.id}`, structure.mapData.geoJson,  Proj4Helper.createCustomProj4String(0,0,0,0), {
                excludeFromExtents: true,
            });
        }

        this.map.setStyleHandlers(`inspection_${inspectionClassificationDetails.id}`, this.getInspectionStyle);
        this.map.setTooltipHandler(`inspection_${inspectionClassificationDetails.id}`, this.getInspectionTooltip);
        
        try
        {
            this.map.addFeatureLayer(`inspection_${inspectionClassificationDetails.id}`, inspectionClassificationDetails.mapData.geoJson, inspectionClassificationDetails.mapData.proj4Definition);
        }
        catch
        {
            this.map.addFeatureLayer(`inspection_${inspectionClassificationDetails.id}`, inspectionClassificationDetails.mapData.geoJson, Proj4Helper.createCustomProj4String(0,0,0,0));
        }

        this.map.fitBounds();
    }

    private getAxisStyle = (): PathOptions => {
        return {
            color: Constants.colorForegroundM1blue,
            interactive: false,
            weight: 3,
        };
    }

    private getInspectionStyle = (id: string): PathOptions => {
        const feature = this.props.inspectionClassificationDetails.mapData.geoJson.features.find(f => f.id === id);
        const classificationValue = feature && feature.properties && feature.properties.classification;
        const color = InspectionHelper.colorForClassification(classificationValue);
        return {
            color,
            lineCap: "butt",
            weight: 10,
        };
    }

    private getInspectionTooltip = (id: string) => {
        const feature = this.props.inspectionClassificationDetails.mapData.geoJson.features.find(f => f.id === id);
        const name = (feature && feature.properties && feature.properties.name) || t("labelUnknown");
        const classificationValue = feature && feature.properties && feature.properties.classification;
        const remark = (feature && feature.properties && feature.properties.remark) || t("labelUnknown");

        return `Block: ${name}<br/>Classification: ${classificationValue}<br/>Remark: ${remark}`;
    }
}

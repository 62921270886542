import { Feature, FeatureCollection } from "geojson";
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import proj4 from "proj4";
import "proj4leaflet";
import Constants from "../helpers/Constants";
import MapProviderHelper from "../helpers/MapProviderHelper";

export default class OverviewMap {
    public readonly map: L.Map;
    private readonly tileLayers: Map<string, L.Layer>;
    private readonly featureLayers: Map<string, L.FeatureGroup>;
    private readonly featureLayersForExents: Map<string, L.FeatureGroup>;
    private readonly clickHandlers: Map<string, (id: string) => void>;
    private readonly tooltipHandlers: Map<string, (id: string) => string>;
    private readonly styleHandlers: Map<string, (id: string) => L.PathOptions>;
    private readonly styleActiveHandlers: Map<string, (id: string) => L.PathOptions>;

    constructor(leafletmapdiv: string) {
        this.tileLayers = new Map<string, L.Layer>();
        this.featureLayers = new Map<string, L.FeatureGroup>();
        this.featureLayersForExents = new Map<string, L.FeatureGroup>();
        this.clickHandlers = new Map<string, (id: string) => void>();
        this.tooltipHandlers = new Map<string, (id: string) => any>();
        this.styleHandlers = new Map<string, (id: string) => L.PathOptions>();
        this.styleActiveHandlers = new Map<string, (id: string) => L.PathOptions>();

        this.map = L.map(leafletmapdiv, {
            attributionControl: false,
            crs: L.CRS.EPSG3857,
        });

        L.control.scale().addTo(this.map);
        this.map.setView([0.0, 0.0], 1)
    }

    public invalidateSize() {
        this.map.invalidateSize()
    }

    public setMapProvider = (mapProvider: string) => {
        this.addMapProvider(mapProvider);
        this.tileLayers.forEach(l => {
            this.map.removeLayer(l);
        });
        const layer = this.tileLayers.get(mapProvider);
        if (layer) {
            this.map.addLayer(layer);
        }
    }

    public addFeatureLayer = (layerId: string, geojson: FeatureCollection, proj4Definition: string, options?: { initiallyHidden?: boolean, excludeFromExtents?: boolean }) => {
        if (geojson.features.length === 0 || !proj4Definition) {
            return;
        }
        const initiallyHidden = (options && options.initiallyHidden) ? true : false;
        const excludeFromExtents = (options && options.excludeFromExtents) ? true : false;

        proj4.defs("custom", proj4Definition);
        const geojsonOptions: L.GeoJSONOptions = {
            onEachFeature: (feature: Feature, layer: L.Layer) => {
                this.addClickHandler(layerId, layer);
                this.addTooltipHandler(layerId, layer);
                this.addActiveStyleHandlers(layerId, layer);
            },
            pointToLayer: (feature: Feature, latlng: L.LatLng) => {
                const layer = L.circleMarker(latlng, { radius: 3, interactive: false });
                (layer as any).feature = feature;
                featureGroup.addLayer(layer);
                return layer;
            },
            style: (feature: any) => {
                const style = this.styleHandlers.get(layerId);
                if (style) {
                    return style(feature.id);
                }
                // default style
                return {
                    color: Constants.colorForegroundM1blue,
                };
            },
        };

        const featureGroup = new L.FeatureGroup();
        featureGroup.addLayer((L as any).Proj.geoJson(geojson, geojsonOptions));
        this.featureLayers.set(layerId, featureGroup);

        if (!excludeFromExtents) {
            this.featureLayersForExents.set(layerId, featureGroup);
        }
        if (!initiallyHidden) {
            this.toggleFeatureLayer(layerId, true);
        }
    }

    public setClickHandler = (layerId: string, onClick: (id: string) => void) => {
        this.clickHandlers.set(layerId, onClick)
    }

    public setTooltipHandler = (layerId: string, onTooltip: (id: string) => string) => {
        this.tooltipHandlers.set(layerId, onTooltip)
    }

    public setStyleHandlers = (layerId: string, getStyle: (id: string) => L.PathOptions, getStyleActive?: (id: string) => L.PathOptions) => {
        this.styleHandlers.set(layerId, getStyle);
        this.styleActiveHandlers.set(layerId, getStyleActive ? getStyleActive : getStyle);
    }

    public toggleFeatureLayer = (id: string, show: boolean) => {
        const layer = this.featureLayers.get(id);
        if (layer) {
            if (show) {
                this.map.addLayer(layer);
            }
            else {
                this.map.removeLayer(layer);
            }
        }
    }

    public fitBounds = () => {
        const bounds = this.getBounds();;
        if (bounds && bounds.isValid()) {
            this.map.fitBounds(bounds);
        }
    }

    private addMapProvider = (mapProvider: string) => {
        if (!this.tileLayers.get(mapProvider)) {
            const urlTemplate = MapProviderHelper.urlTemplateForMapProvider(mapProvider);
            const tileLayer = L.tileLayer(urlTemplate);
            this.tileLayers.set(mapProvider, tileLayer);
        }
    }

    private getBounds = (): L.LatLngBounds => {
        let bounds: any;
        this.featureLayersForExents.forEach(featureGroup => {
            bounds = bounds ? bounds.extend(featureGroup.getBounds()) : featureGroup.getBounds();
        });
        if (!bounds) {
            this.featureLayers.forEach(featureGroup => {
                bounds = bounds ? bounds.extend(featureGroup.getBounds()) : featureGroup.getBounds();
            });
        }
        return bounds;
    }

    private addClickHandler = (layerId: string, layer: L.Layer) => {
        layer.on("click", (e: any) => {
            const callback = this.clickHandlers.get(layerId);
            if (callback && e.target.feature) {
                callback(e.target.feature.id);
            }
        });
    }

    private addTooltipHandler = (layerId: string, layer: L.Layer) => {
        const callback = this.tooltipHandlers.get(layerId);
        if (callback) {
            const options: L.TooltipOptions = {
                sticky: true,
            };
            layer.bindTooltip(l => {
                const feature = (l as any).feature;
                if (feature) {
                    return callback(feature.id);
                }
                return "";
            }, options);
        }
    }

    private addActiveStyleHandlers = (layerId: string, layer: L.Layer) => {
        layer.on("mouseover", (e: any) => {
            const callback = this.styleActiveHandlers.get(layerId);
            const feature = (layer as any).feature;
            if (callback && feature && e.target.setStyle) {
                e.target.setStyle(callback(feature.id));
            }
        });
        layer.on("mouseout", (e: any) => {
            const callback = this.styleHandlers.get(layerId);
            const feature = (layer as any).feature;
            if (callback && feature && e.target.setStyle) {
                e.target.setStyle(callback(feature.id));
            }
        });
    }
}

import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import PageTitleItem from "../components/PageTitleItem";
import Table, {
  ActionsTableColumn,
  CountBadgeTableColumn,
  LinkTableColumn,
  TableItemAction,
  TextTableColumn,
} from "../components/Table";
import AdministrationResourcesPageData
  from "../dto/pages/AdministrationResourcesPageData";
import ResourceSummary from "../dto/responses/ResourceSummary";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";

export interface IAdministrationResourcesPageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationResourcesPageState {
    data?: AdministrationResourcesPageData;
}

class AdministrationResourcesPage extends React.Component<IAdministrationResourcesPageProps, IAdministrationResourcesPageState> {
    constructor(props: IAdministrationResourcesPageProps) {
        super(props);
        this.state = {
        };
    }

    public render() {
        const { data } = this.state;
        const { accountId } = this.props.match.params;
        const resourceLinkUrlBase = AuthService.isAmbergAdmin() ? Routes.administrationAccountResource.patch(accountId) : Routes.resource;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleProjects")} testID={"admin-recourses-page-title"}>
                </PageTitleItem>
                <div className="tableContainer">
                    <Table
                        data={data && data.resources}
                        columns={[
                            new LinkTableColumn<ResourceSummary>(
                                t("labelName"),
                                item => item.name,
                                item => resourceLinkUrlBase.patch(item.id).path
                            ),
                            new TextTableColumn<ResourceSummary>(t("labelId"), item => item.id),
                            new TextTableColumn<ResourceSummary>(t("labelType"), item => item.type),
                            new TextTableColumn<ResourceSummary>(t("labelVisibility"), item => item.isPrivate ? t("labelPrivate") : t("labelAccount")),
                            new CountBadgeTableColumn<ResourceSummary>(t("labelUsers"), item => (item.users && item.users.length) || 0),]
                            .concat(AuthService.isAmbergAdmin() ? [
                                new ActionsTableColumn<ResourceSummary>(item => [
                                    new TableItemAction(t("buttonEdit"), this.onEditResource),
                                ]),] : [])}
                    />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const { accountId } = this.props.match.params;
            const dataUrl = AuthService.isAmbergAdmin() ? `administrationresources/${accountId}` : `resources`;
            const data = await AuthService.get<AdministrationResourcesPageData>(dataUrl);
            this.setState({ data });
            this.props.setLocationName(data.account.name);
        } catch (error) {
            //
        }
    }

    private onEditResource = (resource: ResourceSummary) => {
        const { accountId } = this.props.match.params;
        this.props.history.push(Routes.administrationAccountResource.patch(accountId, resource.id).path)
    }
}

export default compose(withNamespaces())(AdministrationResourcesPage)

import { useState } from 'react';

export interface ICookieContent {
    value: string,
    days?: number
}

// const isBrowser = typeof window !== 'undefined';

const getItem = (name: string, defaultValue: string): ICookieContent => {
    const value = document.cookie.split('; ').reduce((total, currentCookie) => {
        const item = currentCookie.split('=');
        const storedKey = item[0];
        const storedValue = item[1];

        return name === storedKey ? decodeURIComponent(storedValue) : total;
    }, '') || defaultValue;

    return {value};
}

const setItem = (key: string, content: ICookieContent) => {
    if(content.days === undefined) {
        document.cookie = `${key}=${content.value}; path=/`;
    }
    else {
        const now = new Date();   // set the time to be now + numberOfDays
        now.setTime(now.getTime() + (content.days * 60 * 60 * 24 * 1000));
        document.cookie = `${key}=${content.value}; expires=${now.toUTCString()}; path=/`;
    }
 };

const useCookie = (name: string, defaultVaue: string) => {
    const getCookie = () => getItem(name, defaultVaue);
    const [cookieContent, setCookie] = useState<ICookieContent>(getCookie());

    const updateCookieContent = (content: ICookieContent) => {
        setCookie(content);
        setItem(name, content);
    };

    return {cookieContent, updateCookieContent};
};

export { useCookie as default };
import AvatarAK from "@atlaskit/avatar";
import * as React from "react";
import Constants from "../../helpers/Constants";

export interface IAvatarProps {
    name?: string;
    size?: any;
    src?: string;
}

export default function Avatar(props: IAvatarProps) {
    return (
        <AvatarAK
            name={props.name}
            size={props.size}
            borderColor={Constants.borderUnselected}
            src={props.src}
        />
    );
}

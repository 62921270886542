import ButtonAK from "@atlaskit/button";
import Tooltip from "@atlaskit/tooltip";

import * as React from "react";

export interface IButtonProps {
    appearance?: string;
    children?: any;
    className?: string;
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
    id?: string;
    isDisabled?: boolean;
    isSelected?: boolean;
    label?: string;
    onClick?: (e: any) => void;
    shouldFitContainer?: boolean
    spacing?: string;
    type?: string;
    tooltip?: string;
    testId: string;
}

export default function Button(props: IButtonProps) {
    return (
        <Tooltip content={props.isDisabled ? undefined : props.tooltip}>
                <ButtonAK
                    appearance={props.appearance}
                    className={props.className}
                    label={props.label}
                    iconBefore={props.iconBefore}
                    iconAfter={props.iconAfter}
                    id={props.id}
                    isDisabled={props.isDisabled}
                    isSelected={props.isSelected}
                    onClick={props.onClick}
                    shouldFitContainer={props.shouldFitContainer}
                    spacing={props.spacing}
                    type={props.type}   
                    testId={props.testId}                 
                >
                    {props.children}
                </ButtonAK>
        </Tooltip>
    );
}

import Form from '@atlaskit/form'
import Spinner from '@atlaskit/spinner';
import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import PageTitleItem from '../components/PageTitleItem';
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import SingleSelectComboBoxLabeled from '../components/labeled/SingleSelectComboBoxLabeled';
import ScaleSettingLabeled from '../components/labeled/ScaleSettingLabeled';
import StructureMeasurementBasics from '../dto/components/StructureMeasurementBasics';
import InspectionAnalysisPageData from '../dto/pages/InspectionAnalysisPageData';
import IRouteParams from '../helpers/IRouteParams';
import { isNoneZeroPositiveDouble } from '../helpers/StringHelpers';
import AuthService from '../services/AuthService';
import Logger from '../services/Logger';

export interface IInspectionAnalysisSingleCadExportPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string, structureItemName: string) => void;
}

export interface IInspectionAnalysisSingleCadExportPageState {
    data?: InspectionAnalysisPageData;
    measurements: StructureMeasurementBasics[];
    measurementId?: string
    ReportStngScale?: string
    waiting: boolean
    waitingDownloading: boolean;
}

class InspectionAnalysisSingleCadExportPage extends React.Component<IInspectionAnalysisSingleCadExportPageProps, IInspectionAnalysisSingleCadExportPageState> {
    constructor(props: IInspectionAnalysisSingleCadExportPageProps) {
        super(props);
        this.state = {
            measurements: [],
            waiting: true,
            waitingDownloading: false,
        };
    }

    public render() {

        const { data, measurements, measurementId,ReportStngScale: stationingScale, waiting, waitingDownloading } = this.state;
        const measurementOptions = measurements.map(m => {
            return {
                label: m.name,
                value: m.id,
            };
        });
        const selectedMeasurementOption = measurementOptions.find(m => m.value === measurementId);

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleCadExport")}  testID={"inspection-analysis-single-cad-export-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>      
                {data && 
                <Form onSubmit={this.onDownload}>{
                    ({formProps}) => (
                        <form {...formProps}>
                            <SingleSelectComboBoxLabeled
                                label={t("labelMeasurement")}
                                placeholder={t("labelSelect")}
                                options={measurementOptions}
                                selectedOption={selectedMeasurementOption}
                                onChange={this.onMeasurementSelectionChange}
                            />
                            <ScaleSettingLabeled
                                label={t("labelScaleStationing")}
                                value={stationingScale}
                                step={0.1}
                                onChange ={value =>  this.setState({ReportStngScale: value})}
                                testId = {"Dxf-scale"}
                                validate = { isNoneZeroPositiveDouble }
                                invalid = {t("validatorNumberBoxMin", { minimum: 0.0 })}
                            />
                            <ButtonLabeled
                                type="submit"
                                isDisabled={waitingDownloading}
                                testId={"download-button"}
                            >
                                {t("buttonDownload")}
                                {waitingDownloading && (
                                    <span style={{ display: "inline", margin: "5px" }}> <Spinner size={"small"} /></span>
                                )}
                            </ButtonLabeled>
                        </form>
                    )}
                </Form>}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/data`);
            this.setState({
                data: new InspectionAnalysisPageData(data),
                measurements: data.measurements,
                ReportStngScale: data.reportStngScale.toString(),
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            this.setState({waiting:false,})
        }
    }

    private onMeasurementSelectionChange = (option?: ISSelectOption) => {
        const measurementId = option && option.value;
        this.setState({ measurementId });
    }

    private onDownload = async (event: any) => {
        if (!this.validate()) {
            return;
        }

        const { data, measurementId, ReportStngScale: stationingScale } = this.state;
        if (!data || !measurementId) {
            return;
        }
        if (!stationingScale || parseFloat(stationingScale)<=0)
        {
            return;
        }

        try {
            this.setState({waitingDownloading: true});
            await AuthService.downloadFile(`inspectionanalysis/${data.inspectionAnalysis.project.id}/${data.inspectionAnalysis.structure.id}/${measurementId}/${stationingScale}/downloaddxf`, "inspection.dxf");
        } catch (error) {
            Logger.error(error, "InspectionAnalysisSingleCadExportPage");
            this.setState({waitingDownloading: false});
        }
        this.setState({waitingDownloading: false});
    }

    private validate = (): boolean => {
        const { data, measurementId } = this.state;
        if (!data) {
            return false;
        }
        if (!measurementId) {
            return false;
        }
        return true;
    }
}

export default compose(withNamespaces())(InspectionAnalysisSingleCadExportPage)

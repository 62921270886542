import { t } from 'i18next';

export default class LicenseHelper {
    public static cloudAdmin = "Amberg Inspection Cloud Admin";
    public static cloudEditor = "Amberg Inspection Cloud Editor";
    public static cloudInspector = "Amberg Inspection Cloud Inspector";
    public static cloudViewer = "Amberg Inspection Cloud Viewer";
    public static cloudDeepLearning =  "Amberg Inspection Cloud Deep Learning";

    public static supportedLicenses = (): string[] => {
        return [
            "Amberg Navigator Cloud Basic",
            "Amberg Navigator Cloud Profile",
            "Amberg Navigator Cloud Tunnelscan",
            "Amberg Navigator Cloud Geotechnics",
            LicenseHelper.cloudAdmin,
            LicenseHelper.cloudEditor,
            LicenseHelper.cloudInspector,
            LicenseHelper.cloudViewer,
            // LicenseHelper.cloudDeepLearning,
        ];
    }

    public static descriptionForLicense = (license: string): string => {
        switch (license) {
            case "Amberg Navigator Cloud Basic": return "22018";
            case "Amberg Navigator Cloud Profile": return "22019";
            case "Amberg Navigator Cloud Tunnelscan": return "22020";
            case "Amberg Navigator Cloud Geotechnics": return "22021";
            case LicenseHelper.cloudAdmin: return "22262";
            case LicenseHelper.cloudEditor: return "22263";
            case LicenseHelper.cloudInspector: return "22264";
            case LicenseHelper.cloudViewer: return "22180";
            // case LicenseHelper.cloudDeepLearning: return "22265";
            default: return "";
        }
    }

    public static labelForLicense = (license: string): string => {
        switch (license) {
            case LicenseHelper.cloudAdmin: return t("labelLicenseCloudAdmin");
            case LicenseHelper.cloudEditor: return t("labelLicenseCloudEditor");
            case LicenseHelper.cloudInspector: return t("labelLicenseCloudInspector");
            case LicenseHelper.cloudViewer: return t("labelLicenseCloudViewer");
            case LicenseHelper.cloudDeepLearning: return t("labelNotApplicable"); //t("labelLicenseCloudDeepLearning");
            default: return license;
        }
    }

    public static isAssignableToUser = (license: string): boolean => {
        switch (license) {
            case LicenseHelper.cloudAdmin:
            case LicenseHelper.cloudEditor:
            case LicenseHelper.cloudInspector:
            case LicenseHelper.cloudViewer:
                return true;
            case LicenseHelper.cloudDeepLearning:
            default: return false;
        }
    }
}
import InspectionTaskSummary from 'dto/components/InspectionTaskSummary';
import CatalogData from '../components/CatalogData';
import InspectionAnalysisSummary from '../components/InspectionAnalysisSummary';
import InspectionClassification from '../components/InspectionClassification';
import InspectionDrawing from '../components/InspectionDrawing';
import InspectionTileLayer from '../components/InspectionTileLayer';
import StructureMeasurementBasics from '../components/StructureMeasurementBasics';
import MapData from '../maps/MapData';

export default class InspectionAnalysisMapsPageData {
    public inspectionAnalysis: InspectionAnalysisSummary;
    public measurements: StructureMeasurementBasics[];
    public catalog: CatalogData;
    public theoreticalSectionsMapData: MapData;
    public blockDefinitionMapData: MapData;
    public sectorDefinitionMapData: MapData;
    public stationingDefinitionMapData: MapData;
    public tileLayers: InspectionTileLayer[];
    public inspectionDrawings: InspectionDrawing[];
    public inspectionClassifications: InspectionClassification[];
    public inspectionSummaries: InspectionTaskSummary[];
    public proj4Definition: string;

    constructor (data: InspectionAnalysisMapsPageData) {
        this.inspectionAnalysis= data.inspectionAnalysis;
        this.measurements= data.measurements;
        this.catalog = new CatalogData(data.catalog);
        this.theoreticalSectionsMapData= data.theoreticalSectionsMapData;
        this.blockDefinitionMapData= data.blockDefinitionMapData;
        this.sectorDefinitionMapData= data.sectorDefinitionMapData;
        this.stationingDefinitionMapData= data.stationingDefinitionMapData;
        this.tileLayers= data.tileLayers;
        this.inspectionDrawings= data.inspectionDrawings;
        this.inspectionClassifications= data.inspectionClassifications;
        this.inspectionSummaries= data.inspectionSummaries;
        this.proj4Definition= data.proj4Definition;
    }  
}

import * as React from "react";
import CatalogHeading from "./CatalogHeading";


export type Appearance = "subtle" | "primary"


export interface ICatalogBaseProps {
    appearance?: Appearance;
    isExpanded?: boolean;
    marginLeft?: number;
    marginRight?: number;
    name?: string; 
    testId?: string;
}


export interface ICatalogBaseState {
    appearance?: Appearance;
    isExpanded: boolean,
    marginLeft?: number;
    marginRight?: number;
    name: string;
}


export default class CatalogBase<P extends ICatalogBaseProps, S extends ICatalogBaseState> extends React.Component<P, S> {
    constructor(props: P) {
        super(props);
        this.state = this.getInitialState();
    }

    public getInitialState(): S { return {
        appearance: this.props.appearance || "primary",
        isExpanded: this.props.isExpanded || false,
        marginLeft: this.props.marginLeft !== undefined ? this.props.marginLeft : 8,
        marginRight: this.props.marginRight !== undefined ? this.props.marginLeft : 8,
        name: this.props.name || ""
    } as S }

    public render() {
        const { testId } = this.props;
        const { appearance, isExpanded, marginLeft, marginRight, name } = this.state;

        return (
            <div style={{ width: "100%"}}>
            <div style={{marginRight}} data-testid={testId}>
                <CatalogHeading
                    name={name}
                    isExpanded={isExpanded}
                    onClick={this.toggleExpand}
                    iconAfter={this.renderTools()}
                    appearance={appearance}
                />
                {isExpanded &&
                <div style={{ marginLeft}}>
                    {this.renderChildren()}
                </div>
                }
            </div>
            </div>
        );
    };

    public toggleExpand = () => {
        this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
    };

    public renderChildren = () => {
        return <React.Fragment/>
    };

    public renderTools = (): any => {
        return undefined
    };
}
export default class Route {
  public exact: boolean;
  public path: string;
  public policy?: string;

  constructor(
    { path, policy, exact = true, route }:
      {
        path: string,
        policy?: string,
        exact?: boolean,
        route?: Route,
      }
  ){
    this.exact = exact;
    this.path = path;
    if (route) {
      this.policy = policy || route.policy;
      this.path = route.path + path;
    }
    if (policy) {
      this.policy = policy
    }
  }

  /**
   * Used to replace the placeholders in the paths with actual values supplied as args.
   * Will return a Route object.
   * @param args - strings to replace the placeholders with (in order of appearance)
   */
  public patch(...args: string[]) {
    let patchedPath = this.path.slice(0);
    for (const arg of args){
      patchedPath = patchedPath.replace(/:.[^/]*/, arg)
    }
    return new Route({
      path: patchedPath,
      policy: this.policy,
      exact: this.exact,
    })
  }
}
import TextArea from "@atlaskit/textarea";
import { Field } from "@atlaskit/form";
//import { t } from "i18next";
import React from "react";

export interface IAtTextArea {
  label?: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  //validate?: (value: string) => boolean;
  minimumRows?: number;
}

export default function AtTextArea(props: IAtTextArea) {
  return (
      <Field
        name={"textArea"}
        isRequired={props.required || false}
        /*
        validators={
          props.validate && [
            <Validator
              key="validator"
              func={props.validate}
              invalid={t("labelInvalid")}
              valid={t("labelOk")}
            />,
          ]
        }
        */
      >
        {() => (
          <TextArea
            placeholder={props.placeholder || ""}
            value={props.value}
            isDisabled={props.disabled || false}
            onChange={(event: any) =>
              props.onChange && props.onChange(event.target.value)
            }
            minimumRows={props.minimumRows || undefined}
          />
        )}
      </Field>
  );
}

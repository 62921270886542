export default class BackupFrequencyHelper {
    public static Never = "never";
    public static Daily = "daily";
    public static Weekly = "weekly";
    public static Monthly = "monthly";
    public static Yearly = "yearly";

    public static backupFrequencies = (): Map<string, string> => {
        return new Map([
            [BackupFrequencyHelper.Never, "Never"],
            [BackupFrequencyHelper.Daily, "Daily"],
            [BackupFrequencyHelper.Weekly, "Weekly"],
            [BackupFrequencyHelper.Monthly, "Monthly"],
            [BackupFrequencyHelper.Yearly, "Yearly"]
        ]);
    }
}
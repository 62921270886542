import Spinner from "@atlaskit/spinner";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import InspectionMapBase, {
    IInspectionMapBaseProps,
} from "../components/InspectionMapBase";
import InspectionMapView from "../components/InspectionMapView";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import CatalogLayerAnalysis from "../components/toolbar/CatalogLayerAnalysis";
import InspectionAnalysisMapsPageData
    from "../dto/pages/InspectionAnalysisMapsPageData";
import AuthService from "../services/AuthService";
import MapPageBase, { IMapPageBaseProps, IMapPageBaseState } from "./MapPageBase";
import InspectionPageData from "../dto/pages/InspectionPageData";
import InspectionAnalysisControlsButtons from "../components/toolbar/InspectionAnalysisControlsButtons";
import CreateInspectionPdfModal from "../modals/CreateInspectionPdfModal";


export interface IInspectionAnalysisSingleMapPageState extends IMapPageBaseState {
    data?: InspectionAnalysisMapsPageData;
    showPdfPreview: boolean;
}

class InspectionAnalysisSingleMapPage extends MapPageBase<IMapPageBaseProps, IInspectionAnalysisSingleMapPageState> {
    public map: InspectionMapBase<IInspectionMapBaseProps, {}> | null;
    public printMap: InspectionMapView<IInspectionMapBaseProps, {}> | null;

    public doSomeThing = () => {
        const {showPdfPreview} = this.state;
        this.setState({ showPdfPreview : !showPdfPreview });
    }

    public render() {
        const {
            blockDefinitionSelected,
            classificationSelected,
            data,
            inspectionDrawingId,
            hiddenFeatureTypeCodes,
            sectorDefinitionSelected,
            textHiddenFeatureTypeCodes,
            theoreticalSectionsSelected,
            waiting,
            showPdfPreview,
            tileLayerId,
        } = this.state;

        const inspectionClassification = data && data.inspectionClassifications.find(l => l.id === inspectionDrawingId);

        return (
            <div className="pageMap">
                <div>
                    { waiting && (
                        <Spinner size={"large"}/>
                    )}
                </div>
                {
                    showPdfPreview && this.renderCreateInspectionPdfModal()
                }
                {
                    <div className="pageMaximized">
                        {data && 
                        <div className="mapWithStationingSlider" style={{overflow:"hidden"}}>
                            {<InspectionMapBase
                                blockDefinition={data.blockDefinitionMapData}
                                blockDefinitionSelected={blockDefinitionSelected}
                                catalog={data.catalog}
                                classification={inspectionClassification && inspectionClassification.mapData}
                                classificationSelected={classificationSelected}
                                hideSectorSelection={true}
                                hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                                inspectionDrawing={this.getDrawing(data.inspectionDrawings)}
                                ref={instance => this.map = instance}
                                sectorDefinition={data.sectorDefinitionMapData}
                                sectorDefinitionSelected={sectorDefinitionSelected}
                                selectedTileLayerId={tileLayerId}
                                textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                                theoreticalSections={data.theoreticalSectionsMapData}
                                theoreticalSectionsSelected={theoreticalSectionsSelected}
                                tileLayers={data.tileLayers}
                                initialPosition = {{id : this.props.match.params.id, value: this.props.match.params.value}}
                            />}
                        </div>
                        }
                        {data &&
                        <div className="sideBar">
                            <div style={{ flex: "1 1 1", width: "100%" }}>
                                <InspectionAnalysisControlsButtons 
                                    onCreatePdfReport={this.doSomeThing} 
                                    allowPdfReport={!showPdfPreview}
                                />
                            </div>
                            <div
                                style={{ flex: "1 1 auto", width: "100%", height: "100%" }}>
                                <Scrollbar>
                                    <CatalogLayerAnalysis
                                        blockDefinitionSelected={blockDefinitionSelected}
                                        classificationDataSelected={classificationSelected}
                                        inspectionDrawings={data.inspectionDrawings}
                                        name={t("buttonLayers")}
                                        onBlockDefinitionSelectionChange={this.onBlockDefinitionSelectionChange}
                                        onClassificationDataSelectionChange={this.onClassificationSelectionChange}
                                        onInspectionDrawingSelectionChange={this.onInspectionDrawingSelectionChange}
                                        onSectorDefinitionSelectionChange={this.onSectorDefinitionSelectionChange}
                                        onTheoreticalSectionsSelectionChange={this.onTheoreticalSectionsSelectionChange}
                                        onTileLayerSelectionChange={this.onTileLayerSelectionChange}
                                        sectorDefinitionSelected={sectorDefinitionSelected}
                                        selectedInspectionDrawingId={inspectionDrawingId}
                                        selectedTileLayerId={tileLayerId}
                                        testId={"layer-catalog"}
                                        theoreticalSectionsSelected={theoreticalSectionsSelected}
                                        tileLayers={data.tileLayers}
                                    />
                                    <Separator/>
                                    <CatalogFeatureType
                                        catalog={data.catalog}
                                        onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                        onTextVisibilityChange={this.onFeatureTypeTextVisibilityChange}
                                        testId={"feature-type-catalog"}
                                    />
                                </Scrollbar>
                            </div>
                        </div>
                        }
                    </div>
                }
                {
                <div style={{position:'absolute', left:'-10000px', overflow:'hidden'}}>
                    <div className='hiddenInspection' style={{overflow:'scroll', width:'2284px', height:'1240px'}}>
                        {data &&
                        <InspectionMapView
                            blockDefinition={data.blockDefinitionMapData}
                            blockDefinitionSelected={blockDefinitionSelected}
                            catalog={data.catalog}
                            classification={inspectionClassification && inspectionClassification.mapData}
                            classificationSelected={classificationSelected}
                            hideSectorSelection={true}
                            hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                            inspectionDrawing={this.getDrawing(data.inspectionDrawings)}
                            ref={instance => this.printMap = instance}
                            sectorDefinition={data.sectorDefinitionMapData}
                            sectorDefinitionSelected={sectorDefinitionSelected}
                            selectedTileLayerId={tileLayerId}
                            textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                            theoreticalSections={data.theoreticalSectionsMapData}
                            theoreticalSectionsSelected={theoreticalSectionsSelected}
                            tileLayers={data.tileLayers}
                            initialPosition = {{id : this.props.match.params.id, value: this.props.match.params.value}}
                        />}
                    </div>

                </div>
                }
            </div>
        );
    }

    public loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisMapsPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/mapdata`);
            const info = await AuthService.get<InspectionPageData>(`inspection/${this.props.match.params.projectId}/${this.props.match.params.structureId}/data`);

            data.inspectionSummaries = info.inspectionTasks;

            this.setState({
                data: new InspectionAnalysisMapsPageData(data),
                tileLayerId: data.tileLayers[0].id,
                inspectionDrawingId: data.inspectionDrawings[0].id,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            this.setState({waiting:false,})
        }
    };

    private renderCreateInspectionPdfModal = () => {
        const { data, inspectionDrawingId, tileLayerId } = this.state;
        const { accountId } = this.props.match.params;
        const createUrl = AuthService.isAmbergAdmin() ? `administrationusers/${accountId}/createuser` : `users/createuser`;

        return (
            <CreateInspectionPdfModal
                createUrl={createUrl}
                leafletMap={this.printMap?.map}
                inspectionDrawingId={inspectionDrawingId}
                inspectionData={data}
                tileLayerId={tileLayerId}
                onOk={() => {this.setState({showPdfPreview: false})}}
                onCancel={() => {this.setState({showPdfPreview: false})}}
            />
        );
    }
}

export default compose(withNamespaces())(InspectionAnalysisSingleMapPage);

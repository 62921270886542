import Badge from '@atlaskit/badge';
import * as React from 'react';
import Constants from '../helpers/Constants';

export interface IBadgeWithNumberProps {
    value?: number;
}

export default function BadgeWithNumber(props: IBadgeWithNumberProps) {
    if (props.value === undefined) {
        return (<div />);
    }
    return (
        <Badge            
            appearance={props.value ? { backgroundColor: Constants.colorForegroundM1blue, textColor: Constants.colorForegroundM3white } : "default"}
        >
            {props.value}
        </Badge>
    );
}

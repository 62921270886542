import Form from '@atlaskit/form';
import Modal from "@atlaskit/modal-dialog";
import { t } from "i18next";
import React from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import CreateAccountRequest from "../dto/requests/CreateAccountRequest";
import CreateUserRequest from "../dto/requests/CreateUserRequest";
import AccountDetails from "../dto/responses/AccountDetails";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";

export interface ICreateAccountModalProps extends WithNamespaces {
    onCancel: () => void;
    onOk: () => void;
}

export interface ICreateAccountModalState {
    companyName: string;
    referenceId: string;
    adminLoginName: string;
    adminFullName: string;
    adminPassword: string;
}

class CreateAccountModal extends React.Component<ICreateAccountModalProps, ICreateAccountModalState> {
    constructor(props: ICreateAccountModalProps) {
        super(props);
        this.state = {
            companyName: "",
            referenceId: "",
            adminLoginName: "",
            adminFullName: "",
            adminPassword: "",
        }
    }

    public render() {
        const { companyName, referenceId, adminLoginName, adminFullName, adminPassword } = this.state;

        return (
            <Modal
                heading={t("modalTitleCreateAccount")}
                actions={[{
                    onClick: this.onSave,
                    text: t("buttonCreate"),
                }, {
                    onClick: this.props.onCancel,
                    text: t("buttonCancel"),
                }]}
                onClose={this.props.onCancel} 
            >
                <Form onSubmit={this.onSave}>
                    {({formProps})=> (
                        <form {...formProps} className="modalForm">
                            <TextBoxLabeled
                                label={t("labelCompanyName")}
                                placeholder={t("labelCompanyName")}
                                value={companyName}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ companyName: value })}
                                testId = {"company-name-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelCustomerReference")}
                                placeholder={t("labelCustomerReference")}
                                value={referenceId}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ referenceId: value })}
                                testId = {"customer-reference-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelUserName")}
                                placeholder={t("labelEMail")}
                                required={true}
                                value={adminLoginName}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ adminLoginName: value })}
                                // tslint:disable-next-line:jsx-no-lambda
                                validate={value => value !== ""}
                                invalid={t("textInvalidEmptyField")}
                                testId = {"user-name-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelPassword")}
                                placeholder={t("labelPassword")}
                                required={true}
                                value={adminPassword}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ adminPassword: value })}
                                // tslint:disable-next-line:jsx-no-lambda
                                validate={value => value !== ""}
                                invalid={t("textInvalidEmptyField")}
                                isPassword={true}
                                testId = {"user-password-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelFullName")}
                                placeholder={t("labelFullName")}
                                value={adminFullName}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ adminFullName: value })}
                                testId = {"full-name-textbox"}
                            />
                        </form>
                    )}
                </Form>
            </Modal>
        );
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }
        const { companyName, referenceId, adminLoginName, adminFullName, adminPassword } = this.state;
        const data = referenceId ? [{ id: "referenceId", name: referenceId }] : [];
        const createAccountRequest: CreateAccountRequest = {
            data,
            name : companyName,
        };
        const request: CreateUserRequest = {
            groups: [],
            loginName: adminLoginName,
            name: adminFullName,
            password: adminPassword,
            resources: [],
            roles: [],
        };

        try {
            const theAccounts = await AuthService.post<AccountDetails>(`administrationaccounts`, createAccountRequest);
            const accountId = theAccounts.id ;
            await AuthService.post(`administrationusers/${accountId}/createuser`, request);
            FlagService.addInfo(t("labelCreated"), t("textCreated", {what:t("labelAccount"), name:companyName}));
            this.props.onOk();
        } catch (error) {
            Logger.error(error, "CreateAccountModal");
            FlagService.addError(t("textCreationFailed", {what:t("labelAccount"), name:companyName}), error);
        }
    }

    private validate = (): boolean => {
        const { companyName, adminLoginName, adminFullName, adminPassword } = this.state;
        if (!companyName) {
            return false;
        }
        if (!adminLoginName) {
            return false;
        }
        if (!adminFullName) {
            return false;
        }
        if (!adminPassword) {
            return false;
        }
        return true;
    }
}

export default withNamespaces()(CreateAccountModal)

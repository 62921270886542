import * as React from 'react';
import AuthService from '../services/AuthService';
import BlockDefinitionStyle from '../graphics/styles/BlockDefinitionStyle';
import ClassificationStyle from '../graphics/styles/ClassificationStyle';
import FeatureLockedStyle from '../graphics/styles/FeatureLockedStyle';
import FeatureStyle from '../graphics/styles/FeatureStyle';
import Guid from '../helpers/Guid';
import InspectionFeatureGroup from '../graphics/InspectionFeatureGroup';
import InspectionMapBasic from '../graphics/InspectionMapBasic';
import InspectionTileLayer from '../dto/components/InspectionTileLayer';
import MapGrid from '../graphics/grids/MapGrid';
import SectorDefinitionStyle from '../graphics/styles/SectorDefinitionStyle';
import TheoreticalSectionStyle from '../graphics/styles/TheoreticalSectionStyle';
import TooltipHandler from '../graphics/TooltipHandler';
import { GeometryType } from '../dto/components/GeometryType';
import { IInspectionFeature } from '../graphics/FeatureLayer';
import { IInspectionMapBaseProps } from './InspectionMapBase';


export default class InspectionMapView<P extends IInspectionMapBaseProps, S extends {}> extends React.Component<P, S> {
    public map: InspectionMapBasic;
    public mapContainerId: string = Guid.newGuid();
    
    public render() {
        const { zIndex } = this.props;

        return (
            <div
                id={this.mapContainerId}
                className="map"
                style={{zIndex, width: "100%", height: "100%"}}
            >
                <div style={{
                    width: "calc(100% - 20px)",
                    height: "calc(100% - 20px)",
                    padding: "10px 10px 10px 10px",
                }}>
                </div>
                <div className="oneSquareMM"
                    style={{width: "1mm", height: "1mm"}}>
                </div>
            </div>
        );
    }

    public componentDidMount() {
        this.createMap();
        this.initializeMap(this.props);
        this.updateMap(this.props);
    }

    public componentDidUpdate(prevProps: P, prevState: S) {
        this.map.invalidateSize();

        if(prevProps.inspectionDrawing !== this.props.inspectionDrawing){
            this.exchangeMap(this.props)
        }
        this.updateMap(this.props, prevProps);
    }

    public componentWillUnmount(){
        this.map.innerMap.remove();
    }

    public createMap(){
        this.map = new InspectionMapBasic(this.mapContainerId, AuthService.getServiceUrl(), new MapGrid(undefined, undefined, true), true);
    };

    public initializeMap(props: IInspectionMapBaseProps){
        if (!this.map) {
            return;
        }

        const {
            blockDefinition,
            catalog,
            classification,
            inspectionDrawing,
            sectorDefinition,
            selectedTileLayerId,
            theoreticalSections,
            tileLayers,
        } = props;

        const areaPane = this.map.innerMap.createPane(GeometryType.Area);
        areaPane.style.zIndex = "397";
        const linePane = this.map.innerMap.createPane(GeometryType.Line);
        linePane.style.zIndex = "398";
        const pointPane = this.map.innerMap.createPane(GeometryType.Point);
        pointPane.style.zIndex = "399";

        this.updateTileLayer(tileLayers, selectedTileLayerId);

        if (inspectionDrawing) {
            const allFeatureTypes = catalog.groups.map(l => l.featureTypes).reduce((a, b) => a.concat(b), []);

            allFeatureTypes.forEach(featureType => {
                const featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(featureType.code);

                const featureGroup = this.map.addFeatureGroup(
                    featureGroupId,
                    new FeatureStyle({
                        map: this.map.innerMap,
                        interactive: true,
                        featureType,
                        pane:featureType.type
                    }),
                    new FeatureLockedStyle(featureType.type),
                );
                featureGroup.addEventHandler(new TooltipHandler(featureType).addToLayer)
            });

            const randomFeatures = [];
            while (inspectionDrawing.geoJson.features.length) {
                const randomIndex = Math.floor(Math.random() * inspectionDrawing.geoJson.features.length)
                const element = inspectionDrawing.geoJson.features.splice(randomIndex, 1);
                randomFeatures.push(element[0]);
            }
            inspectionDrawing.geoJson.features = randomFeatures;

            randomFeatures.forEach((feature) => {
                const code = (feature as IInspectionFeature).properties.code;
                const featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(code);
                const featureGroup = this.map.featureGroups.get(featureGroupId);
                featureGroup.addData( (feature as any));
            });
        }
        if (classification) {
            const featureGroupId = `inspectionclassification`;
            const featureGroup = this.map.addFeatureGroup(featureGroupId, new ClassificationStyle());
            featureGroup.addData(classification.geoJson);
        }
        if (theoreticalSections) {
            const featureGroupId = `theoreticalsections`;
            const featureGroup = this.map.addFeatureGroup(featureGroupId, new TheoreticalSectionStyle());
            featureGroup.addData(theoreticalSections.geoJson);
        }
        if (blockDefinition) {
            const featureGroupId = `blockdefinition`;
            const featureGroup = this.map.addFeatureGroup(featureGroupId, new BlockDefinitionStyle());
            featureGroup.addData(blockDefinition.geoJson);
        }
        if (sectorDefinition) {
            const featureGroupId = `sectordefinition`;
            const featureGroup = this.map.addFeatureGroup(featureGroupId, new SectorDefinitionStyle());
            featureGroup.addData(sectorDefinition.geoJson);
        }
    };

    public updateTileLayer(layers: InspectionTileLayer[], selectedTileLayerId?: string)
    {
        if (!this.map || layers.length === 0) {
            return;
        }
        if(selectedTileLayerId){
            const selectedTileLayer = layers.find(l => l.id === selectedTileLayerId);
            if(selectedTileLayer){
                this.map.setTileLayer(selectedTileLayer && selectedTileLayer.urlTemplate, selectedTileLayer);
            }
        }
    }

    public updateMap(props: IInspectionMapBaseProps, prevProps?: IInspectionMapBaseProps){
        if (!this.map) {
            return;
        }

        const { catalog, inspectionDrawing, selectedTileLayerId, tileLayers, hiddenFeatureTypeCodes, textHiddenFeatureTypeCodes, classification, classificationSelected, theoreticalSections, theoreticalSectionsSelected, blockDefinition, blockDefinitionSelected, sectorDefinition, sectorDefinitionSelected } = props;

        let tileLayerChanged: boolean = true;
        let inspectionDrawingChanged: boolean = true;

        if(prevProps){
            tileLayerChanged = selectedTileLayerId !== prevProps.selectedTileLayerId;
            inspectionDrawingChanged = (
                inspectionDrawing !== prevProps.inspectionDrawing ||
                hiddenFeatureTypeCodes !== prevProps.hiddenFeatureTypeCodes ||
                textHiddenFeatureTypeCodes !== prevProps.textHiddenFeatureTypeCodes
            );
        }

        if(tileLayerChanged){
            this.updateTileLayer(tileLayers, selectedTileLayerId)
        }

        if (inspectionDrawing && inspectionDrawingChanged) {
            if (inspectionDrawing && inspectionDrawingChanged) {
                const allCodesInCatalog = catalog.groups.map(l => l.featureTypes).reduce((a, b) => a.concat(b), []).map(p => p.code);
                const allCodesInData = Array.from(new Set<string>(inspectionDrawing.geoJson.features.map(f => f.properties && f.properties.code))).filter(f => f);
                const allCodes = Array.from(new Set<string>([...allCodesInCatalog, ...allCodesInData]));
                allCodes.forEach(code => {
                    const featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(code);
                    this.map.changeVisibility(featureGroupId, hiddenFeatureTypeCodes.has(code));
                    this.map.forceTooltip(featureGroupId, textHiddenFeatureTypeCodes.has(code));
                });
            }
        }
        if (classification) {
            const featureGroupId = `inspectionclassification`;
            this.map.changeVisibility(featureGroupId, !classificationSelected);
        }
        if (theoreticalSections) {
            const featureGroupId = `theoreticalsections`;
            this.map.changeVisibility(featureGroupId, !theoreticalSectionsSelected);
        }
        if (blockDefinition) {
            const featureGroupId = `blockdefinition`;
            this.map.changeVisibility(featureGroupId, !blockDefinitionSelected);
        }
        if (sectorDefinition) {
            const featureGroupId = `sectordefinition`;
            this.map.changeVisibility(featureGroupId, !sectorDefinitionSelected);
        }
    };

    public exchangeMap = (props: IInspectionMapBaseProps) => {
        if (!this.map) {
            return;
        }

        this.map.featureGroups.forEach((featureGroup: InspectionFeatureGroup) => {
            featureGroup.removeAllLayers()
        });

        this.initializeMap(props)
    };
}

import FeatureLayer from "./FeatureLayer";
import InspectionFeatureGroup from "./InspectionFeatureGroup";


export interface IDrawingHistoryElement {
    add?: Map<string, FeatureLayer>;
    delete?: Map<string, FeatureLayer>;
    update?: Map<string, FeatureLayer>;
}

export default class DrawingHistoryManager {
    private undoHistory: IDrawingHistoryElement[];
    private currentSelection: Map<string, FeatureLayer>;

    constructor(
        private deleteFeatures: (layers: FeatureLayer[]) => IDrawingHistoryElement,
        private addFeatures: (layers: FeatureLayer[]) => IDrawingHistoryElement,
        private updateFeatures: (layers: FeatureLayer[]) => void,
    ) {
        this.undoHistory =  [] ;
        this.currentSelection = new Map<string, FeatureLayer>();
    };

    public add = (element: IDrawingHistoryElement) => {
        if(
            (element.add && !element.add.keys().next().done) ||
            (element.delete && !element.delete.keys().next().done) ||
            (element.update && !element.update.keys().next().done)

        ) {
            if (element.update) {
                element.update = this.currentSelection
            }
            this.undoHistory.push(element)
        }
    };

    public addSelection = () => {
        if(!this.currentSelection.keys().next().done){
            this.undoHistory.push({update: this.currentSelection})
            this.resetCurrentSelection()
        }
    };

    public hasCurrentSelection = () => this.currentSelection.size >= 0;

    public addToSelection = (featureLayer: FeatureLayer) => {
        this.currentSelection.set(featureLayer.feature.id, this.cloneLayer(featureLayer));
    };

    public getFromSelection = (featureLayer: FeatureLayer): FeatureLayer | undefined => {
        return this.currentSelection.get(featureLayer.feature.id)
    };

    public getAllFeatures = (): FeatureLayer[] => {
        return Array.from(this.currentSelection.values());
    }

    public cancelEdit = () => {
        this.updateFeatures(Array.from(this.currentSelection.values()));
        this.resetCurrentSelection()
    };

    public resetCurrentSelection = () => {
        this.currentSelection = new Map<string, FeatureLayer>()
    };

    public undo = () => {
        const lastDrawingHistoryElement = this.undoHistory.pop();

        if ( lastDrawingHistoryElement ){
            if (lastDrawingHistoryElement.add){
                this.deleteFeatures(Array.from(lastDrawingHistoryElement.add.values()));
            }

            if(lastDrawingHistoryElement.delete){
                this.addFeatures( Array.from(lastDrawingHistoryElement.delete.values()));
            }

            if(lastDrawingHistoryElement.update){
                this.updateFeatures( Array.from(lastDrawingHistoryElement.update.values()));
            }
        }
    };

    public cloneLayer = (featureLayer: any) => {
        let geojson = featureLayer.toGeoJSON();
        geojson = JSON.parse(JSON.stringify(geojson));

        const layer = InspectionFeatureGroup.geometryToLayer(geojson, featureLayer.options);
        (layer as any).feature = InspectionFeatureGroup.asFeature(geojson);

        return layer as FeatureLayer
    };
}
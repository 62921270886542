import * as React from 'react';
import AuthService from '../services/AuthService';
import CatalogData from '../dto/components/CatalogData';
import CreateAutomaticInspectionModal from '../modals/CreateAutomaticInspectionModal';
import FieldBrowserSupport from '../helpers/FieldBrowserSupport';
import InspectionAnalysisSummary from '../dto/components/InspectionAnalysisSummary';
import InspectionHelper from '../helpers/InspectionHelper';
import InspectionPageData from '../dto/pages/InspectionPageData';
import InspectionTable from 'components/InspectionTable';
import InspectionTaskSummary from '../dto/components/InspectionTaskSummary';
import IRouteParams from '../helpers/IRouteParams';
import Logger from '../services/Logger';
import NotificationService from '../services/NotificationService';
import PageTitleItem from '../components/PageTitleItem';
import Routes from '../helpers/Routes';
import Spinner from '@atlaskit/spinner';
import TitleItem from '../components/TitleItem';
import { ais } from '../services/AppInsightsService';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router-dom';
import { t } from 'i18next';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withNamespaces } from 'react-i18next';
import Table, {
    IconTableColumn,
    LinkTableColumn,
    TextTableColumn,
} from "../components/Table";
import FlagService from 'services/FlagService';
import Policies from 'helpers/Policies';
//import { reactPlugin } from "../AppInsights";

export interface IInspectionPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string) => void;
}

export interface IInspectionPageState {
    data?: InspectionPageData;
    currentInspection?: InspectionTaskSummary;
    showAutomaticPredictionModal: boolean;
    waiting: boolean;
}

class InspectionPage extends React.Component<IInspectionPageProps, IInspectionPageState> {


    constructor(props: IInspectionPageProps) {
        super(props);
        this.state = {
            showAutomaticPredictionModal: false,
            waiting: true,
        };
    }

    public render() {
        const { data, showAutomaticPredictionModal, waiting } = this.state;

        return (
            <div className="page">
                {showAutomaticPredictionModal && this.renderAutomaticPredictionModal()}
                <PageTitleItem title={t("pageTitleInspection")}  testID={"inspection-page-title"}/>
                <div>
                    <TitleItem title={t("sectionTitleInspectionTasks")}>
                        {
                        }
                    </TitleItem>
                    <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                    </div> 
                    <div className="tableContainer">{
                        data &&
                        <InspectionTable 
                            inspectionTasks={data.inspectionTasks} 
                            measurements={data.measurements} 
                            offlineTasks={data.offlineStatus && data.offlineStatus.tasks} 
                            showProjectColumn={this.isGlobalLevel()} 
                            showControls={this.showControlMenu()}
                            showStructureColumn={this.isGlobalLevel()||this.isProjectLevel()} 
                            onAutoInspection={data.automaticInspectionTunnelSettings.length > 0 ? this.onShowAutoInspection : undefined}
                            onRefreshImages={this.onRefreshImages}/>
                        }
                        
                    </div>
                </div>
                {data && data.inspectionAnalyses && (data.inspectionAnalyses.length > 0) && !FieldBrowserSupport.isOfflineMode() && this.renderInspectionAnalysis()}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
        NotificationService.registerNotificationConsumer(this.reloadData);
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
        NotificationService.unregisterNotificationConsumer(this.reloadData);
    }

    private reloadData = () => {
        this.loadData().then( () => {Logger.trace("notification handled correctly!")});
    }

    private loadData = async () => {
        try {
            let data: InspectionPageData;
            if (this.isGlobalLevel()) {
                data = await AuthService.get<InspectionPageData>(`inspection/data`);
            } else if (this.isProjectLevel()) {
                data = await AuthService.get<InspectionPageData>(`inspection/${this.props.match.params.projectId}/data`);
            } else if (this.isStructureLevel()) {
                data = await AuthService.get<InspectionPageData>(`inspection/${this.props.match.params.projectId}/${this.props.match.params.structureId}/data`);
            }
            else {
                Logger.error("Unknown inspection level!", "InspectionPage");
                this.setState({waiting:false});
                return;
            }
            this.setState({ data, waiting:false });
            const projectName = data && data.project && data.project.name;
            const structureName = data && data.structure && data.structure.name;
            this.props.setLocationNames(projectName, structureName);
        } catch (error) {
            Logger.error(error, "InspectionPage");
            this.setState({waiting:false});
        }
    }

    private isGlobalLevel = (): boolean => {
        const { projectId, structureId } = this.props.match.params;
        return !projectId && !structureId
    }

    private isProjectLevel = (): boolean => {
        const { projectId, structureId } = this.props.match.params;
        return !!projectId && !structureId
    }

    private isStructureLevel = (): boolean => {
        const { projectId, structureId } = this.props.match.params;
        return !!projectId && !!structureId
    }

    private renderAutomaticPredictionModal = () => {
        const { currentInspection, data} = this.state;
        if (!currentInspection || !data) {
            return;
        }
        return (
            <CreateAutomaticInspectionModal
                tunnelSettings={data.automaticInspectionTunnelSettings}
                catalog={ new CatalogData(data.inspectionCatalog)}
                projectId={currentInspection.project.id}
                structureId={currentInspection.structure.id}
                measurementId={currentInspection.measurement.id}
                onOk={this.hideModalsWithOk}
                onCancel={this.hideModals}
            />
        );
    }
    private renderInspectionAnalysis = () => {
        const { data } = this.state;
        return (
            <div>
                <TitleItem title={t("sectionTitleInspectionAnalysis")}>
                {
                }
                </TitleItem>
                <div className="tableContainer">
                    <Table
                        data={data && data.inspectionAnalyses}
                        columns={[
                            new IconTableColumn<InspectionAnalysisSummary>("", item => InspectionHelper.iconForAnalysisType(item.type)),
                            new LinkTableColumn<InspectionAnalysisSummary>(
                                t("labelName"),
                                item => item.name,
                                item => Routes.analysis.patch(item.project.id, item.structure.id, item.type, item.id).path
                            ),
                            new TextTableColumn<InspectionAnalysisSummary>(t("labelType"), item => InspectionHelper.nameForAnalysisType(item.type)),
                        ]}
                    />
                </div>
            </div>
        );
    }

    private hideModalsAndReloadData = async () => {
        this.hideModals();
        await this.loadData();
    }

    private hideModals = () => {
        this.setState({
            showAutomaticPredictionModal: false,
        });
    }
    
    private hideModalsWithOk = () => {
        this.setState({
            showAutomaticPredictionModal: false,
        });
    }

    private showControlMenu = () => {
        if( !Policies.isValidPolicy(Policies.toUpdateProject) ) {
            return false;
        } else {
            return !this.isGlobalLevel() && !FieldBrowserSupport.isOfflineMode(); 
        }
    }

    private onShowAutoInspection = async (taskSummary: InspectionTaskSummary) => {
        this.setState({
            currentInspection: taskSummary,
            showAutomaticPredictionModal: true,
        });
    }

    private onRefreshImages = async (taskSummary: InspectionTaskSummary) => {
        const url = `inspectiontask/${taskSummary.project.id}/retile/${taskSummary.measurement.id}`;
        try{
            await AuthService.put(url, {});
            await this.hideModalsAndReloadData();
        }
        catch(error) {
            FlagService.addError(t("textSaveFailed"), error)
        }
    }
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, InspectionPage,"InspectionPage","AppInsight"));
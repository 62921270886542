import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";

//import { t } from "i18next";
import React from "react";
import LabelWrapper from "./labeled/Wrapper";

export interface INumberBoxProps {
  label: string;
  placeholder?: number;
  value: any;
  min?: number;
  max?: number;
  isPassword?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: number) => void;
  //validate?: (value: any) => boolean;
}

export default function NumberBox(props: INumberBoxProps) {
  return (
    <LabelWrapper label={props.label}>
      <Field
        name='numberField'
        isRequired={props.required || false}
        /*
        validators={[
          <Validator
            key="validator"
            func={initializeValidator(props.min, props.max)}
            invalid={
              props.min
                ? props.max
                  ? t("validatorNumberBoxMinMax", {
                      maximum: props.max,
                      minimum: props.min,
                    })
                  : t("validatorNumberBoxMin", { minimum: props.min })
                : props.max
                ? t("validatorNumberBoxMax", { maximum: props.max })
                : t("validatorNumberBox")
            }
            valid={t("labelOk")}
          />,
        ]}
        */
      >
        {() => (
        <TextField
          name="NumberBoxTextField"
          placeholder={props.value}
          value={props.value}
          min={props.min || undefined}
          max={props.max || undefined}
          disabled={props.disabled || false}
          type={props.isPassword ? "password" : "text"}
          // tslint:disable-next-line:jsx-no-lambda
          onChange={(event: any) =>
            props.onChange && props.onChange(event.target.value)
          }
        />
        )}
      </Field>
    </LabelWrapper>
  );
}

/*
function initializeValidator(min?: number, max?: number) {
  return (value: any): boolean => {
    let valid: boolean = true;

    if (isNaN(Number(value))) {
      valid = false;
    }
    if (valid && min && Number(value) < min) {
      valid = false;
    }
    if (valid && max && Number(value) > max) {
      valid = false;
    }
    return valid;
  };
}
*/
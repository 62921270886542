import {
  GroupHeading,
  HeaderSection,
  Item,
  MenuSection,
} from "@atlaskit/navigation-next";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import Constants from "../../helpers/Constants";
import INavigationLink from "../../helpers/INavigationLink";

export interface INavigationProps extends RouteComponentProps<any> {
  userName: string;
  navLinks: INavigationLink[];
  navLinkHeader: INavigationLink;
  navLinksAdmin: INavigationLink[];
  isTopoLevelRoute: boolean;
}

class ProductNavigation extends React.Component<INavigationProps> {
  public render() {
    return (
      <div id={"product_navigation"}>
        {this.navigationHeader()}
        {this.navigationBody()}
      </div>
    );
  }

  private navigationHeader = () => {
    let headerComponent: JSX.Element;

    if (this.props.isTopoLevelRoute) {
      headerComponent = (
        <h2 style={{ color: Constants.colorForegroundM3white }}>
          {this.props.navLinkHeader.text}
        </h2>
      );
    } else {
      headerComponent = (
        <Item
          before={this.props.navLinkHeader.icon}
          text={<b>{this.props.navLinkHeader.text}</b>}
        />
      );
    }

    return (
      <HeaderSection>
        {({ className }: any) => (
          <div className={className} style={{marginTop:'6px', marginBottom:'16px'}}>
            <Link key="navigation-header" to={this.props.navLinkHeader.route.path}>
              {headerComponent}
            </Link>
          </div>
        )}
      </HeaderSection>
    );
  };

  private navigationBody = () => {
    return (
      <MenuSection>
        {({ className }: any) => (
          <div className={className}>
            {this.createNavigationItems(this.props.navLinks)}
            {this.props.isTopoLevelRoute && this.props.navLinksAdmin.length > 0 && (
              <div>
                <GroupHeading>{t("navigationAdministration")}</GroupHeading>
                {this.createNavigationItems(this.props.navLinksAdmin)}
              </div>
            )}
          </div>
        )}
      </MenuSection>
    );
  };

  private createNavigationItems = (navLinks: INavigationLink[]) => {
    const navigation: any = [];
    navLinks.forEach(navItem => {
      navigation.push(
        <Link to={navItem.route.path} key={navItem.route.path}>
          <div id={navItem.route.path}>
            <Item
              key={navItem.route.path}
              text={navItem.text}
              before={navItem.icon}
              isSelected={navItem.isSelected || false}
            />
          </div>
        </Link>
      );
    });
    return navigation;
  };
}

export default compose<any, any>(
  withRouter,
  withNamespaces()
)(ProductNavigation);

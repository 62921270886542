import AuthService from "../services/AuthService";

export default class Policies {
  public static toSeeAmbergAdmin = "SuperUser";
  public static toSeeAdmin = "CloudAdmin";
  public static toSeeYourWork = "ToSeeYourWork";
  public static toSeeProjects = "ToSeeProjects";
  public static toSeeProject = "ToSeeProjectOverview";
  public static toUpdateProject = "ToUpdateProject";
  public static toSeeStructures = "ToSeeStructures";
  public static toSeeInspections = "ToSeeInspections";
  public static toSeeInspection = "ToSeeInspection";
  public static toSeeSettings = "ToSeeSettings";
  public static toSeeAutomaticInspection = "ToSeeAutomaticInspection";
  public static toSeeAmbergInspectorItems = "ToSeeAmbergInspectorItems";

  public static isValidPolicy = (policy?: string) => {
    if (policy) {
      const policies = AuthService.policies
      if(AuthService.isInspector())
        console.info(policies);
      return policies.indexOf(policy) > -1;
    }
    return true;
  };
}

import '@atlaskit/css-reset';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { API_ROOT, HOST_NAME } from './Environment';
import './App.css';
import RoutingContainer from './RoutingContainer';
import Logger from './services/Logger';
import FieldBrowserSupport from './helpers/FieldBrowserSupport';
import FlagService from "./services/FlagService";
import { t } from "i18next";

function App() {

    useEffect(()=>{
        // logging
        if (process.env.NODE_ENV !== 'production') {
            localStorage.setItem('debug', 'ambergtunnelcloud:*');
            Logger.info("logging is enabled");
        }

        Logger.info(`hostname: ${HOST_NAME}, backend: ${API_ROOT}`)
        Logger.info(`window width is: ${window.innerWidth}`);

        if (FieldBrowserSupport.isIncompatibleFieldBrowser())
        {
            FlagService.addError(t("labelOutdatedAmbergInspector"), t("messageOutdatedAmbergInspector"));
        }
    }, []);

    return (
        <Router>
            <RoutingContainer />
        </Router>
    );
}

export default App;

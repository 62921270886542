import FeatureType from "../../dto/components/FeatureType";

export default class LineStyle{
    public color: string;
    public weight: number;
    public dashArray?: string;

    public constructor(featureType: FeatureType, dashArray?: string){
        this.color = featureType.strokeColorString;
        this.weight = featureType.strokeThickness;
        this.dashArray = dashArray;
    }
}

import * as React from "react";
import ButtonConfirm, {IButtonWithPopupProps} from "../ButtonConfirm";
import LabelWrapper, {ILabelWrapperProps} from "./Wrapper";



interface IConfirmButtonLabeled extends ILabelWrapperProps, IButtonWithPopupProps {
}

export default function ConfirmButtonLabeled(props: IConfirmButtonLabeled) {
  const { label, children, labelMarginTop, testId,  ...rest } = props as IConfirmButtonLabeled;
  return (
    <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
      <div style={{ height: "32px" }}>
        <div style={{ marginTop: "12px" }}>
          <ButtonConfirm testId={testId} {...rest}/>
        </div>
      </div>
    </LabelWrapper>
  );
}
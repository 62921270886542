
const convertStringToHex = (value: string) => {
    var arr = [];
    for(var i=0; i < value.length; i++){
        arr[i] = ('00' + value.charCodeAt(i).toString(16)).slice(-4);
    }
    return "\\u" + arr.join("\\u");
}

export const isNoneEmptyValidString = (input?: string) => {
    const emptyPattern = /\W|\p{C}+/g;
    const validated = input?.replace(emptyPattern, "")
    if(validated && validated !== ""){
        return true;
    }
    if(process.env.NODE_ENV !== 'production'){
        console.info("Validation Error - isNoneEmptyInvalidString for:" + convertStringToHex(input??''));
    }
    return false;
}

export const hasInvalidChars = (checkme:string): boolean => checkme.includes("%");

export const isNoneZeroPositiveDouble = (input?: string) => {
    const value = Number(input);
    if (Math.sign(value) === 1){
        return true;
    }
    if(process.env.NODE_ENV !== 'production'){
        console.info("Validation Error - isNoneZeroPositiveDouble for:" + input);
    }
    return false;
}

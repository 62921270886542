import { t } from "i18next";
import * as React from "react";
import CatalogData from "../../dto/components/CatalogData";
import FeatureType from "../../dto/components/FeatureType";
import CatalogBase, { ICatalogBaseProps, ICatalogBaseState } from "./CatalogBase";
import CatalogFeatureTypeGroup from "./CatalogFeatureTypeGroup";
import FeatureIconProvider from "./FeatureIconProvider";


export interface ICatalogProps extends ICatalogBaseProps {
    catalog: CatalogData;
    currentId?: string,
    onSelect?: (featureType: FeatureType) => void;
    onVisibilityChange?: (featureType: FeatureType, visible: boolean) => void;
    onLockChange?: (featureType: FeatureType, lock: boolean) => void;
    onTextVisibilityChange?: (featureType: FeatureType, visible: boolean) => void;
}


export interface ICatalogState extends ICatalogBaseState {
    hiddenIds: Set<string>;
    lockedIds: Set<string>;
    textHiddenIds: Set<string>;
}


export default class CatalogFeatureType extends CatalogBase<ICatalogProps, ICatalogState> {

    public getInitialState(): ICatalogState {
        const initialState = super.getInitialState();
        initialState.hiddenIds = new Set<string>();
        initialState.lockedIds = new Set<string>();
        initialState.textHiddenIds = new Set<string>();
        initialState.name = this.props.name || t("labelCatalog");
        initialState.isExpanded = true;
        return initialState;
    }

    public renderChildren = () => {
        const featureTypeGroupsSorted = this.props.catalog.groups.sort(
            (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
        );

        const featureTypeGroups: any = [];
        featureTypeGroupsSorted.forEach(featureTypeGroup => {
            featureTypeGroups.push(
                <CatalogFeatureTypeGroup
                    key={featureTypeGroup.id}
                    name={featureTypeGroup.name}
                    featureTypeGroup={featureTypeGroup}
                    hiddenIds={this.state.hiddenIds}
                    lockedIds={this.state.lockedIds}
                    textHiddenIds={this.state.textHiddenIds}
                    currentId={this.props.currentId}
                    onSelect={this.props.onSelect}
                    onVisibilityToggle={this.getToggleVisibilityMethod()}
                    onLockToggle={this.getToggleLockMethod()}
                    onTextVisibilityToggle={this.getToggleTextVisibilityMethod()}
                    testId={`feature-type-group-id?${featureTypeGroup.id}`}
                />
            );
        });

        return featureTypeGroups
    };

    public renderTools = () => {
        const { catalog} = this.props;
        const { hiddenIds, lockedIds, textHiddenIds } = this.state;

        const isHidden = hiddenIds ? catalog.groups.every(
            featureGroup => featureGroup.featureTypes.every(
                featureType => hiddenIds.has(featureType.id)
            )
        ) : false;
        const isLocked = lockedIds? catalog.groups.every(
            featureGroup => featureGroup.featureTypes.every(
                featureType => lockedIds.has(featureType.id)
            )
        ) : false;
        const isTextHidden = textHiddenIds? catalog.groups.every(
            featureGroup => featureGroup.featureTypes.every(
                featureType => textHiddenIds.has(featureType.id)
            )
        ) : false;

        return FeatureIconProvider.getTools({
            id:catalog.id,
            isHidden,
            isLocked,
            isTextHidden,
            onVisibilityChange: this.getToggleCatalogVisibilityMethod(isHidden),
            onLockedChange: this.getToggleCatalogLockMethod(isLocked),
            onTextVisibilityChange: this.getToggleCatalogTextVisibilityMethod(isTextHidden),
        })
    };

    private getToggleVisibilityMethod = () => {
        return this.handleFeatureTypeToggle(
            "hiddenIds",
            this.props.onVisibilityChange
        );
    };

    private getToggleLockMethod = () => {
        return this.handleFeatureTypeToggle(
            "lockedIds",
            this.props.onLockChange
        );
    };

    private getToggleTextVisibilityMethod = () => {
        return this.handleFeatureTypeToggle(
            "textHiddenIds",
            this.props.onTextVisibilityChange
        );
    };

    private getToggleCatalogVisibilityMethod = (visible: boolean) => {
        return this.handleCatalogToggle(
            visible,
            this.state.hiddenIds,
            this.getToggleVisibilityMethod()
        );
    };

    private getToggleCatalogLockMethod = (lock: boolean) => {
        return this.handleCatalogToggle(
            lock,
            this.state.lockedIds,
            this.getToggleLockMethod()
        );
    };

    private getToggleCatalogTextVisibilityMethod = (force: boolean) => {
        return this.handleCatalogToggle(
            force,
            this.state.textHiddenIds,
            this.getToggleTextVisibilityMethod()
        );
    };

    private handleFeatureTypeToggle = (
        attributeName: string,
        method?: (arg1: any, arg2: any) => void
    ) => {
        if ( method ){
            return (featureTypeId: string) => {
                // @ts-ignore
                this.setState(prevState => {
                    const ids: Set<string> = new Set(prevState[attributeName]);
                    const isActive = ids.has(featureTypeId);
                    const featureType = this.getFeatureById(featureTypeId);

                    if (featureType) {
                        ids.delete(featureType.id);
                        if (!isActive) {
                            ids.add(featureType.id)
                        }
                        method(featureType, isActive);
                    }
                    return {[attributeName]: ids}
                })
            };
        }
        return undefined
    };

    private handleCatalogToggle =  (state: boolean, activeIds?: Set<string>, method?: (arg: any) => void) => {
        if(method) {
            return (event: any) => {
                event.stopPropagation();

                this.props.catalog.groups.forEach(featureGroup => {
                    featureGroup.featureTypes.forEach(featureType => {
                        if (method && activeIds) {
                            const isActive = activeIds.has(featureType.id);
                            if(state && isActive){
                                method(featureType.id)
                            }
                            if(!state && !isActive){
                                method(featureType.id)
                            }
                        }
                    })
                })
            }
        }
        return undefined
    };

    private getFeatureById = (featureTypeId: string) => {
        return this.props.catalog.groups.filter(
            featureTypeGroup => featureTypeGroup.featureTypes.some(f => f.id === featureTypeId)
        )[0].featureTypes.find(f => f.id === featureTypeId);
    };
}
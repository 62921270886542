import Button from '../components/atlaskit/Button';
import CheckBoxField from 'components/CheckBoxField';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Form from '@atlaskit/form';
import InspectionAnalysisMapsPageData from '../dto/pages/InspectionAnalysisMapsPageData';
import InspectionMapBasic from 'graphics/InspectionMapBasic';
import React from 'react';
import SingleSelectComboBoxLabeled from '../components/labeled/SingleSelectComboBoxLabeled';
import TextBoxLabeled from '../components/labeled/TextBoxLabeled';
import { Feature } from 'geojson';
import { N500 } from '@atlaskit/theme/colors';
import { PdfPreview } from '../pages/InspectionPdfReport';
import { t } from 'i18next';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Modal, {
    ModalFooter,
    ModalHeader,
    ModalTitle,
  } from '@atlaskit/modal-dialog';

type BlockOption = { label: string, value: string, obj: Feature, index: number }

export interface ICreateInspectionPdfModalProps extends WithNamespaces {
    createUrl: string;
    leafletMap?: InspectionMapBasic,
    inspectionDrawingId: string;
    inspectionData?: InspectionAnalysisMapsPageData;
    tileLayerId: string;
    onCancel: () => void;
    onOk: () => void;
}

export interface ICreateInspectionPdfModalState {
    companyName: string;
    companyStreet: string;
    zipCodeCity: string;
    country: string;
    generate: boolean;
    includeImage: boolean;
    showPdf: boolean;
    isLoading: boolean;
    currentFirstBlock?: BlockOption;
    currentLastBlock?: BlockOption;
}

class CreateInspectionPdfModal extends React.Component<ICreateInspectionPdfModalProps, ICreateInspectionPdfModalState> {
    constructor(props: ICreateInspectionPdfModalProps) {
        super(props);
        const { inspectionData, inspectionDrawingId } = this.props;
        const inspectionClassification = getInspectionClassification(inspectionData, inspectionDrawingId);
        const firstBlock = inspectionClassification?.mapData.geoJson.features[0]
        const localIncludeImage = localStorage.getItem('reportIncludeImages');
        const predefinedIncludeImage = localIncludeImage === null ? true : localIncludeImage === String(true);
        if(!firstBlock){
            this.state = {
                companyName: localStorage.getItem('reportAddressCompany') || '',
                companyStreet: localStorage.getItem('reportAddressStreet') || '',
                zipCodeCity: localStorage.getItem('reportAddressZipCity') || '',
                country: localStorage.getItem('reportAddressCountry') || '',
                generate: false,
                includeImage: predefinedIncludeImage,
                showPdf: false,
                isLoading: false,
            }
        }
        else{
            this.state = {
                companyName: localStorage.getItem('reportAddressCompany') || '',
                companyStreet: localStorage.getItem('reportAddressStreet') || '',
                zipCodeCity: localStorage.getItem('reportAddressZipCity') || '',
                country: localStorage.getItem('reportAddressCountry') || '',
                generate: false,
                includeImage: predefinedIncludeImage,
                showPdf: false,
                isLoading: false,
                currentFirstBlock: {
                    label: firstBlock.properties && firstBlock.properties.name,
                    value: firstBlock.properties && firstBlock.properties.name,
                    obj: firstBlock,
                    index: 0,
                },
                currentLastBlock: {
                    label: firstBlock.properties && firstBlock.properties.name,
                    value: firstBlock.properties && firstBlock.properties.name,
                    obj: firstBlock,
                    index: 0,
                }
            }
        }
    }

    get firstBlockOptions(): BlockOption[] {
        const { inspectionData, inspectionDrawingId } = this.props;
        const inspectionClassification = getInspectionClassification(inspectionData, inspectionDrawingId);
        const options: BlockOption[] = [];

        if(!inspectionClassification){
            return options
        }

        inspectionClassification.mapData.geoJson.features.forEach((block: Feature, idx: number) => {
            options.push({
                label: block.properties && block.properties.name,
                value: block.properties && block.properties.name,
                obj: block,
                index: idx,
            })
        });

        return options
    };

    get lastBlockOptions(): BlockOption[] {
        const { inspectionData, inspectionDrawingId } = this.props;
        const { currentFirstBlock } = this.state;
        const inspectionClassification = getInspectionClassification(inspectionData, inspectionDrawingId);
        
        const options: BlockOption[] = [];

        if(!inspectionClassification){
            return options
        }

        inspectionClassification.mapData.geoJson.features.slice(currentFirstBlock?.index).forEach((block: Feature, idx: number) => {
            options.push({
                label: block.properties && block.properties.name,
                value: block.properties && block.properties.name,
                obj: block,
                index: idx
            })
        });

        return options
    };

    CustomHeader = () => {
        return (
            <ModalHeader>
                <ModalTitle>{t("modalInspectionReport")}</ModalTitle>
                <Button appearance="link" onClick={this.props.onCancel} testId="close-pdf-modal-header-button">
                    <CrossIcon label={t("labelClose")} primaryColor={N500} />
                </Button>
            </ModalHeader>
        );
    };

    CustomFooter = () => {
        return (
            <ModalFooter>
                {
                    !this.state.generate &&
                    <Button appearance="primary" onClick={this.onCreate} testId="close-pdf-modal-footer-button">
                    {t("labelNext")}
                    </Button>
                }
                {
                    this.state.generate && !this.state.isLoading &&
                    <Button appearance="primary" onClick={() => {this.setState({generate: false})}} testId="close-pdf-modal-footer-button">
                    {t("labelBack")}
                    </Button>
                }
                {
                    !this.state.isLoading &&
                    <Button onClick={this.props.onCancel} testId="close-pdf-modal-footer-button">
                    {t("labelClose")}
                    </Button>
                }
            </ModalFooter>
        );
    }

    public render() {
        const { companyName, companyStreet, zipCodeCity, country, generate, currentFirstBlock, currentLastBlock, includeImage } = this.state;
        const { leafletMap, inspectionDrawingId, inspectionData, } = this.props;

        return (
            <Modal
                onClose={this.props.onCancel}
                width={"x-large"}
                height={"100%"}
            >
                <this.CustomHeader/>
                {
                    !generate &&
                    <div className="pdfForm" style={{margin:'7%'}}>
                        <Form onSubmit={this.props.onCancel}>
                            {({ formProps }) => (
                                <form {...formProps} className="modalForm">
                                <CheckBoxField
                                    label={t("labelIncludeInspectionImage")}
                                    text={''}
                                    value={includeImage}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={value => this.setState({ includeImage: value })}
                                />
                                <SingleSelectComboBoxLabeled
                                    id={"element-block-first"}
                                    label={t("labelBlockFrom")}
                                    placeholder={t("labelSelect")}
                                    options={this.firstBlockOptions}
                                    selectedOption={currentFirstBlock}
                                    onChange={(option: BlockOption) => {
                                        this.setState({currentFirstBlock: option, currentLastBlock: option})
                                    }}
                                    styles={{
                                        control: () => ({
                                            cursor: "pointer",
                                            display: 'flex',
                                            justifyContent: "space between",
                                            flexWrap: "wrap",
                                        }),
                                    }}
                                />
                                <SingleSelectComboBoxLabeled
                                    id={"element-block-last"}
                                    label={t("labelBlockTo")}
                                    placeholder={t("labelSelect")}
                                    options={this.lastBlockOptions}
                                    selectedOption={currentLastBlock}
                                    onChange={(option: BlockOption) => {
                                        this.setState({currentLastBlock: option})
                                    }}
                                    styles={{
                                        control: () => ({
                                            cursor: "pointer",
                                            display: 'flex',
                                            justifyContent: "space between",
                                            flexWrap: "wrap",
                                        }),
                                    }}
                                />
                                <TextBoxLabeled
                                    label={t("labelCustomerCompanyName")}
                                    placeholder={t("labelCustomerCompanyName")}
                                    required={true}
                                    value={companyName}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={value => this.setState({ companyName: value })}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    isSpellCheckEnabled={false}
                                    testId = {"user-company-name-textbox"}
                                />
                                <TextBoxLabeled
                                    label={t("labelCustomerStreet")}
                                    placeholder={t("labelCustomerStreet")}
                                    required={true}
                                    value={companyStreet}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={value => this.setState({ companyStreet: value })}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    isSpellCheckEnabled={false}
                                    testId = {"user-company-name-textbox"}
                                />
                                <TextBoxLabeled
                                    label={t("labelCustomerZIPCodeCity")}
                                    placeholder={t("labelCustomerZIPCodeCity")}
                                    required={true}
                                    value={zipCodeCity}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={value => this.setState({ zipCodeCity: value })}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    isSpellCheckEnabled={false}
                                    testId = {"user-company-name-textbox"}
                                />
                                <TextBoxLabeled
                                    label={t("labelCustomerCountry")}
                                    placeholder={t("labelCustomerCountry")}
                                    required={true}
                                    value={country}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={value => this.setState({ country: value })}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    isSpellCheckEnabled={false}
                                    testId = {"user-company-name-textbox"}
                                />
                                
                                </form>
                            )}
                        </Form>
                    </div>
                }
                {
                    generate &&
                    <div className="pdfPreview" style={{height: '80%'}}>
                        <PdfPreview 
                            data={inspectionData}
                            inspectionDrawingId={inspectionDrawingId}
                            inspectionMap={leafletMap}
                            blockRange={this.getBlockRange(currentFirstBlock, currentLastBlock)}
                            customer={{companyName: companyName, street: companyStreet, zipCity: zipCodeCity, country: country}}
                            onDone={() => {this.setState({isLoading: false})}} />
                    </div>
                }
                <this.CustomFooter/>
            </Modal>
        );
    }

    private onCreate = async (event: any) => {
        event.preventDefault();
        const {companyName, companyStreet, zipCodeCity, country, includeImage } = this.state;

        localStorage.setItem('reportIncludeImages', String(includeImage));
        localStorage.setItem('reportAddressCompany', companyName);
        localStorage.setItem('reportAddressStreet', companyStreet);
        localStorage.setItem('reportAddressZipCity', zipCodeCity);
        localStorage.setItem('reportAddressCountry', country);
        
        if(includeImage === false){
            this.props.leafletMap?.selectTileLayer(undefined);
        }
        else{
            
            const selectedTileLayer = this.props.inspectionData?.tileLayers.find(l => l.id === this.props.tileLayerId);
            if(selectedTileLayer){
                this.props.leafletMap?.setTileLayer(selectedTileLayer && selectedTileLayer.urlTemplate, selectedTileLayer);
            }
        }

        this.setState({generate: true, isLoading: true});
    }


    private getBlockRange(currentFirstBlock: BlockOption | undefined, currentLastBlock: BlockOption | undefined) {
        const { inspectionData, inspectionDrawingId } = this.props;
        const inspectionClassification = getInspectionClassification(inspectionData, inspectionDrawingId);

        let begItem = inspectionClassification?.mapData.geoJson.features.findIndex(f => {
            if (f.properties)
                return f.properties.name === currentFirstBlock?.value;
            else
                return false;
        });
        let endItem = inspectionClassification?.mapData.geoJson.features.findIndex(f => {
            if (f.properties)
                return f.properties.name === currentLastBlock?.value;
            else
                return false;
        });
        const begIndex = begItem ? begItem : 0;
        const endIndex = endItem ? endItem : begIndex; 

        const test = inspectionClassification && inspectionClassification.mapData.geoJson.features.slice(begIndex, endIndex + 1);
        return test;
    }
}

export default withNamespaces()(CreateInspectionPdfModal)

function getInspectionClassification(inspectionData: InspectionAnalysisMapsPageData | undefined, inspectionDrawingId: string) {
    return inspectionData?.inspectionClassifications.find(l => l.id === inspectionDrawingId);
}


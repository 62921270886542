import FeatureLayer from "./FeatureLayer";

export default class FeatureNode {
    private _layers: Map<string, FeatureLayer> = new Map<string, FeatureLayer>();
    private _stationingThreshold?: number;
    private _sizeThreshold: number = 6;
    private _left: FeatureNode | null;
    private _right: FeatureNode | null;

    constructor(stationingThreshold?: number)
    {
        this._stationingThreshold = stationingThreshold;
        this._left = null;
        this._right = null;
    };

    public set = (id: string, layer: FeatureLayer) => {
        if(this._left && this._right && this._stationingThreshold){
            this.sortLayer(layer, id, this._stationingThreshold, this._left, this._right);
        }
        else{
            this._layers.set(id, layer);

            if(this._layers.size >= this._sizeThreshold){
                this.branch()
            }
        }
    };

    public delete = (id: string) => {
        if( this._layers.has(id) ){
            this._layers.delete(id);
        }
        else {
            if(this._left){
                this._left.delete(id);
            }
            if(this._right){
                this._right.delete(id);
            }
        }
    };

    public get = (south: number, north: number): FeatureLayer[] => {
        const features: FeatureLayer[] = [];

        if(this._stationingThreshold && this._stationingThreshold > south){
            if(this._left){
                features.push(...this._left.get(south, north));
            }
        }
        if(this._stationingThreshold && this._stationingThreshold < north){
            if(this._right){
                features.push(...this._right.get(south, north));
            }
        }
        features.push(...Array.from(this._layers.values()));

        return features
    };

    private branch = () => {
        if(this._left || this._right){return;}

        this._left = new FeatureNode();
        this._right = new FeatureNode();

        if(!this._stationingThreshold){
            this._stationingThreshold = this.getMeanStationing()
        }

        const left = this._left;
        const right = this._right;
        const stationingThreshold = this._stationingThreshold;

        this._layers.forEach((layer: FeatureLayer, id: string) => {
            this.sortLayer(layer, id, stationingThreshold, left, right);
        });
    };

    private sortLayer = (layer:FeatureLayer, id: string, stationingThreshold: number, left: FeatureNode, right: FeatureNode) => {
        const stationing = this.getLayerStationing(layer);
        if(stationing.south < stationingThreshold && stationing.north < stationingThreshold){
            left.set(id, layer);
            this._layers.delete(id);
        }
        else if(stationing.south > stationingThreshold && stationing.north > stationingThreshold){
            right.set(id, layer);
            this._layers.delete(id);
        }
        else{
            this._layers.set(id, layer);
        }
    };

    private getMeanStationing = () => {
        let south = 0;
        let north = 0;
        let meanStationing: number;
        const stationings: Set<Number> = new Set<Number>();

        this._layers.forEach((layer: FeatureLayer) => {
            const stationing = this.getLayerStationing(layer);
            south = south + stationing.south;
            north = north + stationing.north;
            stationings.add(stationing.south)
            stationings.add(stationing.north)
        });

        if(stationings.size === 1){
            meanStationing =  stationings[0]
        }
        else {
            south = (south / this._layers.size);
            north = (north / this._layers.size);
            const distance  = north - south;
            meanStationing = south + (distance / 2);
        }

        return  meanStationing
    };

    private getLayerStationing = (layer: FeatureLayer): {south: number, north: number} => {
        if((layer as any)._latlng){
            return{
                south: (layer as any)._latlng.lat,
                north: (layer as any)._latlng.lat,
            }
        }
        else{
            return{
                south:  (layer as any)._bounds.getSouth(),
                north:  (layer as any)._bounds.getNorth(),
            }
        }
    };
}
import * as React from "react";
import * as L from "leaflet";

type Props =  { map: L.Map,
                isRightToLeft?: boolean}
type State = {  }

export default class MapControlInhibitDrawing extends React.Component<Props, State> {
    lastRelatedTarget : string; 
    constructor(props: Props) {
        super(props);
        this.lastRelatedTarget = "";
    }

    

    render() {
        return (
            <div id={this.props.isRightToLeft ? "map-element-container-rtl" : "map-element-container"} 
                 onMouseEnter={this.addDisableDrawingHandler} 
                 onMouseLeave={this.removeDisableDrawingHandler}
                 onMouseUp = {this.onMouseUpHandler}
                 onMouseOver = {this.onMouseOverHandler}
            > 
                {this.props.children} 
            </div>
        );
      }

    addDisableDrawingHandler = () => {        
        this.props.map.on('editable:drawing:click', this.disableDrawingHandler);
    }

    removeDisableDrawingHandler = () => {              
        this.props.map.off('editable:drawing:click', this.disableDrawingHandler);
    }

    disableDrawingHandler = (e: any) => {        
        e.cancel();
    }

    onMouseUpHandler = (e: any) => {
        // For the special case where the dropdown selection was clicked. 
        //   In that case the onMouseLeave handler is not triggered, so we need a workaround
        //   on mouseover the relatedTarget is stored and evaluated on mouseup
        if (this.lastRelatedTarget.startsWith('react-select'))
        {
            this.removeDisableDrawingHandler();
        }       
    }

    onMouseOverHandler = (e: any) => {
        if (e.relatedTarget) {
            this.lastRelatedTarget = e.relatedTarget.id;
        } 
    }
}
import { t } from "i18next";
import Dropdown, { DropdownItem } from "@atlaskit/dropdown-menu";
import { GlobalItem } from "@atlaskit/navigation-next";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Policies from '../../helpers/Policies';
import Routes from "../../helpers/Routes";
import { HelpIcon } from "../Icons";

interface ILegalInformationMenuProps extends RouteComponentProps<any> {
}

class LegalInformationMenu extends React.Component<ILegalInformationMenuProps, {}> {
  public render() {
    if(Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems)){
      return (
        <div id={"about_menu_button"}>
          <GlobalItem icon={HelpIcon} size="small" onClick={this.navigateToAboutPage} />
        </div>
      );
    } else {
      return (
        <div id={"legal_information_menu_button"}>
          <Dropdown trigger={<GlobalItem icon={HelpIcon} size="small"/>}>
            <DropdownItem onClick={this.navigateToAboutPage}>{t("labelAbout")}</DropdownItem>
            <DropdownItem onClick={this.navigateToCookiePolicyPage}>{t("labelCookiePolicies")}</DropdownItem>
          </Dropdown>
        </div>
      );
    }
  }

  private navigateToCookiePolicyPage = () => {
    this.props.history.push(Routes.cookiePolicies.path);
  };

  private navigateToAboutPage = () => {
    this.props.history.push(Routes.about.path);
  };
}

export default withRouter(LegalInformationMenu);

export function LabelLegalInformation() {
  if(Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems)){
    return t("navigationAbout");
  } else {
    return t("navigationLegalInformation");
  }
};
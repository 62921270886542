import Policies from "./Policies";
import Route from "./Route";


export default class Routes {
  public static home = new Route({
    path: "/"
  });
  public static login = new Route({
    path: "/login"
  });

  // Global Navigation
  public static about = new Route({
    path: "/about"
  });
  public static cookiePolicies = new Route({
    path: "/cookies"
  });
  public static userprofile = new Route({
    path: "/userprofile"
  });

  public static projects = new Route({
    path: "/projects",
    policy: Policies.toSeeProjects,
  });
  public static structures = new Route({
    path: "/structures",
    policy: Policies.toSeeStructures,
  });
  public static inspections = new Route({
    path: "/inspections",
    policy: Policies.toSeeInspections,
  });
  public static settings = new Route({
    path: "/settings",
    policy: Policies.toSeeSettings,
  });

  // Global Navigation - Cloud Admin Routes
  public static users = new Route({
    path: "/users",
    policy: Policies.toSeeAdmin
  });
  public static user = new Route({
    path: "/user/:userId",
    policy: Policies.toSeeAdmin,
  });
  public static group = new Route({
    path: "/group/:groupId",
    policy: Policies.toSeeAdmin,
  });

  public static resources = new Route({
    path: "/resources",
    policy: Policies.toSeeAdmin,
  });
  public static resource = new Route({
    path: "/resource/:resourceId",
    policy: Policies.toSeeAdmin,
  });

  public static licences = new Route({
    path: "/licenses",
    policy: Policies.toSeeAdmin,
  });
  public static licence = new Route({
    path: "/license/:licenseId",
    policy: Policies.toSeeAdmin,
  });

  // Project Level Routes
  public static project = new Route({
    path: "/project/:projectId",
    policy: Policies.toSeeProject,
  });

  public static projectStructures = new Route({
    path: "/structures",
    route: Routes.project,
  });

  public static projectInspections = new Route({
    path: "/inspections",
    policy: Policies.toSeeInspections,
    route: Routes.project,
  });

  public static projectSettings = new Route({
    path: "/settings",
    route: Routes.project,
  });

  
  // Structure Level Routes
  public static projectStructure = new Route({
    path: "/structure/:structureId",
    route: Routes.project,
  });
  public static projectStructureInspections = new Route({
    path: "/inspections",
    route: Routes.projectStructure,
  });
  
  // Structure Measurement Routes  
  public static projectStructureMeasurementFiles = new Route({
    path: "/structuremeasurement/:structureMeasurementId/files",
    route: Routes.projectStructure,
  });
  
  // -----------------------------------------------------------------------------------
  // INSPECTIONS
  // -----------------------------------------------------------------------------------

  // Tasks
  public static task = new Route({
    path: "/structuretask/:objectId/:objectId",
    policy: Policies.toSeeInspection,
    route: Routes.projectStructure,
  });

  public static inspectionTask = new Route({
    path: "/structuretask/inspection/:objectId",
    policy: Policies.toSeeInspection,
    route: Routes.projectStructure,
  });
  
  // Analyses
  public static analysis = new Route({
    path: "/inspectionanalysis/:objectId/:objectId",
    route: Routes.projectStructure,
  });

  public static singleNumerical = new Route({
    path: "/inspectionanalysis/singlenumerical/:objectId",
    route: Routes.projectStructure,
  });
  public static singleCadExport = new Route({
    path: "/inspectionanalysis/singlecadexport/:objectId",
    route: Routes.projectStructure,
  });
  public static overlayMaps = new Route({
    path: "/inspectionanalysis/overlaymaps/:objectId",
    route: Routes.projectStructure,
  });
  public static sideBySideMaps = new Route({
    path: "/inspectionanalysis/sidebysidemaps/:objectId",
    route: Routes.projectStructure,
  });
  public static singleMap = new Route({
    path: "/inspectionanalysis/singlemap/:objectId/:id?/:value?",
    route: Routes.projectStructure,
  });
  public static singleFeatureBasedCharts = new Route({
    path: "/inspectionanalysis/singlephenomenonbasedcharts/:objectId",
    route: Routes.projectStructure,
  });
  public static singleBlockBasedCharts = new Route({
    path: "/inspectionanalysis/singleblockbasedcharts/:objectId",
    route: Routes.projectStructure,
  });
  public static infra3d = new Route({
    path: "/inspectionanalysis/infra3d/:objectId",
    route: Routes.projectStructure,
  });


  // -----------------------------------------------------------------------------------
  // ADMINISTRATION AREA (ambergAdmin)
  // -----------------------------------------------------------------------------------

  public static administration = new Route({
    path: "/administration",
    policy: Policies.toSeeAmbergAdmin,
  });

  public static administrationAccounts = new Route({
    path: "/accounts",
    route: Routes.administration,
  });
  public static administrationAccount = new Route({
    path: "/account/:accountId",
    route: Routes.administration,
  });

  public static administrationAccountUsers = new Route({
    path: "/users",
    route: Routes.administrationAccount,
  });
  public static administrationAccountUser = new Route({
    path: "/user/:userId",
    route: Routes.administrationAccount,
  });
  public static administrationAccountGroup = new Route({
    path: "/group/:groupId",
    route: Routes.administrationAccount,
  });

  public static administrationAccountResources = new Route({
    path: "/resources",
    route: Routes.administrationAccount,
  });
  public static administrationAccountResource = new Route({
    path: "/resource/:resourceId",
    route: Routes.administrationAccount,
  });

  public static administrationAccountLicenses = new Route({
    path: "/licenses",
    route: Routes.administrationAccount,
  });
  public static administrationAccountLicense = new Route({
    path: "/license/:licenseId",
    exact: false,
    route: Routes.administrationAccount,
  });
}
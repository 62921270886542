import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import ButtonConfirm from "../components/ButtonConfirm";
import AvatarLabeled from "../components/labeled/AvatarLabeled";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import MultiSelectComboBoxLabeled
  from "../components/labeled/MultiSelectComboBoxLabeled";
import TagsLabeled from "../components/labeled/TagsLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import PageTitleItem from "../components/PageTitleItem";
import UserPageData from "../dto/pages/UserPageData";
import UpdateUserRequest from "../dto/requests/UpdateUserRequest";
import GroupSummary from "../dto/responses/GroupSummary";
import ResourceSummary from "../dto/responses/ResourceSummary";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import UpdateUserPasswordModal from "../modals/UpdateUserPasswordModal";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";
import LicenseHelper from "../helpers/LicenseHelper";

export interface IAdministrationUserPageProps
  extends RouteComponentProps<IRouteParams> {
  setLocationName: (accountName: string) => void;
}

export interface IAdministrationUserPageState {
  data?: UserPageData;
  id: string;
  loginName: string;
  name: string;
  groups: GroupSummary[];
  ownRoles: string[];
  groupRoles: string[];
  ownResources: ResourceSummary[];
  groupResources: ResourceSummary[];
  allGroups: GroupSummary[];
  allResources: ResourceSummary[];
  allRoles: string[];
  showUpdateUserPasswordModal: boolean;
  showDeleteButton: boolean;
}

class AdministrationUserPage extends React.Component<
  IAdministrationUserPageProps,
  IAdministrationUserPageState
> {
  constructor(props: IAdministrationUserPageProps) {
    super(props);
    this.state = {
      allGroups: [],
      allResources: [],
      allRoles: [],
      groupResources: [],
      groupRoles: [],
      groups: [],
      id: "",
      loginName: "",
      name: "",
      ownResources: [],
      ownRoles: [],
      showUpdateUserPasswordModal: false,
      showDeleteButton: false,
    };
  }

  public render() {
    const {
      data,
      loginName,
      name,
      groups,
      ownRoles,
      groupRoles,
      ownResources,
      groupResources,
      allGroups,
      allRoles,
      allResources,
      showUpdateUserPasswordModal,
      showDeleteButton,
    } = this.state;

    return (
      <div className="page">
        {showUpdateUserPasswordModal && this.renderUpdateUserPasswordModal()}
        <PageTitleItem title={t("labelUser")} testID={"admin-user-page-title"}>
          {showDeleteButton && <ButtonConfirm
            textButton={t("buttonDelete")}
            textPopup={t("textDeleteWarning", {what: t("labelUser")})}
            appearance={"danger"}
            onClick={this.onDelete}
            testId={"delete-user-button"}
          />}
        </PageTitleItem>
        {data && (
          <form style={{margin:0}} onSubmit={this.onSave}>
            <AvatarLabeled
              label={t("labelAvatar")}
              name={""}
              size="xxlarge"
              src={' '}
            />
            <TextBoxLabeled label={t("labelUserName")} disabled={true} value={loginName} />
            <ButtonLabeled
              label={t("labelPassword")}
              onClick={this.showUpdateUserPasswordModal}
              testId={"change-user-password-button"}
              >
              {t("buttonChange")}
            </ButtonLabeled>
            <TextBoxLabeled
              label={t("labelFullName")}
              placeholder={t("labelFullName")}
              value={name}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={value => this.setState({ name: value })}
              testId = {"full-name-textbox"}
            />
            <MultiSelectComboBoxLabeled
              label={t("labelGroups")}
              placeholder={t("labelSelect")}
              options={allGroups.map(group => {
                return {
                  label: group.name,
                  value: group.id,
                };
              })}
              selectedOptions={groups.map(group => {
                return {
                  label: group.name,
                  value: group.id,
                };
              })}
              onChange={this.onGroupSelectionChange}
            />
            <MultiSelectComboBoxLabeled
              label={t("labelLicences")}
              placeholder={t("labelSelect")}
              options={allRoles.map(role => {
                return {
                  label: LicenseHelper.labelForLicense(role),
                  value: role,
                };
              })}
              selectedOptions={ownRoles.map(role => {
                return {
                  label: LicenseHelper.labelForLicense(role),
                  value: role,
                };
              })}
              onChange={this.onRoleSelectionChange}
            />
            <TagsLabeled
              label={t("labelGroupLicences")}
              values={groupRoles.length > 0 ? groupRoles.map(l => LicenseHelper.labelForLicense(l)) : []}
              readonly={true}
            />
            <MultiSelectComboBoxLabeled
              label={t("labelProjects")}
              placeholder={t("labelSelect")}
              options={allResources.map(resource => {
                return {
                  description: resource.type,
                  label: resource.name,
                  value: resource.id,
                };
              })}
              selectedOptions={ownResources.map(resource => {
                return {
                  label: resource.name,
                  value: resource.id,
                };
              })}
              onChange={this.onResourceSelectionChange}
            />
            <TagsLabeled
              label={t("labelGroupProjects")}
              values={groupResources.map(r => r.name)}
              readonly={true}
            />
            <ButtonLabeled
                type="submit"
                appearance={"primary"}
                testId={"save-user-button"}
            >
              {t("buttonSave")}
            </ButtonLabeled>
          </form>
        )}
      </div>
    );
  }

  public async componentDidMount() {
    await this.loadData();
  }

  public componentWillUnmount() {
    AuthService.cancelAllRequests();
  }

  private loadData = async () => {
    try {
      const { accountId, userId } = this.props.match.params;
      const dataUrl = AuthService.isAmbergAdmin()
        ? `administrationuser/${accountId}/${userId}`
        : `user/${userId}`;
      const data = await AuthService.get<UserPageData>(dataUrl);

      const userGroupIds = data.user.groups.map(g => g.id);
      const groups = data.groups.filter(g =>
        userGroupIds.some(value => value === g.id)
      );

      const ownResourceIds = data.user.resources.map(r => r.id);
      const ownResources = data.resources.filter(g =>
        ownResourceIds.some(value => value === g.id)
      );

      const groupResourceIds = data.user.groupResources.map(r => r.id);
      const groupResources = data.resources.filter(g =>
        groupResourceIds.some(value => value === g.id)
      );

      const otherAccount = AuthService.userName !== data.user.loginName;
      this.setState({
        allGroups: data.groups,
        allResources: data.resources,
        allRoles: data.roles,
        data,
        groupResources,
        groupRoles: data.user.groupRoles,
        groups,
        id: data.user.id,
        name: data.user.name,
        loginName: data.user.loginName,
        ownResources,
        ownRoles: data.user.roles,
        showDeleteButton: otherAccount,
      });
      this.props.setLocationName(accountId && data.account.name);
    } catch (error) {
      //
    }
  };

  private showUpdateUserPasswordModal = () => {
    this.setState({
      showUpdateUserPasswordModal: true,
    });
  };

  private hideModals = () => {
    this.setState({
      showUpdateUserPasswordModal: false,
    });
  };

  private renderUpdateUserPasswordModal = () => {
    const { id, loginName, name } = this.state;
    const { accountId } = this.props.match.params;
    const updateUrl = AuthService.isAmbergAdmin()
      ? `administrationuser/${accountId}/password`
      : `user/password`;

    return (
      <UpdateUserPasswordModal
        updateUrl={updateUrl}
        id={id}
        loginName={loginName}
        name={name}
        onOk={this.hideModals}
        onCancel={this.hideModals}
      />
    );
  };

  private onGroupSelectionChange = (options: ISSelectOption[]) => {
    // update inherited and user roles/resouces according to group memberships
    const { allGroups, allRoles, allResources } = this.state;

    const selectedGroupIds = options.map(o => o.value);
    const groups = allGroups.filter(g =>
      selectedGroupIds.some(value => value === g.id)
    );

    const groupRoleIds = Array.from(
      new Set(groups.map(g => g.roles).reduce((a, b) => a.concat(b), []))
    );
    const groupResourceIds = Array.from(
      new Set(
        groups
          .map(g => g.resources)
          .reduce((a, b) => a.concat(b), [])
          .map(resource => resource.id)
      )
    );

    const groupRoles = allRoles.filter(role => groupRoleIds.some(id => id === role));
    const groupResources = allResources.filter(resource =>
      groupResourceIds.some(id => id === resource.id)
    );

    this.setState({
      groupResources,
      groupRoles,
      groups,
    });
  };

  private onRoleSelectionChange = (options: ISSelectOption[]) => {
    const { allRoles } = this.state;
    const selectedRoleIds = options.map(o => o.value);
    const ownRoles = allRoles.filter(role => selectedRoleIds.some(id => id === role));
    this.setState({ ownRoles });
  };

  private onResourceSelectionChange = (options: ISSelectOption[]) => {
    const { allResources } = this.state;
    const selectedResourceIds = options.map(o => o.value);
    const ownResources = allResources.filter(resource =>
      selectedResourceIds.some(id => id === resource.id)
    );
    this.setState({ ownResources });
  };

  private onDelete = async () => {
    const { data, id, name } = this.state;
    if (!data) {
      return;
    }
    try {
      const { accountId } = this.props.match.params;
      await AuthService.delete(AuthService.isAmbergAdmin() ?
        `administrationuser/${accountId}/${id}`
        :
        `user/${id}`
      );
      this.props.history.push(AuthService.isAmbergAdmin() ?
        Routes.administrationAccountUsers.patch(accountId).path
        :
        Routes.users.path
      );
      FlagService.addInfo(t("labelDeleted"), t("textDeleted", {what:t("labelUser"), name}));
    } catch (error) {
      Logger.error(error, "AdministrationUserPage");
      FlagService.addError(t("textDeletionFailed", {what:t("labelUser"), name}), error);
    }
  };

  private onSave = async (event: any) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    const { loginName, id, name, groups, ownRoles, ownResources } = this.state;
    const request: UpdateUserRequest = {
      groups: groups.map(group => group.id),
      id,
      loginName,
      name,
      resources: ownResources.map(resource => resource.id),
      roles: ownRoles,
    };
    try {
      const { accountId } = this.props.match.params;
      await AuthService.put(AuthService.isAmbergAdmin() ?
        `administrationuser/${accountId}`
        :
        `user`,
        request);

      this.props.history.push(AuthService.isAmbergAdmin() ?
        Routes.administrationAccountUsers.patch(accountId).path
        :
        Routes.users.path
      );
      FlagService.addInfo(t("labelSaved"), t("textSaved", {what:t("labelUser"), name}));
    } catch (error) {
      Logger.error(error, "AdministrationUserPage");
      FlagService.addError(t("textSaveFailed", {what:t("labelUser"), name}), error);
    }
  };

  private validate = (): boolean => {
    const { id, name, groups, ownRoles, ownResources } = this.state;
    if (!id) {
      return false;
    }
    if (!name) {
      return false;
    }
    if (!groups) {
      return false;
    }
    if (!ownRoles) {
      return false;
    }
    if (!ownResources) {
      return false;
    }
    return true;
  };
}

export default compose(withNamespaces())(AdministrationUserPage);

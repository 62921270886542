import * as React from "react";
import FeatureType from "../../dto/components/FeatureType";
import FeatureTypeGroupData from "../../dto/components/FeatureTypeGroupData";
import CatalogBase, { ICatalogBaseProps, ICatalogBaseState } from "./CatalogBase";
import CatalogItem from "./CatalogItem";
import FeatureIconProvider from "./FeatureIconProvider";


interface IProps extends ICatalogBaseProps {
    featureTypeGroup: FeatureTypeGroupData,
    currentId?: string,
    hiddenIds?: Set<string>,
    lockedIds?: Set<string>,
    textHiddenIds?: Set<string>,
    onSelect?: (featureType: FeatureType) => void;
    onVisibilityToggle?: (featureId: string) => void;
    onLockToggle?: (featureId: string) => void;
    onTextVisibilityToggle?: (featureId: string) => void;
}

export default class CatalogFeatureTypeGroup extends CatalogBase<IProps, ICatalogBaseState> {

    public getInitialState(): ICatalogBaseState {
        const initialState = super.getInitialState();
        initialState.appearance = "subtle";
        initialState.marginRight = 0;
        return initialState;
    }

    public renderChildren = () => {
        const {featureTypeGroup, currentId } = this.props;

        const featureTypesSorted = featureTypeGroup.featureTypes.sort(
            (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
        );

        const featureTypes: any = [];
        featureTypesSorted.forEach(featureType => {
            const isHidden = this.props.hiddenIds && this.props.hiddenIds.has(featureType.id);
            const isLocked = this.props.lockedIds && this.props.lockedIds.has(featureType.id);
            const isTextHidden = this.props.textHiddenIds && this.props.textHiddenIds.has(featureType.id);


            featureTypes.push(
                <CatalogItem
                    key={featureType.id}
                    name={featureType.name}
                    id={featureType.id}
                    iconBefore={FeatureIconProvider.getFeatureIcon(featureType)}
                    isSelected={(currentId && featureType.id) === currentId || false}
                    onClick={this.getHandleSelectMethod(featureType)}
                    isHidden={isHidden}
                    onVisibilityToggle={this.props.onVisibilityToggle}
                    isLocked={isLocked}
                    onLockToggle={this.props.onLockToggle}
                    isTextHidden={isTextHidden}
                    onTextVisibilityToggle={this.props.onTextVisibilityToggle}
                    testId={`feature-type-id?${featureType.id}`}
                />
            );
        });

        return featureTypes;
    };

    public renderTools = () => {
        const {featureTypeGroup, hiddenIds, lockedIds, textHiddenIds} = this.props;

        const isHidden = hiddenIds ? this.props.featureTypeGroup.featureTypes.every(
            featureType => hiddenIds.has(featureType.id)
        )  : false;
        const isLocked = lockedIds ? this.props.featureTypeGroup.featureTypes.every(
            featureType => lockedIds.has(featureType.id)
        ) : false;
        const isTextHidden = textHiddenIds ? this.props.featureTypeGroup.featureTypes.every(
            featureType => textHiddenIds.has(featureType.id)
        ) : false;

        return FeatureIconProvider.getTools({
            id: featureTypeGroup.id,
            isHidden,
            isLocked,
            isTextHidden,
            onVisibilityChange: this.getToggleVisibilityMethod(isHidden),
            onLockedChange: this.getToggleLockMethod(isLocked),
            onTextVisibilityChange: this.getToggleTextVisibilityMethod(isTextHidden),
        })
    };

    private getHandleSelectMethod = (featureType: FeatureType) => {
        return () => {
            if (this.props.onSelect){
                this.props.onSelect(featureType)
            }
        }
    };


    private getToggleVisibilityMethod = (visible: boolean) => {
        if (this.props.onVisibilityToggle) {
            return (event: any) => {
                this.handleToggle(
                    event,
                    visible,
                    this.props.hiddenIds,
                    this.props.onVisibilityToggle
                );
            }
        }
        return
    };

    private getToggleLockMethod = (lock: boolean) => {
        if (this.props.onLockToggle) {
            return (event: any) => {
                this.handleToggle(
                    event,
                    lock,
                    this.props.lockedIds,
                    this.props.onLockToggle
                );
            }
        }
        return
    };

    private getToggleTextVisibilityMethod = (lock: boolean) => {
        if (this.props.onTextVisibilityToggle) {
            return (event: any) => {
                this.handleToggle(
                    event,
                    lock,
                    this.props.textHiddenIds,
                    this.props.onTextVisibilityToggle
                );
            }
        }
        return
    };

    private handleToggle = (
        event: any,
        state: boolean,
        activeIds?: Set<string>,
        method?: (arg: any) => void
    ) => {
        event.stopPropagation();
        this.props.featureTypeGroup.featureTypes.forEach(featureType => {
            if (method && activeIds) {
                const isActive = activeIds.has(featureType.id);
                if(state && isActive){
                    method(featureType.id)
                }
                if(!state && !isActive){
                    method(featureType.id)
                }

            }
        })
    };
}

import { colors } from '@atlaskit/theme';
import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProjectSummary from '../dto/components/ProjectSummary';
import BadgeWithNumber from './BadgeWithNumber';
import GeographicalMapThumbnail from './GeographicalMapThumbnail';

export interface IProjectPreviewProps extends WithNamespaces {
    projectSummary: ProjectSummary;
}

function ProjectPreview(props: IProjectPreviewProps) {
    const ProjectItem = styled.div`
        &:hover {
            background-color: ${colors.N20};
            -webkit-box-shadow:  5px 5px 5px 0 #ccc;
            -moz-box-shadow: 5px 5px 5px 0 #ccc;
            box-shadow: 5px 5px 5px 0 #ccc; 
        }
    `;

    const SummaryItem = styled.div`
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.6em 1em;
    `;

    const regexLongString = new RegExp(/(\S{24,25})/g);
    const internalProjectName = props.projectSummary.name;
    const subst = `$1 `;
    const projectName = internalProjectName.replace(regexLongString, subst);
    return (
        <ProjectItem className="projectPreview" key={`item_${props.projectSummary.id}`}>
            <Link className="mapLink" to={`/project/${props.projectSummary.id}`} key={props.projectSummary.id}>
                <GeographicalMapThumbnail
                    key={`thumbnail_${props.projectSummary.id}`}
                    mapDiv={`map_${props.projectSummary.id}`}
                    geojson={props.projectSummary.mapData.geoJson}
                    proj4config={props.projectSummary.mapData.proj4Definition}
                    mapProvider={props.projectSummary.mapData.mapProvider}
                />
            </Link>
            <SummaryItem>
                <b>{projectName}</b>
            </SummaryItem>
            <SummaryItem>
                <p>{t("labelStructure")}</p>
                <BadgeWithNumber value={props.projectSummary.numStructures} />
            </SummaryItem>
            <SummaryItem>
                <p>{t("labelInspectionTasks")}</p>
                <BadgeWithNumber value={props.projectSummary.numInspectionTasks} />
            </SummaryItem>
            <SummaryItem>
                <p>{t("labelInspectionAnalyses")}</p>
                <BadgeWithNumber value={props.projectSummary.numInspectionAnalyses} />
            </SummaryItem>
        </ProjectItem>
    );
}

export default withNamespaces()(ProjectPreview)

import { Feature } from "geojson";
import "leaflet";
import * as L from "leaflet";
import { InspectionDrawingTools } from "../dto/components/InspectionDrawingTools";
import InspectionTileLayer from "../dto/components/InspectionTileLayer";
import "../libs/leaflet.boundingbox";
import "./leaflet.testId";
import Logger from "../services/Logger";
import MapGrid from "./grids/MapGrid";
import MapGridBlockBased from "./grids/MapGridBlockBased";
import InspectionFeatureGroup from "./InspectionFeatureGroup";
import InspectionFeatureGroups from "./InspectionFeatureGroups";
import ILayerStyle from "./styles/ILayerStyle";


export default class InspectionMapBasic {
    public readonly innerMap: L.Map;
    public readonly featureGroups: InspectionFeatureGroups;
    public readonly tileLayers: Map<string, L.Layer>;
    public readonly tooltipHandlers: Map<string, (feature: Feature, showAbbreviation: boolean) => string>;
    public keyboardSuppressed: boolean;
    public _currentTool: InspectionDrawingTools;

    public boxZoomHandler =  {
        "boundingbox": (e: any) => {
            if(e.bounds.isValid()){
                this.innerMap.fitBounds(e.bounds, {animate: false})
            }
        }
    };

    constructor(public leafletmapdiv: string, public serviceUrl: string, mapGrid: MapGridBlockBased | MapGrid, usedByReport?: boolean) {
        this.tileLayers = new Map<string, L.Layer>();
        this.tooltipHandlers = new Map<string, (feature: Feature) => any>();
        this.keyboardSuppressed = false;

        this.innerMap = L.map(this.leafletmapdiv, {
            attributionControl: false,
            boxZoom: false,
            contextmenu: true,
            crs: L.CRS.Simple,
            editOptions: {
                drawingCSSClass: 'leaflet-crosshair',
            },
            editable: true,
            zoomDelta: 0.5,
            zoomSnap: 0.1,
            doubleClickZoom: false,
            zoomControl: false,
            zoomAnimation: false,
            fadeAnimation: false,
            markerZoomAnimation: false,
        } as any).setView([0.0, 0.0], 5);

        this.featureGroups = new InspectionFeatureGroups(this.innerMap);

        if(usedByReport){
            L.control.scale({position: 'topright', imperial: false}).addTo(this.innerMap);
        }
        else{
            L.control.scale().addTo(this.innerMap);
        }
        

        this.innerMap.addLayer(mapGrid as any);
        this.attachEventHandlers(this.boxZoomHandler);
        this._currentTool = InspectionDrawingTools.Select;
    }

    public attachEventHandlers = (...handlers: Array<{[key: string]: (event: any) => void}>) => {
        this.innerMap.on(Object.assign({}, ...handlers));
    };

    public attachEventListeners = (...handlers: Array<{[key: string]: (event: any) => void}>) => {
        L.DomEvent.on(document as any, Object.assign({}, ...handlers), this.innerMap);
    };

    public removeEventHandlers = (...handlers: Array<{[key: string]: (event: any) => void}>) => {
        this.innerMap.off(Object.assign({}, ...handlers));
    };

    public invalidateSize () {
        this.innerMap.invalidateSize()
    }

    public switchGrid = (prevGrid: MapGrid | MapGridBlockBased, newGrid: MapGrid | MapGridBlockBased) => {
        this.innerMap.removeLayer(prevGrid as any);
        this.innerMap.addLayer(newGrid as any);
    };

    public setTileLayer = (layerId: string | undefined, inspectionTileLayer?: InspectionTileLayer, options?: { initiallyHidden?: boolean }) => {
        this.selectTileLayer(undefined);
        if (layerId && inspectionTileLayer) {
            this.addTileLayer(layerId, this.serviceUrl, inspectionTileLayer);
            const initiallyHidden = (options && options.initiallyHidden) ? true : false;
            if (!initiallyHidden) {
                this.selectTileLayer(layerId);
            }
        }
    };

    public setTooltipHandler = (layerId: string, onTooltip: (feature: Feature, showAbbreviation: boolean) => string) => {
        this.tooltipHandlers.set(layerId, onTooltip)
    };

    public getExtents = (): L.LatLngBounds => {
        let bounds: any;
        this.featureGroups.forEach(featureGroup => {
            bounds = bounds ? bounds.extend(featureGroup.getBounds()) : featureGroup.getBounds();
        });
        return bounds;
    };

    public deactivateCurrentTool = () => {
        (this.innerMap as any).boundingBox.setAlwaysActive(false);
    };

    public currentTool = () => {
        return this._currentTool
    };

    public changeTool = (tool: InspectionDrawingTools) => {
        switch (tool) {
            case InspectionDrawingTools.BoxZoom:
                (this.innerMap as any).boundingBox.setAlwaysActive(true)
                break;
            default:
                break;
        }

        this._currentTool = tool;
    };

    public addFeatureGroup(featureGroupId: string, style: ILayerStyle, styleLocked?: ILayerStyle) {
        if (this.featureGroups.has(featureGroupId)) {
            Logger.warning(`FeatureGroup ${featureGroupId} does already exist`);
            return this.featureGroups.get(featureGroupId);
        }

        const featureGroup = this.featureGroups.set(new InspectionFeatureGroup({
            id: featureGroupId,
            map: this.innerMap,
            style,
            styleLocked,
        }));
        this.innerMap.addLayer(featureGroup);

        return featureGroup;
    };

    public changeVisibility = (featureGroupId: string, hidden: boolean) => {
        const featureGroup = this.featureGroups.get(featureGroupId);
        if(featureGroup){
            featureGroup.changeVisibility(hidden)
        }
    };

    public forceTooltip = (featureGroupId: string, tooltipForced: boolean) => {
        const featureGroup = this.featureGroups.get(featureGroupId);
        if(featureGroup){
            featureGroup.forceTooltip(tooltipForced)
        }
    };

    public addTileLayer = (id: string, serviceUrl: string, layer: InspectionTileLayer) => {
        if (this.tileLayers.get(id)) {
            return;
        }
        const urlTemplate = `${serviceUrl}${layer.urlTemplate}`;
        const latLngBounds = new L.LatLngBounds(new L.LatLng(layer.minStationing, layer.minDerolment), new L.LatLng(layer.maxStationing, layer.maxDerolment));
        const tileLayer = L.tileLayer(urlTemplate, {
            bounds: latLngBounds,
            maxNativeZoom: layer.maxZoomLevel,
        });
        this.tileLayers.set(id, tileLayer);
    };

    public selectTileLayer = (id: string | undefined) => {
        this.tileLayers.forEach(l => {
            this.innerMap.removeLayer(l);
        });
        if (id) {
            const layer = this.tileLayers.get(id);
            if (layer) {
                this.innerMap.addLayer(layer);
            }
        }
    };

    public layerInBounds = (layer: any, bounds: L.LatLngBounds): boolean => {
        
        return isLayerInBounds(layer, bounds);
    };

    public layerIsPolylineOrPolygon = (layer: any): boolean => {
        return layer.getBounds || false;
    };

    public layerIsPoint = (layer: any): boolean => {
        return layer.getLatLng || false;
    };
}

export const isLayerInBounds = (layer: any, bounds: L.LatLngBounds): boolean => {
    try{
        if (isLayerPolylineOrPolygon(layer)) {
    
            const layerBounds = layer.getBounds();
            if(isLatLngBoundsType(layerBounds)){
                return bounds.contains(layerBounds);
            }
            else{
                return false;
            }
        }
        if (isLayerPoint(layer)) {
            return bounds.contains(layer.getLatLng());
        }
        
        return false;
    }
    catch(error){
        return false;
    };
};

const isLatLngBoundsType = (boundsObj: any): boundsObj is L.LatLngBounds =>{
    const bounds = boundsObj as L.LatLngBounds;
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    return southWest !== undefined && northEast !== undefined;
}

const isLayerPolylineOrPolygon = (layer: any): boolean => {
    return layer.getBounds || false;
};

const isLayerPoint = (layer: any): boolean => {
    return layer.getLatLng || false;
};
declare global {
    interface Window { __DEFAULTS__: string; }
}

interface IEnvConfig{
    SERVICE_HOST: string;
}

const loadConfig = () : IEnvConfig => {
    try{
        return JSON.parse(window && window.__DEFAULTS__) as IEnvConfig
    }
    catch(e)
    {
        console.info(process.env.NODE_ENV);
        console.error(e);
        return { SERVICE_HOST: "" } as IEnvConfig;
    }
}
const config = loadConfig()

const getApiValue = () => {
    if(config.SERVICE_HOST.length !== 0){
        return config.SERVICE_HOST;
    }
    else if(process.env.REACT_APP_BACK_END_HOST_NAME){
        return process.env.REACT_APP_BACK_END_HOST_NAME as string;
    }
    else{
        return "";
    }
}

export const HOST_NAME = window && window.location && window.location.host;
export const API_ROOT = getApiValue();

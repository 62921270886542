import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ButtonConfirm from "../components/ButtonConfirm";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import PageTitleItem from "../components/PageTitleItem";
import AdministrationAccountOverviewPageData
  from "../dto/pages/AdministrationAccountOverviewPageData";
import UpdateAccountRequest from "../dto/requests/UpdateAccountRequest";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import AuthService from "../services/AuthService";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";

export interface IAdministrationAccountOverviewPageProps extends RouteComponentProps<IRouteParams> {
    setLocationName: (accountName: string) => void;
}

export interface IAdministrationAccountOverviewPageState {
    data?: AdministrationAccountOverviewPageData;
    id: string;
    name: string;
    referenceId: string;
}

class AdministrationAccountOverviewPage extends React.Component<IAdministrationAccountOverviewPageProps, IAdministrationAccountOverviewPageState> {
    constructor(props: IAdministrationAccountOverviewPageProps) {
        super(props);
        this.state = {
            id: "",
            name: "",
            referenceId: "",
        };
    }

    public render() {
        const { data, id, name, referenceId } = this.state
        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleAccount")}  testID={"admin-account-overview-page-title"}>
                    <ButtonConfirm
                        textButton={t("buttonDelete")}
                        textPopup={t("textDeleteWarning", {what: t("labelAccount")})}
                        appearance={"danger"}
                        onClick={this.onDelete}
                        testId={"delete-account-button"}
                    />
                </PageTitleItem>
                {data && <form onSubmit={this.onSave}>
                    <TextBoxLabeled
                        label={t("labelId")}
                        disabled={true}
                        value={id}
                        testId = {"id-textbox"}
                    />
                    <TextBoxLabeled
                        label={t("labelCompanyName")}
                        placeholder={t("labelCompanyName")}
                        value={name}
                        // tslint:disable-next-line:jsx-no-lambda
                        onChange={value => this.setState({ name: value })}
                        testId = {"company-name-textbox"}
                    />
                    <TextBoxLabeled
                        label={t("labelCustomerReference")}
                        placeholder={t("labelCustomerReference")}
                        value={referenceId}
                        // tslint:disable-next-line:jsx-no-lambda
                        onChange={value => this.setState({ referenceId: value })}
                        testId = {"customer-reference-textbox"}
                    />
                    <ButtonLabeled
                        type="submit"
                        testId={"save-account-button"}
                    >
                        {t("buttonSave")}
                    </ButtonLabeled>
                </form>}
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<AdministrationAccountOverviewPageData>(`administrationaccountoverview/${this.props.match.params.accountId}`);

            const referenceIdItem = data.details.data.find(_ => _.id === "referenceId");
            const referenceId = (referenceIdItem && referenceIdItem.name) || "";

            this.setState({
                data,
                id: data.details.id,
                name: data.details.name,
                referenceId,
            });
            this.props.setLocationName(data.details.name);
        } catch (error) {
            //
        }
    }

    private onDelete = async () => {
        const { data } = this.state;
        if (!data) {
            return;
        }
        try {
            await AuthService.delete(`administrationaccountoverview/${this.props.match.params.accountId}`);
            this.props.history.push(Routes.administrationAccounts.path);
            FlagService.addInfo(t("labelDeleted"), t("textDeleted", {what:t("labelAccount"), name: data.details.name}));
        } catch (error) {
            Logger.error(error, "AccountOverviewPage");
            FlagService.addError(t("textDeletionFailed", {what:t("labelAccount"), name: data.details.name}), error);
        }
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        if (!this.validate()) {
            return;
        }
        const { id, name, referenceId } = this.state;
        const data = referenceId ? [{ id: "referenceId", name: referenceId }] : [];

        const request: UpdateAccountRequest = {
            data,
            id,
            name,
        };

        try {
            await AuthService.put(`administrationaccountoverview`, request);
            this.props.history.push(Routes.administrationAccounts.path);
            FlagService.addInfo(t("labelSaved"), t("textSaved", {what:t("labelAccount"), name}));
        } catch (error) {
            Logger.error(error, "CreateAccountModal");
            FlagService.addError( t("textSaveFailed", {what:t("labelAccount"), name}), error);
        }
    }

    private validate = (): boolean => {
        const { id, name } = this.state;
        if (!id) {
            return false;
        }
        if (!name) {
            return false;
        }
        return true;
    }
}

export default compose(withNamespaces())(AdministrationAccountOverviewPage)

import { Feature } from "geojson";
import "leaflet";
import * as L from "leaflet";
import * as React from "react";
import Button from ".././atlaskit/Button";
import AddIcon from '@atlaskit/icon/glyph/add'
import EditorDividerIcon from '@atlaskit/icon/glyph/editor/divider';
import MapControlInhibitDrawing from "./MapControlInhibitDrawing";

type State = { currentBlock?: Feature; }

export default class MapZoomControl extends React.Component<{}, State> {
    map: L.Map;

    render() {
        return (
            <div>
            {this.map &&
                <MapControlInhibitDrawing map={this.map}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <Button
                            id={"map-element-top"}
                            onClick={this.handleZoomIn}
                            iconBefore={<AddIcon label={""} size={"medium"} />}
                            testId={"zoom-in-button"}
                        />
                        <Button
                            id={"map-element-bottom"}
                            onClick={this.handleZoomOut}
                            iconBefore={<EditorDividerIcon label={""} size={"medium"} />}
                            testId={"zoom-out-button"}
                        />
                    </div>
                </MapControlInhibitDrawing>
            }
            </div>
        );
    };


    initialize = (map: L.Map) => {
        this.map = map;
    };

    handleZoomIn = () => {
        this.map.setZoom(this.map.getZoom() + 0.5);
    };

    handleZoomOut = () => {
        this.map.setZoom(this.map.getZoom() - 0.5);
    };
}

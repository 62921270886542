import { t } from "i18next";

export default class Proj4Helper {
  public static CH1903 = "21781";
  public static CH1903plus = "2056";
  public static CustomLocalCrs = "Custom";
  public static CustomProj4String = "Proj4";

  public static Proj4HelperProjs: string[] = ['adams_hemi',
  'adams_wsI',
  'adams_wsII',
  'aea',
  'aeqd',
  'airy',
  'aitoff',
  'alsk',
  'apian',
  'apian1',
  'apian2',
  'ardn_cls',
  'arma',
  'august',
  'bacon',
  'baker',
  'baranyi4',
  'barth',
  'bipc',
  'boggs',
  'bonne',
  'braun2',
  'brny_1',
  'brny_2',
  'brny_3',
  'brny_4',
  'brny_5',
  'brny_6',
  'brny_7',
  'bromley',
  'calcofi',
  'cass',
  'cc',
  'cea',
  'chamb',
  'collg',
  'crast',
  'cyl_stere',
  'denoy',
  'dummy',
  'eck_greif',
  'eck1',
  'eck2',
  'eck3',
  'eck4',
  'eck5',
  'eck6',
  'eisen',
  'eq_moll',
  'eqc',
  'eqdc',
  'erdi_krusz',
  'etmerc',
  'euler',
  'fahey',
  'fc_ar',
  'fc_gen',
  'fc_pe',
  'fc_pp',
  'fouc',
  'fouc_s',
  'four1',
  'four2',
  'ftmerc',
  'gall',
  'gbtmerc',
  'gen_ts',
  'geocent',
  'geos',
  'gilbert',
  'gins8',
  'gn_sinu',
  'gnom',
  'goode',
  'gs48',
  'gs50',
  'gstmerc',
  'guyou',
  'hammer',
  'hatano',
  'healpix',
  'hill',
  'holzel',
  'igh',
  'imw_p',
  'isea',
  'kav5',
  'kav7',
  'kh_sh',
  'krovak',
  'ktmerc',
  'labrd',
  'laea',
  'lagrng',
  'larr',
  'lask',
  'latlon',
  'latlong',
  'lcc',
  'lcca',
  'leac',
  'lee_os',
  'longlat',
  'lonlat',
  'loxim',
  'lsat',
  'maurer',
  'mayr',
  'mb_S3',
  'mbt_fps',
  'mbt_s',
  'mbtfpp',
  'mbtfpq',
  'mbtfps',
  'merc',
  'mil_os',
  'mill',
  'mill_2',
  'moll',
  'mpoly',
  'murd1',
  'murd2',
  'murd3',
  'natearth',
  'near_con',
  'nell',
  'nell_h',
  'nicol',
  'nsper',
  'nzmg',
  'ob_tran',
  'ocea',
  'oea',
  'omerc',
  'ortel',
  'ortho',
  'oxford',
  'pconic',
  'poly',
  'psfig:mb P3',
  'psfig:mb Q3',
  'psfig:mb S2',
  'put2',
  'putp1',
  'putp1p',
  'putp2',
  'putp3',
  'putp3p',
  'putp4',
  'putp4p',
  'putp5',
  'putp5p',
  'putp6',
  'putp6p',
  'qsc',
  'qua_aut',
  'rhealpix',
  'robin',
  'rouss',
  'rpoly',
  's_min_err',
  'sch',
  'siemon4',
  'sinu',
  'somerc',
  'stere',
  'sterea',
  'tcc',
  'tcea',
  'times',
  'tissot',
  'tmerc',
  'tob_g1',
  'tob_sqr',
  'tobler_1',
  'tobler_2',
  'tpeqd',
  'tpers',
  'ups',
  'urm_2',
  'urm_3C',
  'urm5',
  'urmfps',
  'utm',
  'vandg',
  'vandg2',
  'vandg3',
  'vandg4',
  'vitk1',
  'wag1',
  'wag2',
  'wag3',
  'wag4',
  'wag5',
  'wag6',
  'wag7',
  'wag8',
  'wag9',
  'weren',
  'weren2',
  'weren3',
  'wink1',
  'wink2',
  'wintri'];

  public static Proj4HelperUnits: string[] = ['ch',
  'cm',
  'dm',
  'fath',
  'ft',
  'in',
  'ind-ch',
  'ind-ft',
  'ind-yd',
  'km',
  'kmi',
  'link',
  'm',
  'mi',
  'mm',
  'us-ch',
  'us-ft',
  'us-in',
  'us-mi',
  'us-yd',
  'yd'];

  public static Proj4HelperDatums: string[] = ['carthage',
  'GGRS87',
  'hermannskogel',
  'ire65',
  'NAD27',
  'NAD83',
  'nzgd49',
  'OSGB36',
  'potsdam',
  'WGS84']

  public static Proj4HelperPrimeMeridians: string[] = ['athens',
  'bern',
  'bogota',
  'brussels',
  'ferro',
  'greenwich',
  'jakarta',
  'lisbon',
  'madrid',
  'oslo',
  'paris',
  'rome',
  'stockholm']

  public static Proj4HelperSpheroids: string[] = ['airy',
  'andrae',
  'APL4.9',
  'aust_SA',
  'bessnam',
  'bessel',
  'clrk66',
  'clrk80',
  'CPM',
  'delmbr',
  'engelis',
  'evrst30',
  'evrst48',
  'evrst56',
  'evrst69',
  'evrstSS',
  'fschr60',
  'fschr60m',
  'fschr68',
  'GRS67',
  'GRS80',
  'helmert',
  'hough',
  'IAU76',
  'intl',
  'kaula',
  'krass',
  'lerch',
  'MERIT',
  'modairy',
  'mprts',
  'newintl',
  'NWL9D',
  'plessis',
  'SEasia',
  'SGS85',
  'sphere',
  'walbeck',
  'WGS60',
  'WGS66',
  'WGS72',
  'WGS84']

  public static Proj4HelperParams = {
    A: function(v: string) {
      return true;
    },
    a: function(v: string) {
      return !isNaN(Number(v));
    },
    alpha: function(v: string) {
      return !isNaN(Number(v));
    },
    aperture: function(v: string) {
      return true;
    },
    axis: function(v: string) {
      var legalAxis = "ewnsud";
      if (v.length === 3 && legalAxis.indexOf(v.substr(0, 1)) !== -1 && legalAxis.indexOf(v.substr(1, 1)) !== -1 && legalAxis.indexOf(v.substr(2, 1)) !== -1) {
        return true;
      }
      return false;
    },
    azi: function(v: string) {
      return true;
    },
    b: function(v: string) {
      return !isNaN(Number(v));
    },
    belgium: function(v: string) {
      return true;
    },
    beta: function(v: string) {
      return true;
    },
    catalog: function(v: string) {
      return true;
    },
    czech: function(v: string) {
      return true;
    },
    date: function(v: string) {
      return true;
    },
    datum: function(v: string){
      return Proj4Helper.Proj4HelperDatums.includes(v);
    },
    del_A: function(v: string) {
      return true;
    },
    del_S: function(v: string) {
      return true;
    },
    e: function(v: string) {
      return !isNaN(Number(v));
    },
    ellps: function(v: string){
      return Proj4Helper.Proj4HelperSpheroids.includes(v);
    },
    es: function(v: string) {
      return !isNaN(Number(v));
    },
    f: function(v: string) {
      return !isNaN(Number(v));
    },
    from_greenwich: function(v: string) {
      return !isNaN(Number(v));
    },
    gamma: function(v: string) {
      return true;
    },
    geoc: function(v: string) {
      return true;
    },
    geoidgrids: function(v: string) {
      return true;
    },
    guam: function(v: string) {
      return true;
    },
    h: function(v: string) {
      return true;
    },
    h_0: function(v: string) {
      return true;
    },
    init: function(v: string) {
      return true;
    },
    inv: function(v: string) {
      return true;
    },
    K: function(v: string) {
      return true;
    },
    k: function(v: string) {
      return !isNaN(Number(v));
    },
    k_0: function(v: string) {
      return !isNaN(Number(v));
    },
    lat_0: function(v: string) {
      return !isNaN(Number(v));
    },
    lat_1: function(v: string) {
      return !isNaN(Number(v));
    },
    lat_2: function(v: string) {
      return !isNaN(Number(v));
    },
    lat_b: function(v: string) {
      return !isNaN(Number(v));
    },
    lat_t: function(v: string) {
      return !isNaN(Number(v));
    },
    lat_ts: function(v: string) {
      return !isNaN(Number(v));
    },
    lon_0: function(v: string) {
      return !isNaN(Number(v));
    },
    lon_1: function(v: string) {
      return !isNaN(Number(v));
    },
    lon_2: function(v: string) {
      return !isNaN(Number(v));
    },
    lon_wrap: function(v: string) {
      return !isNaN(Number(v));
    },
    lonc: function(v: string) {
      return !isNaN(Number(v));
    },
    lsat: function(v: string) {
      return true;
    },
    m: function(v: string) {
      return true;
    },
    M: function(v: string) {
      return true;
    },
    mode: function(v: string) {
      return true;
    },
    n: function(v: string) {
      return true;
    },
    n_A: function(v: string) {
      return true;
    },
    n_S: function(v: string) {
      return true;
    },
    nadgrids: function(v: string) {
      return true;
    },
    no_cut: function(v: string) {
      return true;
    },
    no_defs: function(v: string) {
      return true;
    },
    no_off: function(v: string) {
      return true;
    },
    no_rot: function(v: string) {
      return true;
    },
    no_uoff: function(v: string) {
      return true;
    },
    north_square: function(v: string) {
      return true;
    },
    npole: function(v: string) {
      return true;
    },
    ns: function(v: string) {
      return true;
    },
    o_alpha: function(v: string) {
      return true;
    },
    o_lat_1: function(v: string) {
      return true;
    },
    o_lat_2: function(v: string) {
      return true;
    },
    o_lat_c: function(v: string) {
      return true;
    },
    o_lat_p: function(v: string) {
      return true;
    },
    o_lon_1: function(v: string) {
      return true;
    },
    o_lon_2: function(v: string) {
      return true;
    },
    o_lon_c: function(v: string) {
      return true;
    },
    o_lon_p: function(v: string) {
      return true;
    },
    o_proj: function(v: string) {
      return true;
    },
    orient: function(v: string) {
      return true;
    },
    over: function(v: string) {
      return true;
    },
    p: function(v: string) {
      return true;
    },
    path: function(v: string) {
      return true;
    },
    phdg_0: function(v: string) {
      return true;
    },
    plat_0: function(v: string) {
      return true;
    },
    plon_0: function(v: string) {
      return true;
    },
    pm: function(v: string) {
      if(Proj4Helper.Proj4HelperPrimeMeridians.includes(v)){
        return true;
      }
      if(!isNaN(Number(v))){
        return true;
      }
      return false;
    },
    proj: function(v: string){
      return Proj4Helper.Proj4HelperProjs.includes(v);
    },
    q: function(v: string) {
      return true;
    },
    R: function(v: string) {
      return true;
    },
    R_A: function(v: string) {
      return true;
    },
    r_A: function(v: string) {
      return true;
    },
    R_a: function(v: string) {
      return true;
    },
    R_g: function(v: string) {
      return true;
    },
    R_h: function(v: string) {
      return true;
    },
    R_lat_a: function(v: string) {
      return true;
    },
    R_lat_g: function(v: string) {
      return true;
    },
    R_V: function(v: string) {
      return true;
    },
    rescale: function(v: string) {
      return true;
    },
    resolution: function(v: string) {
      return true;
    },
    rf: function(v: string){
      return !isNaN(Number(v));
    },
    rot: function(v: string) {
      return true;
    },
    rot_conv: function(v: string) {
      return true;
    },
    S: function(v: string) {
      return true;
    },
    s: function(v: string) {
      return true;
    },
    south: function(v: string) {
      return true;
    },
    south_square: function(v: string) {
      return true;
    },
    spole: function(v: string) {
      return true;
    },
    sweep: function(v: string) {
      return true;
    },
    sym: function(v: string) {
      return true;
    },
    syn: function(v: string) {
      return true;
    },
    t: function(v: string) {
      return true;
    },
    theta: function(v: string) {
      return true;
    },
    tilt: function(v: string) {
      return true;
    },
    title: function(v: string) {
      return true;
    },
    to: function(v: string) {
      return true;
    },
    to_meter: function(v: string) {
      return !isNaN(Number(v));
    },
    towgs84: function(v: string) {
      var nums = v.split(",");
      for (var num in nums) {
        if (isNaN(Number(num))) {
          return false;
        }
      }
      return true;
    },
    units: function(v: string) {    
      return Proj4Helper.Proj4HelperUnits.includes(v); 
    },
    vopt: function(v: string) {
      return true;
    },
    vto_meter: function(v: string) {
      return true;
    },
    vunits: function(v: string) {
      return true;
    },
    W: function(v: string) {
      return true;
    },
    westo: function(v: string) {
      return true;
    },
    x_0: function(v: string) {
      return !isNaN(Number(v));
    },
    y_0: function(v: string) {
      return !isNaN(Number(v));
    },   
    zone: function(v: string) {
      return !isNaN(Number(v));
    },
    
  };

  public static supportedCoordinateSystems = (): Map<string, string> => {
    return new Map([
      [Proj4Helper.CH1903plus, t("coordinateSystemCH1903plus")],
      [Proj4Helper.CH1903, t("coordinateSystemCH1903")],
      [Proj4Helper.CustomLocalCrs, t("coordinateSystemCustomLocal")],
      [Proj4Helper.CustomProj4String, t("coordinateSystemCustomProj4")],
    ]);
  };

  public static getNumericParameterFromProj4 = (
    proj4String?: string,
    parameter?: string
  ): number | undefined => {
    if (proj4String && parameter) {
      const match = proj4String.match(`(\\${parameter}=)([\\d]*[\\.]{0,1}[\\d]*)`);
      if (match) {
        return Number(match[2]);
      }
    }
    return undefined;
  };

  public static latLonBoundsForEPSG = (
    epsgCode: string | undefined
  ): [[number, number], [number, number]] => {
    switch (epsgCode) {
      case Proj4Helper.CH1903plus:
        return [[45.82, 5.96], [47.81, 10.4]];
      case Proj4Helper.CH1903:
        return [[45.82, 5.96], [47.81, 10.49]];
      default:
        return [[-180, -90], [180, 90]];
    }
  };

  public static createCustomProj4String = (
    originLatitude: number,
    originLongitude: number,
    falseEasting: number,
    falseNorthing: number
  ): string => {
    return `+proj=tmerc +lat_0=${originLatitude} +lon_0=${originLongitude} +k=1 +x_0=${falseEasting} +y_0=${falseNorthing} +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs`;
  };

  public static proj4StringForEPSG = (epsgCode: string): string | undefined => {
    switch (epsgCode) {
      case "2056":
        return "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs";
      case "21781":
        return "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs";
      default:
        return undefined;
    }
  };

  public static validateProj4String = (
    proj4String: string | undefined
  ): boolean => {

    var paramObj = proj4String!.split('+').map(function(v) {
      return v.trim();
    }).filter(function(a) {
      return a;
    }).reduce(function(p, a) {
      var split = a.split('=');
      split.push("");
      p[split[0].toLowerCase()] = split[1];
      return p;
    }, {});

    var hasProj = false;
    var hasLat = false;
    var hasLon = false;

    for (var paramName in paramObj) {
      var paramVal = paramObj[paramName];
      if (paramName in Proj4Helper.Proj4HelperParams) {

        if(paramName === "proj") {
          hasProj = true;
        }
        if(paramName === "lat_0") {
          hasLat = true;
        }
        if(paramName === "lon_0") {
          hasLon = true;
        }

        var paramfunc = Proj4Helper.Proj4HelperParams[paramName];
        if(!paramfunc(paramVal)){
          return false;
        }
      }
      else {
        return false;
      }
    }
    return hasProj && hasLat && hasLon;
  }
}

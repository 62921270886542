import { Grid, GridColumn } from "@atlaskit/page";

import * as React from "react";
import { ThemeProvider } from "styled-components";

export interface ILabelWrapperProps {
  label?: string;
  children?: any;
  labelMarginTop?: number;
}

export default function LabelWrapper(props: ILabelWrapperProps) {
  return (
    <ThemeProvider
        theme={{}}
    >
      <div style={{ marginTop: 6 }}>
        <Grid>
          <GridColumn medium={2}>
            <div style={{ float: "right",   marginTop:  props.labelMarginTop || 18 }}>
              <label style={{
                fontSize: "0.8571428571428571em",
                fontStyle: "inherit",
                lineHeight: 1.3333333333333333,
                color: "#6B778C",
                fontWeight: 600,
                display: "inline-block",
                marginBottom: 4,
                marginTop: 0,
                textAlign:"right",
              }}>{props.label|| ""}</label>
            </div>
          </GridColumn>
          <GridColumn medium={10}>{props.children}</GridColumn>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

import * as React from "react";
import Tag, { ITagProps } from "../Tag";
import LabelWrapper, { ILabelWrapperProps } from "./Wrapper";

interface ITagsLabeled extends ILabelWrapperProps, ITagProps {}

export default function TagLabeled(props: ITagsLabeled) {
  const { label, children, labelMarginTop, ...rest } = props as any;
  return (
    <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
      <Tag label={""} {...rest}>
        {children}
      </Tag>
    </LabelWrapper>
  );
}

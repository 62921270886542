import { DatePicker } from "@atlaskit/datetime-picker";
import { Field } from "@atlaskit/form";
//import { t } from "i18next";
import React from "react";

export interface IDateSelectProps {
  label?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  //validate?: (value: string) => boolean;
}

export default function DateSelect(props: IDateSelectProps) {
  return (
      <Field
        name='dateSelect'
        isRequired={props.required || false}
        /*
        validators={
          props.validate && [
            <Validator
              key="validator"
              func={props.validate}
              invalid={t("labelInvalid")}
              valid={t("labelOk")}
            />,
          ]
        }
        */
       
      >
        {() => (
        <DatePicker
          name="datePicker"
          value={props.value}
          isDisabled={props.disabled || false}
          // tslint:disable-next-line:jsx-no-lambda
          onChange={(value: string) => props.onChange && props.onChange(value)}
          hideIcon={true}
        />
        )}
      </Field>
  );
}

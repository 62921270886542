import InspectionMapBase, { IInspectionMapBaseProps } from "../components/InspectionMapBase";
import InspectionDrawing from "../dto/components/InspectionDrawing";
import MapPageBase, { IMapPageBaseProps, IMapPageBaseState } from "./MapPageBase";


export interface IDoubleMapPageBaseProps extends IMapPageBaseProps {
}


export interface IDoubleMapPageBaseState extends IMapPageBaseState {
    secondBlockDefinitionSelected: boolean;
    secondClassificationSelected: boolean;
    secondInspectionDrawingId: string;
    secondSectorDefinitionSelected: boolean;
    secondTheoreticalSectionsSelected: boolean;
    secondTileLayerId: string;
}


export default class DoubleMapPageBase<P extends IDoubleMapPageBaseProps, S extends IDoubleMapPageBaseState> extends MapPageBase<P, S> {
    public secondMap: InspectionMapBase<IInspectionMapBaseProps, {}> | null;

    constructor(props: P) {
        super(props);
        this.state = this.getInitialState();
    }

    public getInitialState(): S {
        const initialState = super.getInitialState();
        initialState.secondBlockDefinitionSelected = false;
        initialState.secondClassificationSelected = false;
        initialState.secondInspectionDrawingId = "";
        initialState.secondSectorDefinitionSelected = false;
        initialState.secondTheoreticalSectionsSelected = false;
        initialState.secondTileLayerId = "";
        return initialState;
    }

    public async componentDidMount() {
        await super.componentDidMount();
        this.linkMaps();
    }

    public linkMaps = () => {
        if (this.map && this.secondMap) {
            (this.map.map.innerMap as any).sync(this.secondMap.map.innerMap);
            (this.secondMap.map.innerMap as any).sync(this.map.map.innerMap);
        }
    };

    public getSecondDrawing = (inspectionDrawings: InspectionDrawing[]): InspectionDrawing => {
        for (const inspectionDrawing of inspectionDrawings) {
            if (inspectionDrawing.id === this.state.secondInspectionDrawingId) {
                return inspectionDrawing;
            }
        }
        return inspectionDrawings[-1];
    };

    public onSecondInspectionDrawingSelectionChange = (id: string) => {
        this.setState({ secondInspectionDrawingId: id });
    };

    public onSecondTileLayerSelectionChange = (tileLayerId: string) => {
        this.setState({ secondTileLayerId: tileLayerId });
    };

    public onSecondBlockDefinitionSelectionChange = () => {
        this.setState(prevState => {
            return { secondBlockDefinitionSelected: !prevState.secondBlockDefinitionSelected };
        });
    };

    public onSecondClassificationSelectionChange = () => {
        this.setState(prevState => {
            return { secondClassificationSelected: !prevState.secondClassificationSelected };
        });
    };

    public onSecondSectorDefinitionSelectionChange = () => {
        this.setState(prevState => {
            return { secondSectorDefinitionSelected: !prevState.secondSectorDefinitionSelected };
        });
    };

    public onSecondTheoreticalSectionsSelectionChange = () => {
        this.setState(prevState => {
            return { secondTheoreticalSectionsSelected: !prevState.secondTheoreticalSectionsSelected };
        });
    };
}
import { Field } from "@atlaskit/form";
//import { t } from "i18next";
import React from "react";
import { CheckboxSelectTouch, ISSelectOption } from "./atlaskit/SelectTouch";


export interface IMultiSelectComboBoxProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options?: ISSelectOption[];
  required?: boolean;
  selectedOptions?: ISSelectOption[];
  styles?: any;
  onChange?: (options: ISSelectOption[]) => void;
  //validate?: (options: ISSelectOption[]) => boolean;
}

const formatOptionLabel = (option: ISSelectOption, { context }: any) => {
  if (context === "menu") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>{option.label}</div>
        {option.description ? (
          <div
            style={{
              fontSize: 12,
              fontStyle: "italic",
            }}
          >
            {option.description}
          </div>
        ) : null}
      </div>
    );
  }
  return option.label;
};

export default function MultiSelectComboBox(props: IMultiSelectComboBoxProps) {
  return (
      <Field
        name="multiSelectComboBox"
        isRequired={props.required || false}
        /*
        validators={
          props.validate && [
            <Validator
              key="validator"
              func={props.validate}
              invalid={t("labelInvalid")}
              valid={t("labelOk")}
            />,
          ]
        }
        */
      >
        {() => (
        <CheckboxSelectTouch
            id={props.id}
            formatOptionLabel={formatOptionLabel}
            name="multiselect"
            placeholder={props.placeholder}
            options={props.options}
            value={props.selectedOptions}
            onChange={props.onChange}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }), ...props.styles }}
        />
        )}
      </Field>
  );
}

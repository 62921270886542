import Spinner from "@atlaskit/spinner";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "recompose";
import InspectionMapBase from "../components/InspectionMapBase";
import PageTitleItem from "../components/PageTitleItem";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import CatalogLayerAnalysis from "../components/toolbar/CatalogLayerAnalysis";
import InspectionAnalysisMapsPageData
    from "../dto/pages/InspectionAnalysisMapsPageData";
import "../libs/leaflet.sync";
import AuthService from "../services/AuthService";
import DoubleMapPageBase, {
    IDoubleMapPageBaseProps,
    IDoubleMapPageBaseState,
} from "./DoubleMapPageBase";


export interface IInspectionAnalysisSideBySideMapsPageState extends IDoubleMapPageBaseState {
    data?: InspectionAnalysisMapsPageData;
}

class InspectionAnalysisSideBySideMapsPage extends DoubleMapPageBase<IDoubleMapPageBaseProps, IInspectionAnalysisSideBySideMapsPageState> {

    public render() {
        const {
            blockDefinitionSelected,
            classificationSelected,
            data,
            inspectionDrawingId,
            secondBlockDefinitionSelected,
            secondClassificationSelected,
            secondInspectionDrawingId,
            secondSectorDefinitionSelected,
            secondTheoreticalSectionsSelected,
            secondTileLayerId, hiddenFeatureTypeCodes,
            sectorDefinitionSelected,
            textHiddenFeatureTypeCodes,
            theoreticalSectionsSelected,
            tileLayerId,
            waiting,
        } = this.state;

        const classification = data && data.inspectionClassifications.find(l => l.id === inspectionDrawingId);
        const secondClassification = data && data.inspectionClassifications.find(l => l.id === secondInspectionDrawingId);
        const showSpinner = !data && waiting;

        return (
            <div className="pageMap">
                <PageTitleItem title={t("pageTitleSideBySideMaps")}  testID={"inspection-analysis-side-by-side-maps-page-title"}/>
                <div>
                    {showSpinner && (
                        <Spinner size={"large"}/>
                    )}
                </div>
                <div className="pageMaximized">
                    {data &&
                    <div className="mapWithStationingSlider">
                        <InspectionMapBase
                            blockDefinition={data.blockDefinitionMapData}
                            blockDefinitionSelected={blockDefinitionSelected}
                            catalog={data.catalog}
                            classification={classification && classification.mapData}
                            classificationSelected={classificationSelected}
                            hideSectorSelection={true}
                            hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                            inspectionDrawing={this.getDrawing(data.inspectionDrawings)}
                            ref={instance => this.map = instance}
                            sectorDefinition={data.sectorDefinitionMapData}
                            sectorDefinitionSelected={sectorDefinitionSelected}
                            selectedTileLayerId={tileLayerId}
                            textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                            theoreticalSections={data.theoreticalSectionsMapData}
                            theoreticalSectionsSelected={theoreticalSectionsSelected}
                            tileLayers={data.tileLayers}
                        />
                        <div style={{
                            backgroundColor: "white",
                            width: 4,
                            height: "100%",
                        }}/>
                        <InspectionMapBase
                            blockDefinition={data.blockDefinitionMapData}
                            blockDefinitionSelected={secondBlockDefinitionSelected}
                            catalog={data.catalog}
                            classification={secondClassification && secondClassification.mapData}
                            classificationSelected={secondClassificationSelected}
                            hideSectorSelection={true}
                            hiddenFeatureTypeCodes={hiddenFeatureTypeCodes}
                            inspectionDrawing={this.getSecondDrawing(data.inspectionDrawings)}
                            ref={instance => this.secondMap = instance}
                            sectorDefinition={data.sectorDefinitionMapData}
                            sectorDefinitionSelected={secondSectorDefinitionSelected}
                            selectedTileLayerId={secondTileLayerId}
                            textHiddenFeatureTypeCodes={textHiddenFeatureTypeCodes}
                            theoreticalSections={data.theoreticalSectionsMapData}
                            theoreticalSectionsSelected={secondTheoreticalSectionsSelected}
                            tileLayers={data.tileLayers}
                        />
                    </div>
                    }
                    {data &&
                    <div className="sideBar">
                        <div
                            style={{ flex: "1 1 auto", width: "100%", height: "100%" }}>
                            <Scrollbar>
                                <CatalogLayerAnalysis
                                    blockDefinitionSelected={blockDefinitionSelected}
                                    classificationDataSelected={classificationSelected}
                                    inspectionDrawings={data.inspectionDrawings}
                                    name={t("labelMapLeft")}
                                    onBlockDefinitionSelectionChange={this.onBlockDefinitionSelectionChange}
                                    onClassificationDataSelectionChange={this.onClassificationSelectionChange}
                                    onInspectionDrawingSelectionChange={this.onInspectionDrawingSelectionChange}
                                    onSectorDefinitionSelectionChange={this.onSectorDefinitionSelectionChange}
                                    onTheoreticalSectionsSelectionChange={this.onTheoreticalSectionsSelectionChange}
                                    onTileLayerSelectionChange={this.onTileLayerSelectionChange}
                                    sectorDefinitionSelected={sectorDefinitionSelected}
                                    selectedInspectionDrawingId={inspectionDrawingId}
                                    selectedTileLayerId={tileLayerId}
                                    testId={"layer-catalog"}
                                    theoreticalSectionsSelected={theoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                                />
                                <Separator/>
                                <CatalogLayerAnalysis
                                    blockDefinitionSelected={this.state.secondBlockDefinitionSelected}
                                    classificationDataSelected={this.state.secondClassificationSelected}
                                    inspectionDrawings={data.inspectionDrawings}
                                    name={t("labelMapRight")}
                                    onBlockDefinitionSelectionChange={this.onSecondBlockDefinitionSelectionChange}
                                    onClassificationDataSelectionChange={this.onSecondClassificationSelectionChange}
                                    onInspectionDrawingSelectionChange={this.onSecondInspectionDrawingSelectionChange}
                                    onSectorDefinitionSelectionChange={this.onSecondSectorDefinitionSelectionChange}
                                    onTheoreticalSectionsSelectionChange={this.onSecondTheoreticalSectionsSelectionChange}
                                    onTileLayerSelectionChange={this.onSecondTileLayerSelectionChange}
                                    sectorDefinitionSelected={this.state.secondSectorDefinitionSelected}
                                    selectedInspectionDrawingId={this.state.secondInspectionDrawingId}
                                    selectedTileLayerId={this.state.secondTileLayerId}
                                    testId={"layer-catalog"}
                                    theoreticalSectionsSelected={this.state.secondTheoreticalSectionsSelected}
                                    tileLayers={data.tileLayers}
                                />
                                <Separator/>
                                <CatalogFeatureType
                                    catalog={data.catalog}
                                    onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                    onTextVisibilityChange={this.onFeatureTypeTextVisibilityChange}
                                    testId={"feature-type-catalog"}
                                />
                            </Scrollbar>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        super.componentDidMount();
    }

    public loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisMapsPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/mapdata`);

            this.setState({
                data: new InspectionAnalysisMapsPageData(data),
                tileLayerId: data.tileLayers[0].id,
                secondTileLayerId: data.tileLayers[data.tileLayers.length - 1].id,
                inspectionDrawingId: data.inspectionDrawings[0].id,
                secondInspectionDrawingId: data.inspectionDrawings[data.tileLayers.length - 1].id,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            //
            this.setState({waiting:false})
        }
    };
}

export default compose(withNamespaces())(InspectionAnalysisSideBySideMapsPage);

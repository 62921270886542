import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from "../../services/AuthService";

export default function AuthenticatedUserRoute(
  {
    component: Component,
    permits,
    ...rest
  }: any
) {
  return (
    <Route exact={true} {...rest} render={
  // tslint:disable-next-line:jsx-no-lambda
  (routeProps) => {
    return AuthService.isAuthenticatedUser() ?
      AuthService.isAmbergAdmin() ?
        <Redirect to={{
            pathname: "/administration",
            state: { from: routeProps.location },
          }}
        />
      :
        AuthService.hasNoLicences() ?
          <Redirect to={{
            pathname: "/userprofile",
            state: { from: routeProps.location },
          }}
        />
        :
          <Component {...routeProps} />
  :
    <Redirect to={{
      pathname: "/login",
        state: { from: routeProps.location },
    }}
    />;
  }}/>
);
}
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import Policies from "../../helpers/Policies";
import UnauthorizedPage from "../../pages/UnauthorizedPage";
import AuthService from "../../services/AuthService";

export default function AuthenticatedUserRoute(
  {
    component: Component,
    policy,
    ...rest
  }: any
) {
  return (
    <Route exact={true} {...rest} render={
      // tslint:disable-next-line:jsx-no-lambda
      (routeProps) => {
        return AuthService.isAuthenticatedUser() ?
          Policies.isValidPolicy(policy) ?
            <Component {...routeProps} />
            :
            <UnauthorizedPage/>
          :
          <Redirect to={{
            pathname: "/login",
            state: { from: routeProps.location },
          }}
          />;
      }}/>
  );
}

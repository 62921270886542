import Logger from '../services/Logger';
import IApplicationBridge from './IApplicationBridge';
import { API_ROOT } from "../Environment";

declare const CefSharp: any;

declare const fieldApplicationBridgeAsync: IApplicationBridge;

class FieldBrowserSupport {
    private static readonly RequiredApplicationBridgeMajorVersion = 1; 
    private state = 0;
    // 0 - not initialized;
    // 1 - initialized in a normal browser;
    // 2 - initialized in the field browser;
    // 3 - initialized in the field browser - offline mode;
    // -1 - field browser, invalid version
    public initialize = async (): Promise<void> => {
        try
        {
            const appBridge = await this.bridge();
            const majorVersion = await appBridge.majorVersion();
            if (majorVersion !== FieldBrowserSupport.RequiredApplicationBridgeMajorVersion)
            {
                Logger.error(`CEF - fieldApplicationBridgeAsync is found but version (${majorVersion}) is incompatible (${FieldBrowserSupport.RequiredApplicationBridgeMajorVersion})`);
                this.state = -1;
                return;
            }
            Logger.trace(`CEF backend version: ${majorVersion}`);
            // It also enable field browser backend's man-in-the-middle
            await appBridge.setServiceUrl(API_ROOT);
            const offlineMode = await appBridge.isOfflineMode();
            this.releaseBridge();
            this.state = (offlineMode ? 3 : 2);
        }
        catch (err) {
            this.state = 1;
        }
    }
    public bridge = async (): Promise<IApplicationBridge> => {
        if (typeof fieldApplicationBridgeAsync !== 'undefined')
        {
            return fieldApplicationBridgeAsync;
        }
        if (typeof CefSharp === 'undefined')
        {
            return Promise.reject();
        }
        Logger.trace(`CEF backend found`);
        await CefSharp.BindObjectAsync('fieldApplicationBridgeAsync');
        if (typeof fieldApplicationBridgeAsync === 'undefined')
        {
            Logger.error(`CEF - fieldApplicationBridgeAsync is not found`);
            return Promise.reject();
        }
        return fieldApplicationBridgeAsync;
    }
    public releaseBridge = () => {
        const b = CefSharp.DeleteBoundObject("fieldApplicationBridgeAsync");
        if (b) {
            Logger.trace(`CEF bridge released`);
        } else {
            Logger.error(`CEF - fieldApplicationBridgeAsync is not found`);
        }
    }
    public isFieldBrowser = (): boolean => {
        if (this.state === 0)
        {
            throw new Error("FieldBrowser is not yet initialized");
        }
        return this.state === 2 || this.state === 3;
    }
    public isIncompatibleFieldBrowser = (): boolean => {
        if (this.state === 0)
        {
            throw new Error("FieldBrowser is not yet initialized");
        }
        return this.state === -1;
    }
    public isOfflineMode = (): boolean => {
        if (!this.isFieldBrowser()) {
            return false;
        }
        return this.state === 3;
    }
    public setOfflineMode = async(mode: boolean) => {
        if (!this.isFieldBrowser()) {
            return;
        }
        const appBridge = await this.bridge();
        await appBridge.setOfflineMode(mode);
        const offlineMode = await appBridge.isOfflineMode();
        this.state = offlineMode ? 3 : 2;
        this.releaseBridge();
    }
    public isInitialized = (): boolean => this.state !== 0;
}
const fieldBrowserSupport = new FieldBrowserSupport();
export default fieldBrowserSupport;

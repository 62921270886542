import Spinner from "@atlaskit/spinner";
import * as chartjs from "chart.js";
import "chartjs-plugin-datalabels";
import { t } from "i18next";
import * as React from "react";
import { ChartData, Pie } from "react-chartjs-2";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import PageTitleItem from "../components/PageTitleItem";
import Scrollbar from "../components/Scrollbar";
import Separator from "../components/Separator";
import TitleItem from "../components/TitleItem";
import CatalogFeatureType from "../components/toolbar/CatalogFeatureType";
import { ICatalogItemProps } from "../components/toolbar/CatalogItem";
import CatalogLayerGroupSingleChoice
    from "../components/toolbar/CatalogLayerGroupSingleChoice";
import FeatureType from "../dto/components/FeatureType";
import { GeometryType } from "../dto/components/GeometryType";
import StructureMeasurementBasics from "../dto/components/StructureMeasurementBasics";
import InspectionAnalysisChartsPageData
    from "../dto/pages/InspectionAnalysisChartsPageData";
import Constants from "../helpers/Constants";
import IRouteParams from "../helpers/IRouteParams";
import AuthService from "../services/AuthService";

export interface IInspectionAnalysisSingleFeatureBasedChartsPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structureName: string, structureItemName: string) => void;
}

export interface IInspectionAnalysisSingleFeatureBasedChartsPageState {
    data?: InspectionAnalysisChartsPageData;
    measurements: StructureMeasurementBasics[];
    measurementId?: string;
    hiddenFeatureTypeCodes: Set<string>;
    waiting: boolean;
}

class InspectionAnalysisSingleFeatureBasedChartsPage extends React.Component<IInspectionAnalysisSingleFeatureBasedChartsPageProps, IInspectionAnalysisSingleFeatureBasedChartsPageState> {
    constructor(props: IInspectionAnalysisSingleFeatureBasedChartsPageProps) {
        super(props);
        this.state = {
            measurements: [],
            hiddenFeatureTypeCodes: new Set<string>(),
            waiting: true,
        };
    }

    public render() {
        const { data, waiting } = this.state;

        const pointChartData = this.getChartData(GeometryType.Point);
        const lineChartData = this.getChartData(GeometryType.Line);
        const areaChartData = this.getChartData(GeometryType.Area);

        const pointChartOptions = this.getChartOptions();
        const lineChartOptions = this.getChartOptions();
        const areaChartOptions = this.getChartOptions();

        return (
            <div className="pageMap">
                <PageTitleItem title={t("pageTitleFeatureBasedCharts")}  testID={"inspection-analysis-single-feature-based-charts-page-title"} />
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div>                   
                <div className="pageMaximized">
                    <div className="diagramshorizontal">
                        <div className="diagram">
                            <TitleItem title={t("sectionTitlePointFeatures")} />
                            {pointChartData && <Pie
                                data={pointChartData}
                                legend={{ display: false }}
                                options={pointChartOptions}
                            />}
                        </div>
                        <div className="diagram">
                            <TitleItem title={t("sectionTitleLineFeatures")} />
                            {lineChartData && <Pie
                                data={lineChartData}
                                legend={{ display: false }}
                                options={lineChartOptions}
                            />}
                        </div>
                        <div className="diagram">
                            <TitleItem title={t("sectionTitleAreaFeatures")} />
                            {areaChartData && <Pie
                                data={areaChartData}
                                legend={{ display: false }}
                                options={areaChartOptions}
                            />}
                        </div>
                    </div>
                    {data &&
                    <div className="sideBar">
                        <CatalogLayerGroupSingleChoice
                            name={t("labelInspectionDrawings")}
                            items={this.getMeasurementItems()}
                            appearance={"primary"}
                            isExpanded={true}
                        />
                        <Separator/>
                        <Scrollbar>
                            <CatalogFeatureType
                                catalog={data.catalog}
                                isExpanded={true}
                                onVisibilityChange={this.onFeatureTypeVisibilityChange}
                                testId={"feature-type-catalog"}
                            />
                        </Scrollbar>
                    </div>
                    }
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<InspectionAnalysisChartsPageData>(`inspectionanalysis/${this.props.match.params.projectId}/${this.props.match.params.structureId}/${this.props.match.params.objectId}/chartdata`);
            const firstMeasurement = data.measurements[0];

            this.setState({
                data: new InspectionAnalysisChartsPageData(data),
                measurementId: firstMeasurement && firstMeasurement.id,
                measurements: data.measurements,
                waiting: false,
            });
            this.props.setLocationNames(data.inspectionAnalysis.project.name, data.inspectionAnalysis.structure.name, data.inspectionAnalysis.name);
        } catch (error) {
            this.setState({ waiting: false})
        }
    }

    private getMeasurementItems = (): ICatalogItemProps[] => {
        return this.state.measurements.map(measurement => {
            const isHidden = this.state.measurementId !== measurement.id;
            return {
                name: measurement.name,
                id: measurement.id,
                isHidden,
                onVisibilityToggle: this.getMeasurementSelectionChangeMethod(measurement.id),
                testId: `item-id?${measurement.id}`,
            }}
        )
    };


    private getMeasurementSelectionChangeMethod = (measurementId?: string) => {
        return () => this.setState({ measurementId });
    }

    public onFeatureTypeVisibilityChange = (featureType: FeatureType, visible: boolean) => {
        this.setState(prevState => {
            const codes: Set<string> = new Set(prevState.hiddenFeatureTypeCodes);

            if (visible) {
                codes.delete(featureType.code);
            } else {
                codes.add(featureType.code);
            }

            return { hiddenFeatureTypeCodes: codes };
        });
    };

    private getChartData = (type: GeometryType): ChartData<chartjs.ChartData> | undefined => {
        const { data, measurementId, hiddenFeatureTypeCodes } = this.state;
        if (!data) {
            return;
        }
        const chartData = data.inspectionChartDatas.find(d => d.id === measurementId);
        if (!chartData) {
            return;
        }
        const featureData = chartData.statisticsPerPhenomenon.filter(_ => _.type === type && !hiddenFeatureTypeCodes.has(_.code));
        featureData.sort((a, b) => b.value - a.value);
        const dataValues = featureData.map(_ => _.value);
        const allFeatureTypes= data.catalog.groups.map(l => l.featureTypes).reduce((a, b) => a.concat(b), []);

        const dataLabels = featureData.map(_ => {
            const featureType = allFeatureTypes.find(f => f.code === _.code);
            return (featureType && featureType.name) || t("labelNotApplicable");
        });

        const abbreviationLabels = featureData.map(_ => {
            const featureType = allFeatureTypes.find(f => f.code === _.code);
            return (featureType && featureType.abbreviation) || "";
        });

        const backgroundColors = featureData.map(_ => {
            const featureType = allFeatureTypes.find(f => f.code === _.code);
            return (featureType && featureType.strokeColorString) || Constants.colorBackgroundM3;
        });

        const result = {
            datasets: [{
                abbreviations: abbreviationLabels,
                backgroundColor: backgroundColors,
                data: dataValues,
            }],
            labels: dataLabels,
        };

        return result;
    }

    private getChartOptions = () => {
        const options = {
            plugins: {
                datalabels: {
                    backgroundColor: (context: any) => context.dataset.backgroundColor,
                    borderRadius: 4,
                    color: 'white',
                    display: (context: any) => {
                        const abbreviation = context.dataset.abbreviations[context.dataIndex];
                        const value = context.dataset.data[context.dataIndex];
                        return abbreviation && context.dataIndex < 5 && value >= 5;
                    },
                    font: {
                        weight: 'bold',
                    },
                    formatter: (value: number, context: any) => context.dataset.abbreviations[context.dataIndex],
                },
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem: any, d: any) => {
                        let l = (d as any).labels[tooltipItem.index] || '';
                        if (l) { l += ': '; }
                        const value = d.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        l += Math.round(value * 100) / 100;
                        return l;
                    },
                },
            },
        };

        return options;
    }
}

export default compose(withNamespaces())(InspectionAnalysisSingleFeatureBasedChartsPage)

import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import Logger from '../services/Logger';

class AppInsightsService {
    public reactPlugin: ReactPlugin;
    private appInsights?: ApplicationInsights;

    constructor() {
        this.reactPlugin = new ReactPlugin();
    }

    public initialize = (instrumentationKey: string, browserHistory: any) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (instrumentationKey.length !== 0) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    maxBatchInterval: 0,
                    disableFetchTracking: false,
                    extensions: [this.reactPlugin],
                    extensionConfig: {
                        [this.reactPlugin.identifier]: {
                            history: browserHistory
                        }
                    }
                }
            });
    
            this.appInsights.loadAppInsights();
            Logger.info("logging to kubernetes is enabled");
        }
    }
}
export const ais = new AppInsightsService();

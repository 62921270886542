import Form from '@atlaskit/form';
import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import UpdateUserPasswordRequest from '../dto/requests/UpdateUserPasswordRequest';
import {hasInvalidChars} from '../helpers/StringHelpers';
import AuthService from '../services/AuthService';
import FlagService from '../services/FlagService';
import Logger from '../services/Logger';

export interface IUpdateUserPasswordModalProps extends WithNamespaces {
    updateUrl: string;
    id: string;
    loginName: string;
    name: string;
    onCancel: () => void;
    onOk: () => void;
}

export interface IUpdateUserPasswordModalState {
    password: string;
    currentPassword?: string;
    confirmationPassword: string;
    currentPasswordIsNeeded: boolean;    
}

class UpdateUserPasswordModal extends React.Component<IUpdateUserPasswordModalProps, IUpdateUserPasswordModalState> {
    constructor(props: IUpdateUserPasswordModalProps) {
        super(props);
        this.state = {
            password: "",
            confirmationPassword: "",
            currentPasswordIsNeeded: this.props.updateUrl.includes("userProfile")
        }
    }

    public render() {
        const { password, currentPassword, confirmationPassword, currentPasswordIsNeeded } = this.state;
        const { loginName, name } = this.props;

        return (
            <Modal
                heading={t("textUpdateUserPassword")}
                actions={[{
                    onClick: this.onSave,
                    text: t("buttonSave"),
                }, {
                    onClick: this.props.onCancel,
                    text: t("buttonCancel"),
                }]}
                onClose={this.props.onCancel}
                scrollBehavior={"outside"}
            >
                <Form onSubmit={this.onSave}>
                    {({formProps}) => (
                        <form {...formProps} className="modalForm">
                            <TextBoxLabeled
                                label={t("labelUserName")}
                                disabled={true}
                                value={loginName}
                                testId = {"User-name-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelFullName")}
                                placeholder={t("labelFullName")}
                                disabled={true}
                                value={name}
                                testId = {"Full-name-textbox"}
                            />
                            { (currentPasswordIsNeeded) &&
                                <TextBoxLabeled
                                    label={t("labelCurrentPassword")}
                                    placeholder={t("labelCurrentPassword")}
                                    required={true}
                                    value={currentPassword}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={value => this.setState({ currentPassword: value })}
                                    validate={this.isValidCurrentPassword}
                                    invalid={t("textInvalidEmptyField")}
                                    isPassword={true}
                                    testId = {"Current-password-textbox"}
                                />
                            }
                            <TextBoxLabeled
                                label={t("labelNewPassword")}
                                placeholder={t("labelNewPassword")}
                                required={true}
                                value={password}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ password: value })}
                                validate={this.isValidPassword}
                                invalid={t("textInvalidNewPassword")}
                                isPassword={true}
                                testId = {"New-password-textbox"}
                            />
                            <TextBoxLabeled
                                label={t("labelPasswordConfirmation")}
                                placeholder={t("textPasswordConfirmation")}
                                required={true}
                                value={confirmationPassword}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ confirmationPassword: value })}
                                validate={this.isValidConfirmationPassword}
                                invalid={t("textPasswordNotMatching")}
                                isPassword={true}
                                testId = {"Confirmation-password-textbox"}
                            />
                        </form>
                    )}
                </Form>
            </Modal>
        );
    }    

    private isValidPassword = (password: string) => {
        return (
            password !== "" &&
            !hasInvalidChars(password) &&
            this.state.currentPassword !== password            
        )
    };

    private isValidCurrentPassword = (password?: string): boolean => {
        return password !== undefined && password !== "" && !hasInvalidChars(password);
    };

    private isValidConfirmationPassword = (password: string) => {
        return (
            this.state.password === password
        )
    };

    private isAdmin = () => { 
        return (
            AuthService.isAmbergAdmin() || AuthService.isCloudAdmin()
         )
    } ;

    private onSave = async (event: any) => {
        event.preventDefault();
        const { id, loginName } = this.props;
        const { password, currentPassword, confirmationPassword, currentPasswordIsNeeded } = this.state;

        if (
            this.isValidPassword(password) &&
            this.isValidConfirmationPassword(confirmationPassword) &&
            (currentPasswordIsNeeded ? this.isValidCurrentPassword(currentPassword): true)
        ) {
            const request: UpdateUserPasswordRequest = { id, password, currentPassword };

            try {
                const { updateUrl } = this.props;
                await AuthService.put(updateUrl, request);
                FlagService.addInfo(
                    t("labelSaved"),
                    t("textPasswordUpdated", {name:loginName})
                );
                this.props.onOk();
            } catch (error) {
                Logger.error(error, "UpdateUserPasswordModal");
                FlagService.addError(
                    t("textPasswordUpdateFailed", {name:loginName}),
                    error
                );
            }
        }
    }
}

export default withNamespaces()(UpdateUserPasswordModal)

import * as React from "react";


interface ISeparatorProps {
    margin?: boolean;
    testId?: string;
}


export default class Separator extends React.Component<ISeparatorProps, {}> {
    public render() {
        return (
            <div
                style={{
                    flex: "none",
                    width: "100%",
                    height: 2,
                    backgroundColor:"rgba(9,30,66,0.08)",
                    marginTop: this.props.margin ? 5 : 0,
                    marginBottom: this.props.margin ? 5 : 0,
                }}
                data-testid={this.props.testId}
            />
        )
    };
}

import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import AutomaticInspectionItem from "../dto/responses/AutomaticInspectionItem"
import AutomaticInspectionRequest from "../dto/requests/AutomaticInspectionRequest"
import AuthService from '../services/AuthService';
import Logger from '../services/Logger';
import FlagService from '../services/FlagService';
import SingleSelectComboBoxLabeled from '../components/labeled/SingleSelectComboBoxLabeled';
import Separator from '../components/Separator';
import { ISSelectOption } from '../components/atlaskit/SelectTouch';
import CatalogData from '../dto/components/CatalogData';
import AutomaticInspectionFeature from '../dto/components/AutomaticInspectionFeature';
import automaticInspectionTunnelSetting from '../dto/components/AutomaticInspectionTunnelSetting';

type Option = { label: string, value: string, obj: AutomaticInspectionItem }

export interface ICreateAutomaticPredictionModalProps extends WithNamespaces {
    tunnelSettings: automaticInspectionTunnelSetting[];
    catalog: CatalogData;
    projectId: string;
    structureId: string;
    measurementId: string;
    onCancel: () => void;
    onOk: () => void;
}

export interface ICreateAutomaticPredictionModalState{
    selectedTunnelSetting: string;
    selectedInspections: Map<string, AutomaticInspectionItem>;
}

class CreateAutomaticInspectionModal extends React.Component<ICreateAutomaticPredictionModalProps, ICreateAutomaticPredictionModalState>{
    constructor(props: ICreateAutomaticPredictionModalProps){
        super(props);
        this.state = {
            selectedTunnelSetting: props.tunnelSettings[0].id,
            selectedInspections: new  Map<string, AutomaticInspectionItem>(),
        }
    };

    public render()
    {
        const {selectedTunnelSetting, selectedInspections} = this.state;
        const tunnelSettingOption = this.props.tunnelSettings.map(t => {
            return {
                label: t.name,
                value: t.id,
                features: t.features,
            };
        });
        const selectedTypeOption = tunnelSettingOption.find(l => l.value === selectedTunnelSetting);

        return (<Modal
        heading={t("modalTitleAutomaticInspection")}
        actions={[{
            onClick: this.onStart,
            text: t("buttonStart"),
            isDisabled: selectedInspections.size === 0,
        }, {
            onClick: this.props.onCancel,
            text: t("buttonCancel"),
        }]}
        scrollBehavior={"outside"}
        onClose={this.props.onCancel}
        >
            <form className="modalForm" onSubmit={this.onStart}>
                <SingleSelectComboBoxLabeled
                        label={t("labelTunnelType")}
                        placeholder={t("labelSelect")}
                        required={true}
                        options={tunnelSettingOption}
                        selectedOption={selectedTypeOption}
                        onChange={this.onTunnelTypeChanged}
                    />
                <div style={{ margin: "1em" }}/>
                <Separator/>
                {selectedTypeOption && this.renderInspectionItems(selectedTypeOption.features)}
            </form>
        </Modal>)
    };

    private renderInspectionItem = (item: AutomaticInspectionFeature) =>
    {
        const {selectedInspections} = this.state;

        let catalogOptions: ISSelectOption [] = [];
        this.props.catalog.groups.forEach(featureTypeGroups => {
            const featureTypesSorted = featureTypeGroups.featureTypes.sort(
                (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
            );
            featureTypesSorted.forEach(
                featureType => {
                    if (featureType.type === item.geometryType) {
                        catalogOptions.push({
                            label: `${featureTypeGroups.name} - ${featureType.name}`,
                            value: featureType.code,
                            obj: {mlFeatureCode: String(item.mlFeatureCode), mlFeatureText: item.mlFeatureText, customerCode: featureType.code} as AutomaticInspectionItem,   
                        } as ISSelectOption)
                    }
                }
            )
        });
        
        return (
            <SingleSelectComboBoxLabeled
                key={item.mlFeatureCode}
                label={item.mlFeatureText}
                isClearable={true}
                placeholder={t("labelNotUsed")}
                required={true}
                options={catalogOptions}
                onChange={(option: Option) => {
                    this.setState((prevState: ICreateAutomaticPredictionModalState) => {
                        const selectedInspections = new Map(prevState.selectedInspections);
                        if(!option){
                            selectedInspections.delete(String(item.mlFeatureCode))
                        }
                        else{
                            selectedInspections.set(String(item.mlFeatureCode), option.obj);
                        }
                        return {selectedInspections}
                    })
                }}
                styles={
                    selectedInspections.has(String(item.mlFeatureCode)) ?
                        {control: (styles: any) => ({...styles , borderColor: "#00875A", borderWidth: 2, borderStyle: "solid", borderRadius: 3})}
                        :
                        {}
                }
            />
        );
    };
    
    private renderInspectionItems = (features : AutomaticInspectionFeature[]) =>{
        let lines = [];
        for (let index = 0; index < features.length; index++) {
            lines[index]= this.renderInspectionItem(features[index]);            
        }
        return lines;
    };

    private onTunnelTypeChanged= (option: ISSelectOption) =>  
    {
        const selectedTunnelSetting = option && option.value;
        this.setState({ selectedTunnelSetting });
    };

    private onStart = async (event: any) => {
        event.preventDefault();
        const {selectedTunnelSetting, selectedInspections} = this.state;

        const request: AutomaticInspectionRequest = 
        {
            structureId: this.props.structureId,
            measurementId: this.props.measurementId,
            tunnelType: selectedTunnelSetting,
            inspectionItems: Array.from(selectedInspections.values()),
        };

        try {
            const url = `inspectiontask/${this.props.projectId}/autoinspection`;
            await AuthService.put(url, request);
            this.props.onOk();
        } catch (error) {
            Logger.error(error, "CreateInspectionAnalysisModal");
            FlagService.addError(t("textStartedFailed", {what:t("labelAutomaticInspection")}), error);

        }
    }
}

export default withNamespaces()(CreateAutomaticInspectionModal)
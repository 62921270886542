import { Feature } from "geojson";
import { t } from "i18next";
import * as L from "leaflet";
import FeatureType from "../dto/components/FeatureType";
import FeatureLayer from "./FeatureLayer";

export default class TooltipHandler {

    private _featureType: FeatureType;

    constructor(featureType: FeatureType){
        this._featureType = featureType;
    }

    public addToLayer = (layer: FeatureLayer) => {
        const options: L.TooltipOptions = {
            sticky: true,
        };

        layer.bindTooltip(l => {
            const feature = (l as any).feature;
            if (feature) {
                return this.tooltip(layer.feature, true);
            }
            return "";
        }, options);
        layer.on("mouseover", () => {
            const tooltip = layer.getTooltip();
            if(tooltip){
                (tooltip as L.Tooltip).setContent(this.tooltip(layer.feature, false));
                tooltip.bringToFront()
            }
        });
        layer.on("mouseout", () => {
            const tooltip = layer.getTooltip();
            if(tooltip){
                (tooltip as L.Tooltip).setContent(this.tooltip(layer.feature, true));
            }
        })

    };

    private tooltip = (feature: Feature, showAbbreviation: boolean) => {
        const tooltip = [];

        if(showAbbreviation && this._featureType.abbreviation){
            tooltip.push(`<strong>${this._featureType.abbreviation}</strong>`)
        }
        else {
            tooltip.push(`<strong>${this._featureType.name}</strong>`);
        }

        if (feature.properties && feature.properties.remark) {
            tooltip.push(`${feature.properties.remark}`)
        }

        return tooltip ? tooltip.join("</br>") : t("labelNotApplicable");
    };

}
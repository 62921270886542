import * as React from "react";
import Button, {IButtonProps} from "../atlaskit/Button";
import LabelWrapper, {ILabelWrapperProps} from "./Wrapper";



interface IButtonLabeled extends ILabelWrapperProps, IButtonProps {
}

export default function ButtonLabeled(props: IButtonLabeled) {
  const { label, children, labelMarginTop, testId,  ...rest } = props as IButtonLabeled;
  return (
    <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
      <div style={{ height: "32px" }}>
        <div style={{ marginTop: "12px" }}>
          <Button label={""} testId={testId} {...rest}>
            {children}
          </Button>
        </div>
      </div>
    </LabelWrapper>
  );
}

import Form from '@atlaskit/form';
import Modal from '@atlaskit/modal-dialog';
import { t } from 'i18next';
import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import DateSelectLabeled from "../components/labeled/DateSelectLabeled";
import SingleSelectComboBoxLabeled
  from "../components/labeled/SingleSelectComboBoxLabeled";
import TextBoxLabeled from "../components/labeled/TextBoxLabeled";
import CreateLicenseRequest from '../dto/requests/CreateLicenseRequest';
import LicenseHelper from '../helpers/LicenseHelper';
import { isNoneZeroPositiveDouble } from "../helpers/StringHelpers";
import AuthService from '../services/AuthService';
import FlagService from '../services/FlagService';
import Logger from '../services/Logger';

export interface ICreateLicenseModalProps extends WithNamespaces {
    accountId: string;
    onCancel: () => void;
    onOk: () => void;
}

export interface ICreateLicenseModalState {
    id?: string;
    count: string;
    expirationDate: string;
}

class CreateLicenseModal extends React.Component<ICreateLicenseModalProps, ICreateLicenseModalState> {
    constructor(props: ICreateLicenseModalProps) {
        super(props);
        this.state = {
            count: "1",
            expirationDate: new Date().toISOString(),
        }
    }

    public render() {
        const { id, count, expirationDate } = this.state;

        const licenseOptions = LicenseHelper.supportedLicenses().map(l => {
            return {
                description: LicenseHelper.descriptionForLicense(l),
                label: LicenseHelper.labelForLicense(l),
                value: l,
            };
        });
        const selectedlicenseOption = licenseOptions.find(l => l.value === id);

        return (
            <Modal
                heading={t("modalTitleCreateLicense")}
                actions={[{
                    onClick: this.onSave,
                    text: t("buttonCreate"),
                }, {
                    onClick: this.props.onCancel,
                    text: t("buttonCancel"),
                }]}
                onClose={this.props.onCancel}
            >
                <Form onSubmit={this.onSave}>{
                    ({formProps}) => (
                        <form {...formProps}>
                            <SingleSelectComboBoxLabeled
                                label={t("labelLicence")}
                                placeholder={t("labelSelect")}
                                options={licenseOptions}
                                selectedOption={selectedlicenseOption}
                                onChange={this.onLicenseSelectionChange}
                            />
                            {id && LicenseHelper.isAssignableToUser(id) && <TextBoxLabeled
                                label={t("labelCount")}
                                value={count}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ count: value })}
                                validate = { isNoneZeroPositiveDouble }
                                invalid = {t("validatorNumberBoxMin", { minimum: 0.0 })}
                            />}
                            <DateSelectLabeled
                                label={t("labelExpirationDate")}
                                value={expirationDate}
                                // tslint:disable-next-line:jsx-no-lambda
                                onChange={value => this.setState({ expirationDate: value })}
                            />
                        </form>
                    )}
                </Form>
            </Modal>
        );
    }

    private onLicenseSelectionChange = (option?: ISSelectOption) => {
        const id = option && option.value;
        this.setState({ id });
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        const { id, count, expirationDate } = this.state;
        if (!id || !expirationDate || !isNoneZeroPositiveDouble(count)) {
            return;
        }

        const assignableToUserAsRole = LicenseHelper.isAssignableToUser(id);

        const request: CreateLicenseRequest = {
            assignableToUserAsRole,
            count: assignableToUserAsRole ? parseInt(count, undefined) : 1,
            expirationDate,
            id,
        };

        try {
            const { accountId } = this.props;
            await AuthService.post(`administrationlicenses/${accountId}`, request);
            FlagService.addInfo(t("labelCreated"),t("textCreated", {what:t("labelLicence"), name:id}));
            this.props.onOk();
        } catch (error) {
            Logger.error(error, "CreateLicenseModal");
            FlagService.addError(t("textCreationFailed", {what:t("labelLicence"), name:id}), error);
        }
    }
}

export default withNamespaces()(CreateLicenseModal)

import { Feature } from "geojson";
import * as L from "leaflet";
import FeatureType from "../../dto/components/FeatureType";
import { GeometryType } from "../../dto/components/GeometryType";
import { LineStyleType } from "../../dto/components/LineStyleType";
import { PatternStyleType } from "../../dto/components/PatternStyleType";
import { PointStyleType } from "../../dto/components/PointStyleType";
import Constants from "../../helpers/Constants";
import "../../libs/leaflet.pattern";
import "../../libs/leaflet.textpath";
import "../../libs/svg.icon";
import FeatureLayer from "../FeatureLayer";
import Line from "./elements/Line";
import ILayerStyle from "./ILayerStyle";
import LineStyle from "./LineStyle";
import PatternStyle from "./PatternStyle";

export default class FeatureStyle implements ILayerStyle {
    private static weightFactor(switchLevel: number, zoomLevel?: number){
        if (zoomLevel && zoomLevel < switchLevel) {
            return 0.5
        }
        else {
            return Math.pow(2, (zoomLevel || switchLevel) - switchLevel)
        }
    }

    public eventHandlers: Array<(layer: FeatureLayer) => void> = [];

    private readonly map: any; // L.Map
    private pane?: string;
    private interactive: boolean;
    private featureType: FeatureType;

    private text: any;
    private style: any;

    constructor(
        {map, interactive, featureType, pane}:
        {map: any, interactive: boolean, featureType: FeatureType, pane?: string}
    ) {
        this.map = map;
        this.pane = pane;
        this.interactive = interactive;
        this.featureType = featureType;

        this.initializeStyle();
        this.eventHandlers.push(this.lineThicknessHandler)
    }

     public getOptions = (): L.GeoJSONOptions => {
        return {
            onEachFeature: (feature: Feature, layer: L.Layer) => {
                (layer as any).feature = feature;
                if((layer as any).setText){
                    this.applyLineStyle(layer);
                }
            },
            pane: this.pane,
            pointToLayer: (feature: Feature, latlng: L.LatLng) => {
                const layer = this.getMarker(latlng);
                layer.feature = feature;
                return layer;
            },
            style: (feature: any) => {
                if(this.style){
                    const style = { ...this.style, interactive: this.interactive };

                    if (style && ["LineString", "Polygon"].indexOf(feature.geometry.type) >= 0){
                        style.weight = this.getEffectiveLineWeight( this.map.getZoom());

                        if (style.dashArray) {
                            style.dashArray = this.getEffectiveDashArray(style.weight)
                        }
                    }
                    return style;
                }
                return {
                    color: Constants.colorForegroundM1blue,
                };
            },
        }
     };

    public getMarker = (latlng: any): any => {
        const style = this.style;
        if (style) {
            style.iconOptions = {};

            if (style.fillColor){
                style.iconOptions.fillColor = style.fillColor;
            }

            if (style.fillColor && style.fillColor.toString().includes(": 0")){
                style.fillOpacity = 0;
                style.iconOptions.fillOpacity = 0;
            }
            else {
                style.fillOpacity = 1;
                style.iconOptions.fillOpacity = 1;
            }
            const svgMarkerOptions = {
                iconOptions: style,
            };
            if (this.pane) {
                (svgMarkerOptions as any).pane = this.pane;
            }
            return (L.marker as any).svgMarker(latlng, svgMarkerOptions);
        }

        const circleMarkerOptions = {
            color: "red",
            radius: 3,
        };
        if (this.pane) {
            (circleMarkerOptions as any).pane = this.pane;
        }
        return L.circleMarker(latlng, circleMarkerOptions);
    };

    public applyLineStyle = (layer: any): void => {
        if (this.text) {
            const textOptions = { ...this.text.options, pane: this.pane };
            layer.setText(this.text.text, textOptions);
        }
    };

    private lineThicknessHandler = (layer: FeatureLayer) => {
        if (["LineString", "Polygon"].indexOf(layer.feature.geometry.type) >= 0) {
            this.map.on('zoomend', (event: any) => {

                const zoomLevel = (event.target as any).getZoom();
                const weight = this.getEffectiveLineWeight(zoomLevel);

                (layer as any).setStyle({ weight });

                if ((layer as any).options.dashArray) {
                    (layer as any).setStyle({ dashArray: this.getEffectiveDashArray(weight) })
                }

            });
        }
    };

    private getEffectiveLineWeight = (zoomLevel: number): number => {
        return this.featureType.strokeThickness * FeatureStyle.weightFactor(8, zoomLevel + 1);
    };

    private getEffectiveDashArray = (weight: number): string => {
        const dashes: string[] =  this.style.dashArray.split(",");
        return parseFloat(dashes[0]) * weight + "," + parseFloat(dashes[1]) * weight;
    };

    private initializeStyle = (): void => {
        switch (this.featureType.type) {
            case GeometryType.Point:
                this.initializePointStyle(this.featureType);
                break;
            case GeometryType.Line:
                this.initializeLineStyle(this.featureType);
                break;
            case GeometryType.Area:
                this.initializeAreaStyle(this.featureType);
                break;
            default:
                // tslint:disable-next-line:no-console
                console.log(`unknwon feature type: ${this.featureType.type}`);
        }
    };

    private initializePointStyle = (featureType: FeatureType): void => {
        // create marker patterns
        const symbol = this.initializeSymbol(featureType);

        let fillColorString = featureType.fillColorString;
        if (symbol.fillColor) {
            fillColorString = symbol.fillColor;
        }

        // create options
        this.style = {
            color: featureType.strokeColorString,
            fillColor: fillColorString,
            iconSize: symbol.iconSize,
            path: symbol.path,
            weight: featureType.strokeThickness,
        };
    };

    private initializeSymbol = (featureType: FeatureType): any => {
        function SVGMove(x: number, y: number): string {
            return "M " + x + " " + y + " ";
        }
        function SVGLine(x: number, y: number): string {
            return "L " + x + " " + y + " ";
        }
        function SVGArc(rx: number, ry: number, xRot: number, laFlag: number, sFlag: number, x: number, y: number): string {
            return "A " + rx + " " + ry + " " + xRot + " " + laFlag + " " + sFlag + " " + x + " " + y + " ";
        }
        function SVGZ(): string {
            return "Z ";
        }

        if (featureType.type !== GeometryType.Point) { return undefined; }

        const s = 2 * featureType.pointSize;
        const sB = featureType.strokeThickness / 2;
        const sE = s - featureType.strokeThickness / 2;
        const r = s / 2;
        const r0 = r - featureType.strokeThickness;
        const smallR0 = r / 2 - featureType.strokeThickness;

        var filledRect = "";
        for (let i = s / 4; i < s / 4 * 3 + 1; i++) {
            filledRect += SVGMove(sB, i) + SVGLine(r, i) ;
          }

        var filledRectTopLeft = "";
          for (let i = s / 8; i <= s / 8 * 5; i++) {
            filledRectTopLeft += SVGMove(sB, i) + SVGLine(s / 4 * 3, i);
            } 
        
        var filledRectBottomRight = "";
        for (let i = s / 8 * 3; i < s / 8 * 5; i++) {
            filledRectBottomRight += SVGMove(s / 4 * 3, i) + SVGLine(sE, i);
          }    
        for (let i = s / 8 * 5; i <= s / 8 * 7; i++) {
            filledRectBottomRight += SVGMove(s / 4, i) + SVGLine(sE, i);
          }  


        switch (featureType.pointStyle) {
            case PointStyleType.EmptyCircle:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, r) + SVGArc(r0, r0, 0, 1, 0, sE, r) + SVGArc(r0, r0, 0, 1, 0, sB, r),
                };
            case PointStyleType.EmptySquare:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sB) + SVGLine(sE, sB) + SVGLine(sE, sE) + SVGLine(sB, sE) + SVGZ(),
                };
            case PointStyleType.XShape:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sB) + SVGLine(sE, sE) + SVGMove(sB, sE) + SVGLine(sE, sB),
                };
            case PointStyleType.rShape:
                return {
                    fillColor: "transparent",
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sE) + SVGLine(sB, sB) + SVGLine(sE, sB),
                };
            case PointStyleType.EmptyVRectangle:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sB) + SVGLine(r, sB) + SVGLine(r, sE) + SVGLine(sB, sE) + SVGZ(),
                };
            case PointStyleType.EmptyHRectangle:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sB) + SVGLine(sE, sB) + SVGLine(sE, r) + SVGLine(sB, r) + SVGZ(),
                };
            case PointStyleType.FilledVRectangle:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sB) + SVGLine(r, sB) + SVGLine(r, sE) + SVGLine(sB, sE) + SVGZ(),
                };
            case PointStyleType.FilledHRectangle:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, sB) + SVGLine(sE, sB) + SVGLine(sE, r) + SVGLine(sB, r) + SVGZ(),
                };
            case PointStyleType.XShapeInCircle:
                const offset = Math.sqrt(r0 * r0 / 2.0);
                return {            
                    iconSize: L.point(s, s),
                    path: SVGMove(r - offset, r - offset) + SVGLine(r + offset, r + offset) + SVGMove(r - offset, r + offset) + SVGLine(r + offset, r - offset) + SVGMove(sB, r) + SVGArc(r0, r0, 0, 1, 0, sE, r) + SVGArc(r0, r0, 0, 1, 0, sB, r),
                };
            case PointStyleType.LetterSInRectangle:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(r - r0/4*3, sB) + SVGLine(r + r0/4*3, sB) + SVGLine(r + r0/4*3, sE) + SVGLine(r - r0/4*3, sE) + SVGZ()+ SVGMove(r, r- r0/4) + SVGArc(r0/4, r0/4, 0, 1, 1, r + r0/4, r - r0/4- r0/4) + SVGMove(r, r- r0/4) + SVGArc(r0/4, r0/4, 0, 1, 1, r - r0/4, r + r0/4- r0/4),
                };
            case PointStyleType.LineWithCircles:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB +  smallR0 * 2, r) + SVGLine(sE - smallR0 * 2, r) + SVGMove(sB, r) + SVGArc(smallR0, smallR0, 0, 1, 0, sB +  smallR0 * 2, r) + SVGArc(smallR0, smallR0, 0, 1, 0, sB, r) + SVGMove(sE -  smallR0 * 2, r) + SVGArc(smallR0, smallR0, 0, 1, 0, sE, r) + SVGArc(smallR0, smallR0, 0, 1, 0, sE -  smallR0 * 2, r),
                };
            case PointStyleType.OverlappingRectanglesVar1:
                return {
                    iconSize: L.point(s, s),
                    path: filledRectTopLeft + SVGMove(s / 4, s / 8 * 3) + SVGLine(sE, s / 8 * 3) + SVGLine(sE, s / 8 * 7) + SVGLine(s / 4, s / 8 * 7) + SVGZ(),
                };
            case PointStyleType.OverlappingRectanglesVar2:
                return {
                    iconSize: L.point(s, s),
                    path: filledRectBottomRight + SVGMove(s / 4 * 3, s / 8 * 5) + SVGLine(sB,  s / 8 * 5) + SVGLine(sB,  s / 8) + SVGLine(s / 4 * 3, s / 8) + SVGZ(),
                };
            case PointStyleType.SplitHRectangle:
                return {
                    iconSize: L.point(s, s),
                    path: filledRect + SVGMove(r, s / 4) + SVGLine(sE, s / 4) + SVGLine(sE, s / 4 * 3) + SVGLine(r, s / 4 * 3),
                };
            case PointStyleType.CircleOnCross:
                return {
                    iconSize: L.point(s, s),
                    path: SVGMove(sB, r) + SVGLine(r - smallR0, r) + SVGMove(r, sB) + SVGLine(r, r - smallR0) + SVGMove(r + smallR0, r) + SVGLine(sE, r) + SVGMove(r, r + smallR0) + SVGLine(r, sE) + SVGMove(r - smallR0, r) + SVGArc(smallR0, smallR0, 0, 1, 0, r + smallR0, r) + SVGArc(smallR0, smallR0, 0, 1, 0, r - smallR0, r),
                };
            default:
                // tslint:disable-next-line:no-console
                console.log(`unknown pointStyle: ${featureType.pointStyle}`);
                return undefined;
        }
    };

    private initializeLineStyle = (featureType: FeatureType): void => {
        // create stroke patterns
        this.style = this.initializeStrokeOption(featureType);

        // create text options
        this.text = this.initializeTextPattern(featureType);
    };

    private initializeAreaStyle = (featureType: FeatureType): void => {
        // create stroke patterns
        const strokeOptions = this.initializeStrokeOption(featureType);

        // create fill patterns
        const fillOptions = this.initializeFillOption(featureType);

        if (fillOptions.fillPattern) {
            fillOptions.fillPattern.addTo(this.map);
        }

        const options = { ...strokeOptions, ...fillOptions };

        this.style = options;

        // create text options
        this.text = this.initializeTextPattern(featureType);
    };

    private initializeStrokeOption = (featureType: FeatureType): any => {
        if (featureType.type !== GeometryType.Line &&
            featureType.type !== GeometryType.Area) {
            return undefined;
        }

        const lineStyle = featureType.type === GeometryType.Area ? featureType.areaStyle : featureType.lineStyle;

        switch (lineStyle) {
            case LineStyleType.LineSolid:
                return new LineStyle(featureType);
            case LineStyleType.LineDash:
                return new LineStyle(featureType, "5,10");
            case LineStyleType.LineDot:
                return new LineStyle(featureType, "1,10");
            case LineStyleType.LineDouble:
                return new LineStyle(featureType);
            case LineStyleType.LineTriple:
                return new LineStyle(featureType);
            case LineStyleType.LineWithDots:
                return new LineStyle(featureType);
            case LineStyleType.LineWithSquares:
                return new LineStyle(featureType);
            case LineStyleType.LineWithXCentered:
                return new LineStyle(featureType);
            case LineStyleType.LineWithXUpper:
                return new LineStyle(featureType);
            case LineStyleType.LineWithICentered:
                return new LineStyle(featureType);
            case LineStyleType.LineWithIUpper:
                return new LineStyle(featureType);
            case LineStyleType.LineWithSlashUpper:
                return new LineStyle(featureType);
            case LineStyleType.LineWithSlashDenseUpper:
                return new LineStyle(featureType);
            case LineStyleType.LineWithSlashDenseDashedUpper:
                return new LineStyle(featureType);
            case LineStyleType.LineWithSlashSparseUpper:
                return new LineStyle(featureType);
            case LineStyleType.LineWithOverlappingRectanglesVar1Pattern:
                return new LineStyle(featureType);
            case LineStyleType.LineWithOverlappingRectanglesVar2Pattern:
                return new LineStyle(featureType);
            default:
                // tslint:disable-next-line:no-console
                console.log(`unknown patternStyle: ${featureType.lineStyle}`);
                return { color: Constants.colorForegroundM1blue }
        }
    };

    private initializeFillOption = (featureType: FeatureType): any => {
        const fillColor = featureType.fillColorString;
        const fillPatternText = featureType.fillPatternText;

        const sparse = [24,18];
        const normal = [16,10];
        const dense = [8,5];

        //const diagSize1 = [24,18];
        const diagSize2 = [21,15];
        const diagSize3 = [18,12.5];
        //const diagSize4 = [15,10];
        const diagSize5 = [12,7.5];
        //const diagSize6 = [9,5];

        const horizontal = new Line(0, 0.5, 1, 0.5);
        const vertical = new Line(0.5, 0, 0.5, 1);
        const bDiagonal = new Line(0, 0, 1, 1);
        const fDiagonal = new Line(0, 1, 1, 0);

        switch (featureType.fillPattern) {
            case PatternStyleType.PatternNone:
                return new PatternStyle({ color: fillColor, fillOpacity: 0 });
            case PatternStyleType.PatternSolid:
                return new PatternStyle({ color: fillColor });

            case PatternStyleType.PatternHorizontal:
                return new PatternStyle({ color: fillColor, size: normal, path: [horizontal] });
            case PatternStyleType.PatternHorizontalDense:
                return new PatternStyle({ color: fillColor, size: dense, path: [horizontal] });
            case PatternStyleType.PatternHorizontalSparse:
                return new PatternStyle({ color: fillColor, size: sparse, path: [horizontal] });

            case PatternStyleType.PatternVertical:
                return new PatternStyle({ color: fillColor, size: normal, path: [vertical] });
            case PatternStyleType.PatternVerticalDense:
                return new PatternStyle({ color: fillColor, size: dense, path: [vertical] });
            case PatternStyleType.PatternVerticalSparse:
                return new PatternStyle({ color: fillColor, size: sparse, path: [vertical] });

            case PatternStyleType.PatternBDiagonal:
                return new PatternStyle({ color: fillColor, size: normal, path: [bDiagonal] });
            case PatternStyleType.PatternBDiagonalDashed:
                return new PatternStyle({ color: fillColor, size: normal, path: [bDiagonal], dashed: true });

            case PatternStyleType.PatternBDiagonalDense:
                return new PatternStyle({ color: fillColor, size: dense, path: [bDiagonal] });
            case PatternStyleType.PatternBDiagonalDenseDashed:
                return new PatternStyle({ color: fillColor, size: dense, path: [bDiagonal], dashed: true });
            case PatternStyleType.PatternBDiagonalSparse:
                return new PatternStyle({ color: fillColor, size: sparse, path: [bDiagonal] });
            case PatternStyleType.PatternBDiagonalSparseDashed:
                return new PatternStyle({ color: fillColor, size: sparse, path: [bDiagonal], dashed: true });

            case PatternStyleType.PatternFDiagonal:
                return new PatternStyle({ color: fillColor, size: normal, path: [fDiagonal] });
            case PatternStyleType.PatternFDiagonalDashed:
                return new PatternStyle({ color: fillColor, size: normal, path: [fDiagonal], dashed: true });
            case PatternStyleType.PatternFDiagonalDense:
                return new PatternStyle({ color: fillColor, size: dense, path: [fDiagonal] });
            case PatternStyleType.PatternFDiagonalDenseDashed:
                return new PatternStyle({ color: fillColor, size: dense, path: [fDiagonal], dashed: true });
            case PatternStyleType.PatternFDiagonalSparse:
                return new PatternStyle({ color: fillColor, size: sparse, path: [fDiagonal] });
            case PatternStyleType.PatternFDiagonalSparseDashed:
                return new PatternStyle({ color: fillColor, size: sparse, path: [fDiagonal], dashed: true });

            case PatternStyleType.PatternDiagCross:
                return new PatternStyle({ color: fillColor, size: normal, path: [bDiagonal, fDiagonal] });                
            case PatternStyleType.PatternDiagCrossLong:
                return new PatternStyle({ color: fillColor, size: sparse, path:
                        [
                            new Line(0, 1, 1, 0),
                            new Line(0, 0.5, 0.5, 0),
                            new Line(0.5, 1, 1, 0.5),
                            new Line(0, 1, 0.5, 0),
                            new Line(0.5, 1, 1, 0),
                        ]
                });
            case PatternStyleType.PatternDiagCrossDashed:
                return new PatternStyle({ color: fillColor, size: normal, path: [bDiagonal, fDiagonal], dashed: true });
            case PatternStyleType.PatternCrossPattern:
                return new PatternStyle({ color: fillColor, size: normal, path: [horizontal, vertical] });
            case PatternStyleType.PatternCrossDashed:
                return new PatternStyle({ color: fillColor, size: normal, path: [horizontal, vertical], dashed: true });
            case PatternStyleType.PatternCrossDiagCross:
                return new PatternStyle({ color: fillColor, size: normal, path: [horizontal, vertical, bDiagonal, fDiagonal] });

            case PatternStyleType.PatternDotted1:
                return new PatternStyle({ color: fillColor, size: sparse, lineCap: "round", path:
                        [
                            new Line(0.1, 0.1, 0.1, 0.1),
                            new Line(0.6, 0.6, 0.6, 0.6)
                        ]
                });
            case PatternStyleType.PatternDotted2:
                return new PatternStyle({ color: fillColor, size: normal, lineCap: "round", path:
                        [
                            new Line(0.1, 0.1, 0.1, 0.1),
                            new Line(0.6, 0.6, 0.6, 0.6)
                        ]
                });
            case PatternStyleType.PatternDotted3:
                return new PatternStyle({ color: fillColor, size: dense, lineCap: "round", path:
                        [
                            new Line(0.1, 0.1, 0.1, 0.1),
                            new Line(0.6, 0.6, 0.6, 0.6)
                        ]
                });

            case PatternStyleType.PatternDots1:
                return new PatternStyle({ color: fillColor, size: sparse, lineCap: "round", path:
                        [
                            new Line(1/16, 1/16, 1/16, 1/16),
                            new Line(6/16, 1/16, 6/16, 1/16),
                            new Line(11/16, 1/16, 11/16, 1/16),
                        ]
                });
            case PatternStyleType.PatternDots2:
                return new PatternStyle({ color: fillColor, size: sparse, lineCap: "round", path:
                        [
                            new Line(1, 1/16, 1, 1/16),
                            new Line(10/16, 7/16, 10/16, 7/16),
                            new Line(4/16, 13/16, 4/16, 13/16),
                            new Line(1/16, 1/16, 1/16, 1/16),
                            new Line(6/16, 1/16, 6/16, 1/16),
                            new Line(11/16, 1/16, 11/16, 1/16),
                        ]
                });
            case PatternStyleType.PatternDots3:
                return new PatternStyle({ color: fillColor, size: sparse, lineCap: "round", path:
                        [
                            new Line(1, 1/16, 1, 1/16),
                            new Line(10/16, 7/16, 10/16, 7/16),
                            new Line(4/16, 13/16, 4/16, 13/16),
                        ]
                });
            case PatternStyleType.PatternFDiagonal2:
                return new PatternStyle({ color: fillColor, size: diagSize2, path: [fDiagonal] });
            case PatternStyleType.PatternBDiagonal2:
                return new PatternStyle({ color: fillColor, size: diagSize2, path: [bDiagonal] });
            case PatternStyleType.PatternFDiagonal3:
                return new PatternStyle({ color: fillColor, size: diagSize3, path: [fDiagonal] });
            case PatternStyleType.PatternBDiagonal3:
                return new PatternStyle({ color: fillColor, size: diagSize3, path: [bDiagonal] });
            case PatternStyleType.PatternFDiagonal5:
                return new PatternStyle({ color: fillColor, size: diagSize5, path: [fDiagonal] });
            case PatternStyleType.PatternBDiagonal5:
                return new PatternStyle({ color: fillColor, size: diagSize5, path: [bDiagonal] });
            case PatternStyleType.PatternTextInRectangle:
                return new PatternStyle({ color: fillColor, size: diagSize5, hasText: true, text: fillPatternText });
            default:
                // tslint:disable-next-line:no-console
                console.log(`unknown patternStyle: ${featureType.fillPattern}`);
                return undefined;
        }
    };

    private initializeTextPattern = (featureType: FeatureType): any => {
        if (featureType.type !== GeometryType.Line &&
            featureType.type !== GeometryType.Area) {
            return undefined;
        }

        const lineStyle = featureType.type === GeometryType.Area ? featureType.areaStyle : featureType.lineStyle;

        switch (lineStyle) {
            case LineStyleType.LineSolid:
                return undefined;
            case LineStyleType.LineDash:
                return undefined;
            case LineStyleType.LineDot:
                return undefined;
            case LineStyleType.LineDouble:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1.1,
                        repeat: true,
                    },
                    text: "\u0332 ",
                };
            case LineStyleType.LineTriple:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1.1,
                        repeat: true,
                    },
                    text: "\u0333 ",
                };
            case LineStyleType.LineWithDots:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1.1,
                        repeat: true,
                    },
                    text: "\u25CF    ",
                };
            case LineStyleType.LineWithSquares:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1,
                        repeat: true,
                    },
                    text: "\u25A0    ",
                };
            case LineStyleType.LineWithXCentered:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1.1,
                        repeat: true,
                    },
                    text: "x    ",
                };
            case LineStyleType.LineWithXUpper:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 0,
                        repeat: true,
                    },
                    text: "x    ",
                };
            case LineStyleType.LineWithICentered:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "16",
                        },
                        offset: 1.1,
                        repeat: true,
                    },
                    text: "|    ",
                };
            case LineStyleType.LineWithIUpper:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "16",
                        },
                        offset: -0.6,
                        repeat: true,
                    },
                    text: "|    ",
                };
            case LineStyleType.LineWithSlashUpper:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 0,
                        repeat: true,
                    },
                    text: "/  ",
                };
            case LineStyleType.LineWithSlashDenseUpper:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset : 0,
                        repeat: true,
                    },
                    text: "/",
                };
            case LineStyleType.LineWithSlashSparseUpper:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 0,
                        repeat: true,
                    },
                    text: "/    ",
                };
            case LineStyleType.LineWithSlashDenseDashedUpper:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: -1,
                        repeat: true,
                    },
                    text: "ˏˊ",
                };
            case LineStyleType.LineWithOverlappingRectanglesVar1Pattern:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1.5,
                        repeat: true,
                    },
                    text: "\u274F 	 ",
                };
            case LineStyleType.LineWithOverlappingRectanglesVar2Pattern:
                return {
                    options: {
                        attributes: {
                            fill: featureType.strokeColorString,
                            "font-size": "20",
                        },
                        offset: 1.5,
                        repeat: true,
                    },
                    text: "\u2750    ",
                };
            default:
                // tslint:disable-next-line:no-console
                console.log(`unknown patternStyle: ${featureType.lineStyle}`);
                return undefined;
        }
    };
}

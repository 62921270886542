import { t } from 'i18next';
import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import FlagService from '../services/FlagService';
import Logger from '../services/Logger';
import { ISSelectOption } from "../components/atlaskit/SelectTouch";
import ButtonLabeled from "../components/labeled/ButtonLabeled";
import SingleSelectComboBoxLabeled
  from "../components/labeled/SingleSelectComboBoxLabeled";
import PageTitleItem from '../components/PageTitleItem';
import i18n from '../i18n';
import AuthService from '../services/AuthService';
import fieldBrowserSupport from '../helpers/FieldBrowserSupport';
import Policies from '../helpers/Policies';
import ConfirmButtonLabeled from "../components/labeled/ConfirmButtonLabeled"
import OfflineLicenseInfo from '../dto/components/OfflineLicenseInfo';
import TitleItem from '../components/TitleItem';
import Table, { TextTableColumn, ToggleTableColumn, TableItemAction } from '../components/Table';
import LicenseHelper from "../helpers/LicenseHelper";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";

export interface ISettingsPageProps {
    setLocationName: () => void;
}

export interface ISettingsPageState {
    selectedLanguage?: string;
    licenses: OfflineLicenseInfo[];
}

class SettingsPage extends React.Component<ISettingsPageProps, ISettingsPageState> {
    constructor(props: ISettingsPageProps) {
        super(props);

        this.state = {
            selectedLanguage: "en",
            licenses: [],
        };
    }

    public render() {

        const { selectedLanguage, licenses } = this.state;
        const languageOptions: ISSelectOption[] = [
            { label: "English", value: "en" },
            { label: "Deutsch", value: "de" },
            { label: "Chinese", value: "zh-CN" },
			{ label: "Japanese", value: "ja" }
        ];
        const selectedLanguageOption = languageOptions.find(_ => _.value === selectedLanguage);

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleSettings")}  testID={"settings-page-title"}/>
                <form onSubmit={this.onSave}>
                    <SingleSelectComboBoxLabeled
                        label={t("labelLanguage")}
                        options={languageOptions}
                        placeholder={t("labelLanguage")}
                        selectedOption={selectedLanguageOption}
                        onChange={this.onLanguageSelectionChange}
                        />
                    <ButtonLabeled
                        type="submit"
                        testId={"save-settings-button"}
                        >
                        {t("buttonSave")}
                    </ButtonLabeled>
                </form>


                {Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems) &&
                <ConfirmButtonLabeled
                    label={t("labelDeleteAllTasks")}
                    textButton={t("buttonDelete")}
                    textPopup={t("textDeleteWarningAll", { what: t("labelFieldInspectionTask"), state: t("labelDownloaded") })}
                    appearance={"danger"}
                    onClick={this.onDeleteAllInspectionTasks}
                    testId={"delete-inspections-button"}
                />
                }
                    
                { Policies.isValidPolicy(Policies.toSeeAmbergInspectorItems) &&
                <div>
                    <TitleItem title={t("managementOfOfflineLicenses")} />
                    <div className="tableContainer">
                        <Table
                            data={licenses}
                            columns={[
                                new TextTableColumn<OfflineLicenseInfo>(t("labelLicenseName"), item => LicenseHelper.labelForLicense(item.licenseId)),
                                new ToggleTableColumn<OfflineLicenseInfo>(t("labelDownloaded"),
                                _ => "",
                                _ => new TableItemAction(t("buttonBorrow"), this.onBorrowLicenseChange),
                                _ => new TableItemAction(t("buttonReturn"), this.onReturnLicenseChange),
                                item => {return { isDisabled:!item.enabled, isChecked:item.borrowed};}
                                )
                            ]}
                            noDefaultSort={true}
                        />
                    </div>
                </div> }
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
        await this.loadLicenses();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const selectedLanguage = localStorage.getItem("id_language") || "en";
            this.setState({
                selectedLanguage,
            });
            this.props.setLocationName();
        } catch (error) {
            //
        }
    }

    private onSave = async (event: any) => {
        event.preventDefault();
        const { selectedLanguage } = this.state;
        if (!selectedLanguage) {
            return;
        }

        try {
            localStorage.setItem("id_language", selectedLanguage);
            i18n.changeLanguage(selectedLanguage);
            FlagService.addInfo(t("labelSaved"), t("textSaved", {what:t("labelSettings"), name:"", count:2}));
        } catch (error) {
            Logger.error(error, "SettingsPage");
            FlagService.addError(t("textSaveFailed", {what:t("labelSettings"), name:""}), error);
        }
    }
    private onBorrowLicenseChange = async(info:OfflineLicenseInfo) => {
        const appBridge = await fieldBrowserSupport.bridge();
        await appBridge.borrowLicense(info.licenseId);
        this.loadLicenses()
    }
    private onReturnLicenseChange = async(info:OfflineLicenseInfo) => {
        const appBridge = await fieldBrowserSupport.bridge();
        await appBridge.returnLicense(info.licenseId);
        this.loadLicenses()
    }

    private loadLicenses = async() => {
        const appBridge = await fieldBrowserSupport.bridge();
        const licenses = await appBridge.listOfflineLicenses();
        this.setState({licenses: licenses})
    }

    private onDeleteAllInspectionTasks = async () => {
        const appBridge = await fieldBrowserSupport.bridge();
        await appBridge.deleteAllTasks();
        fieldBrowserSupport.releaseBridge();
        FlagService.addInfo(t("labelInspectionTasks"),t("textAllTasksWasRemoved"));
    }

    private onLanguageSelectionChange = (option?: ISSelectOption) => {
        const selectedLanguage = option && option.value;
        this.setState({ selectedLanguage });
    }
}

export default compose(withNamespaces())(withAITracking(ais.reactPlugin, SettingsPage,"SettingsPage","AppInsight"));
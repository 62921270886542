import * as React from "react";
import MultiSelectComboBox, { IMultiSelectComboBoxProps } from "../MultiSelectComboBox";
import LabelWrapper from "./Wrapper";
import { ILabelWrapperProps } from "./Wrapper";

interface IMultiSelectComboBoxPropsProps
    extends ILabelWrapperProps,
        IMultiSelectComboBoxProps {}

export default function MultiSelectComboBoxLabeled(
    props: IMultiSelectComboBoxPropsProps
) {
    const { label, children, labelMarginTop, ...rest } = props as any;
    return (
        <LabelWrapper label={label} labelMarginTop={labelMarginTop}>
            <MultiSelectComboBox label={""} {...rest}>
                {children}
            </MultiSelectComboBox>
        </LabelWrapper>
    );
}

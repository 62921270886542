import { GeometryType } from "./GeometryType";
import { LineStyleType } from "./LineStyleType";
import { PatternStyleType } from "./PatternStyleType";
import { PointStyleType } from "./PointStyleType";
import ProjectItemBasics from "./ProjectItemBasics";

export default class FeatureType extends ProjectItemBasics {
    public abbreviation: string;
    public code: string;
    public type: GeometryType; // RENAME: should be called geometryType, needs a getter
    public pointStyle: PointStyleType;
    public pointSize: number;
    public lineStyle: LineStyleType;
    public areaStyle: LineStyleType;
    public fillPattern: PatternStyleType;
    public fillPatternText: string;
    public strokeThickness: number;
    public strokeColorString: string;
    public fillColorString: string;
    public isLocked?: boolean;
    public isHidden?: boolean;

    get styleCode(): string{
        return this.code;
    }

    constructor(data: FeatureType){
        super(data.id, data.name );

        this.abbreviation = data.abbreviation;
        this.type = data.type;
        this.pointStyle = data.pointStyle;
        this.pointSize = data.pointSize;
        this.lineStyle = data.lineStyle;
        this.areaStyle = data.areaStyle;
        this.fillPattern = data.fillPattern;
        this.fillPatternText = data.fillPatternText;
        this.strokeThickness = data.strokeThickness;
        this.strokeColorString = data.strokeColorString;
        this.fillColorString = data.fillColorString;
        this.isLocked = data.isLocked; // TODO JoSc Is this safe? - the property is optional - 
        this.isHidden = data.isHidden; // TODO JoSc Is this safe? - the property is optional

        this.code = data.code;       

    }

}

import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import Button from "../components/atlaskit/Button";
import { CreateIcon } from "../components/Icons";
import PageTitleItem from "../components/PageTitleItem";
import Table, {
  ActionsTableColumn,
  CountBadgeTableColumn,
  LinkTableColumn,
  TableItemAction,
  TextTableColumn,
} from "../components/Table";
import AdministrationAccountsPageData
  from "../dto/pages/AdministrationAccountsPageData";
import AccountSummary from "../dto/responses/AccountSummary";
import IRouteParams from "../helpers/IRouteParams";
import Routes from "../helpers/Routes";
import CreateAccountModal from "../modals/CreateAccountModal";
import AuthService from "../services/AuthService";

export interface IAdministrationAccountsPageProps extends RouteComponentProps<IRouteParams> {
    dummy?: string;
}

export interface IAdministrationAccountsPageState {
    showCreateAccountModal: boolean;
    data?: AdministrationAccountsPageData;
}

class AdministrationAccountsPage extends React.Component<IAdministrationAccountsPageProps, IAdministrationAccountsPageState> {
    constructor(props: IAdministrationAccountsPageProps) {
        super(props);
        this.state = {
            showCreateAccountModal: false,
        };
    }

    public render() {
        const { data, showCreateAccountModal } = this.state;
        return (
            <div className="page">
                {showCreateAccountModal && this.renderCreateAccountModal()}
                <PageTitleItem title={t("pageTitleAccounts")} testID={"admin-accounts-page-title"}>
                    <Button
                        iconBefore={<CreateIcon />}
                        onClick={this.showCreateAccountModal}
                        testId={"create-account-button"}
                    >
                        {t("buttonCreate")}
                    </Button>
                </PageTitleItem>
                <div className="tableContainer">
                    <Table
                        data={data && data.accounts}
                        columns={[
                            new LinkTableColumn<AccountSummary>(
                                t("labelName"),
                                item => item.name,
                                item => Routes.administrationAccount.patch(item.id).path
                            ),
                            new TextTableColumn<AccountSummary>(t("labelId"), item => item.id),
                            new TextTableColumn<AccountSummary>(t("labelReference"), this.accountReferenceId),
                            new CountBadgeTableColumn<AccountSummary>(t("labelUsers"), item => item.numUsers),
                            new CountBadgeTableColumn<AccountSummary>(t("labelGroups"), item => item.numGroups),
                            new CountBadgeTableColumn<AccountSummary>(t("labelProjects"), item => item.numResources),
                            new CountBadgeTableColumn<AccountSummary>(t("labelLicences"), item => item.numLicenses),
                            new ActionsTableColumn<AccountSummary>(item => [
                                new TableItemAction(t("buttonEdit"), this.onEditAccount),
                            ]),
                        ]}
                    />
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<AdministrationAccountsPageData>(`administrationaccounts`);
            this.setState({ data });
        } catch (error) {
            //
        }
    }

    private accountReferenceId = (account: AccountSummary): string => {
        const referenceIdItem = account.data && account.data.find(_ => _.id === "referenceId");
        return (referenceIdItem && referenceIdItem.name) || t("labelNotApplicable");
    }

    private showCreateAccountModal = () => {
        this.setState({
            showCreateAccountModal: true,
        });
    }

    private hideModals = () => {
        this.setState({
            showCreateAccountModal: false,
        });
    }

    private renderCreateAccountModal = () => {
        return (
            <CreateAccountModal
                onOk={this.onAccountCreated}
                onCancel={this.hideModals}
            />
        );
    }

    private onAccountCreated = async () => {
        this.hideModals();
        await this.loadData();
    }

    private onEditAccount = (account: AccountSummary) => {
        this.props.history.push(Routes.administrationAccount.patch(account.id).path);
    }
}

export default compose(withNamespaces())(AdministrationAccountsPage)

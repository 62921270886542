import Modal from "@atlaskit/modal-dialog";
import { t } from 'i18next';
import * as React from "react";
import Button from "./atlaskit/Button";

export interface IButtonWithPopupProps {
  textButton: string;
  textPopup: string;
  appearance: string;
  onClick: () => void;
  testId: string;
}

export interface IButtonWithPopupState {
  isOpen: boolean;
}

class ButtonConfirm extends React.Component<
  IButtonWithPopupProps,
  IButtonWithPopupState
> {
  constructor(props: IButtonWithPopupProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  public render() {
    const {appearance, testId, textButton, textPopup} = this.props;

    return (
      <React.Fragment>
        <Button appearance={appearance} onClick={this.open} testId={testId}>
          {textButton}
        </Button>
        {this.state.isOpen && (
          <Modal
            key="button-popup"
            actions={[{ 
              text: textButton, 
              onClick: this.onButton 
            },  {
              onClick: this.close,
              text: t("buttonCancel"),
          }]}
            appearance={appearance}
            onClose={this.close}
            heading={textButton}
            scrollBehavior={"outside"}
          >
            {textPopup}
          </Modal>
        )}
      </React.Fragment>
    );
  }

  private onButton = () => {
    this.props.onClick();
    this.close();
  };

  private open = () => this.setState({ isOpen: true });
  private close = () => this.setState({ isOpen: false });
}

export default ButtonConfirm;

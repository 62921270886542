import React, {Component} from 'react';
import { t } from "i18next";
import Modal from '@atlaskit/modal-dialog';

type Props = { heading: string, message: string, buttonLabel?: string , onClick?:()=>void}

export default class CriticalErrorModal extends Component<Props>{   
    render() {
        const { heading, message } = this.props;
        return (  
            <Modal
                key="table-item-popup"
                appearance={"danger"}
                actions={[{ id:"critical-error-button", text: this._buttonLabel(), onClick: this._close}]}
                onClose={this._close}
                heading={heading}
                scrollBehavior={"outside"}>
                    {message}
            </Modal>
        )
    }

    _buttonLabel = () => {
        const {buttonLabel} = this.props;
        return buttonLabel? buttonLabel : t("labelBack")
    }

    _close = () => {
        const {onClick} = this.props;
        return onClick? onClick() : this._goToPreviousPage();
    } 

    _goToPreviousPage = () => {
        window.history.back();
    }
}

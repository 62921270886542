import { Feature } from "geojson";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import InspectionDrawing from "../dto/components/InspectionDrawing";
import InspectionTaskSummary from "../dto/components/InspectionTaskSummary";
import FeatureLayer  from "../graphics/FeatureLayer";
import MapGrid from "../graphics/grids/MapGrid";
import InspectionFeatureGroup from "../graphics/InspectionFeatureGroup";
import InspectionMapDrawing from "../graphics/InspectionMapDrawing";
import FeatureLockedStyle from "../graphics/styles/FeatureLockedStyle";
import AuthService from "../services/AuthService";
import InspectionMapBase, {
    IInspectionMapBaseProps,
} from "./InspectionMapBase";

export interface IInspectionTaskDrawingMapProps extends IInspectionMapBaseProps {
    inspectionTask?: InspectionTaskSummary;
    additionalInspectionDrawings?: InspectionDrawing[];
    hiddenAdditionalInspectionDrawingIds: Set<string>;
    lockedFeatureTypeCodes: Set<string>;
    onDrawingsChanged: (created: Feature[], updated: Feature[], deleted: string[]) => void;
    onEditFeatureProperties: (layers: L.Layer[]) => void;
}


export default class InspectionTaskDrawingMap extends InspectionMapBase<IInspectionTaskDrawingMapProps, {}> {
    public map: InspectionMapDrawing;

    public createMap(){
        this.map = new InspectionMapDrawing(
            this.mapContainerId,
            AuthService.getServiceUrl(),
            this.mapGridControl ? this.mapGridControl.currentGrid : new MapGrid()
        );
        this.map.registerDrawingsChangedHandler(this.props.onDrawingsChanged);
        this.map.registerEditFeaturePropertiesHandler(this.props.onEditFeatureProperties);
    }

    public initializeMap(props: IInspectionTaskDrawingMapProps){
        if (!this.map) {
            return;
        }

        super.initializeMap(props);

        const { additionalInspectionDrawings } = props;
        if (additionalInspectionDrawings) {
            const additionalPane = this.map.innerMap.createPane("additionalinspectiondrawing");
            additionalPane.style.zIndex = "396";

            additionalInspectionDrawings.forEach(additionalInspectionDrawing => {
                const featureGroupId = `additionalinspectiondrawing_${additionalInspectionDrawing.id}`;
                const featureGroup = this.map.addFeatureGroup(featureGroupId, new FeatureLockedStyle("additionalinspectiondrawing"));
                featureGroup.addData(additionalInspectionDrawing.geoJson);
            });
        }
    };

    public updateMap = (props: IInspectionTaskDrawingMapProps, prevProps?: IInspectionTaskDrawingMapProps) => {
        if (!this.map) {
            return;
        }

        super.updateMap(props, prevProps);

        const {
            additionalInspectionDrawings,
            catalog,
            hiddenAdditionalInspectionDrawingIds,
            inspectionDrawing,
            lockedFeatureTypeCodes,
        } = props;


        let inspectionDrawingChanged: boolean = true;
        if(prevProps){
            inspectionDrawingChanged = (
                lockedFeatureTypeCodes !== prevProps.lockedFeatureTypeCodes
            );
        }

        if (inspectionDrawing && inspectionDrawingChanged) {
            if (inspectionDrawing && inspectionDrawingChanged) {
                const allCodesInCatalog = catalog.groups.map(l => l.featureTypes).reduce((a, b) => a.concat(b), []).map(p => p.code);
                const allCodesInData = Array.from(new Set<string>(inspectionDrawing.geoJson.features.map(f => f.properties && f.properties.code))).filter(f => f);
                const allCodes = Array.from(new Set<string>([...allCodesInCatalog, ...allCodesInData]));
                allCodes.forEach(code => {
                    const featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(code);
                    this.map.changeLock(featureGroupId, lockedFeatureTypeCodes.has(code));
                });
            }
        }
        if (additionalInspectionDrawings) {
            additionalInspectionDrawings.forEach(additionalInspectionDrawing => {
                const featureGroupId = `additionalinspectiondrawing_${additionalInspectionDrawing.id}`;
                this.map.changeVisibility(featureGroupId, hiddenAdditionalInspectionDrawingIds.has(additionalInspectionDrawing.id));
            });
        }
    };

    public updateFeatures = (layers: FeatureLayer[]) => {
        this.map.updateFeaturesPublic(layers);
    };

    public setKeyboardSuppression = (value: boolean) => {
        this.map.setKeyboardSuppression(value);
    };
}



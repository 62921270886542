import * as React from 'react';
import styled from 'styled-components';

export interface ITitleItemProps {
    title: string;
    children?: any;
}

export default function TitleItem(props: ITitleItemProps) {
    const Item = styled.div`
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.6em 0;
    `;

    return (
        <Item>
            <h2 style={{ flex: 1 }}>{props.title}</h2>
            {props.children}
        </Item>
    );
}

import { Feature } from "geojson";
import { t } from "i18next";
import "leaflet";
import * as L from "leaflet";
import { GeometryType } from "../dto/components/GeometryType";
import { InspectionDrawingTools } from "../dto/components/InspectionDrawingTools";
import Guid from "../helpers/Guid";
import "../libs/leaflet.contextmenu";
import "../libs/leaflet.contextmenu.css";
import "../libs/Leaflet.Editable";
import "../libs/Path.Drag";
import Logger from "../services/Logger";
import DrawingHistoryManager, { IDrawingHistoryElement } from "./DrawingHistoryManager";
import FeatureLayer from "./FeatureLayer";
import MapGrid from "./grids/MapGrid";
import MapGridBlockBased from "./grids/MapGridBlockBased";
import InspectionFeatureGroup from "./InspectionFeatureGroup";
import InspectionMapBasic from "./InspectionMapBasic";
import ILayerStyle from "./styles/ILayerStyle";
import LayerHelper from "../helpers/LayerHelper";

export default class InspectionMapDrawing extends InspectionMapBasic {
    private drawingsChangedHandler: (created: Feature[], updated: Feature[], deleted: string[]) => void;
    private currentDrawingLayerCode: string;
    private currentDrawingLayerType: GeometryType;
    private editFeaturePropertiesHandler: (layers: L.Layer[]) => void;
    private history: DrawingHistoryManager;

    private commitDrawingHandler = {'editable:drawing:commit': (e: any) => {
        if(e.layer.feature){
            // update
            const featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(e.layer.feature.properties.code);
            const featureGroup = this.featureGroups.get(featureGroupId);
            if (featureGroup){
                this.updateFeaturesPublic([e.layer]);
            }
        }
        else {
            // add
            this.innerMap.removeLayer(e.layer);
            const newHistoryElement = this.addFeatures([e.layer]);
            this.history.add(newHistoryElement);
        }
    }};

    private cancelDrawingHandler = {'editable:drawing:cancel': (e: any) => {
        Logger.trace('editable:drawing:cancel');
        if (e.layer && e.layer.removeFrom) {
            e.layer.removeFrom(this.innerMap);
        }
    }};

    private continueDrawingHandler = {'editable:drawing:end': (e: any) => {
        if(this._currentTool === InspectionDrawingTools.Draw) {
            this.startDrawing()
        }
    }};

    // ctrl+click: to continue polyline in editing
    private extendPolylineHandler = {'editable:vertex:ctrlclick editable:vertex:metakeyclick': (e: any) => {
        Logger.trace('editable:vertex:ctrlclick editable:vertex:metakeyclick');
        e.vertex.continue();
    }};

    private boxZoomAndSelectHandler = {"boundingbox": (e: any) => {
        if(e.ctrlKey || this._currentTool === InspectionDrawingTools.BoxSelect){
            this.featureGroups.forEach((featureGroup: any, key: string) => {
                if (key.startsWith('inspectiondrawing_') && this.innerMap.hasLayer(featureGroup)) {
                    featureGroup.eachActiveLayer((layer: any) => {
                        if (this.layerInBounds(layer, e.bounds)) {
                            this.selectLayer(layer);
                        }
                    });
                }
            });
        }
        else {
            if(e.bounds.isValid()){
                this.innerMap.fitBounds(e.bounds, {animate: false})
            }
        }
    }};

    private keyboardListener = {'keydown': (e: any) => {
            if (e.ctrlKey) {
                const layers = this.getSelectedLayers();
                if (layers.length === 0) {
                    this.stopDrawing();
                }
            }

            switch (e.keyCode) {
                case 90: // Z
                    if (e.ctrlKey) {
                        this.undo()
                    }
                    break;
                case 27: // ESC
                    this.doEscape();
                    break;
                case 13: // ENTER
                    if (!this.keyboardSuppressed) {
                        this.commitDrawing();
                    }
                    break;
                case 46: // DELETE
                case 8: // BACKSPACE
                    if (!this.keyboardSuppressed) {
                        this.deleteSelectedFeatures();
                        break;
                    }
            }
        }
    };

    private vertexMovingHandler = { "editable:vertex:drag" : (e: any) =>
        {            
            LayerHelper.fixOrientation(e.layer);
        }
    };

    constructor(leafletmapdiv: string, serviceUrl: string, mapGrid: MapGridBlockBased | MapGrid) {
        super(leafletmapdiv, serviceUrl, mapGrid);

        this.history = new DrawingHistoryManager(this.deleteFeatures, this.addFeatures, this.updateFeatures);

        this.featureGroups.defaultGroup.addEventHandler(this.editingEventHandler);
        this.featureGroups.defaultGroup.addEventHandler(this.contextMenuHandler);

        this.innerMap.addLayer(mapGrid as any);

        this.removeEventHandlers(this.boxZoomHandler);
        this.attachEventHandlers(
            this.commitDrawingHandler,
            this.cancelDrawingHandler,
            this.continueDrawingHandler,
            this.extendPolylineHandler,
            this.boxZoomAndSelectHandler,
            this.vertexMovingHandler,
        );
        this.attachEventListeners(this.keyboardListener)
    }

    public registerDrawingsChangedHandler = (drawingsChangedHandler: (created: Feature[], updated: Feature[], deleted: string[]) => void) => {
        this.drawingsChangedHandler = drawingsChangedHandler;
    };

    public registerEditFeaturePropertiesHandler = (editFeaturePropertiesHandler: (layers: L.Layer[]) => void) => {
        this.editFeaturePropertiesHandler = editFeaturePropertiesHandler;
    };

    public setDrawingLayer = (code: string, type: GeometryType) => {
        this.commitDrawing();
        this.currentDrawingLayerCode = code;
        this.currentDrawingLayerType = type;
    };

    public setKeyboardSuppression(value: boolean) {
        this.keyboardSuppressed = value;
    };

    public startDrawing = () => {
        if (!this.currentDrawingLayerCode) {
            return;
        }

        switch (this.currentDrawingLayerType) {
            case GeometryType.Point:
                this.editTools().startMarker();
                break;
            case GeometryType.Line:
                this.editTools().startPolyline();
                break;
            case GeometryType.Area:
                this.editTools().startPolygon();
                break;
            default:
                Logger.warning(`unknwon feature type: ${this.currentDrawingLayerType}`);
        }
    };

    public stopDrawing = () => {
        if (this.editTools().drawing()) {
            this.editTools().stopDrawing();
        }
    };

    public cancelEditing = () => {
        this.history.cancelEdit();
        this.clearSelection()
    };
   
    public commitDrawing = () => {
        if (this.hasUncomittedFeatures()) {
            this.editTools().commitDrawing()
        }
        else {
            this.updateSelectedFeatures();
        }
    };

    public updateFeaturesPublic = (featureLayers: FeatureLayer[]) => {
        this.updateFeatures(featureLayers);
        this.history.addSelection()
    };

    public undo = () => {
        this.history.undo();
    };

    public remove = () => {
        this.deleteSelectedFeatures();
    };

    public escape = () => {
        this.doEscape();
    };

    public deactivateCurrentTool = () => {
        (this.innerMap as any).boundingBox.setAlwaysActive(false);
    };

    public currentTool = () => {
        return this._currentTool
    };

    public changeTool = (tool: InspectionDrawingTools) => {
        switch (tool) {
            case InspectionDrawingTools.BoxSelect:
            case InspectionDrawingTools.BoxZoom:
                (this.innerMap as any).boundingBox.setAlwaysActive(true)
                break;
            default:
                break;
        }

        this._currentTool = tool;
    };

    public editProperties = (layer?: any) => {
        this.stopDrawing();
        if(layer){
            this.selectLayer(layer);
        }
        this.setKeyboardSuppression(true);
        this.editFeaturePropertiesHandler(this.history.getAllFeatures())
    };

    public addFeatureGroup = (featureGroupId: string, style: ILayerStyle, styleLocked?: ILayerStyle) => {
        const featureGroup = super.addFeatureGroup(featureGroupId, style, styleLocked);

        featureGroup.addEventHandler(this.editingEventHandler);
        featureGroup.addEventHandler(this.contextMenuHandler);

        return featureGroup;
    };

    public changeLock = (featureGroupId: string, locked: boolean) => {
        const featureGroup = this.featureGroups.get(featureGroupId);
        if(featureGroup){
            featureGroup.changeLock(locked)
        }
    };

    private hasUncomittedFeatures() {
        return (this.editTools().drawing() && this.currentDrawingLayerType !== "Point");
    };

    private addFeatures = (features: Array<FeatureLayer | L.Layer>): IDrawingHistoryElement  => {
        const addedFeaturesGeojson = new Array<any>();
        const addedFeatures: Map<string, FeatureLayer> = new Map<string, FeatureLayer>();

        features.forEach(l => {
            const response = this.addFeature(l);

            if (response){
                addedFeatures.set(response.layer.feature.id, response.layer);
                addedFeaturesGeojson.push(response.geojson);
            }
        });

        if (this.drawingsChangedHandler && addedFeaturesGeojson.length > 0) {
            this.drawingsChangedHandler(addedFeaturesGeojson, [], []);
        }
        return {add: addedFeatures};
    };          

    private addFeature = (layer: FeatureLayer | L.Layer): {layer: FeatureLayer, geojson: any} | undefined => {          
        LayerHelper.fixOrientation(layer);

        const geojson = (layer as any).toGeoJSON();                
        const featureGroupId = (layer as FeatureLayer).feature ?
            InspectionFeatureGroup.getInspectionFeatureGroupID((layer as FeatureLayer).feature.properties.code)
            :
            InspectionFeatureGroup.getInspectionFeatureGroupID(this.currentDrawingLayerCode);
        const featureGroup = this.featureGroups.get(featureGroupId);

        if (!featureGroup) {
            return;
        }

        if (!geojson.id) {
            if (!this.currentDrawingLayerCode) {
                Logger.warning("Failed to add feature: current drawing layer not set");
                return;
            }
            geojson.id = Guid.newGuid();
            geojson.properties.code = this.currentDrawingLayerCode;
        }

        if (!this.isGeojsonFeatureGeometryValid(geojson)) {
            return;
        }

        featureGroup.addData(geojson);

        const updatedLayer = featureGroup.getLayer(geojson.id);
        if (updatedLayer) {
            return { layer: updatedLayer as FeatureLayer, geojson }
        }
        return;
    };

    private updateSelectedFeatures = () => {
        this.updateFeaturesPublic(this.getSelectedLayers());
    };

    private updateFeatures = (featureLayers: FeatureLayer[]) => {
        const changedFeaturesGeojson = new Array<any>();

        featureLayers.forEach(fl => {
            const response = this.updateFeature(fl);
            if(response){
                changedFeaturesGeojson.push(response.geojson);
            }
        });

        if (this.drawingsChangedHandler && changedFeaturesGeojson.length > 0) {
            this.drawingsChangedHandler([], changedFeaturesGeojson, []);
        }
    };

    private updateFeature = (featureLayer: FeatureLayer) => {
        let featureGroupId: string;
        if(featureLayer.feature.properties.prevCode){
            featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(featureLayer.feature.properties.prevCode);
            if(this.history.hasCurrentSelection()){
                const selectedFeatureLayer = this.history.getFromSelection(featureLayer);
                if(selectedFeatureLayer){
                    selectedFeatureLayer.feature.properties.prevCode = featureLayer.feature.properties.code;
                    this.history.addToSelection(selectedFeatureLayer);
                }
            }
        }
        else{
            featureGroupId = InspectionFeatureGroup.getInspectionFeatureGroupID(featureLayer.feature.properties.code)
        }

        const result = this.deleteFeature(featureLayer, featureGroupId);
        if (!result || !result.feature) {
            Logger.warning("Failed to remove feature");
            return;
        }
        featureLayer.feature.properties.prevCode = undefined;
        const response = this.addFeature(featureLayer);

        if(response) {
            return response;
        }
        return;
    };

    private deleteSelectedFeatures = () => {
        const newHistoryElement = this.deleteFeatures(this.getSelectedLayers());
        this.history.add(newHistoryElement);
        this.clearSelection();
    };

    private deleteFeatures = (features: FeatureLayer[]): IDrawingHistoryElement  =>  {
        const deletedIds = new Array<string>();
        const deletedFeatures: Map<string, FeatureLayer> = new Map<string, FeatureLayer>();


        features.forEach(l => {
            const deletedFeatureLayer = this.deleteFeature(l);
            if (!deletedFeatureLayer) {
                Logger.warning("Failed to remove feature");
            } else {
                deletedIds.push(deletedFeatureLayer.feature.id);
                deletedFeatures.set(l.feature.id, l);
            }
        });
        if (this.drawingsChangedHandler && deletedIds.length > 0) {
            this.drawingsChangedHandler([], [], deletedIds);
        }
        this.history.resetCurrentSelection();        
        return {delete: deletedFeatures};
    };

    private deleteFeature = (featureLayer: FeatureLayer, featureGroupId?: string): FeatureLayer | undefined => {
        featureGroupId = featureGroupId || InspectionFeatureGroup.getInspectionFeatureGroupID(featureLayer.feature.properties.code);

        const featureGroup = this.featureGroups.get(featureGroupId);

        if (featureGroup && featureGroup.hasLayer(featureLayer)) {
            featureGroup.removeLayer(featureLayer);
        }

        return featureLayer;
    };

    private doEscape()
    {
        if (this.editTools().drawing()) {
            if (this.editTools()._drawingEditor.feature._bounds && this.editTools()._drawingEditor.feature._bounds.isValid()) {
                this.editTools()._drawingEditor.disable();
                this.startDrawing();
            } else {
                this.cancelEditing();
                this.stopDrawing();
            }
        } else {
            this.cancelEditing();
            this.stopDrawing();
        }        
    }

    private isGeojsonFeatureGeometryValid = (geojson: Feature): boolean => {
        if (geojson.geometry.type === "Point") {
            return geojson.geometry.coordinates.length > 1;
        }
        if (geojson.geometry.type === "LineString") {
            return geojson.geometry.coordinates[0] && geojson.geometry.coordinates[0].length > 1;
        }
        if (geojson.geometry.type === "Polygon") {
            return geojson.geometry.coordinates[0] && geojson.geometry.coordinates[0][0] && geojson.geometry.coordinates[0][0].length > 1;
        }
        Logger.warning("Failed to add feature: invalid geojson");
        Logger.trace(geojson);
        return false;
    };

    private editingEventHandler = (shape: L.Polyline | L.Polygon | L.CircleMarker | L.Marker): void => {
        // toggle editing with ctrl+click 
        shape.on('click', L.DomEvent.stop).on('click', e => {
            if ((e as any).originalEvent.ctrlKey && !(e as any).originalEvent.shiftKey) {
                Logger.trace(`ctrl+click`);
            }
            else if (this._currentTool === InspectionDrawingTools.Select) {
                Logger.trace(`touchSelectMode+click`);
            }
            else
            {
                return;
            }

                const layer = e.target as any;
                if (this.isLayerSelected(layer)) {
                    this.unselectLayer(layer);
                    this.updateFeature(layer);
                } else {
                    this.selectLayer(layer);
            }            
            
        });
    };

    private contextMenuHandler = (layer: any) => {
        layer.bindContextMenu({
            contextmenu: true,
            contextmenuItems: [
                {
                    callback: () => {
                        this.editProperties(layer)
                    },
                    text: t("buttonProperties"),
                },
                {
                    callback: () => {
                        this.stopDrawing();
                        this.selectLayer(layer);
                    },
                    text: t("buttonEdit"),
                },
                {
                    callback: () => {
                        const newHistoryElement = this.deleteFeatures([layer]);
                        this.history.add(newHistoryElement)
                    },
                    text: t("buttonDelete"),
                },
            ],
        });
    };

    private isLayerSelected = (layer: any): boolean => {
        if (this.layerIsEditablePolylineOrPolygon(layer)) {
            return layer.editEnabled();
        }
        if (this.layerIsEditablePoint(layer)) {
            return layer.dragging.enabled();
        }
        return false;
    };

    private selectLayer = (layer: FeatureLayer) => {
        this.stopDrawing();
        this.history.addToSelection(layer);

        if (this.layerIsEditablePolylineOrPolygon(layer)) {
            if (!(layer as any).editEnabled()) {
                (layer as any).enableEdit(this.innerMap);
            }
            return;
        }
        if (this.layerIsEditablePoint(layer)) {
            if (!(layer as any).dragging.enabled()) {
                (layer as any).dragging.enable();
                if ((layer as any).options.iconOptions) {
                    (layer as any).setStyle({
                        iconOptions: {
                            color: "violet",
                        },
                    });
                    return;
                }
                if ((layer as any).setRadius) {
                    (layer as any).setRadius((layer as any).options.radius + 5);
                    return;
                }
            }
            return;
        }
    };

    private clearSelection = () => {
        this.featureGroups.forEach(featureGroup => {
            featureGroup.eachLayer((layer: any) => {
                this.unselectLayer(layer);
            });
        });
    };

    private unselectLayer = (layer: any) => {
        if (this.layerIsEditablePolylineOrPolygon(layer)) {
            layer.disableEdit();
            return;
        }
        if (this.layerIsEditablePoint(layer)) {
            layer.dragging.disable();
            if (layer.options.iconOptions) {
                layer.setStyle({
                    iconOptions: {
                        color: layer.options.iconOptions.color,
                        fillColor: layer.options.iconOptions.fillColor,
                    }
                });
                return
            }
            if (layer.setRadius) {
                layer.setRadius(layer.options.radius - 5);
                return;
            }
            return;
        }
    };

    private getSelectedLayers = () =>{
        const SelectedLayers = new Array<FeatureLayer>();
        this.featureGroups.forEach(featureGroup => {
            featureGroup.eachLayer((layer: any) => {
                if ((layer.editEnabled && layer.editEnabled()) ||
                    (layer.dragging && layer.dragging.enabled && layer.dragging.enabled())) {
                    SelectedLayers.push(layer);
                }
            });
        });
        return SelectedLayers;
    };

    private layerIsEditablePolylineOrPolygon = (layer: any): boolean => {
        return this.layerIsPolylineOrPolygon(layer) && layer.enableEdit && layer.disableEdit;
    };

    private layerIsEditablePoint = (layer: any): boolean => {
        return this.layerIsPoint(layer) && layer.dragging;
    };

    private editTools = () => {
        return (this.innerMap as any).editTools;
    };
}
import { AutoDismissFlag, FlagGroup } from "@atlaskit/flag";
import {
  LayoutManager,
  modeGenerator,
  NavigationProvider,
  ThemeProvider,
} from "@atlaskit/navigation-next";
import * as H from "history";
import { t } from "i18next";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { compose } from "recompose";
import Breadcrumbs from "./components/Breadcrumbs";
import {
  AccountIcon,
  AdministrationIcon,
  FlagErrorIcon,
  HomeIcon,
  InspectionIcon,
  LicensesIcon,
  OverviewIcon,
  ProjectIcon,
  ResourcesIcon,
  SettingsIcon,
  StructureIcon,
  TunnelCloudIcon,
  UsersIcon,
} from "./components/Icons";
import GlobalNavigation from "./components/navigation/GlobalNavigation";
import ProductNavigation from "./components/navigation/ProductNavigation";
import AuthenticatedUserRoute from "./components/routing/AuthenticatedUserRoute";
import DefaultRoute from "./components/routing/DefaultRoute";
import Constants from "./helpers/Constants";
import IBreadcrumbLink from "./helpers/IBreadcrumbLink";
import INavigationLink from "./helpers/INavigationLink";
import IRouteParams from "./helpers/IRouteParams";
import Policies from "./helpers/Policies";
import RouteParser from "./helpers/RouteParser";
import Routes from "./helpers/Routes";
import LoginModal from "./modals/LoginModal";
import AboutPage from "./pages/AboutPage";
import AdministrationAccountOverviewPage
  from "./pages/AdministrationAccountOverviewPage";
import AdministrationAccountsPage from "./pages/AdministrationAccountsPage";
import AdministrationGroupPage from "./pages/AdministrationGroupPage";
import AdministrationLicensePage from "./pages/AdministrationLicensePage";
import AdministrationLicensesPage from "./pages/AdministrationLicensesPage";
import AdministrationPage from "./pages/AdministrationPage";
import AdministrationResourcePage from "./pages/AdministrationResourcePage";
import AdministrationResourcesPage from "./pages/AdministrationResourcesPage";
import AdministrationUserPage from "./pages/AdministrationUserPage";
import AdministrationUsersPage from "./pages/AdministrationUsersPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import HomePage from "./pages/HomePage";
import InspectionAnalysisInfra3dPage
  from "./pages/InspectionAnalysisInfra3dPage";
import InspectionAnalysisOverlayMapsPage
  from "./pages/InspectionAnalysisOverlayMapsPage";
import InspectionAnalysisSideBySideMapsPage
  from "./pages/InspectionAnalysisSideBySideMapsPage";
import InspectionAnalysisSingleBlockBasedChartsPage
  from "./pages/InspectionAnalysisSingleBlockBasedChartsPage";
import InspectionAnalysisSingleCadExportPage
  from "./pages/InspectionAnalysisSingleCadExportPage";
import InspectionAnalysisSingleFeatureBasedChartsPage
  from "./pages/InspectionAnalysisSingleFeatureBasedChartsPage";
import InspectionAnalysisSingleMapPage from "./pages/InspectionAnalysisSingleMapPage";
import InspectionAnalysisSingleNumericalPage
  from "./pages/InspectionAnalysisSingleNumericalPage";
import InspectionPage from "./pages/InspectionPage";
import InspectionTaskPage from "./pages/InspectionTaskPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import ProjectOverviewPage from "./pages/ProjectOverviewPage";
import ProjectSettingsPage from "./pages/ProjectSettingsPage";
import ProjectsPage from "./pages/ProjectsPage";
import SettingsPage from "./pages/SettingsPage";
import StructureOverviewPage from "./pages/StructureOverviewPage";
import StructuresPage from "./pages/StructuresPage";
import UserProfilePage from "./pages/UserProfilePage";
import authService from "./services/AuthService";
import FlagService, { FlagType, IFlag } from "./services/FlagService";
import homePageService from "./services/HomePageService";
import Logger from "./services/Logger";
import NotificationService from "./services/NotificationService";
import FieldBrowserSupport from "./helpers/FieldBrowserSupport";
import StructureMeasurementFilesPage from "./pages/StructureMeasurementFilesPage";

export interface IRoutingContainerProps extends RouteComponentProps<IRouteParams> {}

export interface IRoutingContainerState {
  accountName: string;
  flags: IFlag[];
  openDrawer?: "search" | "create";
  projectName: string;
  structureName: string;
  structureItemName: string;
  userName: string;
  structureOverviewPageReloader?: () => void;
}

class RoutingContainer extends React.Component<
  IRoutingContainerProps,
  IRoutingContainerState
> {
  webApiFrame: HTMLIFrameElement | null;
  constructor(props: IRoutingContainerProps) {
    super(props);
    Logger.info(this.toString());
    this.state = {
      accountName: "",
      flags: [],
      openDrawer: undefined,
      projectName: "",
      structureName: "",
      structureItemName: "",
      userName: "",
      structureOverviewPageReloader: undefined,
    };
    authService.registerSignedInHandler(userId =>
      NotificationService.initialize(userId)
      );
    authService.registerCookieUpdateHandler(this.forcedCookieUpdateHandler);
    authService.registerForcedSignOutHandler(this.handleForcedSignOut);
    NotificationService.registerNotificationReceived(notification =>
      FlagService.addInfo("Notification", notification.message, notification.tag)
    );
    NotificationService.initialize(authService.userName);
    FlagService.initialize(this.addFlag, this.dismissAllFlags);
}

  public componentDidCatch( error : Error, info : React.ErrorInfo) {
    this.addFlag({
      description: "internal error",
      icon: FlagErrorIcon,
      id: Date.now().toString(),
      title: error.name,
      type: FlagType.Error,
    })
    Logger.error(error.message, info.componentStack.toString());
  }

  public render() {
    return (
      <ThemeProvider
        theme={{
          context: RouteParser.isUserTopLevelRoute(this.props.location)
            ? "product"
            : "container",
          mode: RouteParser.isUserTopLevelRoute(this.props.location)
            ? modeGenerator({
                product: {
                  text: Constants.colorForegroundM3white,
                  background: Constants.colorForegroundM2dark,
                },
              })
            : modeGenerator({
                product: {
                  text: Constants.colorBackgroundM2,
                  background: Constants.colorBackgroundM3,
                },
              }),
        }}
      >
        <div id={"page_ready"} style={{height:"100%"}}>
          <LoginModal location={this.props.location}/>
          {this.showNavigation(this.props.location) && (
            <NavigationProvider>
              <LayoutManager
                globalNavigation={this.GlobalNavigation}
                productNavigation={this.ProductNavigation}
              >
                <div className="routingContainer">
                  {this.showNavigation(this.props.location) && (
                    <Breadcrumbs
                      navLinks={this.createBreadcrumbLinks(this.props.location)}
                    />
                  )}
                  {this.createRoutes()}
                </div>
              </LayoutManager>
            </NavigationProvider>
          )}

          {!this.showNavigation(this.props.location) && (
            <div className="routingContent">
              <Route {...Routes.login} component={this.createLoginPage} />
            </div>
          )}
          <div id={"id-flag-group"}>
            {this.createFlagGroup()}
          </div>
          <div>
            <iframe title={"web_api"} id={"web_api"} sandbox="allow-scripts allow-same-origin" ref={(f) => {
              this.webApiFrame = f}} hidden={true}/>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  private GlobalNavigation = () => (
    <GlobalNavigation
      userName={authService.userName}
      isFieldMode={FieldBrowserSupport.isOfflineMode()}
    />
  );

  private ProductNavigation = () => (
    <ProductNavigation
      userName={authService.userName}
      navLinkHeader={this.createNavigationHeaderLink(this.props.location)}
      navLinks={this.createNavigationLinks(this.props.location)}
      navLinksAdmin={this.createAccountAdminNavigationLinks(this.props.location)}
      isTopoLevelRoute={RouteParser.isUserTopLevelRoute(this.props.location)}
    />
  );

  private structureOverviewPageReloader = (func: () => void): void => {
    this.setState({ structureOverviewPageReloader: func });
  };

  private createRoutes = () => {
    homePageService.setRecentHomePageElements(this.props.history.location);

    return (
      <div className="routingContent">
        <Switch>
          <DefaultRoute component={this.createHomePage} {...Routes.home} />

           {/*top level routes */}
          <Route {...Routes.about} component={this.createAboutPage} />
          <Route {...Routes.cookiePolicies} component={this.createCookiePolicyPage} />
          <AuthenticatedUserRoute {...Routes.userprofile} component={this.createUserProfilePage}/>

          <AuthenticatedUserRoute {...Routes.projects} component={this.createProjectsPage} />
          <AuthenticatedUserRoute {...Routes.structures} component={this.createStructuresPage} />
          <AuthenticatedUserRoute {...Routes.inspections} component={this.createInspectionPage} />
          <AuthenticatedUserRoute {...Routes.settings} component={this.createSettingsPage} />

          <AuthenticatedUserRoute {...Routes.users} component={this.createAdministrationUsersPage} />
          <AuthenticatedUserRoute {...Routes.user} component={this.createAdministrationUserPage} />
          <AuthenticatedUserRoute {...Routes.group} component={this.createAdministrationGroupPage} />
          <AuthenticatedUserRoute {...Routes.resources} component={this.createAdministrationResourcesPage} />
          <AuthenticatedUserRoute {...Routes.resource} component={this.createAdministrationResourcePage} />
          <AuthenticatedUserRoute {...Routes.licences} component={this.createAdministrationLicensesPage} />
          <AuthenticatedUserRoute {...Routes.licence} component={this.createAdministrationLicensePage} />

          {/* project level routes */}
          <AuthenticatedUserRoute {...Routes.project} component={this.createProjectOverviewPage} />
          <AuthenticatedUserRoute {...Routes.projectStructures} component={this.createStructuresPage} />
          <AuthenticatedUserRoute {...Routes.projectInspections} component={this.createInspectionPage} />
          <AuthenticatedUserRoute {...Routes.projectSettings} component={this.createProjectSettingsPage} />

          {/* structure measurement level route */}
          <AuthenticatedUserRoute {...Routes.projectStructureMeasurementFiles} component={this.createStructureMeasurementFilesPage} />

          {/* structure level routes */}
          <AuthenticatedUserRoute {...Routes.projectStructure} component={this.createStructureOverviewPage} />
          <AuthenticatedUserRoute {...Routes.projectStructureInspections} component={this.createInspectionPage} />

          {/* lowest level routes */}
          <AuthenticatedUserRoute {...Routes.inspectionTask} component={this.createInspectionTaskPage} />
          <AuthenticatedUserRoute {...Routes.singleNumerical} component={this.createSingleNumericalPage} />
          <AuthenticatedUserRoute {...Routes.singleCadExport} component={this.createSingleCadExportPage} />
          <AuthenticatedUserRoute {...Routes.overlayMaps} component={this.createOverlayMapsPage} />
          <AuthenticatedUserRoute {...Routes.sideBySideMaps} component={this.createSideBySideMapsPage} />
          <AuthenticatedUserRoute {...Routes.singleMap} component={this.createSingleMapPage} />
          <AuthenticatedUserRoute {...Routes.singleFeatureBasedCharts} component={this.createSingleFeatureBasedChartsPage} />
          <AuthenticatedUserRoute {...Routes.singleBlockBasedCharts} component={this.createSingleBlockBasedChartsPage} />
          <AuthenticatedUserRoute {...Routes.infra3d} component={this.createInfra3dPage} />

          {/* admin routes */}
          <AuthenticatedUserRoute {...Routes.administration} component={this.createAdministrationPage}/>
          <AuthenticatedUserRoute {...Routes.administrationAccounts} component={this.createAdministrationAccountsPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccount} component={this.createAdministrationAccountOverviewPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountUsers} component={this.createAdministrationUsersPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountUser} component={this.createAdministrationUserPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountGroup} component={this.createAdministrationGroupPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountResources} component={this.createAdministrationResourcesPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountResource} component={this.createAdministrationResourcePage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountLicenses} component={this.createAdministrationLicensesPage} />
          <AuthenticatedUserRoute {...Routes.administrationAccountLicense} component={this.createAdministrationLicensePage} />

          {/* TODO: Notfound page*/}
          <Route exact={true} component={this.createNotFoundPage} />
        </Switch>
      </div>
    );
  };

  private createLoginPage = (routeProps: any) => {
    return <LoginPage {...routeProps} />;
  };

  private createAboutPage = (routeProps: any) => {
    return <AboutPage {...routeProps} />;
  };

  private createCookiePolicyPage = (routeProps: any) => {
    return <CookiePolicyPage {...routeProps} />;
  };

  private createNotFoundPage = (routeProps: any) => {
    return <NotFoundPage {...routeProps}>Page not found</NotFoundPage>;
  };

  private createUserProfilePage = (routeProps: any) => {
    return <UserProfilePage {...routeProps} />;
  };

  private createHomePage = (routeProps: any) => {
    return <HomePage {...routeProps} />;
  };

  private createProjectsPage = (routeProps: any) => {
    return <ProjectsPage {...routeProps} />;
  };

  private createStructuresPage = (routeProps: any) => {
    return (
      <StructuresPage {...routeProps} setLocationNames={this.updateUserLocationNames} />
    );
  };

  private createInspectionPage = (routeProps: any) => {
    return (
      <InspectionPage {...routeProps} setLocationNames={this.updateUserLocationNames} />
    );
  };

  private createSettingsPage = (routeProps: any) => {
    return <SettingsPage {...routeProps} />;
  };

  private createProjectOverviewPage = (routeProps: any) => {
    return (
      <ProjectOverviewPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createProjectSettingsPage = (routeProps: any) => {
    return (
      <ProjectSettingsPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createStructureMeasurementFilesPage = (routeProps: any) => {
    return (
      <StructureMeasurementFilesPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createStructureOverviewPage = (routeProps: any) => {
    return (
      <StructureOverviewPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
        reloadTrigger={this.structureOverviewPageReloader}
      />
    );
  };

  private createInspectionTaskPage = (routeProps: any) => {
    return (
      <InspectionTaskPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createSingleNumericalPage = (routeProps: any) => {
    return (
      <InspectionAnalysisSingleNumericalPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createSingleCadExportPage = (routeProps: any) => {
    return (
      <InspectionAnalysisSingleCadExportPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createOverlayMapsPage = (routeProps: any) => {
    return (
      <InspectionAnalysisOverlayMapsPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createSideBySideMapsPage = (routeProps: any) => {
    return (
      <InspectionAnalysisSideBySideMapsPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createSingleMapPage = (routeProps: any) => {
    return (
      <InspectionAnalysisSingleMapPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createSingleFeatureBasedChartsPage = (
    routeProps: any
  ) => {
    return (
      <InspectionAnalysisSingleFeatureBasedChartsPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createSingleBlockBasedChartsPage = (routeProps: any) => {
    return (
      <InspectionAnalysisSingleBlockBasedChartsPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createInfra3dPage = (routeProps: any) => {
    return (
      <InspectionAnalysisInfra3dPage
        {...routeProps}
        setLocationNames={this.updateUserLocationNames}
      />
    );
  };

  private createAdministrationPage = (routeProps: any) => {
    return <AdministrationPage {...routeProps} />;
  };

  private createAdministrationAccountsPage = (routeProps: any) => {
    return <AdministrationAccountsPage {...routeProps} />;
  };

  private createAdministrationAccountOverviewPage = (routeProps: any) => {
    return (
      <AdministrationAccountOverviewPage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationUsersPage = (routeProps: any) => {
    return (
      <AdministrationUsersPage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationUserPage = (routeProps: any) => {
    return (
      <AdministrationUserPage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationGroupPage = (routeProps: any) => {
    return (
      <AdministrationGroupPage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationResourcesPage = (routeProps: any) => {
    return (
      <AdministrationResourcesPage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationResourcePage = (routeProps: any) => {
    return (
      <AdministrationResourcePage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationLicensesPage = (routeProps: any) => {
    return (
      <AdministrationLicensesPage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private createAdministrationLicensePage = (routeProps: any) => {
    return (
      <AdministrationLicensePage
        {...routeProps}
        setLocationName={this.updateAdministrationLocation}
      />
    );
  };

  private showNavigation = (location: H.Location): boolean => {
    return this.props.location.pathname !== Routes.login.path;
  };

  private createNavigationHeaderLink = (location: H.Location): INavigationLink => {
    if (RouteParser.isAdministrationTopLevelRoute(location)) {
      return {
        icon: AdministrationIcon,
        route: Routes.administration,
        text: "Administration",
      };
    }

    if (RouteParser.isAdministrationAccountLevelRoute(location)) {
      const accountId = RouteParser.getAccountId(location);
      return {
        icon: AccountIcon,
        route: Routes.administrationAccount.patch(accountId),
        text: this.state.accountName,
      };
    }

    if (RouteParser.isUserTopLevelRoute(location)) {
      return {
        icon: TunnelCloudIcon,
        route: Routes.home,
        text: t("labelTunnelCloud"),
      };
    }

    if (RouteParser.isUserProjectLevelRoute(location)) {
      const projectId = RouteParser.getProjectId(location);
      return {
        icon: ProjectIcon,
        route: Routes.project.patch(projectId),
        text: this.state.projectName,
      };
    }

    if (RouteParser.isUserStructureLevelRoute(location)) {
      const projectId = RouteParser.getProjectId(location);
      const structureId = RouteParser.getStructureId(location);
      return {
        icon: StructureIcon,
        route: Policies.isValidPolicy(Policies.toSeeStructures) ? Routes.projectStructure.patch(projectId, structureId) : Routes.project.patch(projectId),
        text: this.state.structureName,
      };
    }

    return {
      icon: TunnelCloudIcon,
      route: Routes.home,
      text: t("labelTunnelCloud"),
    };
  };

  private createNavigationLinks = (location: H.Location): INavigationLink[] => {
    if (RouteParser.isAdministrationTopLevelRoute(location)) {
      return this.createTopLevelAdministrationNavLinks();
    }
    else if (RouteParser.isAdministrationAccountLevelRoute(location)) {
      const accountId = RouteParser.getAccountId(location);
      return this.createAccountLevelAdministrationNavLinks(accountId);
    }

    if (RouteParser.isUserTopLevelRoute(location)) {
      return this.createTopLevelUserNavLinks();
    }

    if (RouteParser.isUserProjectLevelRoute(location)) {
      const lineId = RouteParser.getProjectId(location);
      return this.createProjectLevelUserNavLinks(lineId);
    }

    if (RouteParser.isUserStructureLevelRoute(location)) {
      const lineId = RouteParser.getProjectId(location);
      const trackId = RouteParser.getStructureId(location);
      return this.createStructureLevelUserNavLinks(lineId, trackId);
    }

    return [];
  };

  private createAccountAdminNavigationLinks = (
    location: H.Location
  ): INavigationLink[] => {
    if (!RouteParser.isUserTopLevelRoute(location)) {
      return [];
    }
    if (FieldBrowserSupport.isOfflineMode())
    {
      return [];
    }
    const links: any = [
      {
        icon: UsersIcon,
        route: Routes.users,
        text: t("navigationUsers"),
      },
      {
        icon: ResourcesIcon,
        route: Routes.resources,
        text: t("navigationProjects"),
      },
      {
        icon: LicensesIcon,
        route: Routes.licences,
        text: t("navigationLicenses"),
      },
    ];

    return this.parseLinks(links);
  };

  private createBreadcrumbLinks = (location: H.Location): IBreadcrumbLink[] => {
    const links = [];
    if (RouteParser.isAdministrationRoute(location)) {
      links.push({
        route: Routes.administration,
        text: t("labelAdministration"),
      });
      if (RouteParser.isAdministrationAccountLevelRoute(location)) {
        const accountId = RouteParser.getAccountId(location);
        links.push({
          route: Routes.administrationAccount.patch(accountId),
          text: `${t("labelAccount")} ${this.state.accountName}`,
        });
      }
    }
    if (RouteParser.isUserRoute(location)) {
      links.push({
        route: Routes.home,
        text: t("labelTunnelCloud"),
      });
      if (
        RouteParser.isUserProjectLevelRoute(location) ||
        RouteParser.isUserStructureLevelRoute(location)
      ) {
        const projectId = RouteParser.getProjectId(location);
        links.push({
          route: Routes.project.patch(projectId),
          text: `${t("navigationProject")}: ${this.state.projectName}`,
        });
        if (RouteParser.isUserStructureLevelRoute(location)) {
          const structureId = RouteParser.getStructureId(location);
          links.push({
            route: Policies.isValidPolicy(Policies.toSeeStructures) ? Routes.projectStructure.patch(projectId, structureId) : undefined,
            text: `${t("navigationStructure")}: ${this.state.structureName}`,
          });
          if (RouteParser.isTaskRoute(location)) {
            links.push({
              text: `${t("navigationStructureTask")}: ${this.state.structureItemName}`,
            });
          }
          else if (RouteParser.isAnalysisRoute(location)) {
            links.push({
              text: `${t("navigationStructureAnalysis")}: ${this.state.structureItemName}`,
            });
          }
          else if (RouteParser.isUserStructureMeasurementFilesRoute(location)){
            links.push({
              text: `${t("navigationStructureMeasurementFiles")}: ${this.state.structureItemName}`,
            });
          }
        }
      }
    }

    return links;
  };

  private createTopLevelUserNavLinks = (): INavigationLink[] => {
    if (FieldBrowserSupport.isOfflineMode()){
      const links: INavigationLink[] = [
        {
          icon: HomeIcon,
          route: Routes.home,
          text: t("navigationHome"),
        },
        {
          icon: ProjectIcon,
          route: Routes.projects,
          text: t("navigationProjects"),
        },
        {
          icon: StructureIcon,
          route: Routes.structures,
          text: t("navigationStructures"),
        },
      ];
      return this.parseLinks(links)
    }
    else {
      const links: INavigationLink[] = [
        {
          icon: HomeIcon,
          route: Routes.home,
          text: t("navigationHome"),
        },
        {
          icon: ProjectIcon,
          route: Routes.projects,
          text: t("navigationProjects"),
        }
      ]

      if (Policies.isValidPolicy(Policies.toSeeStructures)){
        links.push(
          {
            icon: StructureIcon,
            route: Routes.structures,
            text: t("navigationStructures"),
          }
        )
      }
      
      links.push(
        {
          icon: InspectionIcon,
          route: Routes.inspections,
          text: t("navigationInspection"),
        },
        {
          icon: SettingsIcon,
          route: Routes.settings,
          text: t("navigationSettings"),
        }
      )

      return this.parseLinks(links);
    }
  };

  private createProjectLevelUserNavLinks = (projectId: string): INavigationLink[] => {
    const links = [
      {
        icon: OverviewIcon,
        route: Routes.project.patch(projectId),
        text: t("navigationOverview"),
      }
    ];

    if (Policies.isValidPolicy(Policies.toSeeStructures)){
      links.push(
        {
          icon: StructureIcon,
          route: Routes.projectStructures.patch(projectId),
          text: t("navigationStructures"),
        }
      )
    }

    links.push(
      {
        icon: InspectionIcon,
        route:  Routes.projectInspections.patch(projectId),
        text: t("navigationInspection"),
      }
    )

    if(!FieldBrowserSupport.isOfflineMode()){
      links.push({
        icon: SettingsIcon,
        route: Routes.projectSettings.patch(projectId),
        text: t("navigationSettings"),
      })
    }
    return this.parseLinks(links);
  };

  private createStructureLevelUserNavLinks = (
    projectId: string,
    structureId: string
  ): INavigationLink[] => {
    const links: any = 
     Policies.isValidPolicy(Policies.toSeeStructures) ? [
      {
        icon: OverviewIcon,
        route: Routes.projectStructure.patch(projectId, structureId),
        text: t("navigationOverview"),
      },
      {
        icon: InspectionIcon,
        route: Routes.projectStructureInspections.patch(projectId, structureId),
        text: t("navigationInspection"),
      }
    ] : [
      {
        icon: InspectionIcon,
        route: Routes.projectStructureInspections.patch(projectId, structureId),
        text: t("navigationInspection"),
      }
    ];

    return this.parseLinks(links);
  };

  private createTopLevelAdministrationNavLinks = (): INavigationLink[] => {
    const links:any = [
      {
        icon: AccountIcon,
        route: Routes.administrationAccounts,
        text: t("labelAccounts"),
      },
    ];

    return this.parseLinks(links);
  };

  private createAccountLevelAdministrationNavLinks = (
    accountId: string
  ): INavigationLink[] => {
    const links:any = [
      {
        icon: OverviewIcon,
        route: Routes.administrationAccount.patch(accountId),
        text: t("labelOverview"),
      },
      {
        icon: UsersIcon,
        route: Routes.administrationAccountUsers.patch(accountId),
        text: t("labelUsers"),
      },
      {
        icon: ResourcesIcon,
        route: Routes.administrationAccountResources.patch(accountId),
        text: t("labelProjects"),
      },
      {
        icon: LicensesIcon,
        route: Routes.administrationAccountLicenses.patch(accountId),
        text: t("labelLicences"),
      },
    ];

    return this.parseLinks(links);
  };

  private updateUserLocationNames = (projectName: string, structureName?: string, structureItemName?: string): void => {
    this.setState({
      accountName: "",
      projectName: projectName || "",
      structureName: structureName || "",
      structureItemName: structureItemName || "",
    });
  };

  private updateAdministrationLocation = (accountName: string): void => {
    this.setState({
      accountName,
      projectName: "",
      structureName: "",
    });
  };

  private parseLinks = (links: INavigationLink[]): INavigationLink[] => {
    const parsedLinks: INavigationLink[] = [];

    links.forEach(
      link => {
        if (Policies.isValidPolicy(link.route.policy)){
          parsedLinks.push({
            icon: link.icon,
            isSelected: this.props.location.pathname === link.route.path,
            route:  link.route,
            text: link.text,
          })
        }
      }
    );
    return parsedLinks;
  };

  private createFlagGroup = () => {
    const { flags } = this.state;
    return (
      <FlagGroup onDismissed={this.dismissFlag}>
        {flags.map(flag => {
          let description=this.isNoneEmptyString(flag.description) ? flag.description : " ";
          return (
            <AutoDismissFlag
              appearance={flag.type}
              id={flag.id}
              key={flag.id}
              icon={<flag.icon />}
              title={flag.title}
              description={description}
            />
          );
        })}
      </FlagGroup>
    );
  };

  private isNoneEmptyString = (value: any) => {
    if (typeof value === 'string' && value.length > 0 ) {
      return true;
    }
    else {
      return false;
    }
  };

  private addFlag = (flag: IFlag) => {
    const { flags } = this.state;
    if(!this.isNoneEmptyString(flag.title)){
      Logger.warning("Empty title in addFlag", "RoutingContainer");
      return;
    }

    const updatedFlags = flags.filter(f => f.id !== flag.id).concat([flag]);
    this.setState({ flags: updatedFlags });
  };

  private dismissFlag = () => {
    this.setState(prevState => ({
      flags: prevState.flags.slice(1),
    }));
  };

  private dismissAllFlags = () => {
    this.setState({ flags: [] });
  };

  private handleForcedSignOut = (reason: string) => {
    this.props.history.push(Routes.login.path);
    FlagService.addError(t("labelConcurrentLogin"), t("textConcurrentLogin"));
  };

  private forcedCookieUpdateHandler = () =>
  {
    if(this.webApiFrame){
      this.webApiFrame.setAttribute("src", authService.getServiceUrl())
    }
  };
}

export default compose<any, any>(
  withRouter,
  withNamespaces()
)(RoutingContainer);

import { Feature } from "geojson";

export default class CommitInspectionDrawingEditingRequest {
    public structureId: string;
    public measurementId: string;
    public taskId: string;
    public created: Feature[];
    public updated: Feature[];
    public deleted: string[];
    public force: boolean;

    constructor() {
        this.created = [];
        this.updated = [];
        this.deleted = [];
    }
}
import CatalogData from '../components/CatalogData';
import InspectionDrawing from '../components/InspectionDrawing';
import InspectionTaskSummary from '../components/InspectionTaskSummary';
import InspectionTileLayer from '../components/InspectionTileLayer';
import MapData from '../maps/MapData';
import OfflineStatus from "../components/OfflineStatus";
import StatusDTO from '../components/StatusDTO';

export default class InspectionTaskPageData {
    public inspectionTask: InspectionTaskSummary;
    public catalog: CatalogData;
    public theoreticalSectionsMapData: MapData;
    public blockDefinitionMapData: MapData;
    public sectorDefinitionMapData: MapData;
    public inspectionDrawing: InspectionDrawing;
    public tileLayer: InspectionTileLayer;
    public additionalTileLayers: InspectionTileLayer[];
    public additionalInspectionDrawings: InspectionDrawing[];
    public classificationMapData: MapData;
    public offlineStatus?: OfflineStatus;
    public statuses: StatusDTO[];

    constructor(data: InspectionTaskPageData){
        this.inspectionTask = data.inspectionTask;
        this.catalog = new CatalogData(data.catalog);
        this.theoreticalSectionsMapData = data.theoreticalSectionsMapData;
        this.blockDefinitionMapData = data.blockDefinitionMapData;
        this.sectorDefinitionMapData = data.sectorDefinitionMapData;
        this.inspectionDrawing = data.inspectionDrawing;
        this.tileLayer = data.tileLayer;
        this.additionalTileLayers = data.additionalTileLayers;
        this.additionalInspectionDrawings = data.additionalInspectionDrawings;
        this.classificationMapData = data.classificationMapData;
        if (data.offlineStatus) {
            this.offlineStatus = data.offlineStatus;
        }
        this.statuses = data.statuses;
    }
}
import { RouteComponentProps } from "react-router";
import IRouteParams from "../helpers/IRouteParams";
import StructureMeasurementFilesPageData from "../dto/pages/StructureMeasurementFilesPageData";
import React from "react";
import PageTitleItem from "../components/PageTitleItem";
import Spinner from "@atlaskit/spinner";
import TitleItem from "../components/TitleItem";
import AuthService from "../services/AuthService";
import { t } from "i18next";
import { compose } from "recompose";
import { withNamespaces } from "react-i18next";
import Table, { TextTableColumn, DateTimeTableColumn, SingleIconActionTableColumn, TableItemAction } from "../components/Table";
import StructureMeasurementFile from "../dto/components/StructureMeasurementFile";
import DownloadIcon from '@atlaskit/icon/glyph/download';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
//import { reactPlugin } from "../AppInsights";
import { ais } from "../services/AppInsightsService";



export interface IStructureMeasurementFilesPageProps extends RouteComponentProps<IRouteParams> {
    setLocationNames: (projectName: string, structure: string, structureItemName: string) => void;
}

export interface IStructureMeasurementFilesPageState {
    data?: StructureMeasurementFilesPageData
    images?: StructureMeasurementFile[];
    others?: StructureMeasurementFile[];
    waiting: boolean
}


class StructureMeasurementFilesPage extends React.Component<IStructureMeasurementFilesPageProps, IStructureMeasurementFilesPageState> {
    constructor(props: IStructureMeasurementFilesPageProps) {
        super(props);
        this.state = { waiting:true };
    }

    public render() {
        const { data, others, images, waiting } = this.state;

        return (
            <div className="page">
                <PageTitleItem title={t("pageTitleStructureMeasurementFilesPage")}  testID={"structure-overview-page-title"}/>
                <div>
                    { waiting && ( 
                        <Spinner size={"large"} />
                    )}
                </div> 
                <div>
                    <TitleItem title={t("sectionTitleOtherInspectionFiles")} />
                    <div className="tableContainer">
                        <Table
                            data={data && others}
                            columns={[
                                new TextTableColumn<StructureMeasurementFile>(t("labelFilesChainage"), item => item.chainage.toString()),
                                new TextTableColumn<StructureMeasurementFile>(t("labelFilesName"), item => item.name),
                                new TextTableColumn<StructureMeasurementFile>(t("labelFileOperator"), item => item.operator),
                                new DateTimeTableColumn<StructureMeasurementFile>(t("labelDateTime"), item => new Date(item.dateTime)),
                                new SingleIconActionTableColumn<StructureMeasurementFile>(t("labelDownload"),<DownloadIcon label={""} size={"small"} />, () => {
                                    return new TableItemAction("", this.downloadAttachment, false)
                                }),
                            ]}
                        />
                    </div>
                    <TitleItem title={t("sectionTitleImagesInspectionFiles")} />
                    <div className="tableContainer">
                        <Table
                            data={data && images}
                            columns={[
                                new TextTableColumn<StructureMeasurementFile>(t("labelFilesChainage"), item => item.chainage.toString()),
                                new TextTableColumn<StructureMeasurementFile>(t("labelFilesName"), item => item.name),
                                new TextTableColumn<StructureMeasurementFile>(t("labelFileOperator"), item => item.operator),
                                new DateTimeTableColumn<StructureMeasurementFile>(t("labelDateTime"), item => new Date(item.dateTime)),
                                new SingleIconActionTableColumn<StructureMeasurementFile>(t("labelDownload"),<DownloadIcon label={""} size={"small"} />, () => {
                                    return new TableItemAction("", this.downloadAttachment, false)
                                }),
                            ]}
                        />
                    </div>
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public reloadData = async () => {
        await this.loadData();
    };

    public componentWillUnmount() {
        AuthService.cancelAllRequests();
    }
    private downloadAttachment = async(fileInfo: StructureMeasurementFile) => {
        const { data } = this.state;
        data && await AuthService.downloadBinaryFile(`structuremeasurement/attachment/${data.measurementSummary.project.id}/${fileInfo.fileGuid}`, `${fileInfo.filename}`);
    }

    private loadData = async () => {
        try {
            const data = await AuthService.get<StructureMeasurementFilesPageData>(`structuremeasurement/${this.props.match.params.projectId}/${this.props.match.params.structureMeasurementId}/files`);
            this.setState({ data, waiting:false });
            this.props.setLocationNames(data.measurementSummary.project.name, data.measurementSummary.structure.name, data.measurementSummary.name);
            this.splitData();
        } catch (error) {
            this.setState({waiting:false,})
        }
    }
    private splitData() {
        const { data } = this.state;
        if (data){
            let images: StructureMeasurementFile[] = [];
            let others: StructureMeasurementFile[] = [];
            const files = data.files;
            for (let index = 0; index < files.length; index++) {
                const element = files[index];
                if (this.isImage(element.filename))
                    images.push(element);
                else
                    others.push(element);
            }
            this.setState({images, others});
        }
    }
    private isImage = (filename: string)=>
    {
        const imgs: Array<string> =  ["jpg","jpeg","png","tiff","gif","svg","ico"];
        const fileType = filename.split('.').pop();
        return fileType && imgs.includes(fileType)? true: false;
    }
}
export default compose(withNamespaces())(withAITracking(ais.reactPlugin, StructureMeasurementFilesPage,"StructureMeasurementFilesPage","AppInsight"));
